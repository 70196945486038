import styled from 'styled-components';
import { TopNav } from '../../../components/common';

export const NavRoot = styled(TopNav)`
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 1rem;
  align-items: center;
`;

export const DatePickerContainer = styled.div`
  width: 12rem;
`;
