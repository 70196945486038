import React from "react";
import { Text, SlideWrapper, SlideContent } from "./Slides.styled";
import { ButtonWhite } from "../../../../components/common";
import VimeoPlayer from "@u-wave/react-vimeo";

export function Slide2(props) {
  return (
    <SlideWrapper>
      <SlideContent>
        <Text></Text>
        <Text>
          In today's “always-on” society a lot of people live in a near-constant
          state of stress
        </Text>
        <ButtonWhite
          onClick={props.nextSlide}
          style={{ width: "100%", maxWidth: 300 }}
        >
          Next
        </ButtonWhite>
      </SlideContent>
    </SlideWrapper>
  );
}
