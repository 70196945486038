import React, { memo } from "react";
import PropTypes from "prop-types";
import { Heading, Warning } from "../DailyCheckIn.styled";
import EmotionLevelSelector from "./EmotionLevelSelector/EmotionLevelSelector";
import EmotionSelector from "./EmotionSelector/EmotionSelector";
import {
  checkInGroup as checkInGroupPropType,
  checkInItem as emotionPropType,
} from "../../../propTypes";

function getHeading(stateGroups, checkinMode) {
  if (checkinMode === "brainDump") {
    return "Record Your Brain Dump";
  }
  const positives = ["Very Positive", "Positive"];
  const positivesNotDisabled = stateGroups
    .filter((x) => positives.includes(x.title))
    .every((x) => x.disabled === false);

  const notPositivesDisabled = stateGroups
    .filter((x) => positives.includes(x.title) === false)
    .every((x) => x.disabled === true);

  return positivesNotDisabled && notPositivesDisabled
    ? "Record A Positive Reflection From The Last 24 Hours"
    : "How Are You Feeling?";
}

const EmotionStep = ({
  emotionSelected,
  emotionGroups,
  emotionGroup,
  emotionGroupSelected,
  selectedEmotion,
  checkinMode,
  emotionGroupWarning,
}) => {
  const heading = getHeading(emotionGroups, checkinMode);

  return (
    <>
      <Heading>{heading}</Heading>
      {checkinMode !== "brainDump" && (
        <>
          <EmotionLevelSelector
            emotionGroups={emotionGroups}
            emotionGroup={emotionGroup}
            emotionGroupSelected={emotionGroupSelected}
            checkinMode={checkinMode}
          />
          {emotionGroupWarning && (
            <Warning
              emotionIndex={
                emotionGroup
                  ? 4 - emotionGroups.findIndex((g) => g.id === emotionGroup.id)
                  : 0
              }
							colour={emotionGroup.colour}
            >
              {emotionGroupWarning}
            </Warning>
          )}
        </>
      )}
      {emotionGroup && (
        <EmotionSelector
          options={emotionGroup.terms}
          onClick={emotionSelected}
          selectedEmotion={selectedEmotion}
        />
      )}
    </>
  );
};

EmotionStep.propTypes = {
  emotionSelected: PropTypes.func.isRequired,
  emotionGroups: PropTypes.arrayOf(checkInGroupPropType).isRequired,
  emotionGroup: checkInGroupPropType,
  emotionGroupSelected: PropTypes.func.isRequired,
  selectedEmotion: emotionPropType,
};

export default memo(EmotionStep);
