import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { PageContent, Title, ButtonContainer } from './RewireMP.styled';
import { PageRoot, ButtonWhite } from '../../components/common';


const RewireMP = () => {
	const history = useHistory();

	const goToCourse = () => {
		history.push("/course/2")
	}

	return (
		<PageRoot>
			<PageContent>
				<Title>Here's how it works</Title>
				<iframe style={{ maxWidth: "100%", }} src="https://player.vimeo.com/video/567442302?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="600" height="338" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="LUME - Boost Happiness &amp;amp; Positivity"></iframe>
				<ButtonContainer>
					<ButtonWhite onClick={goToCourse}>Get Going</ButtonWhite>
				</ButtonContainer>
			</PageContent>
		</PageRoot>
	);
};

export default memo(RewireMP);