import React, { memo, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useHistory } from "react-router";
import { post } from "../../../../../actions/api";

const Audio = ({ elm, findScreen, course, recordInteraction }) => {
  const history = useHistory();
  const [intRec, setIntRec] = useState(false);
  const [recordedPlay, setRecordedPlay] = useState(false);

  const style = elm.style ? elm.style : {};
  const HOST =
    window.location.hostname === "localhost"
      ? "http://localhost:3000"
      : "https://api.lumeapp.io";

  const audioSrc =
    HOST +
    "/api/course/element/" +
    elm.id +
    "/" +
    elm.id +
    "." +
    elm.fileExtension +
    "?auth_token=" +
    localStorage.getItem("JWT");

  useEffect(() => {
    setIntRec(false);
  }, [elm.id]);

  const finished = async () => {
    if (elm.content.completeActivity) {
      await post("course/completerequired", {
        eventType: elm.content.completeActivity,
      });
    }

    if (elm.content.triggerFeedback && Number(elm.content.triggerFeedback)) {
      if (elm.linkToScreenId !== null) {
        findScreen(elm.linkToScreenId);
      }
      const next =
        elm.content.returnType === "screen"
          ? `/courseopen/${course.id}/${elm.content.returnId}`
          : elm.content.returnType === "suggestions"
          ? `/suggestions`
          : `/`;
      history.push(`/feedback/${elm.content.feedbackId}?next=${next}`);
    }
  };

  const audio = (
    <ReactPlayer
      url={audioSrc}
      width="100%"
      controls={true}
      height={40}
      config={{ file: { attributes: { controlsList: "nodownload" } } }}
      onProgress={(e) => {
        if (e.played === 1) {
          finished();
        }
      }}
      onPlay={() => {
        // record event if it's set to be recorded.
        if (Number(elm.content.progressMarker) && !recordedPlay) {
          setRecordedPlay(true);
          post("event", {
            event: {
              eventType: "progressMk",
              eventData: {
                name: elm.content.progressMarkerName,
                courseId: course.id,
                courseTitle: course.title,
                elmId: elm.id,
              },
            },
          });
        }

        if (!intRec) {
          setIntRec(true);
          recordInteraction(elm.id, true);
        }
      }}
    />
  );

  return <div style={style}>{audio}</div>;
};

export default memo(Audio);
