import React, { memo } from "react";
import PropTypes from "prop-types";
import { SectionMarker, SectionTitleText, Text } from "../Day.styled";
import SectionTitle from "../SectionTitle/SectionTitle";

const ProgressMkDay = ({ data, deleteClicked, courseOutlines }) => {
  const { eventTime, eventData } = data;
  const { courseTitle, name } = data.eventData;
  const course = courseOutlines.find((c) => c.id === eventData.courseId);
  const colour = course ? `#${course.colour}` : "#eee";

  return (
    <div>
      <SectionTitle
        time={eventTime}
        text={
          <>
            <SectionMarker colour={colour} />
            <SectionTitleText title={courseTitle}>
              {courseTitle}
            </SectionTitleText>
          </>
        }
        deleteClicked={deleteClicked}
      />
      {name && <Text>{name}</Text>}
    </div>
  );
};

ProgressMkDay.propTypes = {
  deleteClicked: PropTypes.func.isRequired,
};

export default memo(ProgressMkDay);
