import styled from 'styled-components';

export const ButtonContainer = styled.button`
  width: 8rem;
  height: 3rem;
  background-color: ${props => props.selected ? 'white' : props.emotion.colour};
  border-radius: 2rem;
  color: ${props => props.selected ? props.emotion.colour : 'white'};
  font-weight: bold;
  font-size: 0.9rem;
  position: relative;
  border: ${props => props.selected ? `2px solid ${props.emotion.colour}` : 'inherit'};

  &:hover {
    filter: brightness(95%);
  }
`;
