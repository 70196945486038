import React from "react";
import "../../../../css/ControlPanel.css";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from "recharts";

class CheckinInfluences extends React.Component {
  render() {
    if (!this.props.influenceTotals.length) {
      return null;
    }
    // convert them to an array formatted for rechart
    let infTotalArray = this.props.influenceTotals
      .filter((it) => it.term)
      .map((it) => {
        let data = {
          name: it.term,
        };
        // add all term group values
        Object.entries(it.termGroups).forEach((tg) => {
          data[tg[0]] = tg[1];
        });
        return data;
      });

    let subTitle = null;

    // If there's an inf filter we should remove the main inf (the one being filtered for) and add a title to explain
    if (this.props.dataFilter.inf !== "all") {
      infTotalArray.shift();
      if (infTotalArray.length) {
        subTitle = (
          <div>
            <h2 className="chartSubTitle">
              Influences Related To <b>{this.props.dataFilter.inf}</b>
            </h2>
            <p style={{ fontSize: 14 }}>
              When people selected <b>{this.props.dataFilter.inf}</b> they also
              selected these:
            </p>
          </div>
        );
      } else {
        subTitle = (
          <p style={{ fontSize: 18, textAlign: "center", marginBottom: 84 }}>
            <img
              src={require("../../../../imgs/noData.svg")}
              alt="Not enough data"
              style={{ marginBottom: 30, width: 150, marginTop: 100 }}
            />
            <br />
            No Other Influences
            <br />
            Related To <b>{this.props.dataFilter.inf}</b>
          </p>
        );
      }
    }

    // get only top 10
    infTotalArray = infTotalArray.slice(0, 10);

    // const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    // 	return <text x={x + width + 5} y={y + height - 2} fill="#666" textAnchor="left" dy={-6}>{value}</text>;
    // };

    //         <Bar dataKey="pv" stackId="a" fill="#8884d8" />
    // <Bar dataKey="uv" stackId="a" fill="#82ca9d" />

    const bars = Object.entries(this.props.influenceTotals[0].termGroups).map(
      (tg, i) => {
        return (
          <Bar
            key={i}
            dataKey={tg[0]}
            stackId="a"
            fill={this.props.stateGroupLookup[tg[0]].colour}
            onClick={(e) => {
              this.props.dataFilterSet({
                target: {
                  name: "inf",
                  value: e.name,
                },
              });
            }}
          />
        );
      }
    );

    return (
      <div>
        {subTitle}
        {infTotalArray.length ? (
          <ResponsiveContainer height={400}>
            <BarChart
              data={infTotalArray}
              layout="vertical"
              margin={{
                top: 20,
                right: 30,
                left: 80,
                bottom: 5,
              }}
              barSize={25}
            >
              <YAxis
                dataKey="name"
                scale="point"
                padding={{ top: 10, bottom: 10 }}
                legendType="none"
                type="category"
              />
              <XAxis type="number" style={{ display: "none" }} />
              {/* <Tooltip /> */}
              {bars}
            </BarChart>
          </ResponsiveContainer>
        ) : null}
      </div>
    );
  }
}

export default CheckinInfluences;
