import React, { memo } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Date, ContentBox, SectionContainer, DayContainer } from "./Day.styled";
import CheckInDay from "./CheckInDay/CheckInDay";
import GratitudeDay from "./GratitudeDay/GratitudeDay";
import RakDay from "./RakDay/RakDay";
import BreatheDay from "./BreatheDay/BreatheDay";
import ProgressMkDay from "./ProgressMkDay/ProgressMkDay";
import { groupCourseEvents } from "../../../../helpers/calendarHelpers";
import CourseReqDay from "./CourseReqDay/CourseReqDay";

const sectionLookup = {
  checkin: CheckInDay,
  gratitude: GratitudeDay,
  RAK: RakDay,
  breathe: BreatheDay,
  progressMk: ProgressMkDay,
  courseReqGroup: CourseReqDay,
};
const Day = ({ day, data, deleteEvent, editEvent, courseOutlines, style }) => {
  if (data == null) {
    return null;
  }

  const dayData = groupCourseEvents(data);
  const title = moment(day, "YYMMDD").format("Do MMM");
  const body = dayData.map((d, key) => {
    const Section = sectionLookup[d.eventType]
      ? sectionLookup[d.eventType]
      : () => <span>{d.eventType}</span>;
    return (
      <SectionContainer key={key} seperator={key !== data.length - 1}>
        <Section
          data={d}
          deleteClicked={deleteEvent ? () => deleteEvent(d) : null}
          editClicked={editEvent ? () => editEvent(d) : null}
          courseOutlines={courseOutlines}
        />
      </SectionContainer>
    );
  });

  return (
    <DayContainer data-day={day} style={style}>
      <ContentBox
        titleContent={
          <Date>
            <span>{title}</span>
          </Date>
        }
        body={body}
      />
    </DayContainer>
  );
};

Day.propTypes = {
  day: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteEvent: PropTypes.func.isRequired,
  editEvent: PropTypes.func.isRequired,
};

export default memo(Day);
