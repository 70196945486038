import styled from 'styled-components';

export const ContentRoot = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 7rem;
  gap: 0.5rem;
`;
