import React from 'react';
import { api } from '../actions/api';

class PasswordForgot extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			done: false
		};

	}

	componentDidMount() {

	}

	emailChange = (e) => {
		const email = e.target.value;
		this.setState({ email, emailError: "", formError: '', formErrorMsg: '' });
	}


	reset = (e) => {
		e.preventDefault();
		let t = this;

		if (!this.state.email) {
			this.setState({ formError: "is-danger", emailError: "Please enter your email address" });
			return false;
		}

		api("user/forgotPassword", "post", { email: this.state.email, password: this.state.password, token: this.state.token }, (d) => {
			if (d.message === "recovery email sent") {
				t.setState({ done: true });
			} else {
				t.setState({ formError: "is-danger", formErrorMsg: "We couldn't find that email address" })
			}
		});
	}

	render() {
		const styles = {
			img: {
				width: "auto",
				height: 60
			},
			form: {
				maxWidth: 300,
			},
			account: {
				backgroundColor: "#eee",
				fontSize: 14,
				borderRadius: 5,
				display: "inline-block",
				padding: "5px 10px",
				color: "#136665"
			}
		}

		if (this.state.done) {
			return (
				<div style={{ padding: "30px 50px 50px 50px", backgroundColor: '#a2cdcd', display: "inline-block" }}>
					<h3><b>Success!</b></h3>
					<h5 style={{ marginTop: 20 }}>We've sent an email to {this.state.email} with password reset instructions.</h5>
					<p style={{ marginTop: 20 }}>If you don't see the email within a few minutes please check your spam folder.<br />If you need further assistance please contact hello@lumeapp.io</p>
				</div>
			)
		} else {
			return (
				<div style={{ padding: 20 }}>
					<h1 className='title'>Forgot Your Password?</h1>
					<p style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}>If you've forgotten your password just enter your email address below, click reset and you'll receive an email with a reset link within a few minutes.</p>

					<form style={styles.form} onSubmit={this.reset}>
						<div className="field">
							<label className="label">Please enter your email address</label>
							<div className="control">
								<input className="input" placeholder="Enter your email address" value={this.state.email} name="email" type="email" onChange={this.emailChange} />
							</div>
							<p className="help is-danger">{this.state.emailError}</p>
						</div>
						<div className="control">
							<button className={"button is-primary " + this.state.formError}>Reset</button>
							<p className="help is-danger" style={{ marginTop: 10 }}>{this.state.formErrorMsg}</p>
						</div>
					</form>
					{/* <p>
					<img src={require("../../imgs/apple_app_store.png")} alt="iPhone app" style={styles.img} />
					<img src={require("../../imgs/android_app_store.png")} alt="Android app" style={styles.img} />
				</p> */}
				</div>
			);
		}
	}
}

export default PasswordForgot;
