import React from 'react';
import '../../css/ControlPanel.css';
import { api } from '../actions/api.js';

class Invite extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			credits: [],
			formError: "",
			freeCredits: 0,
			invitationDataEmpty: {
				email: "",
				first_name: "",
				last_name: "",
			},
			creditsToBuy: 100,
		};

		this.state.invitationData = JSON.parse(JSON.stringify(this.state.invitationDataEmpty));
	}

	componentDidMount() {
		this.getCredits();
	}

	getCredits = () => {
		let t = this;
		api("purchase", "GET", null, (d) => {
			if (!d.purchases) {
				return null;
			}
			// find unused credits
			let credits = [];
			let freeCredits = 0;
			d.purchases.forEach((p) => {
				p.credits.forEach((c) => {
					credits.push(c);
					if (!c.userId) {
						freeCredits++;
					}
				})
			})
			t.setState({ credits, freeCredits });
		});
	}

	invite = (e) => {
		e.preventDefault();
		let t = this;
		let invitationData = this.state.invitationData;
		let credits = this.state.credits;

		// find free credit
		let creditIndex = -1;
		credits.forEach((c, i) => {
			if (!c.userId && creditIndex === -1) {
				creditIndex = i;
			}
		})
		console.log('token:', credits[creditIndex]);
		invitationData.user_type = 0;
		invitationData.invitationToken = credits[creditIndex].invitationToken;
		invitationData.password = "---";
		invitationData.username = invitationData.email;

		api("user/register", "post", invitationData, (d) => {
			console.log("reg response:", d);
			if (d.user) {
				credits[creditIndex].userId = d.user.id;
				t.setState({ credits, invitationData: JSON.parse(JSON.stringify(this.state.invitationDataEmpty)), formError: '' });
				t.props.refreshUserList();
				t.getCredits();
			} else {
				console.log("err response:", d.error.response);
				credits[creditIndex].userId = null;
				t.setState({ credits, formError: d.error.response.data.message });
			}

		})
	}

	handleInputChange = (event) => {
		const target = event.target;
		const name = target.name;
		const value = target.value;
		let invitationData = this.state.invitationData;
		invitationData[name] = value;
		this.setState({ invitationData });
	}


	render() {

		let formDisplay = this.state.freeCredits > 0 ? "block" : "none";
		let noCreditsMsg = this.state.freeCredits === 0 ? "block" : "none";

		return (
			<div className="columns">

				<div className="column">
					<div className="panel">
						<p className="panel-heading">
							Invite Users ({this.state.freeCredits} credits remaining)
						</p>
						<div className="panel-block is-primary user" style={{}}>
							<div style={{ width: '100%', display: noCreditsMsg }}>
								<p>Sorry, you've used all your invitation credits. Please get in touch if you'd like to purchase more.</p>
							</div>

							<form style={{ width: '100%', display: formDisplay }} onSubmit={this.invite}>
								<div className="field is-horizontal">
									<div className="field-label is-normal">
										<label className="label">Email</label>
									</div>
									<div className="field-body">
										<div className="field">
											<p className="control">
												<input className="input" value={this.state.invitationData.email} type="email" name="email" placeholder="Email address" onChange={this.handleInputChange} />
											</p>
										</div>
									</div>
								</div>

								<div className="field is-horizontal">
									<div className="field-label is-normal">
										<label className="label">First name</label>
									</div>
									<div className="field-body">
										<div className="field">
											<p className="control">
												<input className="input" value={this.state.invitationData.first_name} type="text" name="first_name" placeholder="First name" onChange={this.handleInputChange} />
											</p>
										</div>
									</div>
								</div>

								<div className="field is-horizontal">
									<div className="field-label is-normal">
										<label className="label">Last name</label>
									</div>
									<div className="field-body">
										<div className="field">
											<p className="control">
												<input className="input" value={this.state.invitationData.last_name} name="last_name" type="text" placeholder="Last name" onChange={this.handleInputChange} />
											</p>
										</div>
									</div>
								</div>

								<div className="control">
									<div className="formError">{this.state.formError}</div>
									<button className="button is-primary is-pulled-right">Invite</button>
								</div>

							</form>
						</div>
					</div>
				</div>

				{/* <div className="column">
					<div className="panel">
						<p className="panel-heading">
							Your credits
						</p>
						<div className="panel-block is-primary user" style={{}}>
							You have {this.state.freeCredits} credits remaining
						</div>
						<div className="panel-block">
							<p>You can request more credits, they will not be available to use until we collect</p>
						</div>
						<div className="field has-addons has-addons-right panel-block is-primary">
							<p className="control">
								<input className="input" onChange={(v) => { this.setState({ creditsToBuy: v }) }} type="number" placeholder="Number of credits" value={this.state.creditsToBuy} />
							</p>
							<p className="control">
								<a className="button is-primary">
									Purchase
								</a>
							</p>
						</div>
					</div>
				</div> */}

			</div>

		);
	}
}

export default Invite;
