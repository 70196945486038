import styled from "styled-components";
import bubbles from "../../../imgs/bubbles_white_square_2.svg";

export const PageContent = styled.div`
  background-color: #58c6ac;
  flex: 1;
  background-image: url(${bubbles});
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Inner = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const InnerTopAlign = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
	width: 100%;
`;

export const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: #fff;
`;
export const SubTitle = styled.div`
  font-size: 20px;
  text-align: center;
  color: #fff;
`;

export const DurationInput = styled.input`
  width: 80px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 20px;
  margin-top: 40px;
`;

export const Top = styled.div`
  height: 160px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const Bottom = styled.div`
  background-color: #fff;
  width: 100%;
  flex: 1;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  display: flex;
`;

export const BottomTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #000;
`;

export const HabitList = styled.div`
  margin: 40px 0;
  width: 100%;
  max-width: 300px;
`;

export const Activity = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 10%);
`;

export const DeleteButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
  }
`;

export const Or = styled.div`
  margin: 40px 0;
  position: relative;
  text-align: center;
  color: #eee;
  font-size: 20px;
  &:before {
    content: "";
    left: 0;
    position: absolute;
    top: 50%;
    height: 1px;
    background-color: #eee;
    width: 100%;
  }
`;
