import React, { memo, useEffect, useState } from "react";
import {
  SectionMarker,
  SectionSubTitleText,
  SectionTitleText,
} from "../Day.styled";
import SectionTitle from "../SectionTitle/SectionTitle";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CourseReqItem } from "./CourseReqDay.styled";
import { post } from "../../../../../actions/api";
import { useHistory, useLocation } from "react-router";

const CourseReqDay = ({ data }) => {
  const history = useHistory();
  const location = useLocation();
  const [eventData, setEventData] = useState(null);
  useEffect(() => {
    setEventData(data);
  }, [data]);

  if (!eventData) {
    return null;
  }

  const { eventTime, courseTitle, colour, screenTitle } = eventData;

  const styles = {
    icon: {
      width: 30,
      height: 30,
      marginRight: 10,
    },
  };

  const reqTypes = {
    req_pChkin: { title: "Record 1 Positive Reflection", order: 0 },
    req_RAK: { title: "Perform 1 Random Act of Kindness", order: 1 },
    req_grat: { title: "Record 3 Things You're Grateful For", order: 2 },
    req_med: { title: "Meditation", order: 3 },
    req_exe: { title: "Exercise", order: 4 },
  };

  const events = eventData.events.sort((a, b) =>
    reqTypes[a.elementType] && reqTypes[b.elementType]
      ? reqTypes[a.elementType].order > reqTypes[b.elementType].order
        ? 1
        : -1
      : -1
  );

  const recordInteraction = async (elementId) => {
    await post("course/elminteraction", {
      elementId,
      value: 1,
      eventTime: eventTime.toDate(),
    });
    setEventData({
      ...eventData,
      events: eventData.events.map((e) =>
        e.id === elementId ? { ...e, complete: true } : e
      ),
    });
  };

  return (
    <div>
      <SectionTitle
        time={eventTime}
        text={
          <>
            <SectionMarker colour={`#${colour}`} />
            <SectionTitleText>{courseTitle}</SectionTitleText>
          </>
        }
      />
      <SectionSubTitleText>{screenTitle}</SectionSubTitleText>
      {events.map((e, i) => {
        return (
          <CourseReqItem
            style={{ cursor: e.complete ? "default" : "pointer" }}
            onClick={() => {
              if (e.elementType === "req_exe" || e.elementType === "req_med") {
                recordInteraction(e.id);
              } else if (e.elementType === "req_RAK" && !e.complete) {
                history.push(
                  `/random-act-of-kindness?date=${eventTime.format(
                    "YYMMDD"
                  )}&next=${location.pathname}`
                );
              } else if (e.elementType === "req_grat" && !e.complete) {
                history.push(
                  `/gratitude?date=${eventTime.format("YYMMDD")}&next=${
                    location.pathname
                  }`
                );
              } else if (e.elementType === "req_pChkin" && !e.complete) {
                history.push(
                  `/daily-reflection?checkinMode=positive&stateGroups=very%20positive,positive&date=${eventTime.format(
                    "DDMMYY"
                  )}&next=${location.pathname}`
                );
              }
            }}
          >
            {e.complete ? (
              <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
            ) : (
              <FontAwesomeIcon icon={faCircle} style={styles.icon} />
            )}
            {reqTypes[e.elementType]
              ? reqTypes[e.elementType].title
              : JSON.stringify(e)}
          </CourseReqItem>
        );
      })}
    </div>
  );
};

export default memo(CourseReqDay);
