import React, { memo, useEffect, useState } from "react";
import {} from "./TickBox.styled";
import TickItem from "../TickItem/TickItem";
const Exercise = (props) => {
  const elm = props.elm;
  const content = props.elm.content;
  const interaction = props.latestInteractions[elm.id];
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if (Number(content.recall)) {
      if (interaction) {
        if (JSON.parse(interaction.value)) {
          setComplete(true);
        }
      }
    }
  }, [content.recall, interaction]);

  function clicked() {
    setComplete(!complete);

    return complete
      ? props.recordInteraction(props.elm.id, false)
      : props.recordInteraction(props.elm.id, true);
  }

  return (
    <TickItem
      onClick={clicked}
      label={content.label}
      complete={complete}
      linkTo={null}
    />
  );
};

export default memo(Exercise);
