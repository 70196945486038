import React, { memo } from "react";
import PropTypes from "prop-types";
import { SectionMarker, SectionTitleText, Text } from "../Day.styled";
import SectionTitle from "../SectionTitle/SectionTitle";

const BreatheDay = ({ data, deleteClicked }) => {
  const { eventTime, eventData } = data;
  return (
    <div>
      <SectionTitle
        time={eventTime}
        text={
          <>
            <SectionMarker colour="#b5e3fa" />
            <SectionTitleText>De-Stress Deep Breathing</SectionTitleText>
          </>
        }
        deleteClicked={deleteClicked}
      />
      {eventData && eventData.duration && (
        <Text>
          {eventData.duration === -1
            ? "Continuous"
            : `For ${eventData.duration} minutes`}
        </Text>
      )}
    </div>
  );
};

BreatheDay.propTypes = {
  deleteClicked: PropTypes.func.isRequired,
};

export default memo(BreatheDay);
