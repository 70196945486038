import React, { useState, memo } from "react";
import {
  NavRoot,
  SlideContainer,
} from "../../../components/common/Onboarding/Onboarding.styled";
import "../../../../css/datePicker.css";
import { NavTitle } from "../../../components/common";
import { PageRoot, BackButton } from "../../../components/common/common.styled";
import { Slide1 } from "./Slides/Slide1";
import { Slide2 } from "./Slides/Slide2";

const Onboarding = ({ onboardingFinished }) => {
  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    if (slide === slides.length - 1) {
      onboardingFinished();
    } else {
      setSlide(slide + 1);
    }
  };

  const slides = [
    <Slide1 nextSlide={nextSlide} />,
    <Slide2 nextSlide={nextSlide} />,
  ];

  return (
    <PageRoot>
      <NavRoot>
        <NavTitle>Daily Reflection</NavTitle>
      </NavRoot>
      <SlideContainer>
        <BackButton
          style={{ color: "#000" }}
          onClick={() => (slide ? setSlide(slide - 1) : onboardingFinished())}
        />
        {slides[slide]}
      </SlideContainer>
    </PageRoot>
  );
};

export default memo(Onboarding);
