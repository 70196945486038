import React from "react";
import { Text, SlideWrapper, SlideContent } from "./Slides.styled";
import { ButtonWhite } from "../../../../components/common";
export function Slide1(props) {
  return (
    <SlideWrapper>
      <SlideContent>
        <Text></Text>
        <Text><b>Deep Breathing</b><br /><br />Reset your nervous system &amp; de-stress<br/><span style={{fontSize:22}}>3 / 5 / 10 / ~ Min</span></Text>
        <ButtonWhite
          onClick={props.nextSlide}
          style={{ width: "100%", maxWidth: 300 }}
        >
          Next
        </ButtonWhite>
      </SlideContent>
    </SlideWrapper>
  );
}
