import React, { useEffect, useState } from "react";
import { get } from "../../../actions/api";
import {
  AddPictureButton,
  AddPictureButtonContainer,
  AddPictureButtonContainerFirst,
  CloseButtonContainer,
  DeleteButton,
  Modal,
  ModalBg,
  SubTitle,
  Thumbnail,
  Wrapper,
} from "./CheckinImages.styled";
import Slider from "react-touch-drag-slider";
import { CloseCircleOutline, CloseOutline } from "react-ionicons";

const CheckinImages = ({ addImage, deleteImage, checkinImages, checkinId }) => {
  const [viewPic, setViewPic] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    async function getImages() {
      if (!checkinImages) {
        return null;
      }

      const imgs = checkinImages
        ? await Promise.all(
            checkinImages.map(async (img) => {
              return img.base64 !== null
                ? { base64: img.base64 }
                : await get(`checkin/${checkinId}/${img.id}?base64=true`);
            })
          )
        : [];

      setImages(imgs);
    }
    getImages();
  }, [checkinImages, checkinId]);

  const imgs = images.map((p, i) => (
    <Thumbnail
      key={i}
      onClick={() => {
        setViewPic(i);
      }}
      style={{ backgroundImage: `url(data:image/jpg;base64,${p.base64})` }}
    >
      {deleteImage && deleteImage !== undefined && (
        <DeleteButton onClick={(e) => deleteImage(i, e)}>
          <CloseOutline width={30} height={30} />
        </DeleteButton>
      )}
    </Thumbnail>
  ));

  const AddButton = () => {
    if (!addImage || images.length > 19) {
      return null;
    }
    return images.length ? (
      <AddPictureButtonContainer>
        <img
          src={require("../../../../imgs/icons/camera.svg")}
          style={{ width: 36, height: 36 }}
        />
        <AddPictureButton
          onChange={(e) => addImage(e)}
          type="file"
          accept="image/jpeg,image/png"
        />
      </AddPictureButtonContainer>
    ) : (
      <AddPictureButtonContainerFirst>
        <img
          src={require("../../../../imgs/icons/camera.svg")}
          style={{ width: 36, height: 36 }}
        />
        <span>Upload Handwritten</span>
        <AddPictureButton
          onChange={(e) => addImage(e)}
          type="file"
          accept="image/jpeg,image/png"
        />
      </AddPictureButtonContainerFirst>
    );
  };
  return (
    <>
      <Wrapper
        style={{ justifyContent: images.length ? "flex-start" : "center" }}
      >
        {imgs}
				<AddButton />
      </Wrapper>
      {addImage && <SubTitle>Or Type An Entry</SubTitle>}
      {viewPic !== null && (
        <ModalBg onClick={() => setViewPic(null)}>
          <Modal onClick={(e) => e.stopPropagation()}>
            <CloseButtonContainer onClick={() => setViewPic(null)}>
              <CloseCircleOutline color={"#00000"} width={30} height={30} />
            </CloseButtonContainer>
            <Slider
              activeIndex={viewPic}
              threshHold={100}
              transition={0.5}
              scaleOnDrag={true}
            >
              {images.map((img, i) => (
                <img src={`data:image/jpg;base64,${img.base64}`} key={i} />
              ))}
            </Slider>
          </Modal>
        </ModalBg>
      )}
    </>
  );
};

export default CheckinImages;
