import React, { memo, useEffect, useState } from "react";
import { ButtonOutline } from "../../../components/common";
import {
  Entry,
  JournalInput,
  PromptModal,
  PromptModalBg,
  PromptModalInner,
  PromptOption,
  PromptSection,
  PromptText,
  Wrapper,
} from "./Journal.styled";
import Icon from "@mdi/react";
import { mdiPencilPlus } from "@mdi/js";
import { get } from "../../../actions/api";

const Journal = ({ notes, setNotes, checkinMode }) => {
  const [promptList, setPromptList] = useState(null);
  const [promptModalOpen, setPromptModalOpen] = useState(false);
  const [promptIndex, setPromptIndex] = useState(null);

  useEffect(() => {
    async function getPrompts() {
      const prompts = await get("checkin/prompts");
      setPromptList(prompts.prompts);
    }
    getPrompts();
  }, []);

  const setPrompt = (prompt) => {
    if (promptIndex === null) {
      setNotes([...notes, { prompt: prompt, entry: "" }]);
    } else {
      let newNotes = notes;
      newNotes[promptIndex].prompt = prompt;
      setNotes(newNotes);
    }

    setPromptModalOpen(false);
    return setPromptIndex(null);
  };

  const editPrompt = (index) => {
    setPromptIndex(index);
    setPromptModalOpen(true);
  };

  if (promptList === null) {
    return null;
  }

  if (notes && notes[0].prompt === "set") {
    if (checkinMode === "brainDump") {
      setNotes([
        { entry: "", prompt: "Coming Up" },
        { entry: "", prompt: "What Went Well" },
        { entry: "", prompt: "Obstacles" },
        { entry: "", prompt: "Solutions" },
      ]);
    } else {
      setNotes([{ entry: "", prompt: promptList[0] }]);
    }
  }

  return (
    <Wrapper>
      {notes &&
        notes.map((entry, i) => {
          return (
            <Entry key={i}>
              <PromptSection onClick={() => editPrompt(i)}>
                <Icon
                  path={mdiPencilPlus}
                  style={{ marginTop: 2, width: 16, height: 16 }}
                />
                <PromptText>{entry.prompt ?? "Pick a prompt"}</PromptText>
              </PromptSection>
              <JournalInput
                placeholder="Journal entry..."
                multiline={true}
                value={entry.entry}
                name="notes"
                onChange={(e) =>
                  setNotes([
                    ...notes.map((n, ii) =>
                      i === ii
                        ? {
                            ...n,
                            entry: e.target.value,
                            prompt: n.prompt,
                          }
                        : n
                    ),
                  ])
                }
              />
            </Entry>
          );
        })}
      {checkinMode !== "brainDump" && (
        <ButtonOutline onClick={() => setPromptModalOpen(true)}>
          Add another prompt
        </ButtonOutline>
      )}
      <PromptModalBg
        style={{ display: promptModalOpen ? "block" : "none" }}
        onClick={() => setPromptModalOpen(false)}
      >
        <PromptModalInner>
          <PromptModal>
            {promptList.map((prompt, i) => (
              <PromptOption key={i} onClick={() => setPrompt(prompt)}>
                {prompt}
              </PromptOption>
            ))}
          </PromptModal>
        </PromptModalInner>
      </PromptModalBg>
    </Wrapper>
  );
};

export default memo(Journal);
