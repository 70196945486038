import styled from "styled-components";
import bubbles from "../../../imgs/bubbles_white_square_2.svg";
import ExpandingTextarea from "react-expanding-textarea";

export const RakText = styled(ExpandingTextarea)`
  background: transparent;
  color: white;
  resize: none;
  margin-bottom: 1rem;
  padding: 0 2rem;
  font-size: 22px;
  line-height: 34px;
  font-weight: bolder;
  border: none;
  &:focus {
    outline: none;
  }
`;

export const SkipButton = styled.button`
  width: auto;
  color: white;
  text-align: center;
  justify-content: center;
  border: 1px white solid;
  background: transparent;
  padding: 3px 10px;
  border-radius: 2rem;
  font-size: 14px;
  &:hover {
    color: white;
  }
`;

export const PageContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 3rem;
  flex-direction: column;
`;

export const FloatingBox = styled.div`
  width: 400px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
`;
export const BoxTop = styled.div`
  width: 100%;
  color: white;
  background-image: url(${bubbles});
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 40px 20px;
  background-color: #407cb4;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const NoteButton = styled.div`
  position: absolute;
  left: 20px;
  background-color: transparent;
  cursor: pointer;
  font-size: 28px;
`;

export const SaveButton = styled.button`
  background-color: white;
  color: ${({ theme }) => theme.primary.colours.blueD};
  width: 12rem;
  padding: 0.7rem;
  border-radius: 3rem;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
  justify-self: center;
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
`;

export const Tab = styled.span`
  display: flex;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-bottom: ${(props) => (props.selected ? "#000" : " #eaebed")} 2px solid;
  color: ${(props) => (props.selected ? "#000" : " #eaebed")};
  cursor: pointer;
`;

export const CatTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`;

export const CatIntro = styled.h3`
  font-size: 20px;
  font-weight: bold;
`;

export const Body = styled.div`
  width: 100%;
  padding: 20px;
`;
