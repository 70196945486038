import styled from "styled-components";

export const TPItem = styled.div`
  flex-direction: row;
  background-color: #fff;
  /* border: #bbb 1px solid; */
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 0 10px;
  height: 54px;
  margin-top: 10px;
  pointer-events: ${(props) => (props.clickable ? "all" : "none")};
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
  opacity: ${(props) => (props.clickable ? 1 : 0.5)};
	transition: .2s;
	&:hover{
		background:#f9f9f9;
		padding-right:5px;
	}
`;

export const TPTitle = styled.div`
  flex: 1;
  align-items: center;
`;

export const TPIcon = styled.div`
  align-items: center;
  height: 32px;
  width: 32px;
`;

export const PItem = styled.div`
  flex-direction: column;
  align-items: center;
  display: inline-block;
  pointer-events: ${(props) => (props.clickable ? "all" : "none")};
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
  opacity: ${(props) => (props.clickable ? 1 : 0.5)};
  padding: 10px;
  text-align: center;
`;

export const PTitle = styled.div`
  flex: 1;
  align-items: center;
`;
