import React from "react";
import { habitActivities } from "./habitActivities";
import theme from "../../theme";

const Activity = ({ activity, handlePress, isCurrentDay }) => {
  console.log(activity);
  return (
    <div
      style={styles.wrapper}
      onClick={handlePress}
      disabled={!isCurrentDay && activity.type !== "custom"}
    >
      <div style={styles.label}>
        {activity.type === "custom"
          ? activity.title
          : habitActivities[activity.type]
          ? habitActivities[activity.type].title
          : activity.type}
      </div>
      {activity.completedOn ? (
        // <AntDesign name="checkcircle" size={32} color={Colors.greenM} />
        <span>X</span>
      ) : (
        <div style={styles.incomplete}></div>
      )}
    </div>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    height: 68,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 16px",
    width: "100%",
    marginTop: 20,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },
  label: {
    fontSize: 18,
  },
  incomplete: {
    border: `3px solid ${theme.primary.colours.greenM}`, // TODO: "theme.primary.colours.greenM
    width: 32,
    height: 32,
    borderRadius: 16,
  },
};

export default Activity;
