import styled from "styled-components";
import { TopNav } from "../../components/common";
import bubbles from "../../../imgs/bubbles_white_square_2.svg";
import ExpandingTextarea from "react-expanding-textarea";

export const RakText = styled(ExpandingTextarea)`
  background: transparent;
  color: white;
  resize: none;
  margin-bottom: 1rem;
  padding: 0 2rem;
  font-size: 22px;
  line-height: 34px;
  font-weight: bolder;
  border: none;
  &:focus {
    outline: none;
  }
`;

export const SkipButton = styled.button`
  width: auto;
  color: white;
  text-align: center;
  justify-content: center;
  border: 1px white solid;
  background: transparent;
  padding: 3px 10px;
  border-radius: 2rem;
  font-size: 14px;
  &:hover {
    color: white;
  }
`;

export const PageContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 3rem;
  flex-direction: column;
`;

export const FloatingBox = styled.div`
  width: 400px;
  max-width: 100%;
  padding: 4rem 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
  background-color: ${({ theme }) => theme.primary.colours.baseColours.rak};
  background-image: url(${bubbles});
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
`;

export const SaveButton = styled.button`
  background-color: white;
  color: ${({ theme }) => theme.primary.colours.blueD};
  width: 12rem;
  padding: 0.7rem;
  border-radius: 3rem;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
  justify-self: center;
`;

export const BoxTitle = styled.h4`
  color: white;
  text-align: center;
  margin-bottom: 1rem;
  padding: 0 2rem;
  font-weight: bold;
  font-size: 22px;
`;

export const ToggleButton = styled.button`
  width: 185px;
  display: flex;
  align-items: center;
  background-color: inherit;
  color: ${({ theme }) => theme.primary.colours.blueM};
  font-size: 2.5rem;
  justify-content: flex-start;
  gap: 5px;
  > * {
    line-height: 20px;
    text-align: left;
  }
`;
