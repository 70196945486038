import React from "react";
import "../../../../css/ControlPanel.css";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g
      onClick={() => {
        props.props.dataFilterSet({
          target: {
            name: "stateGroup",
            value: props.name,
          },
        });
      }}
    >
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`(${(percent * 100).toFixed(0)}%)`}</text>
    </g>
  );
};

class CheckinPie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      checkinStateGroupData: [],
      filter: "all",
      allFilters: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    let data = {};
    if (props.checkinStateGroupData) {
      props.checkinStateGroupData.forEach((c) => {
        Object.entries(c).forEach((s) => {
          if (s[0] !== "date") {
            if (data[s[0]] === undefined) {
              data[s[0]] = 0;
            }

            data[s[0]] += Number(s[1]);
          }
        });
      });
    }

    // apply active index on filter
    if (JSON.stringify(state.checkinStateGroupData) !== JSON.stringify(data)) {
      let active = 0;
      let biggest = 0;
      Object.entries(data).forEach((v, i) => {
        if (v[1] > biggest) {
          biggest = v[1];
          active = i;
        }
      });
      state.activeIndex = active;
    }
    state.allFilters = props.allFilters;
    state.checkinStateGroupData = data;
    return state;
  }

  compileData = () => {
    let data = this.state.checkinStateGroupData;
    let groupData = [];
    Object.entries(data).forEach((row) => {
      groupData.push({
        name: row[0],
        value: row[1],
        color: "#f30",
      });
    });
    return groupData.sort(
      (a, b) =>
        this.props.stateGroupLookup[a.name].order -
        this.props.stateGroupLookup[b.name].order
    );
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    if (!this.props.checkinStateGroupData.length) {
      return null;
    }

    return (
      <ResponsiveContainer height={400}>
        <PieChart>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={this.compileData()}
            // cx={chartWidth / 2}
            // cy={200}
            innerRadius={80}
            outerRadius={150}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={this.onPieEnter}
          >
            {this.compileData().map((entry, index) => {
              let colour = "#000";
              if (this.props.stateGroupLookup[entry.name]) {
                colour = this.props.stateGroupLookup[entry.name].colour;
              }
              return (
                <Cell key={`cell-${index}`} fill={colour} props={this.props} />
              );
            })}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

export default CheckinPie;
