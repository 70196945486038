import theme from '../theme';

const { states } = theme.primary.colours;

export function aggregateInfluenceTotals(influences) {
  const getPositive = tg => tg.Positive + tg['Very Positive'];
  const getNegative = tg => tg.Ok + tg.Negative + tg['Very Negative'];
  const getTotal = tg => getPositive(tg) + getNegative(tg);

  return influences.map(({ id, term, termGroups }) => ({
    id,
    term,
    positive: getPositive(termGroups),
    negative: getNegative(termGroups),
    total: getTotal(termGroups),
  }));
}

export function buildTotal(input) {
  return Object.values(input).reduce((running, current) => running + current);
}

function buildPercentages(input) {
  const total = buildTotal(input);
  if (total === 0) {
    return {
      positive: 0,
      negative: 0,
    }
  }

  const positive = ((input.Positive + input['Very Positive']) / total) * 100;
  const negative = ((input.Ok + input.Negative + input['Very Negative']) / total) * 100;
  return {
    positive,
    negative,
  };
}

export function buildStateGroupTotals(input) {
  const data = Object.entries(input).map(([state, total]) => ({
    state,
    total,
    colour: states[state],
    term: state,
  }));

  const total = buildTotal(input);
  const percentages = buildPercentages(input);

  return {
    data,
    percentages,
    total,
  };
}
