import styled from "styled-components";
import bubbles from "../../../imgs/bubbles_white_square_2.svg";
export const PageContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 3rem;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-image: url(${bubbles});
`;

export const Section = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding: 20px;
  max-width: 600px;
  border-radius: 10px;
`;

export const Tiles = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  display: flex;
  max-width: 600px;
  gap: 10px;
  margin-top: 20px;
`;

export const QuoteContainer = styled.div`
  margin: 20px 0;
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.primary.colours.yellow};
  background-image: url(${bubbles});
  background-size: cover;
  border-radius: 10px;
`;

export const Quote = styled.div`
  color: ${({ theme }) => theme.primary.colours.blueD};
  font-size: 20px;
  font-style: italic;
`;
export const QuoteCredit = styled.div`
  color: ${({ theme }) => theme.primary.colours.blueD};
  font-size: 16px;
  margin-top: 10px;
  align-self: flex-end;
`;
