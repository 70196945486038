import React, { memo } from "react";
import {} from "./Meditation.styled";
import TickItem from "../TickItem/TickItem";
const Meditation = ({
  recordInteraction,
  delInteraction,
  firstScreenInteraction,
  elm,
}) => {
  function clicked() {
    if (elm.courseElmInteractions.length) {
      delInteraction(elm.courseElmInteractions[0].id);
    } else {
      recordInteraction(elm.id, true, firstScreenInteraction ?? new Date());
    }
  }

  return (
    <TickItem
      onClick={clicked}
      label="Meditate"
      complete={elm.courseElmInteractions.length}
      linkTo={{
        text: "See suggested sessions",
        href: "/signposting?page=51&back=51|" + window.location.pathname,
      }}
    />
  );
};

export default memo(Meditation);
