import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  checkInGroup as checkInGroupPropType,
  checkInItem as influencePropType,
} from '../../../../propTypes';
import { ExpandingBox } from '../../../../components/common';
import InfluenceSelectorBody from './InfluenceSelectorBody/InfluenceSelectorBody';

const InfluenceSelector = ({
  influenceGroup,
  onInfluenceSelected,
  onInfluenceDeselected,
  selectedInfluences,
  moodColour,
}) => {
  const body = (
    <InfluenceSelectorBody
      influences={influenceGroup.terms}
      influenceSelected={onInfluenceSelected}
      influenceDeselected={onInfluenceDeselected}
      selectedInfluences={selectedInfluences}
      moodColour={moodColour}
    />
  );

  return (
      <ExpandingBox
        title={influenceGroup.title}
        body={body}
        initialExpanded={influenceGroup.shrinkByDefault === false}
      />
  );
};

InfluenceSelector.propTypes = {
  onInfluenceSelected: PropTypes.func.isRequired,
  onInfluenceDeselected: PropTypes.func.isRequired,
  influenceGroup: checkInGroupPropType,
  selectedInfluences: PropTypes.arrayOf(influencePropType).isRequired,
  moodColour: PropTypes.string.isRequired,
};

export default memo(InfluenceSelector);
