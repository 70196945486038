import React, { memo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  PageContent,
  NavRoot,
  PageWrapper,
  NextScreenButton,
  ProgressSegment,
  ProgressContainer,
  MenuButton,
} from "./Course.styled";
import { api, get, post } from "../../actions/api";
import { BackButton, NavTitle, PageRoot } from "../../components/common";
import ScreenIndex from "./ScreenIndex/ScreenIndex";
import ElmGroups from "./ElmGroups/ElmGroups";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Course = (props) => {
  const history = useHistory();
  const [course, setCourse] = useState(null);
  const [moduleIndex, setModuleIndex] = useState(null);
  const [sectionIndex, setSectionIndex] = useState(null);
  const [screenIndex, setScreenIndex] = useState(null);
  const [viewHistory, setViewHistory] = useState([]);
  const [activeScreenId, setActiveScreenId] = useState(null);

  useEffect(() => {
    async function buildData() {
      const crs = await getCourse();
      if (props.match.params.screenId !== undefined) {
        findScreen(props.match.params.screenId, crs);
      } else if (props.match.params.rootIndex === undefined) {
        // if no index elements are specified we should go to the module index
        if (crs.currentScreenId) {
          findScreen(crs.currentScreenId, crs);
        } else if (crs.firstScreenId !== null) {
          findScreen(crs.firstScreenId, crs);
        }
      } else {
        if (props.match.params.moduleIndex !== undefined) {
          setModuleIndex(props.match.params.moduleIndex);
        }
        if (props.match.params.sectionIndex !== undefined) {
          setSectionIndex(props.match.params.sectionIndex);
        }
        if (props.match.params.screenIndex !== undefined) {
          setScreenIndex(props.match.params.screenIndex);
        }
      }
    }
    buildData();
  }, []);

  useEffect(() => {
    if (activeScreenId && course) {
      if (activeScreenId === course.firstScreenId) {
        setViewHistory([]);
      } else if (viewHistory[viewHistory.length - 1] !== activeScreenId) {
        setViewHistory([...viewHistory, activeScreenId]);
      }
    }
  }, [activeScreenId]);

  const getCourse = async function () {
    const data = await get("course/" + props.match.params.courseId);
    const crs = data.course;
    setCourse(crs);
    return crs;
  };

  const mergeTags = (str) => {
    if (!str) {
      return "";
    }
    const regex = /\<\|(.*?)\|\>/g;
    const result = str.replace(regex, (val) => {
      let out = "";
      if (val.indexOf("id:") > -1) {
        let id = val.split(":")[1].slice(0, -2);
        let value = course.latestInteractions[id]
          ? course.latestInteractions[id].value
          : "";
        if (value) {
          try {
            value = JSON.parse(value);
          } catch {}
        }

        if (Array.isArray(value)) {
          out = "<ul><ul>";
          value.forEach((line) => {
            if (line) {
              out += "<li>" + line + "</li>";
            }
          });
        } else {
          out = value;
        }
      }
      return out;
    });
    return result;
  };

  const findScreen = (screenId, crs) => {
    setModuleIndex(null);
    setSectionIndex(null);
    setScreenIndex(null);

    const c = crs ? crs : course;

    const i = c.lookup[screenId];
    let nextScreenId;
    if (i) {
      if (i.length > 1) {
        setModuleIndex(i[1]);
        if (i.length > 2) {
          setSectionIndex(i[2]);
        }
        if (i.length > 3) {
          setScreenIndex(i[3]);
          nextScreenId =
            c.courseScreens[0] &&
            c.courseScreens[0].children[i[1]] &&
            c.courseScreens[0].children[i[1]].children[i[2]] &&
            c.courseScreens[0].children[i[1]].children[i[2]].children[i[3]]
              ? c.courseScreens[0].children[i[1]].children[i[2]].children[i[3]]
                  .nextScreen
              : null;
        }
      }
    }

    setActiveScreenId(screenId);
    recordView(screenId, nextScreenId);
  };

  const back = () => {
    const gotoId =
      viewHistory.length > 1
        ? viewHistory[viewHistory.length - 2]
        : course.firstScreenId;

    if (viewHistory.length) {
      const newHistory = [...viewHistory];
      newHistory.pop();
      setViewHistory(newHistory);
    }
    findScreen(gotoId);
  };

  const close = () => {
    history.push("/");
  };

  const resetCourse = async () => {
    await post("course/archiveactivity", { courseId: course.id });
    close();
  };

  const recordView = (screenId, nextScreenId) => {
    api("course/screenview", "post", { screenId, nextScreenId });
  };

  if (course === null) {
    return <p>Loading...</p>;
  }

  let screen = course.courseScreens[0];
  let section = null;

  let url = "/course/" + course.id + "/0";
  if (moduleIndex !== null) {
    screen = screen.children[moduleIndex];
    section = screen.children[moduleIndex];
    url += "/" + moduleIndex;
    if (screen.children.length === 1 && sectionIndex === null) {
      setSectionIndex(0);
    }
  }

  if (sectionIndex !== null) {
    screen = screen.children[sectionIndex];
    url += "/" + sectionIndex;
    if (screenIndex !== null) {
      screen = screen.children[screenIndex];
      url += "/" + screenIndex;
    } else {
      screen = screen.children[0];
      url += "/" + 0;
    }
  }

  let progressLink = false;
  let nextScreenAction = null;
  screen.courseScrElmGroups.forEach((eg) => {
    eg.courseScrnElms.forEach((e) => {
      if (e.linkToScreenId) {
        progressLink = true;
      }
    });
  });

  if (!progressLink && screen.status !== 1 && screen.nextScreen) {
    // status = 1 means it's a "Day Sequence" - this means there will be check boxes required to complete it and it's not done just because you click on it
    nextScreenAction = () => {
      findScreen(screen.nextScreen);
    };
  }

  // using this rather than useHistory as it doesn't re-render
  window.history.replaceState(null, course.title, url);

  let screenStyles = {};
  let sectionStyles = {};
  let bgColour = "transparent";

  // check for bg colour at section level
  if (section && section.style) {
    try {
      sectionStyles = JSON.parse(section.style);
      if (sectionStyles.screen) {
        sectionStyles = sectionStyles.screen;
      }
    } catch (error) {
      sectionStyles = {};
    }

    if (sectionStyles.backgroundColor) {
      bgColour = sectionStyles.backgroundColor;
    }
  }

  // check for bg colour at screen level
  if (screen.style) {
    try {
      screenStyles = JSON.parse(screen.style);
    } catch (error) {
      screenStyles = {};
    }

    if (screenStyles.backgroundColor) {
      bgColour = screenStyles.backgroundColor;
      delete screenStyles.backgroundColor;
    } else if (screenStyles.screen && screenStyles.screen.backgroundColor) {
      bgColour = screenStyles.screen.backgroundColor;
    }
  }

  let progress = [];
  if (typeof screen.progress === "string" && screen.progress.length > 2) {
    const total = JSON.parse(screen.progress)[1];
    const complete = JSON.parse(screen.progress)[0];
    for (let i = 0; i < total; i++) {
      if (i >= complete) {
        progress.push(false);
      } else {
        progress.push(true);
      }
    }
  }

  const progressBars = progress.map((p, i) => {
    return (
      <ProgressSegment
        completed={p}
        key={i}
        colComplete="#2F84D1"
        colIncomplete="#ffffff"
      />
    );
  });

  return (
    <PageRoot>
      <NavRoot>
        <NavTitle>
          {course.title}
          {screen.title ? " - " + screen.title : ""}
        </NavTitle>
      </NavRoot>
      <PageWrapper bgColour={bgColour}>
        {screen.status !== 1 && screen.status !== 8 && (
          <BackButton
            style={{ color: bgColour === "transparent" ? "#000" : "#FFF" }}
            onClick={() => {
              back(screen);
            }}
          />
        )}
        {course.firstScreenId && screen.status !== 8 && (
          <MenuButton
            onClick={() => {
              findScreen(course.firstScreenId);
            }}
          >
            <FontAwesomeIcon
              icon={faBars}
              style={{
                width: 30,
                height: 30,
                color: bgColour === "transparent" ? "#000" : "#FFF",
              }}
            />
          </MenuButton>
        )}
        <PageContent style={screenStyles}>
          <ProgressContainer>{progressBars}</ProgressContainer>
          <ElmGroups
            elmGroups={screen.courseScrElmGroups}
            dayComplete={screen.dayComplete}
            findScreen={findScreen}
            course={{ id: course.id, title: course.title }}
            getCourse={getCourse}
            resetCourse={resetCourse}
            mergeTags={mergeTags}
            latestInteractions={course.latestInteractions}
            screenViews={course.screenViews}
            showInTodaySet={course.showInTodaySet}
            userSubId={course.courseUserSubs[0].id}
            currentScreenId={course.currentScreenId}
            screen={screen}
            sectionStyles={sectionStyles}
            screenStyles={
              screenStyles && screenStyles ? screenStyles.screen : null
            }
          />
          {screen.children && screenIndex === null && (
            <ScreenIndex items={screen.children} findScreen={findScreen} />
          )}
          {nextScreenAction && !screen.children && (
            <NextScreenButton onClick={nextScreenAction}>Next</NextScreenButton>
          )}
        </PageContent>
      </PageWrapper>
    </PageRoot>
  );
};

export default memo(Course);
