import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
`;

export const Thumbnail = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 5px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 10px 10px 0;
  cursor: pointer;
  transition: 0.2s;
  opacity: 1;
  &:hover {
    opacity: 0.8;
  }
`;

export const ModalBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 40px;
  z-index: 100;
`;

export const Modal = styled.div`
  position: relative;
  height: 100%;
  background-color: #000;
`;

export const DeleteButton = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #d5d7df;
  border-radius: 10px;
  top: -5px;
  right: -5px;
  transition: 0.2s;
  &:hover {
    background-color: #eee;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  color: #fff;
  z-index: 9999;
  cursor: pointer;
  top: 20px;
  left: 20px;
`;

export const AddPictureButton = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

export const AddPictureButtonContainer = styled.div`
  background-color: ${({ theme }) => theme.primary.colours.blueM};
  color: #fff;
  border-radius: 5px;
  width: 80px;
  height: 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
`;

export const AddPictureButtonContainerFirst = styled.div`
  background-color: ${({ theme }) => theme.primary.colours.blueM};
  color: #fff;
  border-radius: 35px;
  padding: 15px 40px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  span {
    margin-left: 20px;
  }
  &:hover {
    opacity: 0.8;
  }
`;

export const SubTitle = styled.h4`
  color: ${({ theme }) => theme.primary.colours.blueM};
  margin-bottom: 20px;
  margin-top: 20px;
`;
