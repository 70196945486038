import styled from 'styled-components';

export const Wrapper = styled.div`
	border-color: ${({ theme }) => theme.primary.colours.grey_ml};
	border-width: 1px;
	border-style: solid;
	border-radius: 5px; 
  padding: 5px;
	display: flex;
  flex-direction: row;
  align-items: center;
	width: 100%;
	background-color: #fff;
`;

export const Input = styled.input`
  border: 0;
  margin: 0 1rem;
  background-color: transparent;
  font-size: 1rem;
  flex:1;
  margin:0 5px 0 0; 
  &:focus {
    outline: none;
  }
`;

export const Label = styled.span`
	font-size: 1 rem;
	font-weight: normal;
	padding: 0 10px;
`;