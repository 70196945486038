import React, { memo } from 'react';
import { Wrapper, Text } from './SignpostLink.styled';
const SignpostLink = (props) => {
	const elm = props.elm;
	return <Wrapper href={"/signposting?page=" + elm.content.id + "&back=" + elm.content.id + "|" + window.location.pathname}>
		<i className="fas fa-map-signs" style={{ marginRight: 10, width: 16, height: 16, marginBottom: -3 }}></i> <Text>{elm.content.text}</Text>
	</Wrapper>
};

export default memo(SignpostLink);
