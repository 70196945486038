import React, { memo } from 'react';
import { ContentBox } from './../../components/common';
import styled from 'styled-components';
import positiveInfluences from './positive-influences.svg';
import negativeInfluences from './negative-influences.svg';

export const GridThreeRow = styled.div`
  align-self: stretch;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 275px;
  grid-gap: 1rem;
  margin: 3rem 3rem 1rem 3rem;
`;

export const GridTwoRow = styled.div`
  align-self: stretch;
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 315px;
  grid-gap: 1rem;
  margin: 0 3rem 3rem 3rem;
`;

const Title = styled.h5`
  colour: ${({ theme }) => theme.primary.colours.reflectionContent.title};
  font-weight: bold;
  margin-bottom: 1rem;
  > * {
    margin-right: 1rem;
  }
`;

const InfluenceTitleBase = ({ title, imageSource }) => (
  <Title>
    <img src={imageSource === 'positiveInfluences' ? positiveInfluences : negativeInfluences} />
    {title}
  </Title>
);

export const PositiveInfluencesTitle = memo(() => (
  <InfluenceTitleBase title="Positive Influences" imageSource="positiveInfluences" />
));

export const NegativeInfluenceTitle = memo(() => (
  <InfluenceTitleBase title="Negative Influences" imageSource="negativeInfluences" />
));

export const Tile = ({ title, body }) => (
  <ContentBox titleContent={<Title>{title}</Title>} body={body} />
);

export const DateRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding-top: 3rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.6rem;
  overflow: hidden;
  height: 13rem;
  padding: 0.5rem;
  box-sizing: border-box;
  
  overflow-y: auto;
  
  ::-webkit-scrollbar {
    position: absolute;
  }
`;

export const ContentRoot = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1250px;
  overflow-y: scroll;
`;
