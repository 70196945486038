import React, { memo } from "react";
import { useHistory } from "react-router";
import { post } from "../../../../../actions/api";
import { Btn } from "./Button.styled";

const Button = (props) => {
  const history = useHistory();
  const elm = props.elm;

  let styles = {};
  if (elm.style) {
    styles = elm.style;
  }

  function clicked() {
    if (Number(elm.content.recordClick)) {
      props.recordInteraction(elm.id, true);
    }

    if (Number(elm.content.progressMarker)) {
      post("event", {
        event: {
          // noduplicates: true,
          eventType: "progressMk",
          eventData: {
            name: elm.content.progressMarkerName,
            courseId: props.course.id,
            courseTitle: props.course.title,
            elmId: elm.id,
          },
        },
      });
    }

    // now link to something
    const linkType =
      elm.content && elm.content.linkType ? elm.content.linkType : "screen";

    // now link to something
    if (linkType === "screen") {
      if (Number(elm.content.linkToCurrent)) {
        if (props.currentScreenId) {
          props.findScreen(props.currentScreenId);
        } else if (elm.linkToScreenId !== null) {
          props.findScreen(elm.linkToScreenId);
        }
      } else if (elm.linkToScreenId !== null) {
        props.findScreen(elm.linkToScreenId);
      }
    } else {
      // do this first, to ensure they return to the correct screen.
      if (elm.linkToScreenId !== null) {
        props.findScreen(elm.linkToScreenId);
      }

      if (linkType === "checkin") {
        history.push("/daily-reflection");
      } else if (linkType === "journal") {
        history.push("/daily-reflection");
      } else if (linkType === "RAK") {
        history.push("/random-act-of-kindness");
      } else if (linkType === "grat") {
        history.push("/gratitude");
      } else if (linkType === "breathe") {
        history.push("/breathe");
      } else if (linkType === "brainDump") {
        history.push("/daily-reflection?checkinMode=brainDump");
      } else if (linkType === "feedback") {
        const next =
          elm.content.returnType === "screen"
            ? `/courseopen/${props.course.id}/${elm.content.returnId}`
            : elm.content.returnType === "suggestions"
            ? `/suggestions`
            : `/`;
        history.push(`/feedback/${elm.content.feedbackId}?next=${next}`);
      } else if (linkType === "link" && elm.content.href) {
        window.open(elm.content.href, "_blank");
      }
    }

    // close course - this can happen in addition to linking to another screen to mark the next page before closure
    if (Number(elm.content.closeCourse)) {
      history.push("/");
    }
  }

  return (
    <Btn onClick={clicked} style={styles}>
      {elm.content.text}
    </Btn>
  );
};

export default memo(Button);
