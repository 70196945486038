import React from 'react';
import '../../../../css/reports.css';
import { getGraph } from "../../../helpers/getGraph";
import 'rsuite/dist/styles/rsuite-default.css';

class ReportsSurveySection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			HOST: "https://api.lumeapp.io",
			groupTitle: "",
			groupValue: ""
		};

	}

	static getDerivedStateFromProps(props, state) {
		state.groupTitle = props.groupTitle;
		state.groupValue = props.groupValue;
		return state;
	}

	componentDidMount() {
		if (window.location.hostname === "localhost") {
			this.setState({ HOST: "http://localhost:3003" });
		} else {
			this.setState({ HOST: "https://api.lumeapp.io" });
		}
	}

	render() {
		let sec = this.props.section;
		let questions = sec.questions.map((q, i) => {
			let start = Object.keys(q.answers)[0];
			if (!Number(start)) {
				start = Object.keys(q.answers)[1];
			}
			let range = start + " - " + Object.keys(q.answers)[Object.keys(q.answers).length - 1];
			let graph = getGraph(q.answers);
			return (
				<div className='question' key={i}>
					<div className="score small is-pulled-right" style={{ width: 70, backgroundColor: this.props.colours[Math.round(q.score)] }}>{(Math.round(q.score * 10)) / 10}</div>
					<div className="score small is-pulled-right" style={{ width: 70, backgroundColor: "#f9f9f9", marginRight: 5, color: "#000" }}>{range}</div>
					<div className="score is-pulled-right graphContainer">
						{graph}
					</div>
					{q.question}
				</div>
			)
		})

		let notes = sec.notes.map((n, i) => {
			return (
				<div className="full-width report-item less-space comment" key={i}>
					<img src={require("../../../../imgs/quote.svg")} alt="quote marks" />{n}
				</div>
			)
		})
		return (
			<div className="sectionDetails">
				<span onClick={this.props.hideSection} className='back'>&lt;	Back </span>
				<div className='title'>
					<div className="right columns">
						<div className="column">
							<div className='groupTitle'>{this.state.groupTitle}</div>
							<div className='groupValue'>{this.state.groupValue}</div>
						</div>
						<div className="column">
							<div className="score large" style={{ float: 'right', backgroundColor: this.props.colours[Math.round(sec.score)] }}>
								{sec.score}
							</div>
						</div>
					</div>

					<h1>
						<img src={this.state.HOST + "/api/questionnaire/section/" + sec.id + "/icon.png"} alt={sec.title} />
						{sec.title}
					</h1>
				</div>
				<div className="completionRate">
					Completion Rate: {sec.userCompleted + " / " + sec.userCount} <span>{Math.round((sec.userCompleted / sec.userCount) * 100)}%</span>
				</div>
				<div className="analysis">
					<p>{sec.description}</p>
				</div>
				<h4 className="sectionTitle">Responses</h4>
				<div className="full-width report-item">
					{questions}
				</div>
				<h4 className="sectionTitle">Comments</h4>
				{notes}
				<div style={{ height: 20, clear: "both" }}></div>
			</div>

		)
	}
}

export default ReportsSurveySection;
