import React, { memo } from "react";
import PropTypes from "prop-types";
import { DatePickerCalendar } from "react-nice-dates";
import moment from "moment";
import { format } from "date-fns";
import "react-nice-dates/build/style.css";
import { enGB } from "date-fns/locale";
import { DateRangePickerWrapper, PieChart, TodayMarker } from "./Month.styled";

function shouldFill(date, days) {
  const day = moment(date).format("DD");
  const data = days[day];
  return data == null
    ? false
    : data.some((x) => x.eventType.toLowerCase() !== "checkin");
}

const Month = ({ month, days, selectedDateChanged }) => {
  const firstDayOfMonth = moment(month, "YYMM").toDate();

  const modifiers = {
    fill: (date) => shouldFill(date, days),
  };

  const modifiersClassNames = {
    fill: "-fill",
  };

  const selectedDateChangedLocal = (date) => {
    const dayOfMonth = moment(date).format("DD");
    const hasEntry = days[dayOfMonth] != null;
    const dateObj = moment(date);
    selectedDateChanged(dateObj, hasEntry);
  };

  const getPieChartData = (date) => {
    const pieDate = moment(date).format("DD");
    const dayData = days[pieDate];
    if (dayData == null || dayData.length === 0) {
      return [];
    }

    const result = dayData
      .filter((x) => Boolean(x.state))
      .reduce((agg, current) => {
        const { state } = current;
        return {
          ...agg,
          [state.colour]: {
            title: "",
            value: (agg[state.colour]?.value || 0) + 1,
            color: state.colour,
          },
        };
      }, {});

    return Object.values(result);
  };

  return (
    <DateRangePickerWrapper>
      <DatePickerCalendar
        date={firstDayOfMonth}
        onDateChange={selectedDateChangedLocal}
        locale={enGB}
        month={firstDayOfMonth}
        modifiers={modifiers}
        modifiersClassNames={modifiersClassNames}
        renderDay={({
          handleClick,
          handleMouseEnter,
          handleMouseLeave,
          height,
          dayOfMonth,
          date,
          locale,
          rootClassName,
        }) => (
          <>
            <span
              className={rootClassName
                .replace("-disabled", "")
                .replace("-selected", "")}
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onTouchEnd={handleClick}
              style={{ height }}
            >
              {rootClassName.includes("-today") && <TodayMarker />}
              <PieChart data={getPieChartData(date)} lineWidth={20} />
              <div className="fillContainer">
                {dayOfMonth === 1 && (
                  <span className="nice-dates-day_month">
                    {format(date, "LLL", { locale })}
                  </span>
                )}
                <span className="nice-dates-day_date">{dayOfMonth}</span>
              </div>
            </span>
          </>
        )}
      />
    </DateRangePickerWrapper>
  );
};

Month.propTypes = {
  month: PropTypes.string.isRequired,
  days: PropTypes.object.isRequired, // todo: associative array could be specific
  selectedDateChanged: PropTypes.func.isRequired,
};

export default memo(Month);
