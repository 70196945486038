import React, { memo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {} from "./RAK.styled";
import moment from "moment";
import TickItem from "../TickItem/TickItem";

const RAK = ({ elm, firstScreenInteraction }) => {
  const history = useHistory();
  const location = useLocation();
  function clicked() {
    if (!elm.courseElmInteractions.length) {
      history.push(
        `/random-act-of-kindness?date=${
          firstScreenInteraction
            ? moment(firstScreenInteraction).format("YYMMDD")
            : moment().format("YYMMDD")
        }&next=${location.pathname}`
      );
    } else {
      history.push(
        "/calendar-detail?selectedDate=" +
          moment(elm.courseElmInteractions[0].createdAt).format("YYMMDD")
      );
    }
  }

  return (
    <TickItem
      onClick={clicked}
      label={"Perform 1 Random Act of Kindness"}
      complete={elm.courseElmInteractions.length}
    />
  );
};

export default memo(RAK);
