import React, { memo, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  PageContent,
  InputWrapper,
  SaveButton,
  DatePickerContainer,
  NavRoot,
  BoxTitle,
} from "./Gratitude.styled";
import {
  NavTitle,
  PageRoot,
  InputBoxOutlined,
  ActionButtonContainer,
} from "../../components/common";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import "../../../css/datePicker.css";
import { get, post, put } from "../../actions/api";
import { taskCompletedToday } from "../../helpers/dailyCompletionStorageHelper";

const Gratitude = () => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const dateParam = params.get("date");
  const nextPage = params.get("next");
  const [grat1, setGrat1] = useState("");
  const [grat2, setGrat2] = useState("");
  const [grat3, setGrat3] = useState("");
  const [gratitudeDate, setGratitudeDate] = useState(
    dateParam
      ? moment(dateParam, "YYMMDD").set("hour", 12)
      : moment().set("hour", 12)
  );
  const [dateFocused, setDateFocused] = useState(false);
  const { ids } = useParams();
  const [gratIds, setGratIds] = useState(null);

  useEffect(() => {
    if (ids) {
      setGratIds(ids.split(","));
    }
  }, [ids]);

  useEffect(() => {
    async function getGrats() {
      for (let i = 0; i < gratIds.length; i++) {
        const id = gratIds[i];
        const g = await get("gratitude/" + id);
        [setGrat1, setGrat2, setGrat3][i](g.gratitude.gratitude);
      }
    }
    if (gratIds) {
      getGrats();
    }
  }, [gratIds]);

  const save = async () => {
    // gratitudeDate.set({ hour: 12 });
    if (gratIds) {
      gratIds.every(async (id, i) => {
        await put("gratitude/" + id, {
          gratitude: [grat1, grat2, grat3][i],
          gratitudeTime: gratitudeDate.valueOf(),
        });
      });
      const next =
        nextPage ??
        `/suggestions?bgCol=8F79C8%26title=Gratitude%26eventType=grat%26eventId=${gratIds.join(
          "-"
        )}`;
      history.push(`/feedback/gratitude?next=${next}`);
    } else {
      const payload = {
        gratitude: [grat1, grat2, grat3],
        gratitudeTime: gratitudeDate.valueOf(),
      };
      const { gratitude } = await post("gratitude", payload);
      taskCompletedToday("gratitude");
      const next =
        nextPage ??
        `/suggestions?bgCol=8F79C8%26title=Gratitude%26eventType=grat%26eventId=${gratitude
          .map((g) => g.id)
          .join("-")}`;
      history.push(`/feedback/gratitude?next=${next}`);
    }
  };

  return (
    <PageRoot>
      <NavRoot>
        <NavTitle>Daily Gratitude</NavTitle>
        <DatePickerContainer>
          <SingleDatePicker
            date={gratitudeDate}
            onDateChange={setGratitudeDate}
            focused={dateFocused}
            onFocusChange={({ focused }) => {
              setDateFocused(focused);
            }}
            numberOfMonths={1}
            displayFormat="dddd, Do MMM"
            isOutsideRange={(date) =>
              date.startOf("day").isAfter(moment().startOf("day"))
            }
            hideKeyboardShortcutsPanel={true}
            id="gratitude_in_date"
          />
        </DatePickerContainer>
      </NavRoot>
      <PageContent>
        <InputWrapper>
          <BoxTitle>What are you feeling grateful for today?</BoxTitle>
          <InputBoxOutlined
            title="1."
            value={grat1}
            onChange={setGrat1}
            placeholder="I'm grateful for..."
          />
          <InputBoxOutlined
            title="2."
            value={grat2}
            onChange={setGrat2}
            placeholder="I'm grateful for..."
          />
          <InputBoxOutlined
            title="3."
            value={grat3}
            onChange={setGrat3}
            placeholder="I'm grateful for..."
          />
        </InputWrapper>
      </PageContent>
      <ActionButtonContainer>
        <SaveButton onClick={save}>Save</SaveButton>
      </ActionButtonContainer>
    </PageRoot>
  );
};

export default memo(Gratitude);
