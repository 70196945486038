import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export const Heading = styled.h1`
  color: ${({ theme }) => theme.primary.colours.heading};
  font-size: ${({ theme }) => theme.primary.fontSize.primaryHeading};
  font-weight: bold;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const HeadingP = styled.p`
  color: ${({ theme }) => theme.primary.colours.heading};
  font-size: ${({ theme }) => theme.primary.fontSize.primaryHeading};
  font-weight: bold;
  text-align: center;
  margin-top: 0.5rem;
  vertical-align: center;
`;

export const Warning = styled.p`
  text-align: center;
  font-weight: bold;
  color: #222;
  padding: 15px 20px;
  border-radius: 30px;
  background-color: white;
  border: ${({ colour }) => colour} 4px solid;
  position: relative;
  width: 400px;
  margin-top: -10px;
	// box-shadow: 3px 3px 0 0 #bbb;
  &:before {
    content: " ";
    height: 0;
    position: absolute;
    width: 0;
    left: ${({ emotionIndex }) => 42 + emotionIndex * 75}px;
    top: -23px;
    border: 10px solid transparent;
    border-bottom-color: ${({ colour }) => colour};
  }
`;

const OnboardingButtonContainer = styled.div`
  align-self: flex-end;
  padding: 20px 20px 0 0;
  cursor: pointer;
`;

export const OnboardingButton = ({ onClick }) => (
  <OnboardingButtonContainer onClick={onClick}>
    <FontAwesomeIcon
      icon={faBars}
      style={{
        color: "#000",
        width: 30,
        height: 30,
      }}
    />
  </OnboardingButtonContainer>
);
