import React from "react";
import { Text, SlideWrapper, SlideContent } from "./Slides.styled";
import { ButtonWhite } from "../../../../components/common";
import VimeoPlayer from "@u-wave/react-vimeo";

export function Slide3(props) {
  return (
    <SlideWrapper>
      <SlideContent>
        <Text></Text>
        <Text>
          This is not just a mental state, it is a physical response within our
          nervous system
        </Text>
        <ButtonWhite
          onClick={props.nextSlide}
          style={{ width: "100%", maxWidth: 300 }}
        >
          Next
        </ButtonWhite>
      </SlideContent>
    </SlideWrapper>
  );
}
