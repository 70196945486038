import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ContentBox from '../ContentBox/ContentBox';
import { TitleText } from '../common.styled';
import { Input } from './InputBox.styled';

const InputBox = ({ title, placeholder, value, onChange }) => {
  const onChangeLocal = event => onChange(event.target.value);

  const body = (
    <Input type="text" value={value} placeholder={placeholder} onChange={onChangeLocal} />
  );

  return (
    <ContentBox
      titleContent={<TitleText>{title}</TitleText>}
      body={body}
    />
  );
};

InputBox.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default memo(InputBox);
