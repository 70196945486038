import styled from 'styled-components';

export const ContentRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 20px;
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  width: 200px;

  > span {
    font-weight: bold;
  }
`;
