export default {
  primary: {
    colours: {
      baseColours: {
        checkin: "#FFCB68",
        breathe: "#b5e3fa",
        gratitude: "#8F79C8",
        foundation: "#C6D2E1",
        rak: "#58C6AC",
      },
      buttons: {
        delete: "red",
        edit: "#676D77",
      },
      states: {
        Negative: "#fcaf6d",
        Ok: "#737ca8",
        Positive: "#58c6ac",
        "Very Negative": "#e96667",
        "Very Positive": "#7fc1e2",
      },
      heading: "#263b59",
      reflectionContent: "#676D77",
      pageBackground: "#f7f8f9",
      primaryAction: "#233949",
      grey_light: "#eee",
      grey_ml: "#bbbbbb",
      grey_medium: "#575757",
      grey_dark: "#222222",
      blue_grey: "#282c34",
      grey_light2: "#dfe4e8",
      blueL: "#C6D2E1",
      blueD: "#22282b",
      blueMD: "#263b59",
      blueM: "#233949",
      greenM: "#136665",
      greenL: "#1d7f7a",
      greenPositive: "#58c6ac",
      greenVL: "#a2cdcd",
      yellow: "#FFCB68",
      orange: "#FFCB68",
      greenBright: "#58c6ac",
    },
    fontSize: {
      primaryHeading: "2rem",
      secondaryHeading: "1.4rem",
      itemTitle: "1.5",
    },
    home: {
      colours: {
        reflection: "#FFCB68",
        gratitude: "#8F79C8",
        survey: "#1D7F7A",
        comingSoon: "#C6D2E1",
        foundation: "#C6D2E1",
        breathe: "#b5e3fa",
        rak: "#58C6AC",
      },
    },
    calendar: {
      dayTitleColour: "#1D2C41",
      dayTitleBorder: "#C6D2E1",
      arrowColour: "black",
      dayHighlight: "#1D2C41",
    },
    reflectionAnalytics: {
      title: "#1D2C41",
      dateSelectionBackground: "#233949",
      navTile: "#1D7F7A",
    },
  },
};
