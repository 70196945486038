import { get, api } from '../../../actions/api';

export function getSignposts(callback) {
	let t = this;
	api("signpost", "GET", {}, async (spInfo) => {
		let signposts = spInfo.signpostGroups.map(spg => {
			let terms = {};
			let termIds = [];
			spg.signpostTermLinks.forEach(spt => {
				termIds.push(spt.term.id);
				terms[spt.term.id] = {
					term: spt.term.term,
				}
			})
			return {
				title: spg.title,
				body: spg.body,
				level: spg.level,
				contacts: spg.signposts,
				termIds,
				terms
			}
		})
		callback(signposts);
	},
		{
			fetch: 'background',
			store: true,
		})
}

export async function getGeneralSignposting(callback) {
	const response = await get("signpost?tree=true");
	let signpostGroups = response.signpostGroups.filter(sg => {
		if (sg.accOwner !== null) {
			return false;
		}
		return true;
	})

	const lookup = buildLookup(signpostGroups[0], {}, [0]);
	
	callback({ signpostGroups, lookup });
}

const buildLookup = (SPG, lookupLocal, index) => {
	let lookup = lookupLocal;
	if (!lookup[SPG.id]) {
		lookup[SPG.id] = index;
	}
	if (SPG.children) {
		SPG.children.forEach((s, i) => {
			const newIndex = [...index];
			newIndex.push(i);
			lookup[s.id] = newIndex;
			if (s.children) {
				lookup = buildLookup(s, lookup, newIndex);
			}
		});
	}
	return lookup;
};