import React, { memo, useEffect } from "react";
import { post } from "../../../../../actions/api";
const Exercise = ({ elm, latestInteractions, course, recordInteraction }) => {
  const content = elm.content;
  const interaction = latestInteractions[elm.id];

  useEffect(() => {
    // log event
    post("event", {
      // noduplicates: true,
      event: {
        eventType: "progressMk",
        eventData: {
          name: content.name,
          courseId: course.id,
          courseTitle: course.title,
          elmId: elm.id,
        },
      },
    });

    if (!interaction) {
      recordInteraction(elm.id, true);
    }

    if (elm.content.completeActivity) {
      post("course/completerequired", {
        eventType: elm.content.completeActivity,
      });
    }
  }, [interaction]);

  return null;
};

export default memo(Exercise);
