import React from "react";
import { Text, SlideWrapper, SlideContent } from "./Slides.styled";
import { ButtonWhite } from "../../../../components/common";
import VimeoPlayer from "@u-wave/react-vimeo";

export function Slide4(props) {
  return (
    <SlideWrapper>
      <SlideContent>
        <Text></Text>
        <Text>
          One that keeps us locked in a state of worry, anxiety, overthinking
          and has an impact on our physical health too
        </Text>
        <ButtonWhite
          onClick={props.nextSlide}
          style={{ width: "100%", maxWidth: 300 }}
        >
          Next
        </ButtonWhite>
      </SlideContent>
    </SlideWrapper>
  );
}
