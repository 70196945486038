import React from "react";
import "../../../../css/ControlPanel.css";
import { BarChart, Bar, XAxis, YAxis, Cell,ResponsiveContainer } from "recharts";

class CheckinStates extends React.Component {
  render() {
    if (!this.props.stateTotals.length) {
      return null;
    }

    // convert them to an array formatted for rechart
    let stateTotalArray = this.props.stateTotals
      .filter((s) => s.term)
      .map((s) => {
        return {
          name: s.term,
          total: s.total,
          colour: s.colour,
        };
      });

    // If there's an inf filter we should remove the main inf (the one being filtered for) and add a title to explain
    const subTitle =
      this.props.dataFilter.inf !== "all" ? (
        <div>
          <h2 className="chartSubTitle">
            Emotions Related To <b>{this.props.dataFilter.inf}</b>
          </h2>
          <p style={{ fontSize: 14 }}>&nbsp;</p>
        </div>
      ) : null;

    // sort and get the top 10
    stateTotalArray.sort((a, b) => (a.total < b.total ? 1 : -1));
    stateTotalArray = stateTotalArray.slice(0, 10);

    const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
      return (
        <text
          x={x + width + 5}
          y={y + height - 2}
          fill="#666"
          textAnchor="left"
          dy={-6}
        >
          {value}
        </text>
      );
    };
    return (
      <>
        {subTitle}
        <ResponsiveContainer height={400}>
          <BarChart
            data={stateTotalArray}
            layout="vertical"
            margin={{
              top: 20,
              right: 30,
              left: 80,
              bottom: 5,
            }}
            barSize={25}
          >
            <YAxis
              dataKey="name"
              scale="point"
              padding={{ top: 10, bottom: 10 }}
              legendType="none"
              type="category"
            />
            <XAxis type="number" style={{ display: "none" }} />
            {/* <Tooltip /> */}
            <Bar dataKey="total">
              {" "}
              {/*label={renderCustomBarLabel}*/}
              {stateTotalArray.map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.colour}
                    background={{ fill: entry.colour }}
                    onClick={() => {
                      this.props.dataFilterSet({
                        target: {
                          name: "state",
                          value: entry.name,
                        },
                      });
                    }}
                  />
                );
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </>
    );
  }
}

export default CheckinStates;
