import { useEffect, useState } from 'react';
import { get } from '../actions/api';

export default function useCourseData() {
  const [courses, setCourses] = useState([]);
  useEffect(() => {
    async function getCourseData() {
      const data = await get('course?subscribed=true');
      setCourses(data.courses);
    }

    getCourseData();
  }, []);

  return { courses };
}
