import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get } from "../../actions/api";
import {
  ButtonWhite,
  NavRoot,
  NavTitle,
  PageRoot,
  Days,
  H2,
} from "../../components/common";
import { buildEventDayLookup } from "../../helpers/calendarHelpers";
import {
  BreatheTile,
  CourseAndFeatureTile,
  GratefulTile,
  RAKTile,
  ReflectionTile,
} from "../Home/Tiles";
import {
  PageContent,
  Quote,
  QuoteContainer,
  QuoteCredit,
  Section,
  Tiles,
} from "./Suggestions.styled";

function useInitialState() {
  const params = new URLSearchParams(window.location.search);
  const bgCol = params.get("bgCol");
  const title = params.get("title");
  const eventId = params.get("eventId");
  const eventType = params.get("eventType");
  return {
    bgCol,
    title,
    eventId,
    eventType,
  };
}

const Suggestions = () => {
  // const history = useHistory();
  // const { title, backgroundColor, event } = ctx.viewData;
  const [quote, setQuote] = useState(null);
  const [suggestions, setSuggestions] = useState(null);
  const [days, setDays] = useState([]);
  const { bgCol, title, eventType, eventId } = useInitialState();

  useEffect(() => {
    async function getQuotes() {
      const quotes = await get("quotes");
      if (quotes.quoteOfTheWeek) {
        setQuote(quotes.quoteOfTheWeek);
      }
    }

    async function getSuggestions() {
      const suggestions = await get("reports/suggestions");
      if (suggestions) {
        setSuggestions(suggestions.suggestions.currated);
      }
    }

    async function getEvent() {
      let event = null;
      if (eventType === "RAK") {
        const { RAKs } = await get("RAK/records");
        event = RAKs.find((r) => Number(r.id) === Number(eventId));
      } else if (eventType === "checkin") {
        const { checkin } = await get(`checkin/${eventId}`);
        event = {
          ...checkin,
          eventTime: checkin.checkinTime,
          colour: checkin.state.colour,
          eventType: "checkin",
        };
      } else if (eventType === "grat") {
        const { gratitudes } = await get("gratitude");
        event = gratitudes.find((g) => g.gratitudeIds.join("-") === eventId);
      } else if (eventType === "breathe") {
        event = {
          eventType: "breathe",
          eventTime: new Date(),
          eventData: {
            duration: Number(eventId),
          },
        };
      }
      setDays([{ ...event, eventTime: moment(event.eventTime) }]);
    }

    if (eventType && eventId) {
      getEvent();
    }

    getSuggestions();
    getQuotes();
  }, []);

  return (
    <PageRoot>
      <NavRoot>
        <NavTitle>{title ?? "What's Next..."}</NavTitle>
      </NavRoot>
      <PageContent
        style={{
          backgroundColor: bgCol ? "#" + bgCol : "#eee",
          paddingBottom: 20,
        }}
      >
        {days[0] && (
          <Days
            selectedDate={days[0].eventTime}
            eventDayLookup={buildEventDayLookup(days)}
            deleteEvent={null}
            editEvent={null}
            oneDay={true}
            style={{ width: "100%", maxWidth: 680, marginBottom: 20 }}
            styleInner={{ width: "100%", maxWidth: 680 }}
          />
        )}
        <Link
          to="/calendar"
          style={{
            width: "100%",
            maxWidth: 600,
            marginBottom: 20,
            display: "block",
          }}
        >
          <ButtonWhite
            style={{ width: 300, margin: "0 auto 20px auto", display: "block" }}
          >
            Calendar
          </ButtonWhite>
        </Link>
        <Section>
          {suggestions && suggestions.length > 0 && (
            <>
              <H2 style={{ textAlign: "center" }}>Recommendations</H2>
              <Tiles>
                {suggestions &&
                  suggestions.map((itm, i) => {
                    if (itm.contentType === "course") {
                      return (
                        <CourseAndFeatureTile
                          key={i}
                          id={itm.courseId}
                          title={itm.title}
                          url={itm.internalLink}
                          bgCol={itm.colour}
                          superTitle={null}
                          hideRepeatIcon={true}
                        />
                      );
                    } else if (itm.contentType === "rak") {
                      return <RAKTile hideRepeatIcon={true} key={i} />;
                    } else if (itm.contentType === "breathe") {
                      return <BreatheTile hideRepeatIcon={true} key={i} />;
                    } else if (itm.contentType === "gratitude") {
                      return <GratefulTile hideRepeatIcon={true} key={i} />;
                    } else if (itm.contentType === "checkin") {
                      return <ReflectionTile hideRepeatIcon={true} key={i} />;
                    } else {
                      console.log(itm);
                      return null;
                    }
                  })}
              </Tiles>
            </>
          )}
          {quote && (
            <QuoteContainer>
              <Quote>"{quote.quote}"</Quote>
              <QuoteCredit>{quote.by}</QuoteCredit>
            </QuoteContainer>
          )}
        </Section>
      </PageContent>
    </PageRoot>
  );
};

export default memo(Suggestions);
