import React, { memo } from 'react';
import ExpandingTextarea from 'react-expanding-textarea';
import { TextAreaContainer } from './TextAreaBox.styled';
import ContentBox from "../ContentBox/ContentBox";
import { TitleText } from '../common.styled';

const TextAreaBox = ({ title, onChange, ...props }) => {
  const onChangeLocal = event => {
    if (onChange != null) {
      onChange(event.target.value);
    }
  };

  const body = (
    <TextAreaContainer>
      <ExpandingTextarea {...props} onChange={onChangeLocal}/>
    </TextAreaContainer>
  );

  return (
    <ContentBox
      titleContent={<TitleText>{title}</TitleText>}
      body={body}
    />
  );
};

export default memo(TextAreaBox);
