import React from 'react';
import '../../css/ControlPanel.css';
import UserList from './UserList';
import Invite from './Invite';
import UserEdit from './UserEdit';
import { api } from '../actions/api.js';

class UserAccounts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			refreshUserList: 0,
			selectedUser: null,
			users: [],
			cutomProps: {}
		};
	}

	componentDidMount() {
		this.getUsers();
	}

	getUsers = () => {
		const t = this;
		api('user/employees', "get", {}, (response) => {
			t.setState({ users: response.users, usersOrig: JSON.parse(JSON.stringify(response.users)), customProps: response.customProps })
		})
	}

	selectUser = (selectedUser) => {
		this.setState({ selectedUser })
	}

	save = (e, clearUserSelection) => {
		e.preventDefault()

		// save permissions
		this.savePermissions();

		const usr = this.state.selectedUser;
		// save selectedUser
		api("user/" + usr.id, "put", {
			first_name: usr.first_name,
			last_name: usr.last_name,
			email: usr.email,
		}, () => {
			// now update custom properties
			if (Object.entries(usr.customProps).length) {
				Object.entries(usr.customProps).forEach(cp => {
					api("customprop/value", "post", {
						propId: cp[0],
						propValue: cp[1],
						userId: usr.id
					}, () => { });
				})
			}

			// all done
			this.setState({
				usersOrig: JSON.parse(JSON.stringify(this.state.users)),
			})
			if (clearUserSelection) {
				this.setState({
					selectedUser: null
				})
			}
		})
	}

	cancel = (e, clearUserSelection) => {
		e.preventDefault()
		this.setState({
			users: JSON.parse(JSON.stringify(this.state.usersOrig)),
		})

		// if (clearUserSelection) {
			this.setState({
				selectedUser: null
			})
		// }
	}

	resetSelectedUserPermissions = () => {
		let orig = JSON.parse(JSON.stringify(this.state.usersOrig));
		let user = this.state.selectedUser;
		orig.forEach(u => {
			if (u.id === user.id) {
				user.permissions = u.permissions
			}
		})
		this.setState({ user });
	}

	savePermissions = (permissionName) => {
		let orig = JSON.parse(JSON.stringify(this.state.usersOrig));
		let user = this.state.selectedUser;
		let origUser;
		orig.forEach(u => {
			if (u.id === user.id) {
				origUser = u;
			}
		})

		let dataRestrictions = [];
		let userRestrictions = [];
		if (user.permissions[permissionName]) {
			if (user.permissions[permissionName][this.state.selectedUser.accOwner]) {
				if (user.permissions[permissionName][this.state.selectedUser.accOwner].dataRestrictions) {
					dataRestrictions = user.permissions[permissionName][this.state.selectedUser.accOwner].dataRestrictions
				}
				if (user.permissions[permissionName][this.state.selectedUser.accOwner].userRestrictions) {
					userRestrictions = user.permissions[permissionName][this.state.selectedUser.accOwner].userRestrictions
				}
			}
		}
		dataRestrictions = JSON.stringify(dataRestrictions);
		userRestrictions = JSON.stringify(userRestrictions);

		if (!origUser.permissions[permissionName]) {
			api("user/permission/grant", "post", {
				grantedTo: this.state.selectedUser.id,
				permissionName,
				grantedForAccOwner: this.state.selectedUser.accOwner,
				userRestrictions,
				dataRestrictions,
			})
		} else if (origUser.permissions[permissionName][this.state.selectedUser.accOwner] && user.permissions[permissionName][this.state.selectedUser.accOwner]) {
			api("user/permission/grant", "post", {
				grantedTo: this.state.selectedUser.id,
				permissionName,
				grantedForAccOwner: this.state.selectedUser.accOwner,
				userRestrictions,
				dataRestrictions,
			})
		} else if (origUser.permissions[permissionName][this.state.selectedUser.accOwner] && !user.permissions[permissionName][this.state.selectedUser.accOwner]) {
			api("user/permission/delete", "delete", { permissionId: origUser.permissions[permissionName][this.state.selectedUser.accOwner].id })
		} else {
			api("user/permission/grant", "post", {
				permissionName,
				grantedTo: this.state.selectedUser.id,
				grantedForAccOwner: this.state.selectedUser.accOwner,
				userRestrictions,
				dataRestrictions,
			})
		}
	}

	render() {
		return (
			<div className="columns">
				<div className="column is-one-half">
					<UserList users={this.state.users} selectUser={this.selectUser} selectedUser={this.state.selectedUser} />
				</div>
				<div className="column is-one-half">
					<Invite refreshUserList={()=>{this.setState({refreshUserList: Math.random()})}} />
					<UserEdit resetSelectedUserPermissions={this.resetSelectedUserPermissions} selectedUser={this.state.selectedUser} customProps={this.state.customProps} save={this.save} cancel={this.cancel} />
				</div>
			</div>
		);
	}
}

export default UserAccounts;
