import styled from 'styled-components';

export const Container = styled.div`
	color: ${({ theme }) => theme.primary.colours.blueMD};
	/* border-width: 1px;
	border-style: solid;
	border-color: ${props => props.completed ? ({ theme }) => theme.primary.colours.blueMD : "transparent"}; */
	border-radius: 5px;
	width: 100%;
	margin: 10px 0;
	cursor: pointer;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
`;

export const Top = styled.div`
	padding: 10px 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const Label = styled.div`
	font-size: 1.1rem;
	font-weight: bold;
`;

export const Icon = styled.div`
	font-size: 30px;
`;

export const LinkArea = styled.a`
	margin-right: 10px;
	padding: 10px;
	font-weight: bold;
	text-align: left;
	display: block;
	color: ${({ theme }) => theme.primary.colours.blueMD};
	text-decoration: none;
	padding-left: 30px;
	font-size: 1rem;
`;

export const Bottom = styled.div`
	padding: 10px 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;