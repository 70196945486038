export const habitActivities = {
  checkin: {
    view: "Checkin",
    viewData: {},
    title: "Daily Reflection",
    type: "checkin",
    completeonClick: false,
  },
  RAK: {
    view: "RAK",
    viewData: {},
    title: "Random Act Of Kindness",
    type: "RAK",
    completeonClick: false,
  },
  gratitude: {
    view: "Gratitude",
    viewData: {},
    title: "Gratitude",
    type: "gratitude",
    completeonClick: false,
  },
  "checkin-brainDump": {
    view: "Checkin",
    viewData: { checkinMode: "brainDump" },
    title: "Brain Dump",
    type: "checkin-brainDump",
    completeonClick: false,
  },
  "checkin-journal": {
    view: "Checkin",
    viewData: { checkinMode: "journal" },
    title: "Journalling",
    type: "checkin-journal",
    completeonClick: false,
  },
  breathe: {
    title: "De-Stress Deep Breathing",
    view: "Breathe",
    viewData: {},
    type: "breathe",
    completeonClick: false,
  },
  course6: {
    title: "Program Your Mind For Success",
    view: "Course",
    viewData: { courseId: 6 },
    type: "course6",
    completeonClick: true,
  },
  course8: {
    title: "Morning Meditation",
    view: "Course",
    viewData: { navigateTo: { courseId: 8 } },
    type: "course8",
    completeonClick: true,
  },
  course9: {
    title: "Guided Gratitude",
    view: "Course",
    viewData: { navigateTo: { courseId: 9 } },
    type: "course9",
    completeonClick: true,
  },
  course16: {
    title: "Body Scan",
    view: "Course",
    viewData: { navigateTo: { courseId: 16 } },
    type: "course16",
    completeonClick: true,
  },
  course17: {
    title: "Noting Technique",
    view: "Course",
    viewData: { navigateTo: { courseId: 17 } },
    type: "course17",
    completeonClick: true,
  },
};