import React, { memo, useEffect, useState } from "react";
import moment from "moment";
import LeftArrow from "@ant-design/icons/lib/icons/LeftOutlined";
import { useHistory } from "react-router-dom";
import { PageRoot, TopNavContainer, Days } from "../../components/common";
import { NavTitle } from "./CalendarDetail.styled";
import { PageContent } from "./CalendarDetail.styled";
import { useCalendarData } from "../../hooks";
import Months from "./Months/Months";
import { deleteEvent as deleteEventApi } from "../../helpers/eventHelpers";
import { taskRemovedToday } from "../../helpers/dailyCompletionStorageHelper";
import { get } from "../../actions/api";

const CalendarDetail = () => {
  const history = useHistory();
  const [deletedEventKeys, setDeletedEventKeys] = useState([]);
	const [courseOutlines, setCourseOutlines] = useState(null);

  const { eventMonthLookup, months, eventDayLookup, days } = useCalendarData(
    deletedEventKeys
  );
  const params = new URLSearchParams(window.location.search);
  const selectedDateText = params.get("selectedDate");
  const selectedDateParam = selectedDateText
    ? moment(selectedDateText, "YYMMDD")
    : moment().startOf("day");
  const [selectedDate, setSelectedDate] = useState(selectedDateParam);

	useEffect(()=>{
		async function getCourses(){
			const courses = await get("course?outline=true");
			setCourseOutlines(courses.courses);
		}

		getCourses();
	},[])
  const dateClicked = (date, hasEntries) => {
    if (hasEntries === true) {
      setSelectedDate(date);
    } else if (
      selectedDate.startOf("day").isSameOrBefore(moment().startOf("day"))
    ) {
      history.push(`daily-reflection?date=${date.format("DDMMYY")}`);
    }
  };

  const deleteEvent = (event) => {
    // todo deal with error
    deleteEventApi(event);
    setDeletedEventKeys((keys) => [...keys, event.key]);
    const eventIsToday = moment(event.eventTime).isSame(moment(), "day");
    if (eventIsToday === true) {
      taskRemovedToday(event.eventType);
    }
  };

  const editEvent = (event) => {
    switch (event.eventType) {
      case "gratitude":
        history.push(`gratitude/${event.gratitudeIds.join(',')}`);
        break;
      case "checkin":
        history.push(`daily-reflection/${event.id}`);
        break;
      default:
        return false;
        break;
    }
  };
  return (
    <PageRoot>
      <TopNavContainer>
        <NavTitle>
          <LeftArrow onClick={() => history.goBack()} />
          Calendar Details
        </NavTitle>
      </TopNavContainer>
      <PageContent>
        <Months
          months={months}
          eventMonthLookup={eventMonthLookup}
          dateClicked={dateClicked}
        />
        <Days
          selectedDate={selectedDate}
          days={days}
          eventDayLookup={eventDayLookup}
          deleteEvent={deleteEvent}
          editEvent={editEvent}
					courseOutlines={courseOutlines}
        />
      </PageContent>
    </PageRoot>
  );
};

export default memo(CalendarDetail);
