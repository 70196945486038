import React, { memo } from "react";
import PropTypes from "prop-types";
import { SectionMarker, SectionTitleText, Text } from "../Day.styled";
import SectionTitle from "../SectionTitle/SectionTitle";

const RakDay = ({ data, deleteClicked }) => {
  const { eventTime } = data;
  const { kindness } = data.RAK;
  return (
    <div>
      <SectionTitle
        time={eventTime}
        text={
          <>
            <SectionMarker colour="#58C6AC" />
            <SectionTitleText>
              Act of Kindness
            </SectionTitleText>
          </>
        }
        deleteClicked={deleteClicked}
      />
      <Text>{kindness}</Text>
    </div>
  );
};

RakDay.propTypes = {
  deleteClicked: PropTypes.func,
};

export default memo(RakDay);
