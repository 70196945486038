import React, { memo } from 'react';
import { appStore, playStore } from '../../constants/urls';
import bubbles from "../../../imgs/bubbles_white_square_2.svg";

const GetTheApp = () => {
  const styles = {
    img: {
      width: "auto",
      height: 60
    },
    form: {
      maxWidth: 640,
    },
    account: {
      backgroundColor: "#eee",
      fontSize: 14,
      borderRadius: 5,
      display: "inline-block",
      padding: "5px 10px",
      color: "#136665"
    },
    downloadLinks: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 10,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 10,
    },
    install: {
      margin: "10px 0 20px 0",
      display: "inline-block",
      borderRadius: 5,
      overflow:"hidden",
    },
    installIcon: {
      height: 60,
    },
    container: {
      padding: 20,
      width: "100%",
      maxWidth: 640,
      margin: "0 auto"
    },
    wrapper: {
      backgroundColor: "#F1F2F6",
      minHeight: "100%",
      backgroundImage: `url(${bubbles})`,
      backgroundSize: "cover"
    },
    appStoreImg:{
      width: "50%"
    }
  }

  return (
    <div style={styles.wrapper}>
      <div style={styles.container}>
        <h1 className='title is-3 has-text-centered' style={{ marginTop: 50 }}>
          Download The Mobile App Now
        </h1>
        <div style={styles.downloadLinks}>
          <a style={styles.install} target="_blank" rel="noopener noreferrer" href={appStore}>
            <img src={require('../../../imgs/store_apple.png')} style={styles.installIcon} />
          </a>
          <a style={styles.install} target="_blank" rel="noopener noreferrer" href={playStore}>
            <img src={require('../../../imgs/store_google.png')} style={styles.installIcon} />
          </a>
        </div>
        <h2 className='title is-3' style={{ marginTop: 50, }}>Or search &ldquo;<span style={{color:"#2A796F"}}>Lume App</span>&rdquo;...</h2>
        <div style={styles.buttonContainer}>
          <img src={require("../../../imgs/app_store_apple.png")} style={styles.appStoreImg} />
          <img src={require("../../../imgs/app_store_android.png")} style={styles.appStoreImg} />
        </div>
      </div>
    </div>
  );
};

export default memo(GetTheApp);
