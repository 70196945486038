import styled from 'styled-components';

export const Contact = styled.div`
	display:flex;
	flex-direction: row;
	background-color: #fff;
	padding: 10px;
	border-radius: 10px;
	width: 100%;
	max-width: 500px;
	margin: 10px auto 0 auto;
`;

export const Link = styled.a`
	margin-top: 5px;
`;

export const LinkText = styled.span`
	
`;

export const PhotoContainer = styled.div`
	min-width: 100px;
	height: 100px;
	border-radius: 50px;
	overflow: hidden;
	margin-right: 10px;
`;

export const Details = styled.div`
	display:flex;
	flex-direction: column;
`;

export const ContactsWrapper = styled.div`
	
`;

export const ContactName = styled.h4`
	font-size: 1.2rem;
	font-weight: bold;
`;

export const ContactTitle = styled.h5`
	font-size: 1rem;
	line-height: 1.2;
	font-weight: normal;
	margin-bottom: 5px;
`;
