import React from 'react';
import { SubCat, Wrapper } from './SignpostSubGroups.styled';


export default function SignpostSubGroups(props) {
	if(!props.children){
		return null
	}
	
	let children = props.children.map((child, i) => {
		return (
			<SubCat onClick={()=>{props.changeGroup(child.id)}} key={i}>
				<span>{child.titleShort}</span>
			</SubCat>
		)
	})


	return (
		<Wrapper>
			{children}
		</Wrapper>
	)
	
}

