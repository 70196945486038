import React from "react";
import "../../../../css/reports.css";
import CheckinPie from "./CheckinPie";
import CheckinStates from "./CheckinStates";
import CheckinInfluences from "./CheckinInfluences";
import { api } from "../../../actions/api.js";
import moment from "moment";
import "rsuite/dist/styles/rsuite-default.css";
import CheckinPercentageAreaChart from "./CheckinPercentageAreaChart";
import ScoreSection from "./ScoreSection/ScoreSection";
import {
  NavTitle,
  PageRoot,
  TopNavContainer,
} from "../../../components/common";
import MonthPicker from "simple-react-month-picker";
class ReportsCheckins extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkinStateGroupData: [],
      termKeys: [],
      termLookup: {},
      stateGroupLookup: {},
      checkins: [],
      useagePercent: 0,
      userTotal: 0,
      dateFrom: moment.utc().subtract(2, "month").startOf("month").toDate(),
      dateTo: moment.utc().endOf("month").toDate(),
      dataFilter: {
        stateGroup: "all",
        state: "all",
        inf: "all",
      },
      customProps: [],
      peopleFilters: {},
      filtering: false,
      notEnoughData: false,
      influenceTotals: [],
      stateTotals: [],
      stateGroupTotals: null,
    };
  }

  componentDidMount() {
    this.getCheckins();
    this.getCustomProps();
  }

  getCheckins = () => {
    let t = this;
    let params = {
      ...t.state.dataFilter,
      ...t.state.peopleFilters,
    };
    if (this.state.dateFrom !== null) {
      params.from = moment(this.state.dateFrom).toDate();
    }
    if (this.state.dateTo !== null) {
      params.to = moment(this.state.dateTo).toDate();
    }
    api("reports/checkins", "GET", params, (d) => {
      if (!d.checkins || !d.stateGroupLookup) {
        t.setState({ notEnoughData: true });
        return null;
      } else {
        t.setState(
          {
            stateGroupLookup: d.stateGroupLookup,
            checkins: d.checkins,
            termLookup: d.termLookup,
            influenceTotals: d.influenceTotals,
            userTotal: d.employeeTotal,
            userTotalActive: d.employeeTotalActive,
            stateTotals: d.stateTotals,
            useagePercent:
              d.checkinUserCountPreDataFilter && d.employeeTotalActive
                ? Math.round(
                    100 *
                      (d.checkinUserCountPreDataFilter / d.employeeTotalActive)
                  )
                : 0,
            notEnoughData: false,
          },
          t.compileDate
        );
      }
    });
    t.checkFiltering();
  };

  getCustomProps = () => {
    let t = this;
    api("customprop", "GET", null, (d) => {
      if (d.customProps) {
        t.setState({ customProps: d.customProps }, this.getCustomPropSelects);
      }
    });
  };

  getCustomPropSelects = () => {
    let t = this;
    let peopleFilterOps = this.state.customProps.map((cp, i) => {
      if (!cp.uniqueValues.length) {
        return null;
      }
      const options = cp.uniqueValues
        .sort()
        .filter((o) => o)
        .map((o, i) => (
          <option key={i} value={o}>
            {o}
          </option>
        ));
      return (
        <div key={i} className="select">
          <select
            name={"cp_" + cp.id}
            onChange={this.peopleFilterSet}
            value={
              t.state.peopleFilters["cp_" + cp.id]
                ? t.state.peopleFilters["cp_" + cp.id]
                : "all"
            }
          >
            <option value="all">All {cp.title}</option>
            {options}
          </select>
        </div>
      );
    });
    return peopleFilterOps;
  };

  peopleFilterSet = (e) => {
    let peopleFilters = {};
    Object.entries(this.state.peopleFilters).forEach((f) => {
      if (f[0] !== e.target.name) {
        peopleFilters[f[0]] = f[1];
      }
    });
    if (e.target.value !== "all") {
      peopleFilters[e.target.name] = e.target.value;
    }
    this.setState({ peopleFilters }, this.getCheckins);
  };

  checkFiltering = () => {
    if (
      Object.values(this.state.peopleFilters).length ||
      Object.values(this.state.dataFilter).filter((f) => f !== "all").length
    ) {
      this.setState({ filtering: true });
    } else {
      this.setState({ filtering: false });
    }
  };

  compileDate = () => {
    let checkinStateGroupData = [];
    let termKeys = [];
    let t = this;
    let checkins = this.state.checkins;
    checkins.forEach((c, i) => {
      let checkin = {
        date: moment(c.date).format("D MMMM"),
      };
      Object.entries(c.stateGroups).forEach((s) => {
        checkin[s[0]] = Number(s[1]);
        if (!i) {
          termKeys.push(s[0]);
        }
      });
      checkinStateGroupData.push(checkin);
    });
    t.setState({ checkinStateGroupData, termKeys });
  };

  clearFilters = () => {
    let dataFilter = this.state.dataFilter;
    Object.entries(dataFilter).forEach((f, i) => {
      dataFilter[f[0]] = "all";
    });
    this.getCustomPropSelects(true);
    this.setState(
      { dataFilter, peopleFilters: {}, filtering: false },
      this.getCheckins
    );
  };

  dataFilterSet = (evt) => {
    let dataFilter = this.state.dataFilter;
    Object.entries(dataFilter).forEach((f, i) => {
      dataFilter[f[0]] = "all";
    });
    dataFilter[evt.target.name] = evt.target.value;
    this.setState(dataFilter);
    this.getCheckins();
  };

  dateChange = (e) => {
    this.setState(
      {
        dateFrom: e[0],
        dateTo: e[1],
      },
      this.getCheckins
    );
  };

  render() {
    // build filter options
    let stateGroupsOptions = null;
    let stateOptions = null;
    let infOptions = null;
    let selectedShown;
    if (this.state.stateGroupLookup) {
      // Build options lists - always checking that the selected filter is present - as it might not be after a change to custom props

      // ############## STAGE GROUPS
      if (this.state.dataFilter.stateGroup !== "all") {
        selectedShown = 0;
      } else {
        selectedShown = 1;
      }
      stateGroupsOptions = Object.entries(this.state.stateGroupLookup)
        .sort()
        .map((sg, i) => {
          if (sg[0] === this.state.dataFilter.stateGroup) {
            selectedShown = 1;
          }
          return <option key={i}>{sg[0]}</option>;
        });
      if (!selectedShown) {
        stateGroupsOptions.push(
          <option key="missing">{this.state.dataFilter.stateGroup}</option>
        );
      }

      // ############## STAGES
      if (this.state.dataFilter.state !== "all") {
        selectedShown = 0;
      } else {
        selectedShown = 1;
      }
      stateOptions = Object.entries(this.state.termLookup)
        .sort()
        .map((s, i) => {
          if (s[1].termType === "s" && s[0]) {
            if (s[0] === this.state.dataFilter.state) {
              selectedShown = 1;
            }
            return <option key={i}>{s[0]}</option>;
          } else {
            return null;
          }
        });
      if (!selectedShown) {
        stateOptions.push(
          <option key="missing">{this.state.dataFilter.state}</option>
        );
      }

      // ############## INFLUENCES
      if (this.state.dataFilter.inf !== "all") {
        selectedShown = 0;
      } else {
        selectedShown = 1;
      }
      infOptions = Object.entries(this.state.termLookup)
        .sort()
        .map((s, i) => {
          if (s[1].termType === "i") {
            if (s[0] === this.state.dataFilter.inf) {
              selectedShown = 1;
            }
            return <option key={i}>{s[0]}</option>;
          } else {
            return null;
          }
        });
      if (!selectedShown) {
        infOptions.push(
          <option key="missing">{this.state.dataFilter.inf}</option>
        );
      }
    }

    let peopleFilterOps = this.getCustomPropSelects();
    //
    return (
      <PageRoot style={{ backgroundColor: "#fff" }}>
        <TopNavContainer>
          <NavTitle>Daily Reflection Data</NavTitle>
          <MonthPicker
            onChange={this.dateChange}
            value={[
              moment().subtract(2, "month").startOf("month").toDate(),
              moment().endOf("month").toDate(),
            ]}
            presets={[
              {
                title: "This month",
                start: moment().startOf("month"),
                end: moment().endOf("month"),
              },
              {
                title: "Past 3 months",
                start: moment().subtract(2, "month").startOf("month"),
                end: moment().endOf("month"),
              },
              {
                title: "Past 6 months",
                start: moment().subtract(5, "month").startOf("month"),
                end: moment().endOf("month"),
              },
              {
                title: "Past Year",
                start: moment().subtract(12, "month").startOf("month"),
                end: moment().endOf("month"),
              },
              {
                title: "All time",
                start: null,
                end: null,
              },
            ]}
            style={{ position: "absolute", top: 25, right: 20 }}
          />
        </TopNavContainer>
        <section>
          <ScoreSection from={this.state.dateFrom} to={this.state.dateTo} />
          <div className="full-width report-item">
            <div
              onClick={this.clearFilters}
              className={
                this.state.filtering === true
                  ? "clearFilters active"
                  : "clearFilters"
              }
            >
              <div
                className={
                  this.state.notEnoughData ? "completion hide" : "completion"
                }
              >
                <div className="fig">{this.state.useagePercent}%</div>
                <div>Completion rate</div>
              </div>
              <i className="fas fa-trash-alt"></i>
            </div>

            <div className="row">
              <span className="label">Filter People:</span>
              <span className="demFilter">{peopleFilterOps}</span>
            </div>
            <div className="row">
              <span className="label">Filter Checkins:</span>
              <div
                className={
                  "radioButton2 selectHeight " +
                  (this.state.dataFilter.stateGroup !== "all"
                    ? "active"
                    : "null")
                }
              ></div>
              <div className="select">
                <select
                  value={this.state.dataFilter.stateGroup}
                  name="stateGroup"
                  onChange={this.dataFilterSet}
                >
                  <option value="all">All Moods</option>
                  {stateGroupsOptions}
                </select>
              </div>
              <div
                style={{ marginLeft: 15 }}
                className={
                  "radioButton2 selectHeight " +
                  (this.state.dataFilter.state !== "all" ? "active" : "null")
                }
              ></div>
              <div className="select">
                <select
                  value={this.state.dataFilter.state}
                  name="state"
                  onChange={this.dataFilterSet}
                >
                  <option value="all">All Emotions</option>
                  {stateOptions}
                </select>
              </div>
              <div
                style={{ marginLeft: 15 }}
                className={
                  "radioButton2 selectHeight " +
                  (this.state.dataFilter.inf !== "all" ? "active" : "null")
                }
              ></div>
              <div className="select">
                <select
                  value={this.state.dataFilter.inf}
                  name="inf"
                  onChange={this.dataFilterSet}
                >
                  <option value="all">All Influences</option>
                  {infOptions}
                </select>
              </div>
            </div>
          </div>
          {this.state.notEnoughData ? (
            <div>
              <div className="no-data">
                <img
                  src={require("../../../../imgs/noData.svg")}
                  alt="Not enough data"
                />
                <div>
                  <h5>Not Enough Data...</h5>
                  <p>
                    Looks like there is either no data, or not enough data to
                    show under this filter.
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          <div
            style={
              this.state.notEnoughData
                ? { height: 0, opacity: 0, overflow: "hidden" }
                : null
            }
          >
            <div className="report-item" id="checkin-chart">
              <CheckinPie
                checkinStateGroupData={this.state.checkinStateGroupData}
                termKeys={this.state.termKeys}
                termLookup={this.state.termLookup}
                stateGroupLookup={this.state.stateGroupLookup}
                dataFilterSet={this.dataFilterSet}
              />
            </div>
            <div className="report-item">
              <CheckinPercentageAreaChart
                checkinStateGroupData={this.state.checkinStateGroupData}
                termKeys={this.state.termKeys}
                termLookup={this.state.termLookup}
                stateGroupLookup={this.state.stateGroupLookup}
                dataFilterSet={this.dataFilterSet}
                dataFilter={this.state.dataFilter}
              />
            </div>
            <div className="report-item has-title">
              <h2 className="report-item-title">Emotions</h2>
              <CheckinStates
                stateTotals={this.state.stateTotals}
                termLookup={this.state.termLookup}
                dataFilterSet={this.dataFilterSet}
                dataFilter={this.state.dataFilter}
              />
            </div>
            <div className="report-item has-title">
              <h2 className="report-item-title">Influences</h2>
              <CheckinInfluences
                influenceTotals={this.state.influenceTotals}
                termLookup={this.state.termLookup}
                dataFilterSet={this.dataFilterSet}
                stateGroupLookup={this.state.stateGroupLookup}
                dataFilter={this.state.dataFilter}
              />
            </div>
            <div style={{ clear: "both" }}></div>
          </div>
        </section>
      </PageRoot>
    );
  }
}

export default ReportsCheckins;
