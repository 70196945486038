import React from "react";
import "../../../../css/signpost.css";
import { api, get } from "../../../actions/api";
import { MainContext } from "../../../context/MainContext";
import iphone from "../../../../imgs/iphone.svg";
import Reorder, { reorder } from "react-reorder";

class Signposts extends React.Component {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    this.state = {
      signposts: {
        severe: {
          id: 0,
          title: "",
          body: "",
          signposts: [],
          signpostTermLinks: [],
        },
        lessSevere: {
          id: 0,
          title: "",
          body: "",
          signposts: [],
          signpostTermLinks: [],
        },
      },
      editingSevere: "",
      editingLessSevere: "",
      newSignpost: {
        id: 0,
        status: 1,
        name: "",
        title: "",
        phone: "",
        email: "",
        subject: "",
        body: "",
        emailText: "",
        linkType: "txt",
        web: "",
        groupId: 0,
        showAdd: "",
        photo: null,
        photoName: "",
      },
      backupSevere: {},
      backupLessSevere: {},
      markedForDelete: [],
    };
  }

  componentDidMount() {
    this.getSignposts();
  }

  getSignposts = async (noHeaderUpdate) => {
    const signpostInfo = await get("signpost");
    let signposts = {};
    if (signpostInfo.signpostGroups && signpostInfo.signpostGroups.length) {
      signpostInfo.signpostGroups.forEach((sp) => {
        sp.signposts = sp.signposts.map((spost) => {
          spost.email = spost.email ? JSON.parse(spost.email) : null;
          return {
            ...spost,
            subject:
              spost.email && spost.email.subject ? spost.email.subject : "",
            body: spost.email && spost.email.body ? spost.email.body : "",
            email: spost.email && spost.email.email ? spost.email.email : "",
            emailText:
              spost.email && spost.email.emailText ? spost.email.emailText : "",
            linkType:
              spost.email && spost.email.linkType
                ? spost.email.linkType
                : "txt",
          };
        });
        if (Number(sp.level) === 9) {
          signposts.severe = sp;
        } else if (Number(sp.level) === 8) {
          signposts.lessSevere = sp;
        }
      });
      if (noHeaderUpdate) {
        let current = this.state.signposts;
        current.severe.signposts = signposts.severe.signposts;
        current.lessSevere.signposts = signposts.lessSevere.signposts;
        this.setState({ signposts: current });
      } else {
        this.setState({ signposts });
      }
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;
    let newSignpost = this.state.newSignpost;

    if (name === "photo") {
      newSignpost.photoName = event.target.files[0].name;
      value = event.target.files[0];
    }

    newSignpost[name] = value;
    this.setState({ newSignpost });
  };

  addSignpost = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", this.state.newSignpost.name);
    formData.append("title", this.state.newSignpost.title);
    formData.append("phone", this.state.newSignpost.phone);
    formData.append(
      "email",
      JSON.stringify({
        email: this.state.newSignpost.email,
        subject: this.state.newSignpost.subject,
        body: this.state.newSignpost.body,
        emailText: this.state.newSignpost.emailText,
        linkType: this.state.newSignpost.linkType,
      })
    );
    formData.append("web", this.state.newSignpost.web);
    formData.append("groupId", this.state.newSignpost.groupId);
    if (this.state.newSignpost.photo) {
      formData.append("photo", this.state.newSignpost.photo);
    }
    formData.append("status", this.state.newSignpost.status);

    let method = "POST";
    let url = "signpost";
    if (this.state.newSignpost.id) {
      formData.append("id", this.state.newSignpost.id);
      method = "PUT";
      url += "/" + this.state.newSignpost.id;
    }

    api(url, method, formData, (signposts) => {
      this.setState({
        newSignpost: {
          id: 0,
          status: 1,
          name: "",
          title: "",
          phone: "",
          email: "",
          subject: "",
          body: "",
          emailText: "",
          linkType: "txt",
          web: "",
          groupId: 0,
          showAdd: "",
          photo: "",
        },
        showAdd: "",
      });
      this.getSignposts(true);
    });
  };

  delete = (signpostId) => {
    // create array of ids for deletion
    let markedForDelete = this.state.markedForDelete;
    let signposts = this.state.signposts;
    if (!markedForDelete.includes(signpostId)) {
      markedForDelete.push(signpostId);

      // now remove from the signpost arrays
      signposts.severe.signposts = signposts.severe.signposts.filter((sp) => {
        if (sp.id !== signpostId) {
          return sp;
        } else {
          return false;
        }
      });
      signposts.lessSevere.signposts = signposts.lessSevere.signposts.filter(
        (sp) => {
          if (sp.id !== signpostId) {
            return sp;
          } else {
            return false;
          }
        }
      );
      this.setState({ signposts, markedForDelete });
    }

    // console.log('delete', signpostId);
    // api("signpost/" + signpostId, "DELETE", {}, () => {
    // 	this.getSignposts(true);
    // })
  };

  edit = (signpost) => {
    this.setState({ newSignpost: signpost, showAdd: "is-active" });
  };

  introHandleInputChange = (group, event) => {
    let signposts = this.state.signposts;

    const target = event.target;
    const name = target.name;
    let value = target.value;

    signposts[group][name] = value;
    this.setState({ signposts });
  };

  publish = (group) => {
    let formData = this.state.signposts[group];
    // update intro text
    api("signpost/group/" + formData.id, "PUT", formData, () => {
      // mark all in group as published
      api(
        "signpost/group/" + formData.id + "/publish",
        "POST",
        formData,
        () => {
          // save the order of signposts
          let signpostData = this.state.signposts[group].signposts.map(
            (contact, i) => {
              return {
                id: contact.id,
                order: i,
              };
            }
          );
          api("signpost/order", "POST", { signposts: signpostData }, (d) => {
            if (group === "severe") {
              this.setState({ editingSevere: "" });
            } else {
              this.setState({ editingLessSevere: "" });
            }
            if (this.state.markedForDelete.length) {
              this.state.markedForDelete.forEach((signpostId) => {
                api("signpost/" + signpostId, "DELETE", {}, () => {
                  this.getSignposts(true);
                });
              });
              this.setState({ markedForDelete: [] });
            } else {
              this.getSignposts(true);
            }
          });
        }
      );
    });
  };

  cancel = (group) => {
    let signposts = this.state.signposts;

    if (group === "severe") {
      let backup = this.state.backupSevere;
      signposts[group].title = backup.title;
      signposts[group].body = backup.body;
      this.setState({ signposts, editingSevere: "" });
    } else {
      let backup = this.state.backupLessSevere;
      signposts[group].title = backup.title;
      signposts[group].body = backup.body;
      this.setState({ signposts, editingLessSevere: "" });
    }

    this.setState({ markedForDelete: [] });

    this.getSignposts(true);
  };

  showModal = (groupId) => {
    if (this.state.showAdd) {
      this.setState({ showAdd: "" });
    } else {
      let newSignpost = this.state.newSignpost;
      newSignpost.groupId = groupId;
      this.setState({ showAdd: "is-active", newSignpost });
    }
  };

  onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    let signposts = this.state.signposts;
    let contacts = signposts[fromId].signposts;

    signposts[fromId].signposts = reorder(contacts, previousIndex, nextIndex);
    // console.log(test);
    this.setState({ signposts });
  };

  formatSignpost = (s, i) => (
    <div key={i} className={"signpost " + "status_" + s.status}>
      <div className="inner">
        <div className="img_wrapper">
          <img
            src={
              this.context.APIHost +
              "/api/signpost/" +
              s.id +
              "/image.png?" +
              new Date().getTime()
            }
            alt={s.title}
          />
        </div>
        <div className="details_wrapper">
          <div className="sp_name">{s.name}</div>
          <div className="sp_title">{s.title}</div>
          {s.phone && (
            <div className="sp_phone">
              <i className="fas fa-phone"></i> {s.phone}
            </div>
          )}
          {s.email && (
            <div
              className={s.linkType === "btn" ? "button sp_email" : "sp_email"}
            >
              <i className="fas fa-envelope" style={{ marginRight: 5 }}></i>
              <a
                target="_blank"
                href={
                  "mailto:" +
                  s.email +
                  "?subject=" +
                  s.subject +
                  "&body=" +
                  s.body
                }
              >
                {s.emailText ?? s.email}
              </a>
            </div>
          )}
        </div>
      </div>
      {s.web && <div className="sp_web">{s.web}</div>}
      <span
        onClick={() => {
          this.delete(s.id);
        }}
      >
        <i className="fas fa-trash-alt"></i>
      </span>
      <span
        onClick={() => {
          this.edit(s);
        }}
      >
        <i className="fas fa-pencil-alt editSP"></i>
      </span>
    </div>
  );

  render() {
    let severe = this.state.signposts.severe.signposts.map(this.formatSignpost);

    if (this.state.editingSevere) {
      severe = (
        <Reorder
          reorderId="severe" // Unique ID that is used internally to track this list (required)
          reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
          // getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
          component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
          placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
          draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
          lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
          holdTime={100} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
          touchHoldTime={100} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
          mouseHoldTime={100} // Hold time before dragging begins with mouse (optional), defaults to holdTime
          onReorder={this.onReorder.bind(this)} // Callback when an item is dropped (you will need this to update your state)
          autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
          disabled={false} // Disable reordering (optional), defaults to false
          disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
        >
          {severe}
        </Reorder>
      );
    }

    let lessSevere = this.state.signposts.lessSevere.signposts.map(
      this.formatSignpost
    );

    if (this.state.editingLessSevere) {
      lessSevere = (
        <Reorder
          reorderId="lessSevere" // Unique ID that is used internally to track this list (required)
          reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
          // getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
          component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
          placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
          draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
          lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
          holdTime={100} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
          touchHoldTime={100} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
          mouseHoldTime={100} // Hold time before dragging begins with mouse (optional), defaults to holdTime
          onReorder={this.onReorder.bind(this)} // Callback when an item is dropped (you will need this to update your state)
          autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
          disabled={false} // Disable reordering (optional), defaults to false
          disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
        >
          {lessSevere}
        </Reorder>
      );
    }
    let emotionsLS = this.state.signposts.lessSevere.signpostTermLinks.map(
      (t, i) => {
        return (
          <span className="term" key={i}>
            {t.term.term}
          </span>
        );
      }
    );
    let emotionsS = this.state.signposts.severe.signpostTermLinks.map(
      (t, i) => {
        return (
          <span className="term" key={i}>
            {t.term.term}
          </span>
        );
      }
    );

    return (
      <div className="section" style={{ paddingTop: 0 }}>
        <h1 className="is-size-5">Daily Reflection Signposting</h1>
        <div className="columns">
          <div
            className={
              "column report-item signpostArea " + this.state.editingSevere
            }
          >
            <h1 className="subtitle">Severe</h1>
            <button
              className="button is-primary editButton"
              onClick={() => {
                this.setState({
                  editingSevere: "editing",
                  backupSevere: JSON.parse(
                    JSON.stringify(this.state.signposts.severe)
                  ),
                });
              }}
            >
              Edit
            </button>
            <button
              className="button is-success publishButton"
              onClick={() => {
                this.publish("severe");
              }}
            >
              Publish
            </button>
            <button
              className="button cancelButton"
              onClick={() => {
                this.cancel("severe");
              }}
            >
              Cancel
            </button>

            <p>
              Select the contacts displayed to people who report the following
              "very negative" emotions: {emotionsS}
            </p>

            <div className="preview">
              <img src={iphone} className="phone" alt="iphone background" />
              <div className="inner_wrapper">
                <div className="intro">
                  <form
                    onSubmit={(e) => {
                      this.updateIntro("severe", e);
                    }}
                  >
                    <textarea
                      name="title"
                      value={this.state.signposts.severe.title}
                      className="previewTitle"
                      onChange={(e) => {
                        this.introHandleInputChange("severe", e);
                      }}
                    />
                    <i className="fas fa-pencil-alt editText"></i>
                    <textarea
                      name="body"
                      value={this.state.signposts.severe.body}
                      className="previewBody"
                      onChange={(e) => {
                        this.introHandleInputChange("severe", e);
                      }}
                    />
                    <i className="fas fa-pencil-alt editText"></i>
                  </form>
                </div>
                {severe}
                <div style={{ textAlign: "center" }}>
                  <button
                    className="addSignpostButton"
                    onClick={() =>
                      this.showModal(this.state.signposts.severe.id)
                    }
                  >
                    Add Signpost
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "column report-item signpostArea " + this.state.editingLessSevere
            }
          >
            <h1 className="subtitle">Less Severe</h1>
            <button
              className="button is-primary editButton"
              onClick={() => {
                this.setState({
                  editingLessSevere: "editing",
                  backupLessSevere: JSON.parse(
                    JSON.stringify(this.state.signposts.lessSevere)
                  ),
                });
              }}
            >
              Edit
            </button>
            <button
              className="button is-success publishButton"
              onClick={() => {
                this.publish("lessSevere");
              }}
            >
              Publish
            </button>
            <button
              className="button cancelButton"
              onClick={() => {
                this.cancel("lessSevere");
              }}
            >
              Cancel
            </button>
            <p>
              Select the contacts displayed to people who report the following
              "very negative" emotions: {emotionsLS}
            </p>

            <div className="preview">
              <img src={iphone} className="phone" alt="iphone background" />
              <div className="inner_wrapper">
                <div className="intro">
                  <form
                    onSubmit={(e) => {
                      this.updateIntro("lessSevere", e);
                    }}
                  >
                    <textarea
                      name="title"
                      value={this.state.signposts.lessSevere.title}
                      className="previewTitle"
                      onChange={(e) => {
                        this.introHandleInputChange("lessSevere", e);
                      }}
                    />
                    <i className="fas fa-pencil-alt editText"></i>
                    <textarea
                      name="body"
                      value={this.state.signposts.lessSevere.body}
                      className="previewBody"
                      onChange={(e) => {
                        this.introHandleInputChange("lessSevere", e);
                      }}
                    />
                    <i className="fas fa-pencil-alt editText"></i>
                  </form>
                </div>
                {lessSevere}
                <div style={{ textAlign: "center" }}>
                  <button
                    className="addSignpostButton"
                    onClick={() =>
                      this.showModal(this.state.signposts.lessSevere.id)
                    }
                  >
                    Add Signpost
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"modal " + this.state.showAdd}>
          <div className="modal-background" onClick={this.showModal}></div>
          <div
            className="modal-content"
            style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10 }}
          >
            <h3>Add another</h3>
            <form
              style={{ width: "100%", marginTop: 10 }}
              onSubmit={this.addSignpost}
              className="newSignpostForm"
            >
              <input
                type="hidden"
                value={this.state.newSignpost.groupId}
                name="groupId"
              />
              <div className="field is-horizontal item">
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        value={this.state.newSignpost.name}
                        type="text"
                        name="name"
                        placeholder="Full name / title"
                        onChange={this.handleInputChange}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal item">
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        value={this.state.newSignpost.title}
                        type="text"
                        name="title"
                        placeholder="Description"
                        onChange={this.handleInputChange}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal item">
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        value={this.state.newSignpost.phone}
                        type="text"
                        name="phone"
                        placeholder="Phone number"
                        onChange={this.handleInputChange}
                      />
                    </p>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal item">
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        value={this.state.newSignpost.web}
                        type="text"
                        name="web"
                        placeholder="Website"
                        onChange={this.handleInputChange}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal item">
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        value={this.state.newSignpost.email}
                        type="email"
                        name="email"
                        placeholder="Email address"
                        onChange={this.handleInputChange}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal item">
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        value={this.state.newSignpost.subject}
                        type="text"
                        name="subject"
                        placeholder="Email subject"
                        onChange={this.handleInputChange}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal item">
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        value={this.state.newSignpost.body}
                        type="text"
                        name="body"
                        placeholder="Email body"
                        onChange={this.handleInputChange}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal item">
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input"
                        value={this.state.newSignpost.emailText}
                        type="text"
                        name="emailText"
                        placeholder="Email link text"
                        onChange={this.handleInputChange}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal item">
                <div className="select">
                  <select
                    value={this.state.newSignpost.linkType}
                    onChange={this.handleInputChange}
                    name="linkType"
                  >
                    <option value="txt">Text link</option>
                    <option value="btn">Button</option>
                  </select>
                </div>
              </div>
              <div style={{ clear: "both", height: 0 }}></div>
              <div className="file has-name item">
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="photo"
                    accept=".png"
                    onChange={this.handleInputChange}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span>
                    <span className="file-label">Select image</span>
                  </span>
                  <span className="file-name">
                    {this.state.newSignpost.photoName}
                  </span>
                </label>
              </div>
              <div className="control pull-right">
                <div className="formError">{this.state.formError}</div>
                <button className="button is-primary is-pulled-right">
                  Save
                </button>
              </div>
            </form>
          </div>
          <button
            className="modal-close is-large "
            aria-label="close"
            onClick={this.showModal}
          ></button>
        </div>
      </div>
    );
  }
}

export default Signposts;
