import React, { memo } from "react";
import { ImgWRapper } from "./ImgElm.styled";

const ImgElm = (props) => {
  const elm = props.elm;
  const style = elm.style ?? {};
  const wrapperStyle = style.wrapperStyle ?? {};
  if (style.wrapperStyle) {
    delete style.wrapperStyle;
  }
  const HOST =
    window.location.hostname === "localhost"
      ? "http://localhost:3000"
      : "https://api.lumeapp.io";

  const src =
    HOST +
    "/api/course/element/" +
    elm.id +
    "/" +
    elm.id +
    "." +
    elm.fileExtension +
    "?auth_token=" +
    localStorage.getItem("JWT");

  return (
    <ImgWRapper style={wrapperStyle}>
      <img style={style} src={src} />
    </ImgWRapper>
  );
};

export default memo(ImgElm);
