import React, { memo } from "react";
import Items from "./Items/Items";
import {} from "./ScreenIndex.styled";

const ScreenIndex = (props) => {
  let elm = props.elm;

  if (!elm.content.items) {
    return null;
  }
  const items = elm.content.items;
  const itemList = items.map((item, i) => (
    <Items
      item={item}
      key={i}
      number={i + 1}
      listType={elm.content.listType}
      screenViews={props.screenViews}
      latestInteractions={props.latestInteractions}
      findScreen={props.findScreen}
      currentScreenId={props.currentScreenId}
    />
  ));

  const style =
    elm.content.listType === "play"
      ? {
          borderWidth: 1,
          borderColor: "#bbb",
          borderStyle: "solid",
          marginTop: 10,
          marginBottom: 10,
          borderRadius: 5,
          textAlign: "left",
        }
      : {};

  return <div style={{ ...style, ...elm.style }}>{itemList}</div>;
};

export default memo(ScreenIndex);
