import React, { memo } from "react";
import PropTypes from "prop-types";
import { Influences } from "./CheckInDay.styled";
import { InfluenceButton } from "../../../index";
import TextSection from "../TextSection/TextSection";
import SectionTitle from "../SectionTitle/SectionTitle";
import veryNegative from "../../../../../pages/DailyCheckIn/EmotionLevel/very_negative.svg";
import negative from "../../../../../pages/DailyCheckIn/EmotionLevel/negative.svg";
import ok from "../../../../../pages/DailyCheckIn/EmotionLevel/ok.svg";
import positive from "../../../../../pages/DailyCheckIn/EmotionLevel/positive.svg";
import veryPositive from "../../../../../pages/DailyCheckIn/EmotionLevel/very_positive.svg";
import CheckinImages from "../../../CheckinImages/CheckinImages";

const imageMap = {
  1: veryPositive,
  2: positive,
  3: ok,
  4: negative,
  5: veryNegative,
};

const CheckInDay = ({ data: ci, deleteClicked, editClicked }) => {
  let checkIn = {
    ...ci,
  };
  if (!checkIn.state.termGroupId && checkIn.state.group) {
    checkIn.state.termGroupId = checkIn.state.group.id;
  }
  const { term, colour } = checkIn.state;
  const { influences, notes, eventTime } = checkIn;

  const influencesWithId = influences.map((i) => ({
    ...i,
    id: i.id ?? i.termId,
  }));
  const noteText =
    notes && Array.isArray(notes)
      ? notes.map((note, i) => (
          <TextSection key={i} title={note.prompt} text={note.entry} />
        ))
      : null;
  return (
    <div>
      <SectionTitle
        text={
          checkIn.checkinMode === "brainDump" ? (
            <>
              <img
                width={40}
                height={40}
                src={imageMap[checkIn.state.termGroupId]}
              />{" "}
              <span style={{ marginLeft: 10 }}>Brain Dump</span>
            </>
          ) : term ? (
            term
          ) : (
            <img
              width={40}
              height={40}
              src={imageMap[checkIn.state.termGroupId]}
            />
          )
        }
        colour={colour}
        time={eventTime}
        deleteClicked={deleteClicked}
        editClicked={editClicked}
      />
      <Influences>
        {influencesWithId.map((influence, idx) => (
          <InfluenceButton
            key={idx}
            moodColour={colour}
            influence={influence}
            isSelected={true}
          />
        ))}
      </Influences>
      <CheckinImages
        checkinId={checkIn.id}
        checkinImages={checkIn.checkinImages.map((id) => ({
          id,
          base64: null,
        }))}
      />
      {noteText}
    </div>
  );
};

CheckInDay.propTypes = {
  deleteClicked: PropTypes.func.isRequired,
  editClicked: PropTypes.func.isRequired,
};

export default memo(CheckInDay);
