import styled from "styled-components";

export const TextArea = styled.textarea`
  width: 100%;
  min-height: ${(props) => {
		return Number(props.minHeight) ? Number(props.minHeight)+5 : 45;
	}}px;
  border: #eee 1px solid;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  color: #222;
	height: auto;
  font-size: 16px;
	overflow: hidden;
`;

export const AddAnother = styled.button`
	width: 36px;
	height: 36px;
	border-radius: 18px;
	font-size: 18px;
	font-weight: bold;
	color: white;
	background-color: ${({ theme }) => theme.primary.colours.grey_medium};
`;