import styled from 'styled-components';
import React from 'react';
import LeftArrowIcon from '@ant-design/icons/lib/icons/LeftOutlined';

export const ContentRoot = styled.div`
  align-self: stretch;
  padding: 1.5rem 3rem 0 3rem;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  > *:first-child { flex: 1; }
  > *:last-child { flex: 1; }
`;

export const FilterRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding-top: 1.5rem;
  > * {
    font-size: 16px;
    color: #6B7489;
  }
  > div:hover:not(:last-child) {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const MonthFilter = styled.div`
  width: 125px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 10px;
  border: 1px solid #C6D2E1;
`;

export const Title = styled.h3`
  font-weight: bold;
  font-size: 29px;
  color: ${({ theme }) => theme.primary.colours.heading};
`;

export const LeftArrow = ({ onClick }) => (
  <LeftArrowStyle>
    <LeftArrowIcon onClick={onClick} />
  </LeftArrowStyle>
);

const LeftArrowStyle = styled.div`
  svg {
    width 23px;
    height: 23px;
    color: ${({ theme }) => theme.primary.colours.heading};
    
    &:hover {
      cursor: pointer;
    }
  }
`;
