import styled from 'styled-components';

export const ContentRoot = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > * {
    margin: 0.5rem 1rem;
  }
`;
