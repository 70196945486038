import React, { memo } from 'react';
import PropTypes from 'prop-types';
import BulbTwoTone from '@ant-design/icons/lib/icons/BulbTwoTone';
import { ToggleButton } from './RAK.styled';
import theme from '../../theme';

const GetASuggestion = (props) => (
  <ToggleButton {...props}>
    <BulbTwoTone twoToneColor={theme.primary.colours.yellow} style={{fontSize:28}} />
    <h5 style={{fontSize:18}}>Get A Suggestion</h5>
  </ToggleButton>
);

GetASuggestion.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default memo(GetASuggestion);
