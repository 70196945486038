import styled from "styled-components";
import bubbles from "../../../../imgs/bubbles_white_square_2.svg";

export const RankingWrapper = styled.section`
  width: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #58c6ac;
  min-height: 100px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  background-image: url(${bubbles});
  background-size: cover;
	color: white;
	margin-top: 30px;
`;

export const Left = styled.div`
	padding:20px;
	width: 100px;
	flex-grow: 0;
	flex-shrink: 0;
`;

export const Right = styled.div`
	padding:10px;
`;

export const Body = styled.p`
  padding: 0;
  margin: 0;
	font-size: 18px;
`;

export const Title = styled.p`
  padding: 0 0 5px 0;
  margin: 0;
	font-size: 24px;
`;

