import React, { memo, useEffect, useRef, useState } from "react";
import TweenOne from "rc-tween-one";
import SvgMorphPlugin from "rc-tween-one/lib/plugin/SvgMorphPlugin";
import { CountText, Outline, StageText } from "./Breathe.styled";
TweenOne.plugins.push(SvgMorphPlugin);

const CircleAnimation = ({ timing, pause }) => {
  const [stage, setStage] = useState(0);
  const [minSize, maxSize] = [200, 300];

  // countdown
  const [count, setCount] = useState();
  const countRef = useRef(count);
  countRef.current = count;

  const text = ["Breathe in", "Hold", "Breathe out"];

  useEffect(() => {
    const downFrom = timing[stage];
    setCount(downFrom);
    const countdown = setInterval(() => {
      if (countRef.current > 0) {
        setCount(countRef.current - 1);
      }
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [stage]);

  // onStart and onRepeat just doesn't seem to work.. so using onUpdate
  return (
    <Outline>
      {pause ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "100%",
            backgroundColor: "#fff",
          }}
        >
          <StageText style={{ marginTop: -20 }}>Paused</StageText>
        </div>
      ) : (
        <>
          <TweenOne
            animation={[
              {
                width: maxSize,
                height: maxSize,
                duration: timing[0] * 1000,
                onUpdate: ({ index, target, ratio }) => {
                  setStage(0);
                },
              },
              {
                width: maxSize,
                height: maxSize,
                duration: timing[1] * 1000,
                onUpdate: ({ index, target, ratio }) => {
                  setStage(1);
                },
              },
              {
                width: minSize,
                height: minSize,
                duration: timing[2] * 1000,
                onUpdate: ({ index, target, ratio }) => {
                  setStage(2);
                },
              },
            ]}
            repeat={-1}
            style={{
              backgroundColor: "#fff",
              width: minSize,
              height: minSize,
              borderRadius: "100%",
              overflow: "visible",
            }}
          >
            <TweenOne
              animation={[
                { opacity: 0, duration: (timing[0] - 1) * 1000 },
                { opacity: 1, duration: 1000 },
                { opacity: 1, duration: timing[1] * 1000 },
                { opacity: 0, duration: 1000 },
                { opacity: 0, duration: (timing[2] - 1) * 1000 },
              ]}
              repeat={-1}
              style={{
                width: "100%",
                height: "100%",
                opacity: 0,
              }}
            >
              <TweenOne
                animation={[
                  { outlineWidth: 10, duration: 1000, ease: "easeInOutCubic" },
                  { outlineWidth: 0, duration: 1000, ease: "easeInOutCubic" },
                ]}
                repeat={-1}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "100%",
                  outlineStyle: "solid",
                  outlineColor: "#fff",
                  outlineWidth: 0,
                  outlineOffset: -2,
                }}
              />
            </TweenOne>
          </TweenOne>
          <StageText>{text[stage]}</StageText>
          <CountText>{count}</CountText>
        </>
      )}
    </Outline>
  );
};

export default memo(CircleAnimation);
