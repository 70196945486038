import { useEffect, useState } from "react";
import moment from "moment";
import { get } from "../actions/api";
import {
  buildEventMonthLookup,
  buildEventDayLookup,
} from "../helpers/calendarHelpers";
import { getKey } from "../helpers/eventHelpers";

export default function useCalendarData(ignoreKeys = []) {
  const [events, setEvents] = useState([]);
  const notIgnored = events.filter((e) => ignoreKeys.includes(e.key) === false);
  const eventMonthLookup = buildEventMonthLookup(notIgnored);
  const eventDayLookup = buildEventDayLookup(notIgnored);
  const months = Object.keys(eventMonthLookup).sort().reverse();

  useEffect(() => {
    async function getData() {
      const { checkins: checkIns } = await get("checkin");
      const { courses } = await get("course?type=anyActivityDaily");
      const courseEvents = [].concat.apply(
        [],
        courses.map((c) =>
          Object.values(c.latestInteractions)
            .filter((i) => i.elementType.slice(0, 4) === "req_")
            .map((i) => ({
              ...i,
              eventType: "courseReq",
              courseTitle: c.title,
              colour: c.colour,
              courseId: c.id,
              eventTime: i.createdAt,
              complete: true,
            }))
        )
      );
      let courseEventsFormatted = [];
      let added = [];
      courseEvents.forEach((e) => {
        courseEventsFormatted.push(e);
        // find empty req_ elements
        const course = courses.find((c) => c.id === e.courseId);
        if (!course) {
          return;
        }

        const lu = course.lookup[e.screenId].filter((i) => i !== null);

        if (!lu) {
          return;
        }
        const screen =
          lu.length === 1
            ? course.courseScreens[lu[0]]
            : lu.length === 2
            ? course.courseScreens[lu[0]].children[lu[1]]
            : lu.length === 3
            ? course.courseScreens[lu[0]].children[lu[1]].children[lu[2]]
            : course.courseScreens[lu[0]].children[lu[1]].children[lu[2]]
                .children[lu[3]];

        screen.courseScrElmGroups.forEach((eg) =>
          eg.courseScrnElms
            .filter((elm) => elm.type.slice(0, 4) === "req_")
            .forEach((elm) => {
              if (
                !elm.courseElmInteractions.length &&
                !added.includes(elm.id)
              ) {
                added.push(elm.id);
                courseEventsFormatted.push({
                  ...elm,
                  eventType: "courseReq",
                  courseId: e.courseId,
                  screenId: e.screenId,
                  eventTime: e.eventTime,
                  complete: false,
									elementType: elm.type
                });
              }
            })
        );
      });
      const checkInsFormatted = checkIns.map((c) => ({
        ...c,
        eventTime: c.checkinTime,
        colour: c.state.colour,
      }));

      const { RAKs: raks } = await get("rak/records");
      const { gratitudes } = await get("gratitude");
      const { events } = await get("event");
      const eventsLocal = [
        ...checkInsFormatted,
        ...raks,
        ...gratitudes,
        ...events,
        ...courseEventsFormatted,
      ].map((e) => ({
        ...e,
        eventTime: moment(e.eventTime),
        key: getKey(e), // add key for event while id not unique
      }));

      // TEST DATA TO SHOW MULTIPLE MONTHS
      // const testData = [
      //   { eventType: 'checkin', eventTime: moment().subtract(9, 'month') },
      //   { eventType: 'checkin', eventTime: moment().subtract(8, 'month') },
      //   { eventType: 'checkin', eventTime: moment().subtract(7, 'month') },
      //   { eventType: 'checkin', eventTime: moment().subtract(6, 'month') },
      //   { eventType: 'checkin', eventTime: moment().subtract(5, 'month') },
      //   { eventType: 'checkin', eventTime: moment().subtract(4, 'month') },
      //   { eventType: 'checkin', eventTime: moment().subtract(3, 'month') },
      //   { eventType: 'checkin', eventTime: moment().subtract(2, 'month') },
      //   { eventType: 'checkin', eventTime: moment().subtract(1, 'month') },
      // ];
      // setEvents([...eventsLocal, ...testData]);
      // TEST DATA TO SHOW MULTIPLE MONTHS

      setEvents(eventsLocal);
    }
    getData();
  }, []);
  // console.log(notIgnored)
  return { events: notIgnored, eventMonthLookup, eventDayLookup, months };
}
