import React, { memo } from "react";
import {} from "./Text.styled";
import Markdown from "markdown-to-jsx";

const Text = ({ elm, style, screenStyles, mergeTags, sectionStyles }) => {
  let styles = {};
  if (style) {
    styles = style;
  }

	if (sectionStyles && sectionStyles.color && !styles.color) {
    styles.color = sectionStyles.color;
  }
  if (screenStyles && screenStyles.color && !styles.color) {
    styles.color = screenStyles.color;
  }

  return (
    <div style={styles} className="content">
      <Markdown style={{ fontSize: 26, ...styles }}>
        {mergeTags(elm.content.text)}
      </Markdown>
    </div>
  );
};

export default memo(Text);
