import styled, { css } from "styled-components";
import { PieChart as PieChartRaw } from "react-minimal-pie-chart";

import theme from "../../../theme";

const { baseColours } = theme.primary.colours;

const selectedBase = css`
  border-radius: 50%;
  padding: 3px 5px;
`;

const selectedCssValues = Object.entries(baseColours).map(
  ([name, colour]) => `
  .-${name} > span {
    box-shadow: 0 0 0 3px ${colour};
    ${selectedBase}
    width: 30px;
  }`
);

const selectedCss = selectedCssValues.join("\n");

export const TodayMarker = styled.div`
  background-color: ${({ theme }) => theme.primary.colours.orange};
  width: 10px;
  height: 10px;
  border-radius: 5px;
  position: absolute;
  bottom: -3px;
  z-index: 100;
`;

export const DateRangePickerWrapper = styled.div`
  display: flex;
  /* transform: scale(0.8); */
  justify-content: flex-start;
  * {
    font-size: 14px;
  }
	.nice-dates-week-header{
		margin-bottom:10px;
	}
  .nice-dates-day {
    height: 36px !important;
  }
  
  .nice-dates-day.-fill {
    .fillContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${({ theme }) => theme.primary.calendar.dayHighlight};
      width: 32px;
      height: 32px;
      border-radius: 16px;
			box-sizing: border-box;
      span {
        color: white;
      }
    }
  }
  
  .nice-dates-day.-selected {
    border: none;
  }

  .nice-dates-day:before {
    background-color: lightcoral;
  }
  
  .nice-dates-navigation {
    width: 310px;
  }
  
  div.nice-dates-grid {
    height: 250px !important;
  }
  
  .nice-dates-navigation_previous, .nice-dates-navigation_next {
    display: none;
  }
  
  .-outside, .nice-dates-day_month {
    visibility: hidden;
  }
  
  .nice-dates-day:before, .nice-dates-day:after {
    border: none;
  }
  
  .nice-dates-day:hover:after {
    border: none;
  }

  .-selected:before {
    background-color: inherit;
  }

  .nice-dates-day.-disabled
    color: black;
  }

  .nice-dates-day.-selected:not(:hover) *, .nice-dates-day.-selected.-disabled * {
    color: black;
  }
  
  span.nice-dates-day {
    cursor: auto;
  }
  
  .nice-dates-day:hover {
    cursor: pointer;
  }
  
  ${selectedCss}
`;

export const PieChart = styled(PieChartRaw)`
  position: absolute;
`;
