import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ChevronUp,
  ChevronDown,
} from './ExpandingBox.styled';
import { TitleText } from '../common.styled';
import ContentBox from '../ContentBox/ContentBox';

const ExpandingBox = ({ title, body, initialExpanded }) => {
  const [isExpanded, setIsExpanded] = useState(initialExpanded);

  const chevronClicked = () => setIsExpanded(current => !current);

  const titleContent = (
    <>
      <TitleText>{title}</TitleText>
      {isExpanded === true
        ? <ChevronUp onClick={chevronClicked} />
        : <ChevronDown onClick={chevronClicked} />}
    </>
  );

  return (
    <ContentBox
      titleContent={titleContent}
      body={isExpanded === true && body}
    />
  );
};

ExpandingBox.defaultProps = {
  initialExpanded: true,
};

ExpandingBox.propTypes = {
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  initialExpanded: PropTypes.bool,
};

export default memo(ExpandingBox);
