import React from 'react';
import '../../css/UserList.css';

class UserList extends React.Component {
	constructor(props) {
		super(props);
	}

	getListItem = (user) => {
		const userIcons = {
			5: "fa-user-astronaut",
			1: "fa-user",
			0: "fa-user-tie",
		}
		const userClasses = {
			5: "has-text-black-ter",
			1: "has-text-link",
			0: "has-text-primary",
		}

		var styles = {
		};

		if (!Number(user.user_type)) {
			styles.paddingLeft = 20;
		}

		if (this.props.setUser) {
			styles.cursor = "pointer";
		}

		let inactive = "";
		if (user.accountActivated === null) {
			inactive = " inactive";
		}

		let selected = "";
		if (this.props.selectedUser) {
			if (this.props.selectedUser.id === user.id) {
				selected = "selected";
			}
		}

		return (
			<div key={user.id.toString()} className={userClasses[user.user_type] + " panel-block is-primary user " + inactive + " " + selected} style={styles} onClick={() => {
				this.props.selectUser(user);
			}}>
				<span className={userClasses[user.user_type] + " panel-icon"}>
					<i className={userIcons[user.user_type] + " fas"}></i>
				</span>
				{user.first_name} {user.last_name} ({user.email})
			</div>
		)
	}

	formatList = () => {
		if (!this.props.users) {
			return null
		}

		var listItems = [];

		this.props.users.map((user) => {
			listItems.push(this.getListItem(user));
			if (user.employees) {
				user.employees.map((user) => {
					listItems.push(this.getListItem(user));
					return null;

				})
			}
			return null;
		});

		return (
			<div className="panel" >
				<p className="panel-heading">
					Users
					{/* <button className="button is-small is-pulled-right">Resend invitation to all</button> */}
				</p>
				<div style={{ maxHeight: window.innerHeight - 150, overflow: "auto" }}>
					{listItems}
				</div>
			</div>
		);
	}

	render() {
		return this.formatList();
	}
}

export default UserList;
