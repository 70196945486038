import styled from "styled-components";
import { TopNav } from "../../components/common";
import bubbles_light from "../../../imgs/bubbles_white_light_square.svg";
import bubbles_grey from "../../../imgs/bubbles_grey_square.svg";

export const PageContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  max-width: 500px;
  padding-top: 30px;
  padding-bottom: 50px;
  margin-bottom: 50px;
  min-height: 600px;
  overflow: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const PageWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-color: ${(props) => props.bgColour};
  background-image: ${(props) =>
    props.bgColour === "transparent"
      ? "url(" + bubbles_grey + ")"
      : "url(" + bubbles_light + ")"};
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;

export const NavRoot = styled(TopNav)`
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 1rem;
  align-items: center;
`;

export const NextScreenButton = styled.button`
  position: absolute;
  bottom: 0;
  padding: 10px 0;
  border-radius: 1.25em;
  color: white;
  font-size: 1.2em;
  background-color: ${({ theme }) => theme.primary.colours.blueMD};
  margin: 5px;
  width: 300px;
  left: 50%;
  margin-left: -150px;
`;

export const ProgressContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 25%;
  display: flex;
  flex-direction: row;
  width: 50%;
  gap: 5px;
`;

export const ProgressSegment = styled.div`
  border-radius: 5px;
  height: 10px;
  background-color: ${(props) =>
    props.completed ? props.colComplete : props.colIncomplete};
  flex: 1;
`;

export const MenuButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  color: #000;
  font-size: 1rem;
  cursor: pointer;
`;

