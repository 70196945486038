import React, { memo, useState, useEffect, useRef } from "react";
import { TextArea } from "./InputText.styled";

const InputText = (props) => {
  const [val, setVal] = useState("");
  const textAreaRef = useRef(null);
  const [textAreaHeight, setTextAreaHeight] = useState("auto");
  const elm = props.elm;
  const content = elm.content;
  const borderWidth = 1;
  let styles = null;
  if (elm.style) {
    styles = elm.style;
  }

  useEffect(() => {
		if(content.recall){
			const recallId = Number(content.assignTo) ? Number(content.assignTo) : elm.id;
			if(props.latestInteractions[recallId]){
				setVal(props.latestInteractions[recallId].value);
			}
		}
		
    setTimeout(grow, 100);
  }, [content]);

  useEffect(() => {
    grow();
  }, [val]);

  const grow = () => {
    return textAreaRef.current
      ? setTextAreaHeight(textAreaRef.current.scrollHeight + borderWidth * 2)
      : false;
  };

  const textChange = (e) => {
    setVal(e.target.value);
  };

  return (
    <TextArea
      ref={textAreaRef}
      style={{ ...styles, height: textAreaHeight, borderWidth }}
      minHeight={content.height}
      placeholder={content.placeholder}
      onBlur={(e) => {
				const saveToId = Number(content.assignTo) ? Number(content.assignTo) : elm.id;
        props.recordInteraction(saveToId, e.target.value);
      }}
      value={val}
      onChange={textChange}
    />
  );
};

export default memo(InputText);
