import React from "react";
import "../../../../css/ControlPanel.css";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const CheckinPercentageAreaChart = (props) => {
  const toPercent = (decimal, fixed = 0) =>
    `${(decimal * 100).toFixed(fixed)}%`;

  const getPercent = (value, total) => {
    const ratio = total > 0 ? value / total : 0;

    return toPercent(ratio, 2);
  };

  const dateFormatter = (date) => {
    return moment(date).format("DD MMM");
  };

  let maxVal = 0;
  // calculate percentages
  let allData = props.checkinStateGroupData.map((sg) => {
    let out = {
      date: sg.date,
    };
    Object.entries(sg).forEach((v) => {
      if (v[0] !== "date") {
        if (v[1] > maxVal) {
          maxVal = v[1];
        }
        out[v[0]] = v[1];
      }
    });
    maxVal = Math.round(maxVal * 1.05); //  maxVal + 20 then round up to nearest 5
    return out;
  });

  let data = [];
  let foundData = false;
  for (let i = allData.length - 1; i > -1; i--) {
    const row = allData[i];
    if (
      Object.entries(row).find((r) => r[0] !== "date" && Number(r[1])) ||
      foundData
    ) {
      foundData = true;
      data.push(row);
    }
  }

  if (!data.length) {
    return null;
  } else if (data.length === 1) {
    data.push({ ...data[0] });
  } else {
    data.reverse();
  }

  const filterByGroup =
    props.dataFilter.stateGroup !== "all"
      ? props.dataFilter.stateGroup
      : props.dataFilter.state
      ? props.termLookup[props.dataFilter.state]
        ? props.termLookup[props.dataFilter.state].group
        : false
      : false;
  let lines = props.termKeys
    .sort(
      (a, b) =>
        props.stateGroupLookup[a].order - props.stateGroupLookup[b].order
    )
    .sort((a, b) => {
      if (b === filterByGroup) return 1;
      if (a === filterByGroup) return -1;
      return a - b;
    })
    .map((term, i) => {
      const colour = !filterByGroup
        ? props.stateGroupLookup[term]
          ? props.stateGroupLookup[term].colour
          : "#222"
        : term === filterByGroup
        ? props.stateGroupLookup[term]
          ? props.stateGroupLookup[term].colour
          : "#222"
        : "transparent";

      const stroke = props.stateGroupLookup[term]
        ? props.stateGroupLookup[term].colour
        : "#222";

      const strokeOpacity = !filterByGroup ? 1 : term === filterByGroup ? 1 : 0;

      return (
        <Area
          key={"a_" + i}
          type="monotone"
          dataKey={term}
          stackId={1}
          stroke={stroke}
          fill={colour}
          fillOpacity={1}
          strokeOpacity={strokeOpacity}
        />
      );
    });

  const chartWidth = document.getElementById("checkin-chart").clientWidth - 40;

  let int = "preserveStartEnd";
  if (data.length > 9) {
    int = Math.round(data.length / 10);
  }

  return (
    <ResponsiveContainer height={400}>
      <AreaChart
        data={data}
        stackOffset="expand"
        margin={{
          top: 30,
          right: 0,
          left: 0,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" interval={int} tickFormatter={dateFormatter} />
        <YAxis tickFormatter={(value, index) => toPercent(value)} />
        {/* <Tooltip
        formatter={(value) => {
          return value;
        }}
      /> */}
        {lines}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CheckinPercentageAreaChart;
