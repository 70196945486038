import styled from "styled-components";
import { DayLine } from "../Day.styled";

export const CourseReqItem = styled.button`
	border: none;
	outline: none;
	background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
	margin-left: 10px;
`;
