import styled from "styled-components";
import { PageContent as GlobalPageContent } from "../../components/common";

export const TileGrid = styled.section`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`;

export const PageContent = styled(GlobalPageContent)`
  align-items: flex-start;
  max-width: 38rem;
  > * {
    margin-bottom: 3rem;
  }
`;

export const DoneToday = styled.section`
  text-align: center;
	width: 100%;
`;

export const DoneTodayText = styled.p`
  font-size: 20px;
  margin-top: 20px;
  font-weight: bold;
`;
