import styled from "styled-components";

export const Face = styled.img`
  height: ${(props) => props.scale * 62}px;
  width: ${(props) => props.scale * 62}px;
  transition: .2s;
  
  opacity: ${(props) =>
    props.disabled ? (props.checkinMode === "positive" ? 0.6 : 0.2) : 1};
  
  ${({ disabled, checkinMode }) =>
    (disabled === false || checkinMode === "positive") &&
    `&:hover {
    transition: .2s;
    transform:scale(1.25,1.25);
  }`}
  
  ${(props) => (props.isSelected === true ? "transform:scale(1.25,1.25);" : "")}
}
`;

export const Button = styled.button`
  background: none;

  &:hover {
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  }
`;
