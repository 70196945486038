import React, { memo, useEffect, useRef, useState } from "react";
import {
  FloatingBox,
  PageContent,
  OverallTime,
  PauseButton,
  MuteButton,
  IntroText,
  PauseMuteContainer,
} from "./Breathe.styled";
import {
  BackButton,
  ButtonWhite,
  NavRoot,
  NavTitle,
  PageRoot,
} from "../../components/common";
import CircleAnimation from "./CircleAnimation";
import TextAndPlay from "../Course/ElmGroups/Element/ScreenIndex/Items/TextAndPlay";
import {
  PauseCircle,
  PlayCircle,
  VolumeHigh,
  VolumeMute,
} from "react-ionicons";
import Onboarding from "./Onboarding/Onboarding";
import Cookies from "universal-cookie";
import { get, post } from "../../actions/api";
import { OnboardingButton } from "../DailyCheckIn/DailyCheckIn.styled";
import { taskCompletedToday } from "../../helpers/dailyCompletionStorageHelper";
import { useHistory } from "react-router";

const Breathe = () => {
  const history = useHistory();
  const [onboarding, setOnboarding] = useState(false);
  const [selectedOp, setSelectedOp] = useState(null);
  const [pause, setPause] = useState(false);
  const pauseRef = useRef(pause);
  pauseRef.current = pause;
  const [mute, setMute] = useState(true);
  const muteRef = useRef(mute);
  muteRef.current = mute;
  const cookies = new Cookies();
  const tick = new Audio(require("../../../audio/tick.mp3"));
  const [countOverall, setCountOverall] = useState(0);
  const countOverallRef = useRef(countOverall);
  countOverallRef.current = countOverall;

  useEffect(() => {
    if (selectedOp === null) {
      return () => {};
    }

    if (selectedOp.duration === -1) {
      setCountOverall(0);
      const countdownOverall = setInterval(() => {
        if (!pauseRef.current) {
          setCountOverall(countOverallRef.current + 1);
          if (!muteRef.current) {
            tick.play();
          }
        } else if (countOverallRef.current === 0) {
          setSelectedOp(null);
        }
      }, 1000);
      return () => {
        clearInterval(countdownOverall);
      };
    } else {
      setCountOverall(selectedOp.duration * 60);
      const countdownOverall = setInterval(() => {
        if (countOverallRef.current > 0 && !pauseRef.current) {
          setCountOverall(countOverallRef.current - 1);
          if (!muteRef.current) {
            tick.play();
          }
        } else if (countOverallRef.current === 0) {
          setSelectedOp(null);
          history.push(`/feedback/breathe?next=/suggestions?bgCol=b5e3fa%26title=De-Stress Deep Breathing%26eventType=breathe%26eventId=${selectedOp.duration}`);
        }
      }, 1000);
      return () => {
        clearInterval(countdownOverall);
      };
    }
  }, [selectedOp]);

  useEffect(() => {
    const checkOnboarding = async () => {
      if (!cookies.get("hasDoneBreathing")) {
        const useageData = await get("event?type=breathe");
        if (useageData && useageData.events.length) {
        } else {
          setOnboarding(true);
        }
        // for testing
        // setOnboarding(true);
      }
    };
    checkOnboarding();
  }, [cookies]);

  const recordDone = (duration) => {
    taskCompletedToday("breathe");
    post("event", {
      event: {
        eventType: "breathe",
        eventData: {
          duration,
        },
      },
    });
  };

  const Options = () =>
    [
      {
        timing: [4, 4, 6],
        duration: -1,
      },
      {
        timing: [4, 4, 6],
        duration: 3,
      },
      {
        timing: [4, 4, 6],
        duration: 5,
      },
      {
        timing: [4, 4, 6],
        duration: 10,
      },
    ].map(({ timing, duration }, i) => (
      <TextAndPlay
        key={i}
        style={{ width: "100%" }}
        onClick={() => {
          setSelectedOp({ timing, duration });
          setPause(false);
          recordDone(duration);
        }}
        title={duration === -1 ? "Continuous" : `${duration} minutes`}
        clickable={true}
      />
    ));

  const onboardingFinished = () => {
    setOnboarding(false);
    cookies.set("hasDoneBreathing", true, { path: "/" });
  };

  if (onboarding) {
    return <Onboarding onboardingFinished={onboardingFinished} />;
  }

  const mins = Math.floor(countOverall / 60);
  let secs = countOverall - mins * 60;
  if (secs < 10) {
    secs = `0${secs}`;
  }

  return (
    <PageRoot>
      <NavRoot>
        <NavTitle>Deep Breathing</NavTitle>
      </NavRoot>
      <PageContent>
        {selectedOp && (
          <>
            <BackButton
              style={{ color: "#fff" }}
              onClick={() => {
                setSelectedOp(null);
              }}
            />
            <OverallTime>
              {mins}:{secs}
            </OverallTime>
          </>
        )}

        <FloatingBox>
          {selectedOp === null ? (
            <>
              <div style={{ position: "absolute", right: 0, top: 0 }}>
                <OnboardingButton onClick={() => setOnboarding(true)} />
              </div>
              <IntroText>Find a quiet place</IntroText>
              <IntroText>Sitting upright, back straight</IntroText>
              <Options />
            </>
          ) : (
            <>
              <CircleAnimation
                timing={selectedOp.timing}
                duration={selectedOp.duration}
                pause={pause}
              />
              <PauseMuteContainer>
                <PauseButton
                  onClick={() => {
                    setPause(!pause);
                  }}
                >
                  {pause ? (
                    <PlayCircle
                      color="#fff"
                      style={{ width: 80, height: 80 }}
                    />
                  ) : (
                    <PauseCircle
                      color="#fff"
                      style={{ width: 80, height: 80 }}
                    />
                  )}
                </PauseButton>
                <MuteButton
                  onClick={() => {
                    setMute(!mute);
                  }}
                >
                  {!mute ? (
                    <VolumeHigh
                      color="#fff"
                      style={{ width: 30, height: 30 }}
                    />
                  ) : (
                    <VolumeMute
                      color="#fff"
                      style={{ width: 30, height: 30 }}
                    />
                  )}
                </MuteButton>
              </PauseMuteContainer>

              {selectedOp !== null && selectedOp.duration === -1 && (
                <ButtonWhite
                  style={{ marginTop: 30 }}
                  onClick={() => {
                    setSelectedOp(null);
                    history.push(`/feedback/breathe?next=/suggestions?bgCol=b5e3fa%26title=De-Stress Deep Breathing%26eventType=breathe%26eventId=${selectedOp.duration}`);
                  }}
                >
                  Finish
                </ButtonWhite>
              )}
            </>
          )}
        </FloatingBox>
      </PageContent>
    </PageRoot>
  );
};

export default memo(Breathe);
