import React, { memo } from 'react';
import { Tile } from '../ReflectionAnalytics.styled';
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts';
import { PositiveSummary, NegativeSummary, ContentRoot } from './MoodCount.styled';

const RADIAN = Math.PI / 180;

const MoodCount = ({ totals, filterSelected }) => {
  const { data, percentages, total } = totals;

  const renderCustomizedLabel = (entry) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, value } = entry;
    const radius = innerRadius + (outerRadius - innerRadius - 20) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (value === 0) {
      return null;
    }

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {value}
      </text>
    );
  };

  const body = (
    <ContentRoot>
      <NegativeSummary value={percentages.negative} />
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            innerRadius={40}
            outerRadius={90}
            fill="#8884d8"
            paddingAngle={0.2}
            dataKey="total"
            labelLine={false}
            label={renderCustomizedLabel}
          >
            <Label
              value={total} position="center" fontSize='14px'
            />
            {data.map(mood => (
              <Cell
                cursor="pointer"
                key={mood.state}
                fill={mood.colour}
                onClick={() => filterSelected(mood, 'stateGroup')}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <PositiveSummary value={percentages.positive} />
    </ContentRoot>
  );

  return (
    <Tile
      title="Mood Count"
      body={body}
    />
  );
};

export default memo(MoodCount);
