import styled from "styled-components";

export const Course = styled.div`
  background-color: #f9f9f9;
  border-bottom: #222 1px solid;
  padding-bottom: 10px;
  margin: 10px 0;
`;

export const CourseTitle = styled.h6``;
