import React, { memo, useState } from "react";
import {
  Activity,
  Bottom,
  BottomTitle,
  DurationInput,
  HabitList,
  Inner,
  PageContent,
  Title,
  Top,
  DeleteButton,
  Or,
  SubTitle,
} from "./HabitTracker.styled";
import { post } from "../../actions/api";
import { ButtonBlue, ButtonWhite, PageRoot } from "../../components/common";
import { habitActivities } from "./habitActivities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";

const CreateHabitTracker = () => {
  const history = useHistory();
  const activityArray = Object.values(habitActivities);
  const [step, setStep] = useState(0);
  const [customActivity, setCustomActivity] = useState("");

  const [habitTemplate, setHabitTemplate] = useState({
    duration: 30,
    activities: [],
    feedTitle: "",
  });

  const nextButtonStyle = {
    marginTop: 40,
    minWidth: 300,
    position: "absolute",
    bottom: 40,
  };

  const save = async () => {
    const res = await post("habit", habitTemplate);
    setHabitTemplate(res);
    setStep(4);
  };
  return (
    <PageRoot>
      <PageContent>
        {step === 0 && (
          <Inner>
            <img
              src={require("../../../imgs/target.png")}
              style={{ width: "100%", maxWidth: 200, marginBottom: 40 }}
            />
            <Title>Create A Challenge</Title>
            <ButtonWhite onClick={() => setStep(1)} style={nextButtonStyle}>
              Start
            </ButtonWhite>
          </Inner>
        )}
        {step === 1 && (
          <Inner>
            <Title>How mange days do you want to run your challenge for?</Title>
            <DurationInput
              type="number"
              value={habitTemplate.duration}
              onChange={(e) =>
                setHabitTemplate({ ...habitTemplate, duration: e.target.value })
              }
            />
            <ButtonWhite onClick={() => setStep(2)} style={nextButtonStyle}>
              Next
            </ButtonWhite>
          </Inner>
        )}
        {step === 2 && (
          <>
            <Top>
              <Title>{habitTemplate.duration.toString()} Day Challenge</Title>
            </Top>
            <Bottom>
              <BottomTitle>Select Your Daily Habits</BottomTitle>
              <HabitList>
                {habitTemplate.activities.map((activity, i) => (
                  <Activity key={i}>
                    {activity.title}
                    <DeleteButton
                      onClick={() => {
                        const newActivities = [...habitTemplate.activities];
                        newActivities.splice(i, 1);
                        setHabitTemplate({
                          ...habitTemplate,
                          activities: newActivities,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </DeleteButton>
                  </Activity>
                ))}
              </HabitList>
              <ButtonBlue
                onClick={() => setStep(3)}
                style={{ width: "100%", maxWidth: 300 }}
              >
                + Add a Habit
              </ButtonBlue>

              {habitTemplate.activities.length > 0 && (
                <ButtonBlue onClick={save} style={nextButtonStyle}>
                  Done
                </ButtonBlue>
              )}
            </Bottom>
          </>
        )}
        {step === 3 && (
          <>
            <Top>
              <Title>Add a Habit</Title>
            </Top>
            <Bottom>
              <div className="select">
                <select
                  style={{ fontSize: "18px", width: "100%" }}
                  onChange={(e) => {
                    setHabitTemplate({
                      ...habitTemplate,
                      activities: [
                        ...habitTemplate.activities,
                        {
                          url: null,
                          type: activityArray[Number(e.target.value)].type,
                          title: activityArray[Number(e.target.value)].title,
                          linkId: null,
                        },
                      ],
                    });
                    setStep(2);
                  }}
                >
                  <option value={-1}>Select from LUME</option>
                  {activityArray.map((activity, i) => (
                    <option key={i} value={i}>
                      {activity.title}
                    </option>
                  ))}
                </select>
                <Or>
                  <span
                    style={{
                      backgroundColor: "white",
                      padding: "0 20px",
                      position: "relative",
                    }}
                  >
                    OR
                  </span>
                </Or>
                <input
                  type="text"
                  placeholder="Enter your own"
                  style={{
                    fontSize: "18px",
                    width: "100%",
                    padding: "10px 20px",
                    border: "#eee 1px solid",
                    borderRadius: 3,
                  }}
                  value={customActivity}
                  onChange={(e) => setCustomActivity(e.target.value)}
                />
              </div>
              {customActivity && (
                <ButtonBlue
                  style={nextButtonStyle}
                  onClick={() => {
                    setHabitTemplate({
                      ...habitTemplate,
                      activities: [
                        ...habitTemplate.activities,
                        {
                          url: null,
                          type: "custom",
                          title: customActivity,
                          linkId: null,
                        },
                      ],
                    });
                    setStep(2);
                    setCustomActivity("");
                  }}
                >
                  Add
                </ButtonBlue>
              )}
            </Bottom>
          </>
        )}
        {step === 4 && (
          <>
            <Title>Great!</Title>
            <SubTitle>
              Your {habitTemplate.duration.toString()} day challenge is ready.
            </SubTitle>
            <ButtonWhite
              style={{ marginTop: 40, minWidth: 300 }}
              onClick={() => {
								history.push(`/habit-tracker/${habitTemplate.id}`);
              }}
            >
              Start Challenge
            </ButtonWhite>
          </>
        )}
      </PageContent>
    </PageRoot>
  );
};

export default memo(CreateHabitTracker);
