import React, { useContext } from "react";
import { del } from "../../actions/api";
import { MainContext } from "../../context/MainContext";

const HabitdivHeader = ({
  title,
  habitTemplateId,
  showIndex,
  setShowIndex,
  complete,
}) => {
  const ctx = useContext(MainContext);

  const handleDelete = () => {
    deleteChallenge();
  };

  const deleteChallenge = async () => {
		console.log("DELETE")
    // await del(`habit/${habitTemplateId}`);
  };
  return (
    <div style={styles.header}>
      {showIndex && (
        <div
          style={{
            position: "absolute",
            top: 10,
            left: 10,
          }}
          onClick={() => {
            setShowIndex(false);
          }}
        >
          X
        </div>
      )}
      <div style={styles.headerText}>{title}</div>
      {!showIndex && (
        <>
          {!complete && (
            <div
              onClick={() => setShowIndex(true)}
              style={{ position: "absolute", right: 10 }}
            >
              MENU
            </div>
          )}
          <div
            onClick={handleDelete}
            style={{ position: "absolute", right: complete ? 10 : 55 }}
          >
            DEL
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  header: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    
  },
  headerText: {
    textAlign: "center",
    fontSize: 26,
    fontWeight: "bold",
    color: "white",
  },
};

export default HabitdivHeader;
