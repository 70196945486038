import styled from 'styled-components';

export const Title = styled.p`
	font-weight: bold;
	color: #263b59;
	font-size: 1rem;
	margin-bottom: 20px;
`;

export const ReInviteForm = styled.form`
	display: ${props => props.sent ? "none" : "block"};
	margin-bottom: 20px;
`;

export const SentMessage = styled.div`
	display: ${props => props.sent ? "block" : "none"};
`;