import React, { memo } from 'react';
import { Month } from '../../../components/common';
import { ContentRoot } from './Months.styled';

const Months = ({ months, eventMonthLookup, dateClicked }) => {
  return (
    <ContentRoot>
      {months.map(month => (
        <Month
          key={month}
          month={month}
          days={eventMonthLookup[month]}
          selectedDateChanged={dateClicked}
        />
      ))}
    </ContentRoot>
  );
};

export default memo(Months);
