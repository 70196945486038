import React from 'react';
import '../../css/UserEdit.css';
import { api } from '../actions/api.js';
import { MainContext } from '../context/MainContext';
import UserCourses from './UserCourses/UserCourses';

class UserEdit extends React.Component {
	static contextType = MainContext;
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			edited: false,
			grantProp: '',
			grantVal: '',
			permissionEdit: false,
			reinvite: 0,
			permissionEditTarget: {
				name: '',
				permissionName: ''
			}
		};
	}

	componentDidMount() {
		this.setState({ user: this.props.selectedUser, edited: false })
	}

	handleInputChange = (event) => {
		const target = event.target;
		const name = target.name;
		const value = target.value;
		let user = this.state.user;
		user[name] = value;
		this.setState({ user, edited: true });
	}

	handlePropChange = (event) => {
		const target = event.target;
		const name = target.name.substr(2);
		const value = target.value;
		let user = this.state.user;

		user.customProps[name] = value;
		this.setState({ user, edited: true });
	}

	updateUser = () => { }

	componentDidUpdate(prevProps) {
		if (this.props.selectedUser) {
			if (prevProps.selectedUser) {
				if (prevProps.selectedUser.id !== this.props.selectedUser.id) {
					this.setState({ user: this.props.selectedUser })
				}
			} else {
				this.setState({ user: this.props.selectedUser })
			}
		} else if (this.state.user) {
			this.setState({ user: null })
		}
	}

	CPPropChange = (e) => {
		let user = this.state.user;
		console.log(this.state.permissionEditTarget.permissionName);
		this.setState({ grantProp: e.target.value })
	}

	CPValChange = (e) => {
		this.setState({ grantVal: e.target.value })
	}

	CPOpChange = (e) => {
		const op = Number(e.target.name);
		let user = this.state.user;

		if (!op) {
			delete user.permissions[this.state.permissionEditTarget.permissionName][this.state.user.accOwner]
		} else if (op === 1) {
			return;
		} else if (op === 2) {
			if (!user.permissions[this.state.permissionEditTarget.permissionName]) {
				user.permissions[this.state.permissionEditTarget.permissionName] = {}
			}
			user.permissions[this.state.permissionEditTarget.permissionName][this.state.user.accOwner] = {
				userRestrictions: [],
				dataRestrictions: [],
			}
		} else if (op === 3) { // this is just for userManagement at the moment - it sets some limits to prevent permission changes by basic admin users
			if (!user.permissions[this.state.permissionEditTarget.permissionName]) {
				user.permissions[this.state.permissionEditTarget.permissionName] = {}
			}
			user.permissions[this.state.permissionEditTarget.permissionName][this.state.user.accOwner] = {
				dataRestrictions: [
					{ limit: true }
				],
				userRestrictions: []
			}
		}

		this.setState({ user })
	}

	closePermissionEdit = (e) => {
		e.preventDefault();
		this.setState({
			permissionEdit: false, permissionEditTarget: { name: '', permissionName: '' }
		});
	}

	openPermissionEdit = (e) => {
		e.preventDefault();
		const permissionName = e.target.name;
		const names = {
			WDS: "Wellbeing Data System",
			DC: "Daily Checkin",
			userManagement: "User Data"
		}
		let name;
		if (names[permissionName]) {
			name = names[permissionName]
		} else {
			return false;
		}


		let permission = {};
		if (this.state.user.permissions[permissionName]) {
			permission = this.state.user.permissions[permissionName][this.state.user.accOwner];
		}
		this.setState({
			permissionEdit: true,
			permissionEditTarget: {
				name,
				permissionName,
			}
		});
	}

	addPermisssion = (e) => {
		e.preventDefault();

		// one of the selects hasn't been used
		if (!this.state.grantProp || !this.state.grantVal) {
			return false;
		}

		let user = this.state.user;

		// create empty userRestriction object
		if (!user.permissions[this.state.permissionEditTarget.permissionName]) {
			user.permissions[this.state.permissionEditTarget.permissionName] = [{
				userRestrictions: []
			}]
		}


		let permissions = user.permissions[this.state.permissionEditTarget.permissionName][this.state.user.accOwner]

		if (!permissions) {
			user.permissions[this.state.permissionEditTarget.permissionName][this.state.user.accOwner] = {}
			permissions = user.permissions[this.state.permissionEditTarget.permissionName][this.state.user.accOwner]
		}

		// permissions are there but no where clause..
		if (!permissions.userRestrictions) {
			permissions.userRestrictions = []
		}

		// check to see if this filter is already there
		let found = false;
		permissions.userRestrictions.forEach(condition => {
			if (condition.propId === this.state.grantProp && condition.propValue === this.state.grantVal) {
				found = true;
			}
		})

		// if it wasn't found, add it.
		if (!found) {
			permissions.userRestrictions.push({
				propId: this.state.grantProp,
				propValue: this.state.grantVal
			})
		}

		// update user in state
		this.setState({ user })
	}

	savePermissions = (e) => {
		this.closePermissionEdit(e);
	}

	cancelPermissions = (e) => {
		this.closePermissionEdit(e);
		this.props.resetSelectedUserPermissions();
	}

	deletePropFilter = (e, i) => {
		e.preventDefault();
		let user = this.state.user;
		user.permissions[this.state.permissionEditTarget.permissionName][this.state.user.accOwner].userRestrictions.splice(i, 1);
		// none left so mark as no access
		if (!user.permissions[this.state.permissionEditTarget.permissionName][this.state.user.accOwner].userRestrictions.length) {
			delete user.permissions[this.state.permissionEditTarget.permissionName][this.state.user.accOwner]
		}

		this.setState({ user })
	}

	reinvite = e => {
		this.setState({ reinvite: 1 });
		e.preventDefault();
		api("user/reinvite/" + this.state.user.id, "post", {}, resp => {
			if (resp.message === "Invitation resent") {
				this.setState({ reinvite: 2 });
				setTimeout(() => {
					this.setState({ reinvite: 0 });
				}, 10000);
			} else {
				alert("Sorry, there was a problem resetting")
			}
		})
	}

	render() {
		let user = this.state.user;
		if (user === null) {
			return null
		} else {
			if (!user.id) {
				return null
			}
		}

		// find and set custom properties of this user
		let customProps = null;
		if (this.props.customProps) {
			if (Object.values(this.props.customProps).length) {
				customProps = Object.values(this.props.customProps).map((cp, i) => {
					let ops = cp.values.map((v, ii) => {
						let selected = '';
						if (user.customProps[cp.id] === v) {
							selected = "selected='selected'"
						}
						return (
							<option key={ii}>{v}</option>
						)
					})
					return (
						<div className="field is-horizontal" key={i}>
							<div className="field-label is-normal">
								<label className="label">{cp.title}</label>
							</div>
							<div className="field-body">
								<div className="field">
									<div className="select">
										<select value={user.customProps[cp.id]} name={"cp" + cp.id} onChange={this.handlePropChange}>
											{ops}
										</select>
									</div>
								</div>
							</div>
						</div>
					)
				})
			}
		}

		/* USER PERMISSIONS
			This is a bit of a mess to be honest, but roughly:

			We have an overview (down the bottom) which is show in the main user edit pane, then a modal which gives edit options.
			A user can have all some or no access which is selected using a radio button. If some is selected then they can pick
			segments using a pair of select dropdowns.

			- propOps: none = 0, some = 1, all = 2

			Then for granting specific access we need:

			- grantProp: what property should we show options for
			- WDS_vals: the values of the property

		*/
		if (!user.permissions) {
			user.permissions = {}
		}

		let propOps = [];
		if (this.props.customProps) {
			propOps = Object.values(this.props.customProps).map((cp, i) => {
				return (
					<option value={cp.id} key={i}>{cp.title}</option>
				)
			})
		}
		propOps.unshift(<option key='-' value=''>Property</option>)

		let propVals = [];
		if (this.state.grantProp) {
			if (this.props.customProps[this.state.grantProp]) {
				propVals = this.props.customProps[this.state.grantProp].values.map((v, i) => {
					return (
						<option value={v} key={i}>{v}</option>
					)
				})
			}
		}
		propVals.unshift(<option key='-' value=''>Value</option>)

		let currentWhereFilters = null;
		let access = 0;
		if (user.permissions[this.state.permissionEditTarget.permissionName]) {

			if (user.permissions[this.state.permissionEditTarget.permissionName][user.accOwner]) {
				if (user.permissions[this.state.permissionEditTarget.permissionName][user.accOwner].userRestrictions.length) {
					access = 1;
					currentWhereFilters = user.permissions[this.state.permissionEditTarget.permissionName][user.accOwner].userRestrictions.map((f, i) => {
						return (
							<div className="currentPermissionFilter" key={i}>
								<span>{this.props.customProps[f.propId].title}:</span>
								<span>{f.propValue}</span>
								<span onClick={(e) => this.deletePropFilter(e, i)}>
									<i className="fas fa-trash-alt"></i>
								</span>
							</div>
						)
					})
				} else if (user.permissions[this.state.permissionEditTarget.permissionName][user.accOwner].dataRestrictions) {
					if (user.permissions[this.state.permissionEditTarget.permissionName][user.accOwner].dataRestrictions.length) {
						access = 3;
					} else {
						access = 2;
					}
				} else {
					access = 2;
				}
			}
		}
		let onOps;
		if (this.state.permissionEditTarget.permissionName === "userManagement") {
			onOps = (
				<div>
					<div className="permissionAccessOp">
						<label className="radio ">
							<input type="radio" name="3"
								checked={access === 3}
								onChange={this.CPOpChange}
							/>
							<b>Limited</b> admin permissions: edit basic user details (name, email, etc)
						</label>
					</div>
					<div className="permissionAccessOp">
						<label className="radio ">
							<input type="radio" name="2"
								checked={access === 2}
								onChange={this.CPOpChange}
							/>
							<b>Full</b> admin permissions: edit, delete and change user permissions
						</label>
					</div>
				</div>
			)
		} else {
			onOps = (
				<div>
					<div className="permissionAccessOp">
						<label className="radio ">
							<input type="radio" name="2"
								checked={access === 2}
								onChange={this.CPOpChange}
							/>
							<b>All</b> {this.state.permissionEditTarget.name.toLowerCase()} data
						</label>
					</div>

					<div className="permissionAccessOp">
						<label className="radio ">
							<input type="radio" name="1"
								checked={access === 1}
								onChange={this.CPOpChange}
							/>
							<b>Limited</b> {this.state.permissionEditTarget.name.toLowerCase()} data for:
							<div className="propSelectWrapper">
								<div className="select">
									<select value={this.state.grantProp} style={{ marginRight: 5 }} onChange={this.CPPropChange}>
										{propOps}
									</select>
								</div>
								<div className="select" >
									<select value={this.state.grantVal} onChange={this.CPValChange}>
										{propVals}
									</select>
								</div>
								<button className="button is-dark" style={{ marginLeft: 5 }} onClick={this.addPermisssion}>Add permission</button>
								<div style={{ marginTop: 10 }}>
									{currentWhereFilters}
								</div>
							</div>
						</label>
					</div>
				</div>
			)
		}
		let editPermission = (
			<div className={this.state.permissionEdit ? "modal is-active" : "modal"}>
				<div className="modal-background"></div>
				<div className="modal-content">
					<div className="panel">
						<p className="panel-heading">
							Edit {this.state.permissionEditTarget.name} Access Permissions
						</p>
						<div style={{ backgroundColor: "#fff", padding: 20 }}>
							<div>
								{user.first_name} {user.last_name} has access to:
							</div>
							<div className="control">
								<div className="permissionAccessOp">
									<label className="radio " >
										<input type="radio" name="0"
											checked={access === 0}
											onChange={this.CPOpChange}
										/>
										<b>No</b> {this.state.permissionEditTarget.name.toLowerCase()} data
									</label>
								</div>
								{onOps}
							</div>

							<div style={{ height: 40 }}>
								<button className="button is-primary pull-right" onClick={this.savePermissions}>Finish</button>
								<button className="button is-light pull-right" onClick={this.cancelPermissions} style={{ marginRight: 10 }}>Cancel</button>
							</div>

						</div>
					</div>
				</div>
				<button className="modal-close is-large" aria-label="close" onClick={this.closePermissionEdit}></button>
			</div >
		)

		let permissionDescr = {
			WDS: "No access",
			DC: "No access",
			userManagement: "No access"
		}

		if (user.permissions) {
			if (user.permissions.WDS) {
				if (user.permissions.WDS[user.accOwner]) {
					if (user.permissions.WDS[user.accOwner].userRestrictions.length) {
						permissionDescr.WDS = "Limited access"
					} else {
						permissionDescr.WDS = "Full access"
					}
				}
			}
			if (user.permissions.DC) {
				if (user.permissions.DC[user.accOwner]) {
					if (user.permissions.DC[user.accOwner].userRestrictions.length) {
						permissionDescr.DC = "Limited access"
					} else {
						permissionDescr.DC = "Full access"
					}
				}
			}
			if (user.permissions.userManagement) {
				if (user.permissions.userManagement[user.accOwner]) {
					if (user.permissions.userManagement[user.accOwner].dataRestrictions.length) {
						permissionDescr.userManagement = "Limited access"
					} else {
						permissionDescr.userManagement = "Full access"
					}
				}
			}
		}

		// permission overview
		let permissions = (
			<div>
				<div className="field is-vertical">
					<button className="is-pulled-right" onClick={this.openPermissionEdit} name="WDS">Edit</button>
					<h6>Wellbeing Data System Data Access</h6>
					<p>
						{permissionDescr.WDS}
					</p>
				</div>
				<div className="field is-vertical">
					<button className="is-pulled-right" onClick={this.openPermissionEdit} name="DC">Edit</button>
					<h6>Daily Reflection Data</h6>
					<p>
						{permissionDescr.DC}
					</p>
				</div>
				<div className="field is-vertical">
					<button className="is-pulled-right" onClick={this.openPermissionEdit} name="userManagement">Edit</button>
					<h6>Admin Access</h6>
					<p>
						{permissionDescr.userManagement}
					</p>
				</div>
				{editPermission}
			</div>
		)

		let permissionSection = null;
		if (this.context.user.permissions.userManagement || this.context.user.user_type === 5) {
			if (this.context.user.permissions.userManagement[user.accOwner] || this.context.user.user_type === 5) {
				if (!this.context.user.permissions.userManagement[user.accOwner].dataRestrictions.length || this.context.user.user_type === 5) {
					permissionSection = (
						<div>
							<div className="field is-horizontal" style={{ marginTop: 20 }}>
								<h5>Admin &amp; Data Permissions</h5>
							</div>
							{permissions}
						</div>
					)
				}
			} else {
				// console.log("HERE:", this.context.user.permissions.userManagement)
			}
		}

		let inactive = null;
		if (user.accountActivated === null) {
			inactive = (
				<div className={'inactiveNotification ' + ' status_' + this.state.reinvite}>
					This account has not yet been activated: <button onClick={this.reinvite}></button>
				</div>
			)
		}

		return (
			<div className="panel">
				<p className="panel-heading">
					{user.first_name + " " + user.last_name}
				</p>
				<div className="panel-block is-primary  " style={{}}>
					<form onSubmit={this.updateUser} style={{ width: "100%" }}>
						{inactive}
						<div className="field is-horizontal">
							<h5>Properties</h5>
						</div>


						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label">Email</label>
							</div>
							<div className="field-body">
								<div className="field">
									<p className="control">
										<input className="input" value={user.email} type="email" name="email" placeholder="Email address" onChange={this.handleInputChange} />
									</p>
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label">First name</label>
							</div>
							<div className="field-body">
								<div className="field">
									<p className="control">
										<input className="input" value={user.first_name} type="text" name="first_name" placeholder="First name" onChange={this.handleInputChange} />
									</p>
								</div>
							</div>
						</div>

						<div className="field is-horizontal">
							<div className="field-label is-normal">
								<label className="label">Last name</label>
							</div>
							<div className="field-body">
								<div className="field">
									<p className="control">
										<input className="input" value={user.last_name} name="last_name" type="text" placeholder="Last name" onChange={this.handleInputChange} />
									</p>
								</div>
							</div>
						</div>

						{customProps}
						{permissionSection}
						<UserCourses user={user} />
						<button className="button is-primary pull-right" onClick={this.props.save}>Save</button>
						<button className="button is-light pull-right" onClick={this.props.cancel} style={{ marginRight: 10 }}>Cancel</button>
					</form>
				</div>
			</div>

		);
	}
}

export default UserEdit;
