import styled from "styled-components";
import { TopNav } from "../../components/common";
import bubbles from "../../../imgs/bubbles_white_square_2.svg";
import ExpandingTextarea from "react-expanding-textarea";

export const RakText = styled(ExpandingTextarea)`
  background: transparent;
  color: white;
  resize: none;
  margin-bottom: 1rem;
  padding: 0 2rem;
  font-size: 22px;
  line-height: 34px;
  font-weight: bolder;
  border: none;
  &:focus {
    outline: none;
  }
`;

export const SkipButton = styled.button`
  width: auto;
  color: white;
  text-align: center;
  justify-content: center;
  border: 1px white solid;
  background: transparent;
  padding: 3px 10px;
  border-radius: 2rem;
  font-size: 14px;
  &:hover {
    color: white;
  }
`;

export const PageContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 3rem;
  flex-direction: column;
  position: relative;
  width: 100%;
	background-color: ${({ bgColour, theme }) =>
    bgColour ?? theme.primary.colours.pageBackground};
  background-image: url(${bubbles});
`;

export const IntroText = styled.div`
  font-size: 30px;
  margin: 0 0 20px 0;
  text-align: center;
  color: #000;
`;

export const PauseMuteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  gap: 20px;
`;

export const PauseButton = styled.div`
  cursor: pointer;
`;

export const MuteButton = styled.div`
  cursor: pointer;
  border: #fff 1px solid;
  height: 65px;
  border-radius: 50%;
  width: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
`;

export const OverallTime = styled.span`
  text-align: center;
  margin-bottom: 30px;
  margin-top: -20px;
  font-weight: bold;
  font-size: 24px;
  color: #fff;
`;

export const FloatingBox = styled.div`
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
`;

export const Outline = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 100%;
  border: #fff 2px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StageText = styled.span`
  display: block;
  position: absolute;
  text-align: center;
  width: 200px;
  height: 30px;
  font-size: 32px;
  color: #b5e3fa;
  left: 50%;
  top: 50%;
  margin: -40px 0 0 -100px;
  font-weight: lighter;
`;

export const CountText = styled.span`
  display: block;
  position: absolute;
  text-align: center;
  width: 200px;
  height: 24px;
  font-size: 24px;
  font-weight: bold;
  color: #b5e3fa;
  left: 50%;
  top: 50%;
  margin: 10px 0 0 -100px;
`;

export const SaveButton = styled.button`
  background-color: white;
  color: ${({ theme }) => theme.primary.colours.blueD};
  width: 12rem;
  padding: 0.7rem;
  border-radius: 3rem;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
  justify-self: center;
`;

export const BoxTitle = styled.h4`
  color: white;
  text-align: center;
  margin-bottom: 1rem;
  padding: 0 2rem;
`;

export const ToggleButton = styled.button`
  width: 185px;
  display: flex;
  align-items: center;
  background-color: inherit;
  color: ${({ theme }) => theme.primary.colours.blueM};
  font-size: 2.5rem;
  justify-content: flex-start;
  gap: 5px;
  > * {
    line-height: 20px;
    text-align: left;
  }
`;
