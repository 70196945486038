const checkConditions = (con, interactions, dayComplete) => {
	// true = show
	// false = hide

	let result = true;

	// Check input values first
	if (con.subject === "input") {
		if (con.condition === "equal") {
			if (!interactions[Number(con.subjectId)]) {
				result = false;
			} else if (con.value !== interactions[Number(con.subjectId)].value) {
				result = false;
			}
		} else if (con.condition === "notEqual") {
			if (interactions[Number(con.subjectId)]) {
				if (con.value === interactions[Number(con.subjectId)].value) {
					result = false;
				}
			} else if (con.value) {
				result = false;
			}
		} else if (con.condition === "greater") {
			if (!interactions[Number(con.subjectId)]) {
				result = false;
			} else if (
				Number(interactions[Number(con.subjectId)].value) <= Number(con.value)
			) {
				result = false;
			}
		} else if (con.condition === "less") {
			if (!interactions[Number(con.subjectId)]) {
				result = false;
			} else if (
				Number(interactions[Number(con.subjectId)].value) >= Number(con.value)
			) {
				result = false;
			}
		}
	}

	// now check device
	else if (con.subject === "device") {
		if (con.value !== "web") {
			result = false;
		}
	}

	else if (con.subject === "doneDay") {
		result = dayComplete;
	}

	return con.action === "show" ? result : !result;
};

export default checkConditions;
