import styled from 'styled-components';
import { DayLine } from '../Day.styled';

export const Title = styled.span`
  font-weight: bold;
  font-size: 24px;
  color: ${props => props.colour};
  vertical-align: center;
`;

export const Time = styled.span`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  padding-top: 5px;
  font-size: 14px;
	width: 100px;
  * {
    font-size: 14px;
    margin-left: 0.3rem;
  }
`;

export const Influences = styled(DayLine)`
  > * {
    height: 8rem;
    margin-right: 1rem;
  }
`;
