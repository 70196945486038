import React, { memo, useEffect } from "react";
import Text from "./Text/Text";
import Button from "./Button/Button";
import PositiveCheckin from "./PositiveCheckin/PositiveCheckin";
import RAK from "./RAK/RAK";
import Gratitude from "./Gratitude/Gratitude";
import Exercise from "./Exercise/Exercise";
import Meditation from "./Meditation/Meditation";
import SignpostLink from "./SignpostLink/SignpostLink";
import Vimeo from "./Vimeo/Vimeo";
import { post, del } from "../../../../actions/api";
import ResetButton from "./ResetButton/ResetButton";
import InputText from "./InputText/InputText";
import Audio from "./Audio/Audio";
import InputMulti from "./InputMulti/InputMulti";
import MCQ from "./MCQ/MCQ";
import TickBox from "./TickBox/TickBox";
import ScreenIndex from "./ScreenIndex/ScreenIndex";
import AddToToday from "./AddToToday/AddToToday";
import ImgElm from "./ImgElm/ImgElm";
import ProgressMarker from "./ProgressMarker/ProgressMarker";
import Feedback from "./Feedback/Feedback";

const Element = (props) => {
  const recordInteraction = async (elementId, value, eventTime) => {
    await post("course/elminteraction", { elementId, value, eventTime });
    props.getCourse();
  };

  const delInteraction = async (elementId) => {
    await del("course/elminteraction/" + elementId, {});
    props.getCourse();
  };

  const elm = props.elm;

  if (typeof elm.style === "string" && elm.style.length > 2) {
    elm.style = JSON.parse(elm.style);
    if (!Object.keys(elm.style).length) {
      elm.style = null;
    }
  } else if (typeof elm.style === "object") {
    // elm.style = JSON.parse(elm.style);
  } else {
    elm.style = null;
  }
  switch (elm.type) {
    case "txt":
      return (
        <Text
          elm={elm}
          mergeTags={props.mergeTags}
          screenStyles={props.screenStyles}
          sectionStyles={props.sectionStyles}
          style={elm.style}
        />
      );

    case "btn":
      return (
        <Button
          elm={elm}
          course={props.course}
          findScreen={props.findScreen}
          recordInteraction={recordInteraction}
          currentScreenId={props.currentScreenId}
        />
      );

    case "reset":
      return (
        <ResetButton
          elm={elm}
          resetCourse={props.resetCourse}
          recordInteraction={recordInteraction}
        />
      );

    case "req_pChkin":
      return (
        <PositiveCheckin
          elm={elm}
          recordInteraction={recordInteraction}
          firstScreenInteraction={props.screen.firstInteraction}
        />
      );

    case "req_RAK":
      return (
        <RAK
          elm={elm}
          recordInteraction={recordInteraction}
          firstScreenInteraction={props.screen.firstInteraction}
        />
      );

    case "req_grat":
      return (
        <Gratitude
          elm={elm}
          recordInteraction={recordInteraction}
          firstScreenInteraction={props.screen.firstInteraction}
        />
      );

    case "req_exe":
      return (
        <Exercise
          elm={elm}
          recordInteraction={recordInteraction}
          delInteraction={delInteraction}
					firstScreenInteraction={props.screen.firstInteraction}
        />
      );

    case "req_med":
      return (
        <Meditation
          elm={elm}
          recordInteraction={recordInteraction}
          delInteraction={delInteraction}
					firstScreenInteraction={props.screen.firstInteraction}
        />
      );

    case "vim":
      return (
        <Vimeo
          findScreen={props.findScreen}
          course={props.course}
          elm={elm}
          recordInteraction={recordInteraction}
        />
      );

    case "sp_link":
      return <SignpostLink elm={elm} recordInteraction={recordInteraction} />;

    case "in_txt":
      return (
        <InputText
          elm={elm}
          recordInteraction={recordInteraction}
          latestInteractions={props.latestInteractions}
        />
      );

    case "aud":
      return (
        <Audio
          elm={elm}
          course={props.course}
          recordInteraction={recordInteraction}
          mergeTags={props.mergeTags}
          findScreen={props.findScreen}
        />
      );

    case "in_multi":
      return (
        <InputMulti
          elm={elm}
          recordInteraction={recordInteraction}
          latestInteractions={props.latestInteractions}
        />
      );

    case "mcq":
      return (
        <MCQ
          elm={elm}
          findScreen={props.findScreen}
          recordInteraction={recordInteraction}
          latestInteractions={props.latestInteractions}
        />
      );

    case "noti":
      return (
        <div>
          <a href="https://the.lumeapp.io/activated">Download</a> our mobile app
          to set yourself a reminder.
        </div>
      );

    case "tick":
      return (
        <TickBox
          elm={elm}
          findScreen={props.findScreen}
          recordInteraction={recordInteraction}
          latestInteractions={props.latestInteractions}
        />
      );

    case "scrIndex":
      return (
        <ScreenIndex
          elm={elm}
          findScreen={props.findScreen}
          recordInteraction={recordInteraction}
          latestInteractions={props.latestInteractions}
          screenViews={props.screenViews}
          currentScreenId={props.currentScreenId}
        />
      );

    case "addToToday":
      // this just add to course to today - if it's not there already.
      return (
        <AddToToday
          showInTodaySet={props.showInTodaySet}
          userSubId={props.userSubId}
        />
      );

    case "img":
      return <ImgElm elm={elm} />;

    case "progressMk":
      return (
        <ProgressMarker
          elm={elm}
          course={props.course}
          recordInteraction={recordInteraction}
          latestInteractions={props.latestInteractions}
        />
      );

    case "progressMk":
      return (
        <ProgressMarker
          elm={elm}
          course={props.course}
          recordInteraction={recordInteraction}
          latestInteractions={props.latestInteractions}
        />
      );
    case "feedback":
      return <Feedback elm={elm} course={props.course} screen={props.screen} />;

    default:
      return <span>{elm.type}</span>;
  }
};

export default memo(Element);
