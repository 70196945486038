import styled from 'styled-components';
import { NavTitle as NavTitleCommon } from '../../components/common';

export const PageContent = styled.div`
  width: 100%;
  height: 100%;
  align-items: stretch;
`;

export const NavTitle = styled(NavTitleCommon)`
  > * {
    margin-right: 1rem;
  }
`;
