import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { LeftArrow, RightArrow } from "./Days.styled";
import { ContentRoot } from "./Days.styled";
import Day from "./Day/Day";

const Days = ({
  selectedDate,
  eventDayLookup,
  daysToDisplay = 1,
  reverseDays = false,
  deleteEvent,
  editEvent,
  courseOutlines,
  oneDay,
  style,
  styleInner,
}) => {
  const [days, setDays] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const day = days[selectedIndex];
  const day1 = days[selectedIndex + 1];
  const day2 = days[selectedIndex + 2];

  useEffect(() => {
    const daysLocal = Object.keys(eventDayLookup).sort();
    if (reverseDays === true) {
      daysLocal.reverse();
    }
    setDays(daysLocal);

    const dateFormatted = selectedDate?.format("YYMMDD");
    const index = daysLocal.indexOf(dateFormatted);
    setSelectedIndex(index);
  }, [selectedDate, eventDayLookup]);

  const indexChanged = (movement) => {
    const newIndex = selectedIndex + movement;

    if (newIndex < 0 || newIndex > days.length - daysToDisplay) {
      return;
    }

    setSelectedIndex(newIndex);
  };

  const rightClicked = () => indexChanged(1);
  const leftClicked = () => indexChanged(-1);

  const hasDataToDisplay = eventDayLookup[day] != null;
  return (
    <ContentRoot style={style}>
      {hasDataToDisplay === true && !oneDay && (
        <LeftArrow disabled={selectedIndex === 0} onClick={leftClicked} />
      )}
      <Day
        day={day}
        data={eventDayLookup[day]}
        deleteEvent={deleteEvent}
        editEvent={editEvent}
        courseOutlines={courseOutlines}
        style={styleInner}
      />
      {daysToDisplay > 1 && (
        <Day
          day={day1}
          data={eventDayLookup[day1]}
          deleteEvent={deleteEvent}
          editEvent={editEvent}
          courseOutlines={courseOutlines}
          style={styleInner}
        />
      )}
      {daysToDisplay > 2 && (
        <Day
          day={day2}
          data={eventDayLookup[day2]}
          deleteEvent={deleteEvent}
          editEvent={editEvent}
          courseOutlines={courseOutlines}
          style={styleInner}
        />
      )}
      {hasDataToDisplay && !oneDay && (
        <RightArrow
          disabled={selectedIndex >= days.length - daysToDisplay}
          onClick={rightClicked}
        />
      )}
    </ContentRoot>
  );
};

Days.propTypes = {
  deleteEvent: PropTypes.func,
  editEvent: PropTypes.func,
  daysToDisplay: PropTypes.number,
  reverseDays: PropTypes.bool,
  oneDay: PropTypes.bool,
};

export default memo(Days);
