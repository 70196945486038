import React from 'react';
import '../../css/ControlPanel.css';
import UserAccounts from './UserAccounts';

class Users extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};

	}

	render() {
		return (
			<div>
				<section className="section">
					<UserAccounts />
				</section>
			</div>

		);
	}
}

export default Users;
