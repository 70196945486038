import React from "react";
import { PItem, PTitle } from "./Items.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";

export default function PlayNumbered({ clickable, complete, onClick, number }) {

	const iconStyle = {
		width: 48,
		height: 48,
		color: null,
	};
	iconStyle.color = complete ? "#136665" : clickable ? "#233949" : "#bbbbbb";

	return (
		<PItem clickable={clickable} onClick={onClick}>
			{complete ? (
				<FontAwesomeIcon icon={faCheckCircle} style={iconStyle} />
			) : clickable ? (
				<FontAwesomeIcon icon={faPlayCircle} style={iconStyle} />
			) : (
				<FontAwesomeIcon icon={faPlayCircle} style={iconStyle} />
			)}
			<PTitle>{number}.</PTitle>
		</PItem>
	);
}
