import styled from 'styled-components';

export const TextAreaContainer = styled.div`
  padding: 0 1rem;
  
  textarea {
    resize: none;
    width: 100%;
    border: none;
    outline: none;
  }
`;
