import React from "react";
import "../../../../css/reports.css";
import { api } from "../../../actions/api.js";
import moment from "moment";
import "rsuite/dist/styles/rsuite-default.css";
import ReportsSurveySection from "./ReportsSurveySection";
import { getGraph } from "../../../helpers/getGraph";
import ReactGA from "react-ga";
import { MainContext } from "../../../context/MainContext";

class ReportsSurvey extends React.Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      analysis: [],
      HOST: "https://api.lumeapp.io",
      showHideAnalysis: "contract",
      overall: {
        sections: [],
      },
      surveys: [],
      reviews: [],
      groups: [],
      selectedSurvey: 0,
      customProps: [],
      showSection: false,
      groupValue: "",
      groupById: null,
      groupByTitle: null,
    };
  }

  componentDidMount() {
    this.getSurveys();
    ReactGA.pageview("/wellbeing-data-system");
    if (window.location.hostname === "localhost") {
      this.setState({ HOST: "http://localhost:3003" });
    } else {
      this.setState({ HOST: "https://api.lumeapp.io" });
    }
  }

  changeSurvey = (e) => {
    this.setState({ selectedSurvey: e.target.value }, this.getSurvey);
  };

  getSurveys = () => {
    let t = this;
    api("questionnaire/survey", "GET", null, (d) => {
      if (d.surveyGroups) {
        if (d.surveyGroups.length) {
          t.setState(
            {
              surveys: d.surveyGroups,
              selectedSurvey: d.surveyGroups[d.surveyGroups.length - 1].id,
            },
            t.getSurvey
          );
        }
      }
    });
  };

  getSurvey = (groupBy) => {
    let t = this;
    let data = null;
    if (groupBy) {
      data = {
        groupBy,
      };
    }
    api(
      "questionnaire/surveygroup/" + this.state.selectedSurvey,
      "GET",
      data,
      (d) => {
        if (!d.overall) {
          return null;
        } else {
          let analysis = [];
          d.overall.sections.forEach((s) => {
            if (s.analysis) {
              analysis.push({
                analysis: s.analysis,
                sectionTitle: s.title,
                sectionId: s.id,
              });
            }
          });

          if (!this.state.groupById) {
            t.setState({
              groupByTitle: d.customProps[0].title,
              groupById: d.customProps[0].id,
            });
          }

          t.setState(
            {
              overall: d.overall,
              analysis,
              groups: d.groups,
              customProps: d.customProps,
              reviews: d.reviews,
              reportSynopsis: d.reportSynopsis,
            },
            t.compileData
          );
        }
      }
    );
  };

  showHideAnalysis = () => {
    if (this.state.showHideAnalysis) {
      this.setState({ showHideAnalysis: "" });
    } else {
      this.setState({ showHideAnalysis: "contract" });
    }
  };

  changeGrouping = (e) => {
    let groupTitle;
    this.state.customProps.forEach((cp) => {
      if (cp.id === e.target.value) {
        groupTitle = cp.title;
      }
    });

    this.setState({
      groupById: e.target.value,
      groupByTitle: groupTitle,
    });
    this.getSurvey(e.target.value);
  };

  showSection = (section, groupValue) => {
    window.scrollTo(0, 0);
    this.setState({ showSection: section, groupValue });
  };

  hideSection = () => {
    this.setState({ showSection: false });
  };

  render() {
    const colours = {
      0: "#F9F9F9", // empty
      1: "#E96667", // 1-2
      2: "#FFA171", // 2-3
      3: "#F9C76C", // 3-4
      4: "#99DDCD", // 4-5
      5: "#44BFA2", // 5-6
      6: "#1d7f7a", // 6-7
    };

    let sectionsFilteredForAnonymity = this.state.overall.anonymityFilter;

    let sectionTitles = this.state.overall.sections.map((section, i) => {
      return (
        <div className="column" key={i}>
          <img
            src={
              this.state.HOST +
              "/api/questionnaire/section/" +
              section.id +
              "/icon.png"
            }
            alt={section.title_short}
          />
          <div>{section.title_short}</div>
        </div>
      );
    });

    let getSection = (sections, title, key, anonymityFilter) => {
      let row = sections.map((section, i) => {
        if (section.score) {
          return (
            <div className="column" key={i}>
              <div
                className="inner"
                onClick={() => {
                  this.showSection(section, title);
                }}
                style={{
                  backgroundColor: colours[Math.floor(section.score)],
                  cursor: "pointer",
                }}
                key={i}
              >
                {section.score}
              </div>
            </div>
          );
        } else {
          // empty slot
          return (
            <div className="column" key={i}>
              <div className="inner" style={{ backgroundColor: colours[0] }}>
                {anonymityFilter ? (
                  <span style={{ color: "black", fontSize: 20 }}>&ndash;</span>
                ) : (
                  "&nbsp;"
                )}
              </div>
            </div>
          );
        }
      });

      let titleClass = "";
      if (title === "All") {
        titleClass = "all";
      }

      row = (
        <div className="columns survey data" key={key}>
          <div className="column">
            <div
              className={"inner " + titleClass}
              style={{ color: "#263B59", textAlign: "right" }}
            >
              {title}
              {anonymityFilter ? "*" : null}
            </div>
          </div>
          {row}
        </div>
      );
      return row;
    };

    // create section object for simplicity later on (used for analysis and reviews)
    let sectionDetails = {};
    this.state.overall.sections.forEach((s) => {
      sectionDetails[s.id] = {
        title: s.title,
        score: s.score,
        questions: s.questions,
      };
    });

    let overallSectionData = getSection(
      this.state.overall.sections,
      "All",
      "all",
      this.state.overall.anonymityFilter
    );
    let groupSectionData = this.state.groups.map((g, i) => {
      if (!sectionsFilteredForAnonymity && g.anonymityFilter) {
        sectionsFilteredForAnonymity = true;
      }
      let row = getSection(g.sections, g.title, i, g.anonymityFilter);
      return row;
    });

    let sectionData = (
      <div>
        {overallSectionData}
        {groupSectionData}
      </div>
    );

    let analysis = null;
    let analysisTitle = null;
    let synopsis = null;
    if (this.state.reportSynopsis) {
      analysisTitle = (
        <h4 className="sectionTitle" style={{ marginTop: 20 }}>
          Analysis
        </h4>
      );
      synopsis = (
        <div className="synopsis full-width report-item">
          <div className="analysisTitle">
            <span></span>Synopsis
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: this.state.reportSynopsis.split("\n").join("<br />"),
            }}
          />
        </div>
      );
    }

    if (this.state.analysis.length) {
      analysisTitle = (
        <h4 className="sectionTitle" style={{ marginTop: 20 }}>
          Analysis
        </h4>
      );
      analysis = this.state.analysis.map((a, i) => {
        let button = null;
        if (i === 2) {
          button = (
            <div style={{ clear: "both", paddingTop: 1 }}>
              <button
                className="showHide expand button1"
                onClick={this.showHideAnalysis}
              >
                Show all
              </button>
            </div>
          );
        }
        let thisSection;
        this.state.overall.sections.forEach((s) => {
          if (s.id === a.sectionId) {
            thisSection = s;
          }
        });
        // get range and scores
        let range = [6, 0];
        let answers = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
        };
        sectionDetails[a.sectionId].questions.forEach((q) => {
          Object.entries(q.answers).forEach((a) => {
            let score = a[0];
            // // we don't count 6 as a unique score, it's bundled with 5-6 as there's no 0-1 score
            // if (score > 5) {
            //   score = 5;
            // }
            let count = a[1];
            if (score < range[0]) {
              range[0] = score;
            }
            if (score > range[1]) {
              range[1] = score;
            }

            answers[score] += count;
          });
        });

        let graph = getGraph(answers);
        return (
          <div key={i} className="analysisItem">
            <div className="full-width report-item has-rightSection less-space">
              <div className="p20">
                <div
                  className="analysisTitle"
                  onClick={() => {
                    this.showSection(thisSection, "All");
                  }}
                >
                  <img
                    src={
                      this.state.HOST +
                      "/api/questionnaire/section/" +
                      a.sectionId +
                      "/icon.png"
                    }
                    alt={a.sectionTitle}
                  />
                  <span>{a.sectionTitle}</span>
                </div>
                <div
                  className="content analysis"
                  dangerouslySetInnerHTML={{
                    __html: a.analysis.split("\n").join("<br />"),
                  }}
                />
              </div>
              <div className="rightSection analysis">
                <div
                  style={{ border: "#eee 1px solid" }}
                  className="score large graphContainer "
                  onClick={() => {
                    this.showSection(thisSection, "All");
                  }}
                >
                  {graph}
                </div>
                <div
                  className="score large alt"
                  onClick={() => {
                    this.showSection(thisSection, "All");
                  }}
                >
                  {range[0]} - {range[1]}
                </div>
                <div
                  className="score large"
                  onClick={() => {
                    this.showSection(thisSection, "All");
                  }}
                  style={{
                    backgroundColor:
                      colours[Math.floor(sectionDetails[a.sectionId].score)],
                    borderRadius: 5,
                  }}
                >
                  {sectionDetails[a.sectionId].score}
                </div>
              </div>
            </div>
            {button}
          </div>
        );
      });

      analysis = (
        <div className={"analysisArea " + this.state.showHideAnalysis}>
          {analysis}
          <div style={{ clear: "both", paddingTop: 1 }}>
            <button
              className="showHide contract button1"
              onClick={this.showHideAnalysis}
            >
              Show fewer
            </button>
          </div>
        </div>
      );
    }

    let surveyOptions = null;
    if (this.state.surveys.length) {
      surveyOptions = this.state.surveys.map((s, i) => {
        let title;
        let sDate = moment.utc(s.createdAt).format("D MMMM");
        if (s.completed) {
          title = sDate;
        } else {
          title = "ACTIVE (" + sDate + ")";
        }

        return (
          <option key={i} value={s.id}>
            {title}
          </option>
        );
      });

      surveyOptions.reverse();

      surveyOptions = (
        <div className="column">
          <h6 style={{ marginBottom: 10 }}>Version</h6>
          <div className="select">
            <select
              onChange={this.changeSurvey}
              value={this.state.selectedSurvey}
            >
              {surveyOptions}
            </select>
          </div>
        </div>
      );
    }

    let customProps = null;
    if (this.state.customProps.length) {
      customProps = this.state.customProps.map((cp, i) => {
        return (
          <option value={cp.id} key={i}>
            {cp.title}
          </option>
        );
      });

      customProps = (
        <div className="column">
          <h6 style={{ marginBottom: 10, opacity: 0 }}>Group By</h6>
          <div className="select">
            <select onChange={this.changeGrouping} value={this.state.groupById}>
              {customProps}
            </select>
          </div>
        </div>
      );
    }

    let filters = null;
    if (this.state.customProps.length || this.state.surveys.length) {
      filters = (
        <div className="filters columns">
          {customProps}
          {surveyOptions}
        </div>
      );
    }

    let reviews = null;
    if (this.state.reviews.length) {
      let reviewSections = this.state.reviews.map((r, i) => {
        let icons = r.surveyReviewLinks.map((s, i) => {
          let thisSection;
          this.state.overall.sections.forEach((sec) => {
            if (sec.id === s.sectionId) {
              thisSection = sec;
            }
          });
          let score = sectionDetails[s.sectionId].score;
          let sectionScore = (
            <div
              onClick={() => {
                this.showSection(thisSection, "All");
              }}
              style={{
                backgroundColor: colours[Math.floor(score)],
                marginLeft: 30,
              }}
              className="score small"
            >
              {score}
            </div>
          );

          return (
            <div
              className="linkSection"
              key={i}
              title={s.title}
              onClick={() => {
                this.showSection(thisSection, "All");
              }}
            >
              <img
                src={
                  this.state.HOST +
                  "/api/questionnaire/section/" +
                  s.sectionId +
                  "/icon.png"
                }
                style={{ width: 40 }}
                alt={"section icon"}
              />
              {sectionScore}
            </div>
          );
        });

        let linkedTo = null;
        if (r.surveyReviewLinks.length) {
          linkedTo = (
            <div className="rightSection">
              <b>Linked to:</b>
              {icons}
            </div>
          );
        }

        let recommendations = null;
        if (r.recommendations) {
          recommendations = (
            <div>
              <hr />
              <div className="analysisTitle">
                <span>Recommendation</span>
              </div>
              <div
                className="analysis content"
                dangerouslySetInnerHTML={{
                  __html: r.recommendations.split("\n").join("<br />"),
                }}
              />
            </div>
          );
        }

        return (
          <div key={i} className="analysisItem">
            <div className="full-width report-item has-rightSection">
              {linkedTo}
              <div className="p20">
                <div className="analysisTitle">
                  {r.title ? <span>{r.title}</span> : null}
                </div>
                <div
                  className="analysis content"
                  dangerouslySetInnerHTML={{
                    __html: r.review.split("\n").join("<br />"),
                  }}
                />
                {recommendations}
              </div>
            </div>
          </div>
        );
      });
      reviews = (
        <div>
          <h4 className="sectionTitle">Themes</h4>
          {reviewSections}
        </div>
      );
    }

    let viewUserSwitch = null;
    if (this.context.user) {
      if (
        this.context.user.user_type === 5 ||
        this.context.user.user_type === 4
      ) {
        viewUserSwitch = (
          <section>
            <div className="report-item full-width">Switch user view</div>
          </section>
        );
      }
    }

    let completionRate = this.state.overall.completionRate;
    // *********** not using that for now.
    completionRate = 0;
    if (this.state.overall.sentTo) {
      completionRate = Math.round(
        (this.state.overall.completedBy / this.state.overall.sentTo) * 100
      );
    }

    if (this.state.showSection) {
      return (
        <ReportsSurveySection
          hideSection={this.hideSection}
          section={this.state.showSection}
          colours={colours}
          groupTitle={this.state.groupByTitle}
          groupValue={this.state.groupValue}
        />
      );
    } else {
      return (
        <div style={{ marginTop: 20 }}>
          {viewUserSwitch}

          <section>
            <div className="report-item totals size4 columns">
              <div className="column left">
                <div className="bigTitle">{completionRate}%</div>
                Completion
                <br />
                rate
              </div>
              <div className="column right two_rows">
                <b>{this.state.overall.sentTo}</b> sent
                <br />
                <b>{this.state.overall.completedBy}</b> complete
              </div>
            </div>
            <div className="report-item size1 sent">
              <div className="bigTitle">
                {this.state.overall.daysSent}/{this.state.overall.daysTotal}
              </div>
              Days sent
            </div>
            {filters}
            <div
              className="full-width report-item has-left-shade"
              style={{ paddingBottom: 0 }}
            >
              <div className="columns survey titles">
                <div className="column"></div>
                {sectionTitles}
              </div>
              {sectionData}
            </div>
            <div style={{ clear: "both" }}></div>
            {sectionsFilteredForAnonymity ? (
              <div style={{ marginLeft: 20, paddingBottom: 20 }}>
                * Not enough data to show for this group
              </div>
            ) : null}
            {analysisTitle}
            {synopsis}
            {analysis}
            {reviews}
          </section>
        </div>
      );
    }
  }
}

export default ReportsSurvey;
