import React, { memo } from "react";
import { GroupListContainer, GroupContainer } from "./ElmGroups.styled";
import Element from "./Element/Element";
import checkConditions from "../checkConditions";

const ElmGroups = (props) => {
  const list = props.elmGroups.map((group, i) => {
    // check conditionals
    const con = group.conditions;

    if (con) {
      if (!checkConditions(con, props.latestInteractions, props.dayComplete)) {
        return null;
      }
    }

    const elements = group.courseScrnElms.map((elm, ii) => {
      return (
        <Element
          elm={elm}
          course={props.course}
          key={ii}
          findScreen={props.findScreen}
          getCourse={props.getCourse}
          resetCourse={props.resetCourse}
          mergeTags={props.mergeTags}
          latestInteractions={props.latestInteractions}
          screenViews={props.screenViews}
          showInTodaySet={props.showInTodaySet}
          userSubId={props.userSubId}
          currentScreenId={props.currentScreenId}
          screenStyles={props.screenStyles}
          sectionStyles={props.sectionStyles}
          screen={props.screen}
        />
      );
    });

    const elmGroupStyles = group.style ? JSON.parse(group.style) : null;

    return (
      <GroupContainer key={i} style={elmGroupStyles}>
        {elements}
      </GroupContainer>
    );
  });

  return <GroupListContainer>{list}</GroupListContainer>;
};

export default memo(ElmGroups);
