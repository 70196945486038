import React, { memo } from "react";
import PropTypes from "prop-types";
import { checkInGroup as checkInGroupPropType } from "../../../../propTypes";
import { ContentRoot } from "./EmotionLevelSelector.styled";

import EmotionLevel from "../../EmotionLevel/EmotionLevel";

const EmotionLevelSelector = ({
  emotionGroups,
  emotionGroup,
  emotionGroupSelected,
  rootStyle,
  checkinMode,
}) => {
  return (
    <ContentRoot style={rootStyle}>
      {emotionGroups.map((group) => (
        <EmotionLevel
          key={group.id}
          group={group}
          isSelected={group === emotionGroup}
          onClick={emotionGroupSelected}
          checkinMode={checkinMode}
        />
      ))}
    </ContentRoot>
  );
};

EmotionLevelSelector.propTypes = {
  emotionGroups: PropTypes.arrayOf(checkInGroupPropType).isRequired,
  emotionGroup: checkInGroupPropType,
  emotionGroupSelected: PropTypes.func.isRequired,
  rootStyle: PropTypes.object,
};

export default memo(EmotionLevelSelector);
