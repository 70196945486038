import styled from 'styled-components';

export const PageContent = styled.div`
  padding: 3rem;
  min-width: 100%;
  height: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
	gap: 50px;
`;
