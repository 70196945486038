import React, { memo, useState, useEffect } from 'react';
import '../../../css/survey.css';
import { api, get } from '../../actions/api';
import { useCourseData } from '../../hooks';
import { TileGrid } from '../Home/Home.styled';
import { CourseAndFeatureTile } from '../Home/Tiles';

const Survey = (props) => {
	const [surveys, setSurveys] = useState([]);
	const [qTotal, setQTotal] = useState(0);
	const [qNum, setQNum] = useState(0);
	const [notes, setNotes] = useState('');
	const [notesDone, setNotesDone] = useState(false);
	const [lastSelected, setLastSelected] = useState(0);
	const [completedThisSession, setCompletedThisSession] = useState(0);
	const [accessToken, firstAnswer] = decodeURI(window.location.hash.substr(1)).split("|");
	const { courses } = useCourseData();

	useEffect(() => {
		async function getHash() {
			if (accessToken) {
				await getSurveysFromHash();
			} else {
				await getSurveysFromParams();
			}
		}
		getHash();

	}, []);

	useEffect(() => {
		surveys.forEach((s, i) => {
			if (s.selected) {
				if (s.completed) {
					nextSurvey();
				} else {
					setQTotal(s.questions.length)
					if (firstAnswer) {
						answerQuestion(i, 0, firstAnswer);
					}
				}
			}
		})
	}, [surveys]);

	const getSurveysFromParams = async () => {
		const getGroup = props.match.params.surveyGroupId;

		const data = getGroup ?
			await get("questionnaire/mysurveys/" + props.match.params.surveyGroupId)
			:
			await get("questionnaire/mysurveys")
			;

		let surveys0;

		if (getGroup) {
			if (!data.surveyGroup) {
				return false;
			}
			surveys0 = data.surveyGroup.surveys;
		} else {
			if(!data.surveyGroups.incomplete.length){
				return false;
			}
			surveys0 = data.surveyGroups.incomplete[0].surveys;
		}

		let selected = false;
		surveys0.forEach((s, i) => {
			// fix variation in JSON object between endpoints
			s.questions = [...s.questions.answered, ...s.questions.unanswered];
			s.title = s.sectionTitle;
			s.completedDetails = s.completed;
			s.completed = s.complete;
			if (s.id === props.match.params.surveyId || (!selected && !s.completed)) {
				selected = true;
				s.selected = true;
				setQTotal(s.questions.length)
			}
		})
		setSurveys(surveys0);
	}

	const getSurveysFromHash = async () => {
		let data = await get("questionnaire/surveyfromtoken/" + accessToken)
		if (data.surveys) {
			setSurveys(data.surveys);
		}
	}

	const updateNotes = (e) => {
		setNotes(e.target.value);
	}

	const nextSurvey = () => {
		let surveys2 = surveys;
		// 
		let next = null;
		surveys2.forEach((s, i) => {
			if (s.selected) {
				surveys2[i].completed = true;
				surveys2[i].selected = false;
			} else if (!s.completed && !next) {
				surveys2[i].selected = true;
				next = s;
			}
		});

		setNotes("");
		setNotesDone(false);
		setSurveys(surveys2);
		setCompletedThisSession(completedThisSession + 1);
		setQNum(0);
		if (next) {
			setQTotal(next.questions.length);
		}
	}

	const answerQuestion = (surveyIndex, questionIndex, answer, notes) => {
		let s = surveys;
		if (answer) {
			s[surveyIndex].questions[questionIndex].answered = answer;
		}

		let qN = qNum + 1;
		if (qN > qTotal) { // This means they're on to notes
			qN = qTotal;
		}

		setQNum(qN);
		setSurveys(s);
		setLastSelected(surveyIndex + "-" + questionIndex + "-" + answer);

		let data = {
			questionId: surveys[surveyIndex].questions[questionIndex].id,
			surveyId: surveys[surveyIndex].id,
			notes: notes,
			accessToken
		};
		// only add answer if it's there to avoid setting to 0 on notes update
		if (answer) {
			data.answer = answer;
		} else if (surveys[surveyIndex].questions[questionIndex].answered) {
			data.answer = surveys[surveyIndex].questions[questionIndex].answered;
		}
		// answer with token for id
		api("questionnaire/answer", "post", data, (response) => {
		})
	}

	const back = () => {
		let surveys2 = surveys;
		surveys2.forEach((s, i) => {
			if (s.selected) {
				let lastAnswered = -2;
				s.questions.forEach((q, ii) => {
					if (!q.answered && lastAnswered === -2) {
						lastAnswered = ii - 1;
					}
				})
				if (lastAnswered > -1) {
					delete surveys2[i].questions[lastAnswered].answered;
				}
			}
		})

		let qN = qNum - 1;
		if (qN < 0) { qN = 0; }
		setSurveys(surveys2);
		setLastSelected(0);
		setQNum(qN);
	}

	if (!surveys.length) {
		return (
			<h5 style={{ textAlign: "center", marginTop: 60 }}>Hmmm... it looks like you've already finished this!</h5>
		)
	}

	let next = null;
	let completedCount = 0;
	surveys.forEach((s, i) => {
		if (s.completed) {
			completedCount++;
		}
		if (!s.completed && !next && !s.selected) {
			next = s;
		}
	});

	let questions = [];
	let title;
	let descr;
	surveys.forEach((s, i) => {
		if (s.selected) {
			title = s.title;
			descr = s.description;

			questions = s.questions.map((q, ii) => {
				let scores = [1, 2, 3, 4, 5, 6].map((score, iii) => {
					let className = "score";
					if (lastSelected === i + "-" + ii + "-" + score) {
						className = "score selected";
					}
					return (
						<div className={className} key={iii} onClick={() => {
							answerQuestion(i, ii, score, '')
						}}>{score}</div>
					)
				})
				scores = <div className="scores">{scores}</div>

				let cName = q["answered"] !== undefined ? "shrink" : "";
				return (
					<li key={ii} className={cName}>
						<div style={{ width: "100%" }}>
							<div className="questionText">{ii + 1}. {q.question}</div>
							{scores}
						</div>
					</li>
				)
			})
			let cName = '';
			if (notesDone) {
				cName = 'shrink';
			}
			questions.push(
				<li key="notes" className={cName}>
					<div className='notes'>
						<b>Additional comments</b>
						<textarea
							value={notes}
							onChange={updateNotes}
						/>
						{completedCount === surveys.length - 1 ? (
							<button className="button1" onClick={() => {
								answerQuestion(i, 0, 0, notes);
								setNotesDone(true);
								nextSurvey();
							}}>Submit &amp; Finish</button>

						) : (
							<>
								<button className="button1" onClick={() => {
									answerQuestion(i, 0, 0, notes);
									setNotesDone(true);
									nextSurvey();
								}}>Save &amp; Start Next Section</button>
								<div className="overallProgress">
									<h6>Section {completedCount + 2} of {surveys.length}</h6>
								</div>
							</>
						)}

					</div>
				</li>
			)

			if (next) {
				if (!completedThisSession) {
					questions.push(
						<li key="done">
							<div className='done'>
								<img src={require("../../../imgs/tick_in_yellow_circle.svg")} alt="tick in circle" />
								<div className='doneTitle'>Thanks for your response.</div>
								<div className='doneSubTitle'>On to...</div>
								<button className="button2" onClick={() => {
									nextSurvey();
								}}>
									{next.title}
								</button>
								<div className="overallProgress">
									<h6>
										{completedCount + 2} of {surveys.length}
									</h6>
								</div>
							</div>
						</li>
					)
				} else {
					questions.push(
						<li key="done">
							<div className='done'>
								<img src={require("../../../imgs/tick_in_yellow_circle.svg")} alt="tick in circle" />
								<div className='doneTitle'>Thanks for your response.</div>
								<div className='doneSubTitle'>On to...</div>
								<button className="button2" onClick={() => {
									nextSurvey();
								}}>
									{next.title}
								</button>
								<div className="overallProgress">
									<h6>
										{completedCount + 2} of {surveys.length}
									</h6>
								</div>
							</div>
						</li>
					)
				}
			}
		}
	})

	// some default content for users only authenticated via the token sent in the email
	const courseData = courses.length ? courses : [
		{
			id: 2,
			title: "30 Day Rewiring For Positivity Challenge",
			colour: "6fb5f5"
		},
		{
			id: 3,
			title: "Mental Health Awareness Course",
			colour: "737ca8"
		}
	];

	if (!questions.length) {
		const FoundationTiles = () => (
			<TileGrid>
				{courseData.map(c => (
					<CourseAndFeatureTile key={c.id} title={c.title} url={"course/" + c.id} bgCol={c.colour} />
				))}
			</TileGrid>
		);

		questions = (
			<li key="done">
				<div className='done'>
					<img src={require("../../../imgs/tick_in_yellow_circle.svg")} alt="tick in circle" />
					<div className='doneTitle'>You're All Done!</div>
					<div className='doneSubTitle' style={{ marginBottom: 50 }}>What's Next?</div>
					<FoundationTiles />
				</div>
			</li>
		)
	}

	let surveyTitle = (
		<div className="titleSection">
			<h2>{title}</h2>
		</div>
	)

	let progress = (
		<div className="surveyProgress">
			{qNum}/{qTotal}
			<div className="back" onClick={back}>{"<"}</div>
			<div className="progressBarHolder">
				<div className="progressBar" style={{ width: (qNum / qTotal * 100) + "%" }}></div>
			</div>
		</div>
	)

	if (notesDone || !questions.length) {
		surveyTitle = null;
		progress = null;
	}

	return (
		<div className="page">
			<div className="background">
				<img src={require("../../../imgs/blue_grad_circle.svg")} alt="circle" className="circle1" />
				<img src={require("../../../imgs/blue_grad_circle.svg")} alt="circle" className="circle2" />
				<img src={require("../../../imgs/blue_grad_circle.svg")} alt="circle" className="circle3" />
				<img src={require("../../../imgs/blue_grad_circle.svg")} alt="circle" className="circle4" />
			</div>
			<div className="wrapper">
				{surveyTitle}
				<ul className="questions">
					{questions}
				</ul>
				{progress}
			</div>
		</div>

	);
}

export default memo(Survey);
