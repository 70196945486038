import React, { memo } from 'react';
import noDataSvg from '../../../../imgs/noData.svg';
import { ContentRoot } from './NoData.styled';

const NoData = () => {
  return (
    <ContentRoot>
      <img src={noDataSvg} alt="No data to display" />
      <h4>No Data yet.</h4>
      <p>Complete a reflection to track your mood</p>
    </ContentRoot>
  )
};

export default memo(NoData);
