import React from "react";
import styled from "styled-components";

export const ValLabel = styled.div`
  float: left;
  margin-top: -2px;
  padding: 0 5px;
`;

export const Section = styled.div`
  padding: 5px 0;
`;

export const TooLow = styled.span`
	color: #d90;
`;