import React, { memo } from 'react';
import { Container, Label, Icon, Top, LinkArea, Bottom } from './TickItem.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'


const TickItem = (props) => {
	return (
		<Container completed={props.complete}>
			<Top onClick={props.onClick} style={{paddingBottom: props.linkTo ? 0 : 10}}>
				<Label>{props.label}</Label><Icon>{props.complete ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faCircle} />}</Icon>
			</Top>
			{props.linkTo ? <LinkArea linkTo={props.linkTo} href={props.linkTo.href}>{props.linkTo.text}</LinkArea> : null}
			{props.bottom ? <Bottom onClick={props.onClick}>{props.bottom}</Bottom> : null}
		</Container>
	);
};

export default memo(TickItem);
