import styled from "styled-components";

export const FeedbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Question = styled.div`
  font-size: 20px;
  margin: 20px 0 10px 0;
`;

export const QuestionBinary = styled.div`
  font-size: 22px;
  margin: 20px 0 10px 0;
  text-align: center;
`;

export const Context = styled.div`
  font-size: 24px;
  margin: 0 0 10px 0;
`;
