import React, { memo, useEffect } from "react";
import { post } from "../../../../../actions/api";

const AddToToday = ({ userSubId, showInTodaySet }) => {
	useEffect(() => {
		if (showInTodaySet === null) {
			post("todayfeed", {
				itemType: "course",
				itemId: userSubId,
				show: true,
			});
		}
	}, [userSubId]);
	return null
};

export default memo(AddToToday);
