import React, { memo, useState, useEffect } from "react";
import { api } from "../../actions/api";
import { Course, CourseTitle } from "./UserCourses.styled";

const UserCourses = (props) => {
  const [delId, setDelId] = useState(null);
  const [resetId, setResetId] = useState(null);
  const [resetDone, setResetDone] = useState([]);

  useEffect(() => {
    if (delId !== null) {
      setTimeout(() => {
        setDelId(null);
      }, 3000);
    }
  }, [delId]);

  useEffect(() => {
    if (resetId !== null) {
      setTimeout(() => {
        setResetId(null);
      }, 3000);
    }
  }, [resetId]);

  const user = props.user;
  if (!user) {
    return null;
  }

  const delSub = (id) => {
    if (delId === id) {
    } else {
      setDelId(id);
    }
  };

  const reset = (id) => {
    if (resetId === id) {
      api(
        "course/archiveactivity",
        "post",
        { courseId: id, userId: user.id },
        (resp) => {
          let done = resetDone;
          done.push(id);
          setResetDone(done);
        }
      );
    } else {
      setResetId(id);
    }
  };

  const subs = user.courseUserSubs.map((sub, i) => {
    return (
      <Course key={i}>
        <CourseTitle>{sub.course.title}</CourseTitle>
        <button
          style={{ marginRight: 5 }}
          onClick={(e) => {
            e.preventDefault();
            reset(sub.course.id);
          }}
        >
          {resetDone.includes(sub.course.id) ? (
            <span style={{ color: "green" }}>Restart Complete</span>
          ) : resetId === sub.course.id ? (
            <span style={{ color: "red" }}>Confirm Restart</span>
          ) : (
            <span>Restart Course</span>
          )}
        </button>
        {/* <button
          onClick={(e) => {
            e.preventDefault();
            delSub(sub.course.id);
          }}
        >
          {delId === sub.course.id ? (
            <span style={{ color: "red" }}>Confirm Unsub.</span>
          ) : (
            <span>Unsubscribe from course</span>
          )}
        </button> */}
      </Course>
    );
  });
  return (
    <>
      <h5>User Subscriptions</h5>
      {subs}
    </>
  );
};

export default memo(UserCourses);
