import React, { memo } from "react";
import { ButtonOutline, InfluenceButton } from "../../../components/common";
import EmotionLevelSelector from "../EmotionStep/EmotionLevelSelector/EmotionLevelSelector";
import EmotionSelector from "../EmotionStep/EmotionSelector/EmotionSelector";
import { Heading, Warning } from "../DailyCheckIn.styled";

const SelectedMoodEmotionInf = ({
  emotionGroups,
  emotionGroup,
  emotionGroupSelected,
  selectedEmotion,
  setSelectedEmotion,
  setCurrentStep,
  selectedInfluences,
  checkinMode,
  emotionGroupWarning,
}) => {
  if (!emotionGroup) {
    return null;
  }
  const heading = checkinMode === "brainDump" ? "Record Your Brain Dump" : null;
  return (
    <>
      {/* {heading && <Heading>{heading}</Heading>} */}
      <EmotionLevelSelector
        emotionGroups={emotionGroups}
        emotionGroup={emotionGroup}
        checkinMode={checkinMode}
        emotionGroupSelected={(group) => {
          emotionGroupSelected(group);
          setSelectedEmotion(null);
          setCurrentStep(0);
        }}
      />
      {emotionGroupWarning && (
        <Warning
          emotionIndex={
            emotionGroup
              ? 4 - emotionGroups.findIndex((g) => g.id === emotionGroup.id)
              : 0
          }
					colour={emotionGroup.colour}
        >
          {emotionGroupWarning}
        </Warning>
      )}
      {emotionGroup && selectedEmotion && (
        <EmotionSelector
          options={emotionGroup.terms.filter(
            (t) => t.id === selectedEmotion.id
          )}
          onClick={(e) => {
            setCurrentStep(0);
          }}
          selectedEmotion={selectedEmotion}
        />
      )}
      {checkinMode !== "brainDump" && (
        <>
          <p style={{ marginTop: 20, fontWeight: "bold", fontSize: 20 }}>
            Why do you feel like this?
          </p>
          <div
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              width: "100%",
              gap: 20,
              margin: "20px 0 70px 0",
            }}
          >
            {selectedInfluences && selectedInfluences.length ? (
              selectedInfluences.map((influence) => (
                <InfluenceButton
                  style={{ width: "3.8rem", height: "3.8rem" }}
                  key={influence.id}
                  influence={influence}
                  onClick={() => {
                    setCurrentStep(1);
                  }}
                  isSelected={selectedInfluences.some(
                    (i) => i.id === influence.id
                  )}
                  moodColour={selectedEmotion.colour}
                />
              ))
            ) : (
              <>
                <ButtonOutline
                  onClick={() => {
                    setCurrentStep(1);
                  }}
                >
                  Select
                </ButtonOutline>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default memo(SelectedMoodEmotionInf);
