import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;
  padding: 5px 30px 10px 30px;
  background-color: ${({ theme }) => theme.primary.colours.blueD};
  border-radius: 50px;
`;

export const FaceButtonContainer = styled.a`
  margin: 0 2px;
  padding: 3px;
  display: inline-block;
  width: 38px;
  height: 38px;
  transition: 0.2s;
  &:hover {
    padding: 0;
    img {
      width: 38px;
      height: 38px;
    }
  }
`;

export const FaceIcon = styled.img`
  width: 32px;
  height: 32px;
  transition: 0.2s;
`;

export const ButtonTitle = styled.div`
  color: white;
  margin-bottom: 10px;
`;
