import React, { memo, useState, useEffect } from "react";
import {
  Wrapper,
  Options,
  Option,
  OptionText,
  Labels,
  LabelLeft,
  LabelRight,
} from "./MCQ.styled";

const MCQ = (props) => {
  const [selected, setSelected] = useState(null);

  const elm = props.elm;
  const content = props.elm.content;
  const interactions = props.latestInteractions[elm.id];

  useEffect(() => {
    if (interactions) {
      setSelected(interactions.value);
    }
  }, [interactions]);

  const options = content.options.split(",").map((op, i) => {
    return (
      <Option
        key={i}
        first={!i}
        last={i === content.options.split(",").length - 1}
        selected={selected === op}
        onClick={() => {
          props.recordInteraction(elm.id, op);
          setSelected(op);
          if (elm.linkToScreenId) {
            props.findScreen(elm.linkToScreenId);
          }
        }}
      >
        <OptionText>{op}</OptionText>
      </Option>
    );
  });
  return (
    <Wrapper style={elm.style}>
      <Options>{options}</Options>
      {(content.labelMin || content.labelMax) && (
        <Labels>
          <LabelLeft>{content.labelMin}</LabelLeft>
          <LabelRight>{content.labelMax}</LabelRight>
        </Labels>
      )}
    </Wrapper>
  );
};

export default memo(MCQ);
