import React, { memo, useEffect, useState } from "react";
import { FloatingBox, PageContent } from "./Feedback.styled";
import { PageRoot } from "../../components/common";
import { get } from "../../actions/api";
import FeedbackQuestion from "../../components/FeedbackQuestion/FeedbackQuestion";
import { useHistory, useParams } from "react-router";
import { checkIfDue } from "../../helpers/feedbackHelper";

const Feedback = (props) => {
  const { feedbackId } = useParams();
  const params = new URLSearchParams(window.location.search);
  const [feedbackQ, setFeedbackQ] = useState(null);
  const [ready, setReady] = useState(false);
  const history = useHistory();
  const doCompleted = () => {
    history.push(params.get("next") ?? "/");
  };

  useEffect(() => {
    async function getFeedback() {
      const feedback = await get(`feedback/${feedbackId}`);
      const responses = await get(`feedback/response`);

      if (feedback.feedback) {
        const fb = feedback.feedback;
        const rsp = responses.feedbackResponses;

        setFeedbackQ(fb);
        if (checkIfDue(fb, rsp)) {
          setReady(true);
        } else {
          return doCompleted();
        }
      } else {
        // probably no connection so -
        return doCompleted();
      }
    }
    getFeedback();
  }, []);

  if (ready === false) {
    return <p>Could not find this feedback question...</p>;
  }

  return (
    <PageRoot>
      <PageContent bgColour={feedbackQ.colour}>
        <FloatingBox bgColour={feedbackQ.colour}>
          <FeedbackQuestion feedback={feedbackQ} doCompleted={doCompleted} />
        </FloatingBox>
      </PageContent>
    </PageRoot>
  );
};

export default memo(Feedback);
