import styled from 'styled-components';

export const ContentRoot = styled.div`
  width: 100%;
  height: 6rem;
  background-color: ${({ theme }) => theme.primary.colours.baseColours.checkin};
  grid-template-columns: auto 1fr auto;
  align-items: center;
  
  span {
    font-size: 2.5rem;
    padding: 1rem;
  }
  
  h4 {
    line-height: 1.5rem;
  }
  
  button {
    margin: 1rem;
    padding: 0.8rem 1.2rem;
    background-color: ${({ theme }) => theme.primary.colours.blueMD};
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
  }
  
  &:hover {
    cursor: pointer;
  }
  
  display: none;
  
  @media screen and (max-width: 600px) {
    display: grid;
  }
`;
