import React from "react";
import { api } from "../actions/api";
import bubbles from "../../imgs/bubbles_white_square_2.svg";

class Activate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      token: "",
      password: "",
      password2: "",
      pwError: "",
      pwDupeError: "",
      pwErrorMsg: "",
      pwDupeErrorMsg: "",
      formError: "",
      errMessage: "",
    };
  }

  componentDidMount() {
    let hash = decodeURI(window.location.hash.substr(1));

    hash = hash.split("|");
    this.setState({
      email: hash[0],
      token: hash[1],
    });
  }

  passwordChange = (e) => {
    const password = e.target.value;
    this.setState({ password });
    if (!this.pwStrength(password)) {
      this.setState({
        pwError: "is-danger",
        pwErrorMsg: "Please enter a stronger password (at least 8 characters)",
      });
    } else {
      this.setState({
        pwError: "",
        formError: "",
        pwErrorMsg: "",
      });
    }
  };

  passwordRepeat = (e) => {
    const password2 = e.target.value;
    this.setState({ password2 });
    if (password2 !== this.state.password) {
      this.setState({
        pwDupeError: "is-danger",
        pwDupeErrorMsg: "Passwords don't match",
      });
    } else {
      this.setState({
        pwDupeError: "",
        pwDupeErrorMsg: "",
        formError: "",
      });
    }
  };

  pwStrength = (pw) => {
    if (pw.length < 8) {
      return false;
    }
    return true;
  };

  activate = (e) => {
    e.preventDefault();
    let t = this;

    if (
      this.state.pwError ||
      this.state.pwDupeError ||
      !this.pwStrength(this.state.password) ||
      this.state.password2 !== this.state.password
    ) {
      this.setState({ formError: "is-danger" });
      return false;
    }

    api(
      "user/activate",
      "post",
      {
        email: this.state.email,
        password: this.state.password,
        token: this.state.token,
      },
      (res) => {
        if (res.message === "account activated") {
          t.props.redirectTo("/activated");
        } else {
          t.setState({
            formError: "is-danger",
            errMessage:
              "It looks like you've already activated your account. Login or download our app ",
          });
        }
      }
    );
  };

  render() {
    const styles = {
      img: {
        width: "auto",
        height: 60,
      },
      form: {
        maxWidth: 380,
      },
      account: {
        marginLeft: 5,
        backgroundColor: "#ddd",
        fontSize: 14,
        borderRadius: 5,
        display: "inline-block",
        padding: "5px 10px",
        color: "#136665",
      },
      container: {
        padding: 20,
        width: "100%",
        maxWidth: 380,
        margin: "0 auto",
      },
      wrapper: {
        backgroundColor: "#F1F2F6",
        height: "100%",
        backgroundImage: `url(${bubbles})`,
        backgroundSize: "cover",
      },
    };

    // return (
    // 	<div style={{ padding: 20 }}>
    // 		<h1 className='title'>Thank You For You Interest</h1>
    // 		<p style={{ marginTop: 20, marginBottom: 20, fontSize: 18 }}>Sorry, we're not quite ready for you to sign-up.</p>
    // 	</div>
    // )

    return (
      <div style={styles.wrapper}>
        <div style={styles.container}>
          <h1 className="title" style={{ textAlign: "center" }}>
            Activate your account
          </h1>
          <p>
            <b>Hi</b>
            <span style={styles.account}>{this.state.email}</span>
          </p>

          {this.state.errMessage ? (
            <p style={{ fontSize: 20, marginTop: 20 }}>
              {this.state.errMessage}{" "}
              <a href="/activated" style={{ fontWeight: "bold" }}>
                here
              </a>
            </p>
          ) : (
            <div>
              {/* <p style={{ marginTop: 20, marginBottom: 20 }}>Before you download and get started with the Lume app we need you to set a password</p> */}
              <form style={styles.form} onSubmit={this.activate}>
                <input value={this.state.email} name="email" type="hidden" />
                <input value={this.state.token} name="token" type="hidden" />
                <div className="field">
                  <label className="label" style={{ marginTop: 20 }}>
                    Please choose a password
                    <br />(
                    <a
                      href="https://www.ncsc.gov.uk/cyberaware/home#action-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      how to pick a strong password
                    </a>
                    )
                  </label>
                  <div className="control">
                    <input
                      className={"input " + this.state.pwError}
                      type="password"
                      placeholder="Pick a password"
                      onChange={this.passwordChange}
                    />
                  </div>
                  <p className="help is-danger">{this.state.pwErrorMsg}</p>
                </div>
                <div className="field">
                  <label className="label">Repeat password</label>
                  <div className="control">
                    <input
                      className={"input " + this.state.pwDupeError}
                      type="password"
                      placeholder="Repeat your password"
                      onChange={this.passwordRepeat}
                    />
                  </div>
                  <p className="help is-danger">{this.state.pwDupeErrorMsg}</p>
                </div>
                <div
                  className="control"
                  style={{ textAlign: "center", marginTop: 30 }}
                >
                  <button
                    className={"button is-primary " + this.state.formError}
                    style={{ width: 260, backgroundColor: "#1d7f7a" }}
                  >
                    Activate account
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Activate;
