import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Title, Text } from '../Day.styled';

const TextSection = ({ title, text }) => {
  return (
    <div>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </div>
  )
};

TextSection.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default memo(TextSection);
