import PropTypes from 'prop-types';
import checkInItem from './checkInItem';

export default PropTypes.shape({
  colour: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  order: PropTypes.number.isRequired,
  shrinkByDefault: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  terms: PropTypes.arrayOf(checkInItem).isRequired,
});
