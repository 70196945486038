import React, { memo } from 'react';
import { Tile } from '../ReflectionAnalytics.styled';
import InfluenceBody from '../InfluenceBody/InfluenceBody';

const InfluenceCount = ({ influences, filterSelected }) => {
  const getBadge = influence => influence.total;

  return (
    <Tile
      title="Influence Count"
      body={<InfluenceBody influences={influences} getBadge={getBadge} filterSelected={filterSelected} />}
    />
  );
};

export default memo(InfluenceCount);
