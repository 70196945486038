import styled from "styled-components";

export const Wrapper = styled.div`
  flex-direction: column;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Option = styled.button`
  flex: 1;
  border: ${({ theme }) => theme.primary.colours.greyMD} 1px solid;
  border-left-width: ${(props) => (props.first ? 1 : 0)}px;
  border-top-left-radius: ${(props) => (props.first ? 5 : 0)}px;
  border-bottom-left-radius: ${(props) => (props.first ? 5 : 0)}px;
  border-top-right-radius: ${(props) => (props.last ? 5 : 0)}px;
  border-bottom-right-radius: ${(props) => (props.last ? 5 : 0)}px;
  padding: 10px 0;
  flex-grow: 1;
  color: ${({ theme }) => {
    return (props) => (props.selected ? "#fff" : theme.primary.colours.greyMD);
  }};
  background-color: ${({ theme }) => {
    return (props) => (props.selected ? theme.primary.colours.greenM : "#fff");
  }};
  border-color: ${({ theme }) => {
    return (props) =>
      props.selected
        ? theme.primary.colours.greenM
        : theme.primary.colours.greyMD;
  }};
  &:hover {
    background-color: ${({ theme }) => theme.primary.colours.greenM};
    border-color: ${({ theme }) => theme.primary.colours.greenM};
    color: #fff;
  }
`;

export const OptionText = styled.span`
  text-align: center;
  font-size: 19px;
  color: inherit;
  font-weight: bold;
`;

export const Labels = styled.div`
  flex-direction: row;
  display: flex;
  font-size: 12px;
  margin-top: 3px;
`;
export const LabelLeft = styled.div`
  text-align: left;
  flex: 1;
`;
export const LabelRight = styled.div`
  text-align: right;
  flex: 1;
`;
