export function transformTermFromGroup(term, group) {
  return {
    id: term.id,
    colour: group.colour,
    termType: group.termType,
    term: term.term,
    title: group.title,
    termGroupId: term.termGroupId,
  };
}

function transformGroup(itemGroup) {
  const values = itemGroup.map(group => ({
    ...group,
    terms: group.terms.map(t => transformTermFromGroup(t, group)),
    shrinkByDefault: group.shrinkByDefault === true,
  }));
  return [...values].sort((a, b) => a.order > b.order);
}

export function transformResponse({ termGroups }) {
  const { state, influence } = termGroups;

  const emotions = transformGroup(state);
  const influences = transformGroup(influence);
  return [emotions, influences];
}
