import styled from 'styled-components';
export const SubCat = styled.div`
	padding: 20px;
	color: ${({ theme }) => theme.primary.colours.greenM};
	border: ${({ theme }) => theme.primary.colours.greenM} 2px solid;
	border-radius: 5px;
	font-weight: bold;
	font-size: 1.2rem;
	cursor: pointer;
	display: flex;
	align-items: center;
  justify-content: center;
	text-align: center;
	height: 170px;
`;

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 10px;
	width: 100%;
	margin-top: 50px;
`;