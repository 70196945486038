import styled, { css } from 'styled-components';
import LeftArrowIcon from '@ant-design/icons/lib/icons/ArrowLeftOutlined';
import RightArrowIcon from '@ant-design/icons/lib/icons/ArrowRightOutlined';

export const ContentRoot = styled.section`
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem;
  margin: 0 1rem;
  justify-content: center;
`;

const iconStyle = css`
  font-size: 2rem;
  margin: 12rem 2rem;
  color: ${({ theme }) => theme.primary.calendar.arrowColour};
  &:hover {
    cursor: pointer;
  }
  opacity: ${props => props.disabled ? '0.2' : '1'};
`;

export const LeftArrow = styled(LeftArrowIcon)`
  ${iconStyle}
`;

export const RightArrow = styled(RightArrowIcon)`
  ${iconStyle}
`;
