import React, { memo } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { LeftArrow } from './RelatesToHeader.styled';
import { Title, ContentRoot, HeaderRow, FilterRow, MonthFilter } from './RelatesToHeader.styled';

const RelatesToHeader = ({ onBackClick, filters, month, removeFilter }) => {
  const formattedMonth = month.format('MMM');
  const ordered = [...filters].sort((f1, f2) => f1.index - f2.index);
  const withChevrons = ordered.reduce(
    (acc, current) => [...acc, { value: '>' }, current],
    [],
  );

  return (
    <ContentRoot>
      <HeaderRow>
        <LeftArrow onClick={onBackClick} />
        <Title>Relates To</Title>
        <div />
      </HeaderRow>
      <FilterRow>
        <MonthFilter onClick={onBackClick}>{formattedMonth}</MonthFilter>
        {withChevrons.map((f, idx) => f.type
          ? <div key={idx} onClick={() => removeFilter(f)}>{f.value}</div>
          : <span key={idx}>{f.value}</span>
        )}
      </FilterRow>
    </ContentRoot>
  );
};

RelatesToHeader.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  month: momentPropTypes.momentObj.isRequired,
  removeFilter: PropTypes.func.isRequired,
};

export default memo(RelatesToHeader);
