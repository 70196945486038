import React, { memo } from 'react';
import { Tile } from '../ReflectionAnalytics.styled';
import EmotionBody from '../EmotionBody/EmotionBody';

const EmotionCount = ({ emotions, filterSelected }) => {
  const hasTerm = emotions.filter(e => e.term !== '');
  return (
    <Tile
      title="Emotion Count"
      body={<EmotionBody emotions={hasTerm} filterSelected={filterSelected} />}
    />
  );
};

export default memo(EmotionCount);
