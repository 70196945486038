import styled from "styled-components";
import { TopNav } from "../../../components/common";

export const NavRoot = styled(TopNav)`
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 1rem;
  align-items: center;
`;

const getColour = ({ theme, isPrimaryAction }) =>
  isPrimaryAction
    ? theme.primary.colours.primaryAction
    : theme.primary.colours.heading;

export const ActionButton = styled.button`
  background-color: ${getColour};
  color: white;
  width: 12rem;
  padding: 1rem;
  border-radius: 3rem;
  font-size: 18px;
  font-weight: bold;
  justify-self: flex-end;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

export const DatePickerContainer = styled.div`
  width: 12rem;
`;

export const SlideContainer = styled.div`
	display: flex;
	flex:1;
	width:100%;
  position: relative;
`;
