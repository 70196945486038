import { useEffect, useState } from 'react';
import { get } from "../actions/api";
import { transformResponse } from "../helpers/surveyHelper";

const defaultSurveyData = {
  complete: [],
  incomplete: [],
  due: [],
  incompletePerGroup: [],
	incompleteDuePerGroup: [],
}

export default function useSurveyData() {
  const [surveyData, setSurveyData] = useState(defaultSurveyData);

  useEffect(() => {
    async function getSurveyData() {
      const data = await get('questionnaire/mysurveys');
      const [complete, incomplete, incompletePerGroup, incompleteDuePerGroup] = transformResponse(data);
      const due = incomplete.filter(s => s.complete === false && s.due === true);

      setSurveyData({
        complete,
        incomplete,
        due,
        incompletePerGroup,
				incompleteDuePerGroup,
      });
    }
    getSurveyData();
  }, []);

  return surveyData;
}
