import React, { memo, useState, useEffect } from 'react';
import { PageContent, NavRoot, Heading, Description, BackButton } from './Signposting.styled';
import { NavTitle, PageRoot } from '../../components/common';
import { getGeneralSignposting } from './helpers/helpers';
import SignpostContacts from './SignpostContacts';
import SignpostSubGroups from './SignpostSubGroups';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Markdown from 'markdown-to-jsx';
import { useHistory } from 'react-router-dom';

const Signposting = () => {
	const history = useHistory();
	const [signposts, setSignposts] = useState([]);
	const [lookup, setLookup] = useState({});
	const [backTo, setBackTo] = useState(null);
	const [backFrom, setBackFrom] = useState(null);
	const [signpostPage, setPage] = useState({});
	const params = new URLSearchParams(window.location.search);

	useEffect(() => {
		async function getSignposting() {
			getGeneralSignposting(signposting => {
				setSignposts(signposting.signpostGroups);
				setLookup(signposting.lookup);
				setPage(signposting.signpostGroups[0]);

				if (params.get('page')) {
					changeGroup(params.get('page'), signposting.lookup, signposting.signpostGroups);
				}
				if (params.get('back')) {
					setBackFrom(params.get('back').split("|")[0]);
					setBackTo(params.get('back').split("|")[1]);
				}
			})
		}
		getSignposting();

	}, []);

	if (!signposts.length || !signpostPage) {
		return null
	}

	function changeGroup(nextGroupId, useLookup, useSPGs) {
		if (nextGroupId !== signpostPage) {
			let thisLookup = useLookup ? useLookup : lookup;
			let thisSPGs = useSPGs ? useSPGs : signposts;

			const ind = thisLookup[nextGroupId];
			let signpostPageNow = thisSPGs[0];
			if(ind){
				for (let i = 1; i < ind.length; i++) {
					signpostPageNow = signpostPageNow.children[ind[i]];
				}
			}

			history.push(`signposting?page=${signpostPageNow.id}`)
			setPage(signpostPageNow);
		}
	}

	const back = () => {
		if (backFrom) {
			if (Number(signpostPage.id) === Number(backFrom)) {
				return history.push(backTo);
			}
		}

		changeGroup(signpostPage.parentId);
	}

	return (
		<PageRoot>
			<NavRoot>
				<NavTitle>Signposting {backTo}</NavTitle>
			</NavRoot>
			<PageContent>
				{signpostPage.parentId ? <BackButton onClick={back}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faChevronLeft} /> Back</BackButton> : null}
				<Heading>{signpostPage.title}</Heading>
				{signpostPage.body ? <Description className="content"><Markdown>{signpostPage.body}</Markdown></Description> : null}
				<SignpostSubGroups children={signpostPage.children} changeGroup={changeGroup} />
				<SignpostContacts contacts={signpostPage.signposts} />
			</PageContent>
		</PageRoot>
	);
};

export default memo(Signposting);