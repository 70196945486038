import moment from "moment";

export const checkIfDue = (fb, rsp) => {
  /* Skip if:
							- Visibility is set to 0 (default)
							&&
							- They've done this same feedback question within 1x month
							- or they've done 1x feedback questions already today.
							OR
							- Visiblity is 2 (show once only) && they've already done it
				*/

  if (
    fb.visibility === 2 &&
    fb.feedbackResponses.length &&
    fb.feedbackResponses[0].completed
  ) {
    return false;
  } else if (fb.visibility === 0) {
    if (
      (fb.feedbackResponses.length &&
        fb.feedbackResponses[0].completed &&
        moment(fb.feedbackResponses[0].updatedAt).isAfter(
          moment().subtract(1, "week")
        )) ||
      rsp.filter(
        (r) =>
          moment(r.updatedAt).isAfter(moment().subtract(1, "day")) &&
          r.completed
      ).length
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};
