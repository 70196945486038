import React from "react";
import moment from "moment";

const HabitDay = ({ day, setDayIndex, index, setShowIndex }) => {
  return (
    <div
      style={styles.wrapper}
      onClick={() => {
        setDayIndex(index);
        setShowIndex(false);
      }}
      disabled={moment(day.dueOn).isAfter(moment(), "day")}
    >
      <div
        style={{
          ...styles.inner,
          opacity: moment(day.dueOn).isAfter(moment(), "day") ? 0.2 : 1,
        }}
      >
        <div style={styles.label}>{moment(day.dueOn).format("ddd, MMM D")}</div>
        {day.activities.some((a) => !a.completedOn) ? (
          day.activities.some((a) => a.completedOn) ? (
            // <AntDesign name="checkcircle" size={32} color={"#666"} />
            <span>O</span>
          ) : (
            <div style={styles.incomplete} />
          )
        ) : (
          // <AntDesign name="checkcircle" size={32} color={"#136665"} />
          <span>X</span>
        )}
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    height: 68,
    borderRadius: 5,
    backgroundColor: "white",
    paddingHorizontal: 16,
    width: "100%",
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },
  inner: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  label: {
    fontSize: 16,
  },
  incomplete: {
    borderColor: "#666",
    borderWidth: 2,
    width: 32,
    height: 32,
    borderRadius: 16,
  },
};

export default HabitDay;
