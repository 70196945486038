import React, { useContext, useEffect, useReducer, useState } from "react";
import { ReflectionTile, SurveyTile, CourseAndFeatureTile } from "./Tiles";
import { NavTitle, PageRoot, TopNavContainer } from "../../components/common";
import { DoneToday, DoneTodayText, PageContent } from "./Home.styled";
import HomeSection from "./HomeSection/HomeSection";
import { TileGrid } from "./Home.styled";
import { useSurveyData, useCourseData, useHomeData } from "../../hooks";
import { get, post } from "../../actions/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { MainContext } from "../../context/MainContext";
import { Redirect } from "react-router";
import theme from "../../theme";
const featureTileMeta = {
  gainsAndDrains: {
    title: "Gains & Drains",
    colour: "407cb4",
    url: "notes/gainsAndDrains",
  },
  me: {
    title: "Me",
    colour: "407cb4",
    url: "notes/me",
  },
  gratitude: {
    colour: theme.primary.colours.baseColours.gratitude,
    url: "/gratitude",
    title: "What are you Grateful for?",
  },
  rak: {
    colour: theme.primary.colours.baseColours.rak,
    url: "/random-act-of-kindness",
    title: "Random Act of Kindness",
  },
  breathe: {
    colour: theme.primary.colours.baseColours.breathe,
    url: "/breathe",
    title: "De-Stress Deep Breathing",
  },
};

const TodayTiles = ({
  surveys,
  coursesAndFeatures,
  dailyCompletion,
  showInToday,
  toggleRepeat,
}) => {
  // check to see if it's empty
  const tiles = [];
  if (dailyCompletion.checkin === false) {
    tiles.push(<ReflectionTile key="checkin" />);
  }
  coursesAndFeatures.forEach((c) => {
    if (
      (c.showInTodaySet && !c.doneForToday) ||
      (c.type === "feature" && showInToday[c.feature])
    ) {
      const lookup =
        c.type !== "feature" &&
        c.lookup[c.currentScreenId ? c.currentScreenId : c.firstScreenId];
      if (lookup || c.type === "feature") {
        const section =
          c.type !== "feature" &&
          c.courseScreens[lookup[0]] &&
          c.courseScreens[lookup[0]].children[lookup[1]] &&
          c.courseScreens[lookup[0]].children[lookup[1]].children[lookup[2]]
            ? c.courseScreens[lookup[0]].children[lookup[1]].children[lookup[2]]
            : null;
        const sectionTitle =
          c.type === "feature" ? c.title : section ? section.title : c.title;
        
        tiles.push(
          <CourseAndFeatureTile
            key={c.type === "feature" ? c.feature : c.id}
            subId={c.type === "feature" ? c.feature : c.courseUserSubs[0].id}
            id={c.id}
            title={sectionTitle ? sectionTitle : c.title}
            url={"course/" + c.id}
            bgCol={c.colour}
            superTitle={c.progressType === "anyActivityDaily" ? c.title : null}
            repeatIconOn={true}
            toggleRepeat={toggleRepeat}
            type={c.type}
          />
        );
      }
    }
  });
  if (surveys.length > 0) {
    tiles.push(
      <SurveyTile
        title={surveys[0].sectionTitle}
        superTitle={surveys[0].questionnaire.title}
        url={"/questions/" + surveys[0].surveyGroupId}
      />
    );
  }

  return tiles.length ? (
    <TileGrid>{tiles}</TileGrid>
  ) : (
    <DoneToday>
      <FontAwesomeIcon
        icon={faCheckCircle}
        style={{ color: "#FFCB68", width: 60, height: 60 }}
      />
      <DoneTodayText>Looks like you're all caught up for today!</DoneTodayText>
    </DoneToday>
  );
};

const InsightTiles = ({ surveys }) => (
  <TileGrid>
    {surveys.map((s) => (
      <SurveyTile
        key={s.id}
        title={s.questionnaire.title}
        url={"/questions/" + surveys[0].surveyGroupId}
      />
    ))}
  </TileGrid>
);

const CourseFeeds = ({
  courses,
  dailyCompletion,
  showInToday,
  toggleRepeat,
}) => {
  let feeds = {};
  courses.forEach((course, i) => {
    course.feeds
      .filter((f) => !["foundations", "tools"].includes(f))
      .forEach((f, ii) => {
        const [title, order, feedOrder] = f;
        if (!feeds[title]) {
          feeds[title] = {
            title: title,
            tiles: [],
            order: feedOrder,
          };
        } else if (feeds[title].order !== feedOrder) {
          feeds[title].order = feedOrder;
        }

        const lookup =
          course.type !== "feature" && course.lookup[course.currentScreenId];
        const sectionTitle =
          course.type === "feature"
            ? "feature title"
            : lookup
            ? lookup[2]
              ? course.courseScreens[lookup[0]].children[lookup[1]].children[
                  lookup[2]
                ].title
              : lookup[1]
              ? course.courseScreens[lookup[0]].children[lookup[1]].title
              : lookup[0]
              ? course.courseScreens[lookup[0]].title
              : course.title
            : null;

        feeds[title].tiles.push({
          id: course.id,
          lookup: course.lookup,
          firstScreenId: course.firstScreenId,
          currentScreenId: course.currentScreenId,
          progressType: course.progressType,
          order,
          title:
            course.progressType === "anyActivityDaily"
              ? sectionTitle
              : course.title,
          superTitle:
            course.progressType === "anyActivityDaily" ? course.title : null,
          subId:
            course.type === "feature"
              ? course.feature
              : course.courseUserSubs[0].id,
          colour: course.colour ? course.colour : "666",
          showInTodaySet: course.showInTodaySet,
          doneForToday: course.doneForToday,
          type: course.type ?? "course",
          url: course.url ?? "",
        });
      });
  });

  // add completed RAK, Checkin or Gratitude tiles to "Tools & Techniques"
  if (!feeds["Awareness"] && dailyCompletion.checkin) {
    feeds["Awareness"] = {
      title: "Awareness",
      tiles: [<ReflectionTile />].filter(Boolean),
      order: 10,
    };
  }
  const finalFeeds = Object.values(feeds)
    .sort((a, b) => a.order - b.order)
    .map((feed) => {
      feed.tiles.sort((a, b) => a.order - b.order);
      return feed;
    });

  return finalFeeds.map((feed, i) => {
    const tiles = (
      <TileGrid>
        {feed.tiles.map((c, ii) => {
          if (React.isValidElement(c)) {
            return <div key={ii}>{c}</div>;
          }

          // get url from lookup
          const lookup =
            c.type === "feature"
              ? null
              : c.currentScreenId
              ? c.lookup[c.currentScreenId]
              : c.lookup[c.firstScreenId];
          const url =
            c.type === "feature"
              ? c.url
              : lookup
              ? "/course/" + c.id + "/" + lookup.join("/")
              : "/course/" + c.id;

          return (
            <CourseAndFeatureTile
              key={ii}
              subId={c.subId ? c.subId : null}
              id={c.id}
              title={c.title}
              superTitle={c.superTitle}
              progressType={c.progressType}
              doneForToday={c.doneForToday}
              url={url}
              bgCol={c.colour}
              repeatIconOn={
                c.type === "feature" ? showInToday[c.subId] : c.showInTodaySet
              }
              toggleRepeat={toggleRepeat}
              type={c.type}
            />
          );
        })}
      </TileGrid>
    );
    return <HomeSection key={i} title={feed.title} body={tiles} />;
  });
};

const Home = () => {
  const { dailyCompletion, loadingComplete, showInToday } = useHomeData();
  const { incompletePerGroup, incompleteDuePerGroup } = useSurveyData();
  const { courses } = useCourseData();
  // const coursesToday = coursesOngoing(courses);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const ctx = useContext(MainContext);
  const [featureSubs, setFeatureSubs] = useState([]);

  useEffect(() => {
    async function getFeatures() {
      const res = await get("features", {});
      if (res && res.length) {
        setFeatureSubs(
          res.map((f) => ({
            ...f,
            ...(featureTileMeta[f.feature] ?? {}),
            type: "feature",
          }))
        );
      }
    }
    getFeatures();
  }, []);

  if (
    ctx &&
    ctx.user &&
    ctx.user.user_type === 1 &&
    ctx.user.permissions &&
    ctx.user.permissions.ENG
  ) {
    return <Redirect to={{ pathname: "/reports/overview" }} />;
  }

  const toggleRepeat = (itemType, itemId, show) => {
    if (itemType === "course") {
      if (!itemId) {
        return false;
      }
      courses.forEach((c, i) => {
        if (c.courseUserSubs[0].id === itemId) {
          courses[i].showInTodaySet = show;
        }
      });
    } else {
      showInToday[itemType] = show;
    }

    post("todayfeed", { itemType, itemId, show });
    forceUpdate();
  };

  const coursesAndFeatures = [...courses, ...featureSubs];

  if (loadingComplete === false) {
    return <div>Loading...</div>;
  }

  return (
    <PageRoot>
      <TopNavContainer>
        <NavTitle>Home</NavTitle>
      </TopNavContainer>
      <PageContent>
        <HomeSection
          title="Today"
          body={
            <TodayTiles
              dailyCompletion={dailyCompletion}
              surveys={incompleteDuePerGroup}
              coursesAndFeatures={coursesAndFeatures}
              showInToday={showInToday}
              toggleRepeat={toggleRepeat}
            />
          }
        />
        {incompletePerGroup.length > 0 && (
          <HomeSection
            title="Insight & Feedback"
            body={<InsightTiles surveys={incompletePerGroup} />}
          />
        )}
        <CourseFeeds
          courses={coursesAndFeatures}
          dailyCompletion={dailyCompletion}
          showInToday={showInToday}
          toggleRepeat={toggleRepeat}
        />
      </PageContent>
    </PageRoot>
  );
};

export default Home;
