import React from 'react';
import { ThemeProvider } from 'styled-components';
import MainNav from './components/MainNav';
import theme from './theme';
import '../css/App.css';
import { MainProvider, MainContext } from './context/MainContext';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

class App extends React.Component {
	static contextType = MainContext;
	constructor(props) {
		super(props);
		this.state = {}
	}

	render() {
		return (
			<ThemeProvider theme={theme}>
				<MainProvider>
					<MainNav />
				</MainProvider>
			</ThemeProvider>
		);
	}
}
export default App;
