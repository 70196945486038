import React, { memo, useState, useEffect } from "react";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import CheckInNav from "./CheckInNav/CheckInNav";
import { PageContent, PageRoot } from "../../components/common/common.styled";
import { api, del, get, post, put } from "../../actions/api";
import { transformResponse } from "../../helpers/checkInHelper";
import EmotionStep from "./EmotionStep/EmotionStep";
import InfluenceStep from "./InfluenceStep/InfluenceStep";
import Cookies from "universal-cookie";
import Onboarding from "./Onboarding/Onboarding";
import { OnboardingButton } from "./DailyCheckIn.styled";
import { taskCompletedToday } from "../../helpers/dailyCompletionStorageHelper";
import Journal from "./Journal/Journal";
import SelectedMoodEmotionInf from "./SelectedMoodEmotionInf/SelectedMoodEmotionInf";
import { ActionButton, ActionButtonContainer } from "../../components/common";
import CheckinImages from "../../components/common/CheckinImages/CheckinImages";
import Resizer from "react-image-file-resizer";
import FaceButton from "./FaceButton/FaceButton";
import theme from "../../theme";

const steps = {
  emotions: 0,
  influences: 1,
  journal: 2,
};

function useInitialState() {
  const { id, emotion } = useParams();
  const today = moment();
  const params = new URLSearchParams(window.location.search);

  const dateParam = params.get("date");
  const candidateDate = dateParam
    ? moment(dateParam, "DDMMYY").set("hour", 12)
    : today;
  const selectedDate = candidateDate.isAfter(today) ? today : candidateDate;

  const preselectEmotion = Number(emotion);
  const stateGroupsParam = params.get("stateGroups");
  const preselectStateGroup = params.get("mood");
  const stateGroups = stateGroupsParam?.split(",");
  const preselectCheckinMode = params.get("checkinMode") ?? "default";
  const nextPage = params.get("next");

  return {
    selectedDate,
    stateGroups,
    id,
    preselectStateGroup,
    preselectEmotion,
    preselectCheckinMode,
    nextPage,
  };
}

const DailyCheckIn = () => {
  const history = useHistory();

  const {
    selectedDate: initialDate,
    stateGroups: stateGroupFilter,
    id: initialId,
    preselectStateGroup,
    preselectEmotion,
    preselectCheckinMode,
    nextPage,
  } = useInitialState();
  const [id, setId] = useState(initialId);
  const [currentStep, setCurrentStep] = useState(0);
  const [emotionGroups, setEmotionGroups] = useState([]);
  const [influenceGroups, setInfluenceGroups] = useState([]);
  const [selectedEmotion, setSelectedEmotion] = useState(null);
  const [selectedInfluences, setSelectedInfluences] = useState([]);
  const [checkInDate, setCheckInDate] = useState(initialDate);
  const [notes, setNotes] = useState([{ prompt: "set", entry: "" }]);
  const [onboarding, setOnboarding] = useState(false);
  const [emotionGroup, setEmotionGroup] = useState(null);
  const [emotionGroupWarning, setEmotionGroupWarning] = useState(null);
  const [images, setImages] = useState([]);
  const [checkinMode, setCheckinMode] = useState(preselectCheckinMode);

  const showAction = emotionGroup != null;
  const cookies = new Cookies();

  useEffect(() => {
    async function buildData() {
      const data = await get("termgroup");
      const [emotionGroupsLocal, influenceGroupsLocal] =
        transformResponse(data);
      const emotionGroupsDisabled = emotionGroupsLocal.map((e) => ({
        ...e,
        disabled: stateGroupFilter
          ? stateGroupFilter.every(
              (filter) => filter.toLowerCase() !== e.title.toLowerCase()
            )
          : false,
      }));
      setEmotionGroups(emotionGroupsDisabled);
      setInfluenceGroups(influenceGroupsLocal);
      if (preselectStateGroup) {
        setEmotionGroupFromTerm(preselectStateGroup, emotionGroupsDisabled);
      }

      await checkOnboarding();
    }
    buildData();
  }, []);

  useEffect(() => {
    async function getCheckin() {
      const { checkin } = await get(`checkin/${id}`);
      if (!checkin) {
        setId(null);
        return;
      }

      const checkinDateLocal = moment(checkin.checkinTime);

      // need id not term id to match object shape on create
      const transformedInfluences = checkin.influences.map((i) => ({
        ...i,
        id: i.termId,
      }));

      if (typeof checkin.notes === "string") {
        try {
          checkin.notes = JSON.parse(checkin.notes);
        } catch (error) {
          checkin.notes = [];
          console.log(error, checkin.notes);
        }
      }

      setSelectedInfluences(transformedInfluences);
      setSelectedEmotion({
        ...checkin.state,
        title: checkin.state.term,
        termType: "s",
        id: checkin.state.termId,
      });
      setCheckInDate(checkinDateLocal);
      setNotes(
        checkin.notes && checkin.notes.length > 0
          ? checkin.notes
          : [{ prompt: "set", entry: "" }]
      );
      const emotionGroup = emotionGroups.find((e) =>
        e.terms.some((e) => e.term === checkin.state.term)
      );
      setImages(checkin.checkinImages.map((id) => ({ id, base64: null })));
      setEmotionGroup(emotionGroup);

      setCheckinMode(checkin.checkinMode);
      setCurrentStep(2);
      // if there's a journal entry then go to that step
      // if (
      // 	(checkin.notes && checkin.notes.length && checkin.notes[0].entry) ||
      // 	checkin.checkinImages.length
      // ) {
      // 	setCurrentStep(2);
      // }
    }
    if (Number(id)) {
      getCheckin();
    }

    if (emotionGroups.length && preselectEmotion) {
      const grp = emotionGroups.find((g) =>
        g.terms.find((t) => t.id === preselectEmotion)
      );
      const tm = grp.terms.find((t) => t.id === preselectEmotion);

      setEmotionGroup(grp);

      if (tm.term) {
        setSelectedEmotion(tm);
        setCurrentStep(1);
      }

      // save and retrieve id
      api(
        "checkin",
        "post",
        {
          checkinTime: checkInDate.unix() * 1000,
          terms: [preselectEmotion],
          images: images.filter((img) => !img.id).map((img) => img.base64),
        },
        (savedCheckin) => {
          if (savedCheckin.checkin && savedCheckin.checkin.id) {
            setId(savedCheckin.checkin.id);
          }
        }
      );
    }
  }, [emotionGroups]);

  const resetEmotion = () => {
    setCurrentStep(steps.emotions);
    setSelectedEmotion(null);
    setSelectedInfluences([]);
  };

  const saveClick = async (e, emotion) => {
    const emot = emotion ?? selectedEmotion;

    const payload = {
      id,
      checkinTime: checkInDate.unix() * 1000, // api is milli-seconds
      checkinMode,
      terms: JSON.stringify([emot.id, ...selectedInfluences.map((i) => i.id)]),
      ...(notes.length > 0 ? { notes } : {}),
      images: images.filter((img) => !img.id).map((img) => img.base64),
    };

    const newCheckin = Number(id)
      ? await put(`checkin/${payload.id}`, payload)
      : await post("checkin", payload);

    taskCompletedToday("checkin");

    const url =
      nextPage ??
      `/suggestions?bgCol=FFCB68%26title=Reflection%26eventType=checkin%26eventId=${newCheckin.checkin.id}`;
    const feedbackURL =
      checkinMode && checkinMode !== "default"
        ? `/feedback/checkin-${checkinMode}?next=${url}`
        : `/feedback/checkin?next=${url}`;
    return history.push(feedbackURL);
  };

  const checkOnboarding = async () => {
    if (!cookies.get("hasDoneCheckin")) {
      const useageData = await get("reports/streaks");
      if (!Number(useageData.streaks.checkinsTotal)) {
        setOnboarding(true);
      }
    }
  };

  const nextClick = () => {
    if (currentStep === steps.emotions && !selectedEmotion?.title) {
      const defaultTerm = emotionGroup?.terms.find((t) => t.term === "");
      const defaultEmotion = { ...defaultTerm, term: emotionGroup?.title };
      setSelectedEmotion(defaultEmotion);
      setCurrentStep(steps.influences);
    } else {
      setCurrentStep(steps.journal);
    }
  };

  const onEmotionSelected = (newEmotion) => {
    setSelectedEmotion(newEmotion);
    setCurrentStep(1);
  };

  const pickEmotionGroup = (emotionGroup) => {
    if (checkinMode === "positive" && emotionGroup.disabled === true) {
      setEmotionGroupWarning(
        `This won't count as today's "Positive Reflection"`
      );
    } else {
      setEmotionGroupWarning(null);
    }
    setEmotionGroup(emotionGroup);
  };

  const setEmotionGroupFromTerm = (term, groups) => {
    const group = groups.find(
      (g) => g.title.toLowerCase() === term.toLowerCase()
    );
    setEmotionGroup(group);
  };

  const onboardingFinished = () => {
    setOnboarding(false);
    cookies.set("hasDoneCheckin", true, { path: "/" });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const addImage = async (event) => {
    try {
      const file = event.target.files[0];
      const base64 = await resizeFile(file);
      const newImg = {
        id: null,
        base64: base64.replace("data:image/jpeg;base64,", ""),
      };
      setImages([...images, newImg]);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteImage = (i, e) => {
    e.stopPropagation();
    if (images[i].id) {
      del(`checkin/${id}/${images[i].id}`);
    }

    const newImages = [...images];
    newImages.splice(i, 1);
    setImages(newImages);
  };

  const clickAction = currentStep === steps.journal ? saveClick : nextClick;
  const actionText = currentStep === steps.journal ? "Save" : "Next";
  if (onboarding) {
    return <Onboarding onboardingFinished={onboardingFinished} />;
  }

  return (
    <PageRoot>
      <CheckInNav
        checkInDate={checkInDate}
        checkInDateChanged={setCheckInDate}
        checkinMode={checkinMode}
      />
      <OnboardingButton onClick={() => setOnboarding(true)} />
      <PageContent style={{ marginBottom: 130, marginTop: 0 }}>
        {/* <StepBar step={currentStep} /> */}
        {currentStep === steps.emotions && (
          <EmotionStep
            emotionGroups={emotionGroups}
            emotionSelected={onEmotionSelected}
            emotionGroup={emotionGroup}
            emotionGroupSelected={pickEmotionGroup}
            selectedEmotion={selectedEmotion}
            checkinMode={checkinMode}
            emotionGroupWarning={emotionGroupWarning}
          />
        )}
        {currentStep === steps.influences && (
          <InfluenceStep
            selectedEmotion={selectedEmotion}
            selectedInfluences={selectedInfluences}
            influenceGroups={influenceGroups}
            onInfluencesSelected={setSelectedInfluences}
            resetEmotion={resetEmotion}
            emotionGroup={emotionGroup}
          />
        )}
        {(!emotionGroup || currentStep === steps.journal) && (
          <>
            <SelectedMoodEmotionInf
              emotionGroups={emotionGroups}
              emotionSelected={onEmotionSelected}
              emotionGroup={emotionGroup}
              emotionGroupSelected={setEmotionGroup}
              selectedEmotion={selectedEmotion}
              setCurrentStep={setCurrentStep}
              setSelectedEmotion={setSelectedEmotion}
              selectedInfluences={selectedInfluences}
              checkinMode={checkinMode}
              emotionGroupWarning={emotionGroupWarning}
            />
            {checkinMode !== "brainDump" && (
              <h4
                style={{
                  marginBottom: 20,
                  marginTop: 40,
                  color: theme.primary.colours.blueM,
                }}
              >
                Add A Journal Entry
              </h4>
            )}
            <CheckinImages
              checkinImages={images}
              checkinId={id}
              addImage={addImage}
              deleteImage={deleteImage}
            />
            <Journal
              notes={notes}
              setNotes={setNotes}
              checkinMode={checkinMode}
            />
          </>
        )}
      </PageContent>
      <ActionButtonContainer>
        {!showAction && (notes[0].entry || images.length) ? (
          <FaceButton saveClick={saveClick} emotionGroups={emotionGroups} />
        ) : (
          <ActionButton
            visible={showAction}
            onClick={clickAction}
            isPrimaryAction={false}
          >
            {actionText}
          </ActionButton>
        )}
      </ActionButtonContainer>
    </PageRoot>
  );
};

export default memo(DailyCheckIn);
