import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { checkInItem as influencePropType } from '../../../../../propTypes';
import InfluenceButton from '../../../../../components/common/InfluenceButton/InfluenceButton';
import { ContentRoot } from './InfluenceBody.styled';

const InfluenceSelectorBody = ({
  influences,
  influenceSelected,
  influenceDeselected,
  selectedInfluences,
  moodColour,
}) => {
  const onClick = influence => {
    const action = selectedInfluences.some(selected => selected.id === influence.id)
      ? influenceDeselected : influenceSelected;
    action(influence);
  };

  return (
    <ContentRoot>
      {influences.map(influence => (
        <InfluenceButton
          key={influence.id}
          influence={influence}
          onClick={() => onClick(influence)}
          isSelected={selectedInfluences.some(i => i.id === influence.id)}
          moodColour={moodColour}
        />
      ))}
    </ContentRoot>
  );
};

InfluenceSelectorBody.propTypes = {
  influences: PropTypes.arrayOf(influencePropType).isRequired,
  influenceSelected: PropTypes.func.isRequired,
  influenceDeselected: PropTypes.func.isRequired,
  selectedInfluences: PropTypes.arrayOf(influencePropType).isRequired,
  moodColour: PropTypes.string.isRequired
};

export default memo(InfluenceSelectorBody);
