import { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from '../actions/api';
import { aggregateInfluenceTotals, buildStateGroupTotals } from '../helpers/reportHelper';
import { buildEventDayLookup } from '../helpers/calendarHelpers';
import { getKey } from '../helpers/eventHelpers';

const sortInfluence = fieldName => (v1, v2) => v2[fieldName] - v1[fieldName];
const positiveSorter = sortInfluence('positive');
const negativeSorter = sortInfluence('negative');
const totalSorter = sortInfluence('total');

const sortEmotion = (v1, v2) => v2.total - v1.total;

const defaultStateGroups = {
  data: [],
  percentages: {
    positive : 0,
    negative: 0,
  },
};

export default function useReportData({
  fromDate,
  toDate,
  state,
  stateGroup,
  influence,
  deletedEventKeys,
}) {
  const [reportData, setReportData] = useState({});
  const [positiveInfluenceTotals, setPositiveInfluenceTotals] = useState([]);
  const [negativeInfluenceTotals, setNegativeInfluenceTotals] = useState([]);
  const [influenceTotals, setInfluenceTotals] = useState([]);
  const [emotionTotals, setEmotionTotals] = useState([]);
  const [stateGroupTotals, setStateGroupTotals] = useState(defaultStateGroups);
  const [checkIns, setCheckIns] = useState({});

  const from = fromDate.unix();
  const to = toDate.unix();
  useEffect(() => {
    async function getData() {
      const e = text => encodeURIComponent(text);
      const queryUrlPart = `?stateGroup=${e(stateGroup)}&from=${from}&to=${to}&state=${e(state)}&inf=${e(influence)}`;
      const url = `reports/checkins${queryUrlPart}&myData=true`;

      // remove myData=true to get all data which is useful for development
      // const url = `reports/checkins${queryUrlPart}`;

      const reportDataLocal = await get(url);
      const aggregated = aggregateInfluenceTotals(reportDataLocal.influenceTotals);
      const positives = [...aggregated].filter(x => x.positive > 0).sort(positiveSorter);
      const negatives = [...aggregated].filter(x => x.negative > 0).sort(negativeSorter);
      const totals = [...aggregated].sort(totalSorter);

      setReportData(reportDataLocal);
      setPositiveInfluenceTotals(positives);
      setNegativeInfluenceTotals(negatives);
      setInfluenceTotals(totals);

      const emotions = [...reportDataLocal.stateTotals].sort(sortEmotion);
      setEmotionTotals(emotions);

      const stateGroupTotalsLocal = buildStateGroupTotals(reportDataLocal.stateGroupTotals);
      setStateGroupTotals(stateGroupTotalsLocal);
    }
    getData();
  }, [from, to, state, stateGroup, influence]);

  useEffect(() => {
    const checkInEntries = (reportData.checkinsRaw ?? []).map(c => ({
      ...c,
      eventType: 'checkin',
      eventTime: moment(c.checkinTime),
      state: {
        ...c.state,
        colour: c.state.group?.colour,
      },
      key: getKey({ id: c.id, eventType: 'checkin' }),
    })).filter(x => deletedEventKeys.includes(x.key) === false);
    const checkInsLocal = buildEventDayLookup(checkInEntries);

    setCheckIns(checkInsLocal);
  }, [reportData, deletedEventKeys]);

  return {
    reports: reportData,
    positiveInfluenceTotals,
    negativeInfluenceTotals,
    influenceTotals,
    emotionTotals,
    stateGroupTotals,
    checkIns,
  };
}
