import React, { memo } from "react";
import { SectionMarker, SectionTitleText, Text } from "../Day.styled";
import SectionTitle from "../SectionTitle/SectionTitle";

const GratitudeDay = ({ data, deleteClicked, editClicked }) => {
  const { gratitudes, eventTime } = data;
  const toDisplay = gratitudes.filter(Boolean);

  if (toDisplay.length === 0) {
    return null;
  }

  return (
    <>
      <SectionTitle
        time={eventTime}
				text={
          <>
            <SectionMarker colour="#8F79C8" />
            <SectionTitleText>
						Gratitude
            </SectionTitleText>
          </>
        }
        deleteClicked={deleteClicked}
        editClicked={editClicked}
      />
      {toDisplay.map((g, key) => (
        <Text key={key}>{g}</Text>
      ))}
    </>
  );
};

export default memo(GratitudeDay);
