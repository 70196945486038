import React from "react";
import {
  Link,
  LinkText,
  Contact,
  PhotoContainer,
  Details,
  ContactName,
  ContactTitle,
} from "./SignpostContacts.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faPhone,
  faExternalLinkAlt,
  faSms,
} from "@fortawesome/free-solid-svg-icons";

export default function SignpostContacts(props) {
  if (!props.contacts) {
    return null;
  }

  let contacts = props.contacts.map((contact, i) => {
    let phone = null;
    let email = null;
    let web = null;
    const linkType = contact.linkType ?? "www";

    // NOTE: Icons here are throwing a "Converting circular structure to JSON" error - removed for now.

    if (contact.phone && contact.phone !== "undefined") {
      if (contact.phone.substring(0, 3) === "sms") {
        let sms = contact.phone.split(":");
        phone = (
          <Link href={`sms:${sms[1]}?body=${sms[2]}`} target="_blank">
            <FontAwesomeIcon icon={faSms} style={styles.contactIcon} />
            <LinkText style={styles.contactButtonText}>{sms[1]}</LinkText>
          </Link>
        );
      } else {
        phone = (
          <Link
            href={`tel:${contact.phone}`}
            style={styles.contactButton}
            target="_blank"
          >
            <FontAwesomeIcon icon={faPhone} style={styles.contactIcon} />
            <LinkText style={styles.contactButtonText}>
              {contact.phone}
            </LinkText>
          </Link>
        );
      }
    }
    if (contact.email && contact.email !== "undefined") {
      const e = JSON.parse(contact.email);
      email = (
        <Link
          href={`mailto:${e.email}?subject=${e.subject}&body=${e.body}`}
          style={styles.contactButton}
          target="_blank"
          className={e.linkType === "btn" ? "button" : ""}
        >
          {e.linkType !== "btn" && (
            <FontAwesomeIcon icon={faEnvelope} style={styles.contactIcon} />
          )}

          <LinkText style={styles.contactButtonText}>
            {e.emailText ?? e.email}
          </LinkText>
        </Link>
      );
    }
    if ((contact.web && contact.web !== "undefined") || linkType !== "www") {
      let linkTo;
      let target;
			let linkText = "Visit";
      if (linkType === "www") {
        linkTo = contact.web;
        target = "_blank";
				linkText = "Visit website";
      } else if (linkType === "breathe") {
        linkTo = "/breathe";
      } else if (linkType === "course") {
        const [courseId, screenId] = contact.web.split("/");
        // find courseId
        if (courseId && screenId) {
          linkTo = `/courseopen/${courseId}/${screenId}`;
        }
      } else if (linkType === "grat") {
        linkTo = "/gratitude";
      } else if (linkType === "rak") {
        linkTo = "/random-act-of-kindness";
      } else if (linkType === "checkin") {
        linkTo = "/daily-reflection";
      } else if (linkType === "checkinPos") {
        linkTo = "/daily-reflection?checkinMode=positive&stateGroups=very%20positive,positive";
      }

      web = linkTo ? (
        <Link href={linkTo} style={styles.contactButton} target={target} className={linkType !== "www" ? "button" : ""}>
          {linkType === "www" && (
            <FontAwesomeIcon
              icon={faExternalLinkAlt}
              style={styles.contactIcon}
            />
          )}
          <LinkText style={styles.contactButtonText}>{linkText}</LinkText>
        </Link>
      ) : null;
    }

    let out = (
      <Contact key={i}>
        <PhotoContainer>
          <img
            src={
              "https://api.lumeapp.io/api/signpost/" + contact.id + "/image.png"
            }
            style={{ width: 100, height: 100 }}
          />
        </PhotoContainer>
        <Details>
          <ContactName>{contact.name}</ContactName>
          <ContactTitle>{contact.title}</ContactTitle>
          {phone}
          {email}
          {web}
        </Details>
      </Contact>
    );

    return out;
  });

  return contacts;
}

const styles = {
  contactIcon: {
    marginRight: 10,
    width: 18,
  },
};
