import React, { memo } from "react";
import PropTypes from "prop-types";
import Tile from "./Tile/Tile";
import theme from "../../theme";

const { reflection, gratitude, survey, comingSoon, rak, breathe } =
  theme.primary.home.colours;

export const ReflectionTile = memo(() => (
  <Tile
    backgroundColour={reflection}
    url="daily-reflection"
    title="How are you feeling?"
    hideRepeatIcon={true}
  />
));

export const GratefulTile = ({
  repeatIconOn,
  toggleRepeat,
  hideRepeatIcon,
}) => (
  <Tile
    backgroundColour={gratitude}
    url="/gratitude"
    title="What are you Grateful for?"
    repeatIconOn={repeatIconOn}
    hideRepeatIcon={hideRepeatIcon ?? false}
    toggleRepeat={() => {
      toggleRepeat("gratitude", null, !repeatIconOn);
    }}
  />
);

export const RAKTile = ({ repeatIconOn, toggleRepeat, hideRepeatIcon }) => (
  <Tile
    backgroundColour={rak}
    url="/random-act-of-kindness"
    title="Random Act of Kindness"
    repeatIconOn={repeatIconOn}
    hideRepeatIcon={hideRepeatIcon ?? false}
    toggleRepeat={() => {
      toggleRepeat("rak", null, !repeatIconOn);
    }}
  />
);

export const BreatheTile = ({ repeatIconOn, toggleRepeat, hideRepeatIcon }) => (
  <Tile
    backgroundColour={breathe}
    url="/breathe"
    title="De-Stress Deep Breathing"
    repeatIconOn={repeatIconOn}
    hideRepeatIcon={hideRepeatIcon ?? false}
    toggleRepeat={() => {
      toggleRepeat("breathe", null, !repeatIconOn);
    }}
  />
);

export const FeatureTiles = {
  gainsAndDrains: ({ repeatIconOn, toggleRepeat, hideRepeatIcon }) => (
    <Tile
      backgroundColour="#407cb4"
      url="/notes/gainsAndDrains"
      title="Gains & Drains"
      repeatIconOn={repeatIconOn}
      hideRepeatIcon={hideRepeatIcon ?? false}
      toggleRepeat={() => {
        toggleRepeat("notes-gainsAndDrains", null, !repeatIconOn);
      }}
    />
  ),
  me: ({ repeatIconOn, toggleRepeat, hideRepeatIcon }) => (
    <Tile
      backgroundColour="#407cb4"
      url="/notes/me"
      title="Me"
      repeatIconOn={repeatIconOn}
      hideRepeatIcon={hideRepeatIcon ?? false}
      toggleRepeat={() => {
        toggleRepeat("notes-me", null, !repeatIconOn);
      }}
    />
  ),
};

const SurveyTileRaw = memo(({ title, url, superTitle, hideRepeatIcon }) => (
  <Tile
    backgroundColour={survey}
    url={url}
    title={title}
    superTitle={superTitle}
    hideRepeatIcon={hideRepeatIcon ?? false}
  />
));

SurveyTileRaw.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  superTitle: PropTypes.string,
};

export const SurveyTile = memo(SurveyTileRaw);
export const ComingSoonTile = memo(() => (
  <Tile
    title="Coming Soon"
    backgroundColour={comingSoon}
    colour="#ffffff"
    hideRepeatIcon={true}
  />
));

export const CourseAndFeatureTile = ({
  title,
  url,
  bgCol,
  superTitle,
  repeatIconOn,
  toggleRepeat,
  subId,
  doneForToday,
  progressType,
  hideRepeatIcon,
  type,
}) => (
  <Tile
    title={title}
    superTitle={superTitle}
    url={url}
    backgroundColour={"#" + bgCol}
    colour="#ffffff"
    repeatIconOn={repeatIconOn}
    doneForToday={doneForToday}
    progressType={progressType}
    toggleRepeat={() => {
      type === "feature"
        ? toggleRepeat(subId, null, !repeatIconOn)
        : toggleRepeat("course", subId, !repeatIconOn);
    }}
    hideRepeatIcon={hideRepeatIcon ?? false}
  />
);
