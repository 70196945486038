import styled from 'styled-components';

export const ChevronUp = styled.i`
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  color: black;
  
  &::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(-45deg);
    left: 4px;
    bottom: 2px
  }
  
  &:hover {
    cursor: pointer;
  }
`;

export const ChevronDown = styled.i`
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;

  &::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    left: 4px;
    top: 2px;
  }
  
  &:hover {
    cursor: pointer;
  } 
`;
