import React from 'react';
import { Text, SlideWrapper, SlideContent } from './Slides.styled';
import { ButtonWhite } from '../../../../components/common';
export function Slide1(props) {

	return (
		<SlideWrapper>
			<SlideContent>
				<Text></Text>
				<Text>The first step to a healthier happier mind is to be aware of how you feel and why you feel that way</Text>
				<ButtonWhite onClick={props.nextSlide} style={{ width: "100%", maxWidth: 300 }}>Next</ButtonWhite>
			</SlideContent>
		</SlideWrapper>
	)
}
