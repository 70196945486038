import React, { memo } from 'react';
import { IndexContainer, IndexItemContainer } from './ScreenIndex.styled';

const ScreenIndex = (props) => {

	const list = props.items.map((item, i) => {
		return <IndexItemContainer viewed={item.courseScreenViews.length ? true : false} key={i} onClick={() => { props.findScreen(item.id) }}>{item.title}</IndexItemContainer>
	})

	return (
		<IndexContainer>
			{list}
		</IndexContainer>
	);
};


export default memo(ScreenIndex);
