import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { InfluenceButton } from '../../../components/common';
import { ButtonContainer } from './InfluenceBody.styled';
import { ItemContainer } from '../ReflectionAnalytics.styled';

const InfluenceBody = ({ influences, getBadge, filterSelected }) => {
  return (
    <ItemContainer>
      {influences.map(influence => (
        <ButtonContainer key={influence.term}>
          <InfluenceButton
            key={influence.id}
            influence={influence}
            badge={getBadge(influence)}
            onClick={() => filterSelected(influence, 'influence')}
          />
        </ButtonContainer>
      ))}
    </ItemContainer>
  )
};

InfluenceBody.propTypes = {
  getBadge: PropTypes.func.isRequired,
  filterSelected: PropTypes.func.isRequired,
};

export default memo(InfluenceBody);
