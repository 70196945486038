import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { get } from "../../../../../actions/api";
import { checkIfDue } from "../../../../../helpers/feedbackHelper";
const Feedback = ({ elm, course, screen }) => {
  const [due, setDue] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function getFeedback() {
      const feedback = await get(`feedback/${elm.content.feedbackId}`);
      const responses = await get(`feedback/response`);
      if (feedback.feedback) {
        const fb = feedback.feedback;
        const rsp = responses.feedbackResponses;
        setDue(checkIfDue(fb, rsp));
      }
    }
    getFeedback();
  }, [elm.id]);

  if (due) {
    const next = `/courseopen/${course.id}/${screen.id}%3Ffeedbackdone=true`;
    history.push(`/feedback/${elm.content.feedbackId}?next=${next}`);
  }

  return null;
};
export default memo(Feedback);
