import React from "react";
import Users from "./Users";
import {
  Settings,
  Activate,
  Activated,
  PasswordForgot,
  Survey,
  PasswordReset,
  DailyCheckIn,
  Calendar,
  CalendarDetail,
  Home,
  RAK,
  Gratitude,
  ReflectionAnalytics,
  Course,
  Signposting,
  Login,
  Signup,
  GetTheApp,
  Signposts,
  Anonymity,
  Breathe,
} from "../pages";
import Nothing from "./Nothing";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  useLocation,
  Link,
  Switch,
} from "react-router-dom";
import "../../css/App.css";
import { MainContext } from "../context/MainContext";
import RewireMP from "../pages/RewireMP/RewireMP";
import DownloadAppBanner from "./common/DownloadAppBanner/DownloadAppBanner";
import MobileOutlined from "@ant-design/icons/lib/icons/MobileOutlined";
import ReportsCheckins from "../pages/Reports/Checkins/ReportsCheckins";
import ReportsSurvey from "../pages/Reports/Surveys/ReportsSurvey";
import Overview from "../pages/Reports/Overview/Overview";
import Feedback from "../pages/Feedback/Feedback";
import Suggestions from "../pages/Suggestions/Suggestions";
import Notes from "../pages/Notes/Notes";
import CreateHabitTracker from "../pages/HabitTracker/CreateHabitTracker";
import ViewHabitTracker from "../pages/HabitTracker/ViewHabitTracker";

class MainNav extends React.Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      loggedIn: false,
      loggedInChecked: 0,
      user: {},
      redirectElm: null,
    };
  }

  AuthRedirect = () => {
    let ctx = this.context;
    let location = useLocation();

    const UnAuthedRoutes = [
      "/activate",
      "/login",
      "/activated",
      "/forgotpassword",
      "/questions",
      "/rewire-mp",
      "/signup",
      "/gettheapp",
    ];

    // open routes with multiple /* paths
    const UnAuthedRoutesDir = ["/resetpassword/"];

    const redirectIfLoggedIn = ["/login", ""];
    if (ctx.user) {
      // redirect somewhere - where exactly depends on what they have access to and what user level they are.
      //
      // Depending on permission they should go to:
      // WDS, Daily Checkin, Surveys, NOTHING TO DO PAGE

      let redirect = "/";

      if (ctx.user.permissions) {
        if (ctx.user.permissions.ENG) {
          redirect = "/reports/overview";
        } else if (ctx.user.permissions.WDS) {
          redirect = "/reports/wellbeing-data-system";
        } else if (ctx.user.permissions.DC) {
          redirect = "/reports/checkins";
        }
      }

      // if they're on a URL which is in this array we don't need to redirect if they login
      if (redirectIfLoggedIn.includes(location.pathname)) {
        return <Redirect to={{ pathname: redirect }} />;
      } else {
        return null;
      }
    } else if (ctx.authChecked) {
      if (!UnAuthedRoutes.includes(location.pathname)) {
        let found = 0;
        UnAuthedRoutesDir.forEach((openDir) => {
          if (location.pathname.indexOf(openDir) === 0) {
            found = 1;
          }
        });
        if (!found) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { source: location.pathname } }}
            />
          );
        }
      }
    }

    return null;
  };

  redirectTo = (pathname) => {
    this.setState({ redirectElm: () => <Redirect to={{ pathname }} /> });
  };

  headerButtons = () => {
    let location = useLocation();
    let ctx = this.context;

    let reportButtons = null;
    let userManagementButtons = null;
    let settingsManagementButtons = null;

    if (ctx.user) {
      if (ctx.user.permissions && Object.values(ctx.user.permissions).length) {
        reportButtons = (
          <div
            className={
              location.pathname.includes("/reports/")
                ? "leftnav-dd active"
                : "leftnav-dd"
            }
          >
            <div className="lo">
              <span className="icon">
                <i className="fas fa-chart-bar"></i>
              </span>
              <span>Data</span>
              <div className="dd_icon">
                <i className="fas fa-caret-down"></i>
              </div>
            </div>
            <div className="left-menu-dd">
              {ctx.user.permissions.ENG && (
                <Link
                  to="/reports/overview"
                  className={
                    location.pathname === "/reports/overview"
                      ? "leftnav-button dd_item active lo"
                      : "leftnav-button dd_item lo"
                  }
                >
                  <span className="letterIcon">O</span>
                  Engagement Overview
                </Link>
              )}
              {ctx.user.permissions.DC && (
                <Link
                  to="/reports/checkins"
                  className={
                    location.pathname === "/reports/checkins"
                      ? "leftnav-button dd_item active lo"
                      : "leftnav-button dd_item lo"
                  }
                >
                  <span className="letterIcon">D</span>
                  Daily Reflection
                </Link>
              )}
              {ctx.user.permissions.WDS && (
                <Link
                  to="/reports/wellbeing-data-system"
                  className={
                    location.pathname === "/reports/wellbeing-data-system"
                      ? "leftnav-button dd_item active"
                      : "leftnav-button dd_item"
                  }
                >
                  <span className="letterIcon">W</span>
                  Well-being data system
                </Link>
              )}
            </div>
          </div>
        );
      }
      if (ctx.user.permissions.userManagement) {
        userManagementButtons = (
          <Link
            to="/users"
            className={
              location.pathname === "/users"
                ? "leftnav-button active lo"
                : "leftnav-button lo"
            }
          >
            <span className="icon">
              <i className="fas fa-user"></i>
            </span>
            <span>User admin</span>
          </Link>
        );
      }
      if (ctx.user.permissions.settingsManagement) {
        settingsManagementButtons = (
          <div
            className={
              location.pathname === "/settings/anonymity" ||
              location.pathname === "/settings/signposting"
                ? "leftnav-dd active"
                : "leftnav-dd"
            }
          >
            <div className="lo">
              <span className="icon">
                <i className="fas fa-cogs"></i>
              </span>
              <span>Settings</span>
              <div className="dd_icon">
                <i className="fas fa-caret-down"></i>
              </div>
            </div>
            <div className="left-menu-dd">
              <Link
                to="/settings/signposting"
                className={
                  location.pathname === "/settings/signposting"
                    ? "leftnav-button active lo"
                    : "leftnav-button lo"
                }
              >
                <span className="icon">
                  <i className="fas fa-map-signs"></i>
                </span>
                <span>Signposting</span>
              </Link>
              <Link
                to="/settings/anonymity"
                className={
                  location.pathname === "/settings/anonymity"
                    ? "leftnav-button active lo"
                    : "leftnav-button lo"
                }
              >
                <span className="icon">
                  <i className="fas fa-user-shield"></i>
                </span>
                <span>Anonymity</span>
              </Link>
            </div>
          </div>
        );
      }
      return (
        <div className="buttons">
          {!ctx.user.user_type && (
            <div className="buttons">
              <Link
                to="/"
                className={
                  location.pathname === "/"
                    ? "leftnav-button active lo"
                    : "leftnav-button lo"
                }
              >
                <span className="icon">
                  <i className="fas fa-home"></i>
                </span>
                <span>Home</span>
              </Link>
              <Link
                to="/calendar"
                className={
                  location.pathname === "/calendar"
                    ? "leftnav-button active lo"
                    : "leftnav-button lo"
                }
              >
                <span className="icon">
                  <i className="far fa-calendar"></i>
                </span>
                <span>Calendar</span>
              </Link>
              <Link
                to="/my-data"
                className={
                  location.pathname === "/my-data"
                    ? "leftnav-button active lo"
                    : "leftnav-button lo"
                }
              >
                <span className="icon">
                  <i className="fas fa-chart-bar"></i>
                </span>
                <span>My Data</span>
              </Link>
              <Link
                to="/signposting"
                className={
                  location.pathname === "/signposting"
                    ? "leftnav-button active lo"
                    : "leftnav-button lo"
                }
              >
                <span className="icon">
                  <i className="fas fa-map-signs"></i>
                </span>
                <span>Signposting</span>
              </Link>
            </div>
          )}
          {reportButtons}
          {userManagementButtons}
          {settingsManagementButtons}

          <Link to="/gettheapp" className="leftnav-button getApp">
            <span className="icon">
              <MobileOutlined />
            </span>
            Get The Mobile App
          </Link>
          <a href="https://lumeapp.io/help" className="leftnav-button support">
            <span className="icon">
              <i className="far fa-life-ring"></i>
            </span>
            Help &amp; Support
          </a>
          <Link
            to="/login"
            className="leftnav-button logout"
            onClick={ctx.logout}
          >
            <span className="icon">
              <i className="fas fa-sign-out-alt"></i>
            </span>
            Log Out
          </Link>
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    let wrapperClass = "mainWrapper";
    if (!this.context.user) {
      wrapperClass += " loggedout";
    }
    return (
      <Router>
        <Route path="/" component={this.state.redirectElm} />
        <Route path="/" component={this.AuthRedirect} />
        <div className={wrapperClass}>
          <div className="leftnav">
            <DownloadAppBanner />
            <div className="leftnav-container">
              <div className="leftnav-brand">
                <span className="leftnav-item">
                  <a href="/">
                    <img
                      alt="Lume"
                      className="logoIcon"
                      src={require("../../../src/imgs/logo_white.svg")}
                    />
                  </a>
                </span>
              </div>
              <this.headerButtons />
            </div>
          </div>
          <div className="mainarea">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/nothing" component={Nothing} />
              <Route
                exact
                path="/activate"
                render={(props) => (
                  <Activate {...props} redirectTo={this.redirectTo} />
                )}
              />
              <Route exact path="/activated" component={Activated} />
              <Route exact path="/gettheapp" component={GetTheApp} />
              <Route exact path="/forgotpassword" component={PasswordForgot} />
              <Route exact path="/resetpassword/*" component={PasswordReset} />
              <Route
                exact
                path="/reports/checkins"
                component={ReportsCheckins}
              />
              <Route
                exact
                path="/reports/wellbeing-data-system"
                component={ReportsSurvey}
              />
              <Route exact path="/reports/overview" component={Overview} />
              <Route exact path="/users" component={Users} />
              <Route exact path="/settings" component={Settings} />
              <Route exact path="/settings/signposting" component={Signposts} />
              <Route exact path="/settings/anonymity" component={Anonymity} />
              <Route
                exact
                path="/questions/:surveyGroupId?/:surveyId?/:questionId?"
                component={Survey}
              />
              <Route exact path="/daily-reflection" component={DailyCheckIn} />
              <Route
                exact
                path="/daily-reflection/:id/:emotion?"
                component={DailyCheckIn}
              />
              <Route exact path="/calendar" component={Calendar} />
              <Route exact path="/calendar-detail" component={CalendarDetail} />
              <Route exact path="/random-act-of-kindness" component={RAK} />
              <Route exact path="/notes/:type" component={Notes} />
              <Route exact path="/gratitude" component={Gratitude} />
              <Route exact path="/gratitude/:ids" component={Gratitude} />
              <Route exact path="/rewire-mp" component={RewireMP} />
              <Route exact path="/breathe" component={Breathe} />
              <Route exact path="/my-data" component={ReflectionAnalytics} />
              <Route exact path="/suggestions" component={Suggestions} />
              <Route exact path="/habit-tracker/create" component={CreateHabitTracker} />
              <Route exact path="/habit-tracker/:id" component={ViewHabitTracker} />
							
              <Route
                exact
                path="/course/:courseId/:rootIndex?/:moduleIndex?/:sectionIndex?/:screenIndex?"
                component={Course}
              />
              <Route
                exact
                path="/courseopen/:courseId/:screenId"
                component={Course}
              />
              <Route exact path="/signposting" component={Signposting} />
              <Route exact path="/login">
                <Login login={this.login} />
              </Route>
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/feedback/:feedbackId" component={Feedback} />
              <Route
                component={() => {
                  return <div>Page not found</div>;
                }}
              />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default MainNav;
