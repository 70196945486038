import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  TileBase,
  Title,
  SuperTitle,
  ShowInToday,
  Inner,
  DoneWrapper,
  DoneBg,
} from "./Tile.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faRedo } from "@fortawesome/free-solid-svg-icons";

const Tile = ({
  title,
  superTitle,
  backgroundColour,
  url,
  colour = "white",
  repeatIconOn = false,
  hideRepeatIcon = false,
  toggleRepeat = null,
  progressType = null,
  doneForToday = false,
}) => {
  return (
    <TileBase backgroundColour={backgroundColour}>
      {hideRepeatIcon === false && (
        <ShowInToday onClick={toggleRepeat}>
          <FontAwesomeIcon
            icon={faRedo}
            style={{
              width: 16,
              height: 16,
              display: "block",
              color: repeatIconOn === true ? "#080" : "#eee",
            }}
          />
        </ShowInToday>
      )}
      <Inner to={url} colour={colour}>
        <SuperTitle>{superTitle}</SuperTitle>
        <Title>{title}</Title>
        {progressType === "anyActivityDaily" && doneForToday === true && (
          <DoneWrapper>
            <DoneBg />
            <FontAwesomeIcon icon={faCheckCircle} style={{width:40,height:40, marginTop:10, marginBottom:-20}} />
          </DoneWrapper>
        )}
      </Inner>
    </TileBase>
  );
};

Tile.defaultProps = {
  url: "",
  SuperTitle: "",
};

Tile.propTypes = {
  title: PropTypes.string.isRequired,
  superTitle: PropTypes.string,
  backgroundColour: PropTypes.string.isRequired,
  url: PropTypes.string,
  repeatIconOn: PropTypes.bool,
  hideRepeatIcon: PropTypes.bool,
};

export default memo(Tile);
