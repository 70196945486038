import { del } from "../actions/api";

const routes = {
  rak: "RAK/record",
  checkin: "checkin",
  gratitude: "gratitude",
  progressmk: "event",
  breathe: "event",
};

export async function deleteEvent(event) {
  const eventType = event.eventType?.toLowerCase();
  if (eventType == null) {
    throw new Error(`Event has no type ${event.id}`);
  }

  const base = routes[eventType];
  if (base == null) {
		console.error(routes,event.eventType)
    throw new Error(`Cannot find endpoint for event type ${event.eventType}`);
  }

  switch (event.eventType.toLowerCase()) {
    case "gratitude":
      return Promise.all(event.gratitudeIds.map((id) => del(`${base}/${id}`)));
		default:
      return del(`${base}/${event.id}`);
  }
}

export const getKey = (event) => `${event.id}_${event.eventType}`;
