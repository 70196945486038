import React, { memo } from 'react';
import { ButtonContainer } from './EmotionButton.styled';
import { Badge } from '../common.styled';

const EmotionButton = ({ emotion, badge, ...props }) => {
    return (
      <ButtonContainer emotion={emotion} {...props}>
        {badge != null ? <Badge right="0.1rem">{badge}</Badge> : null}
        {emotion.term}
      </ButtonContainer>
    );
};

export default memo(EmotionButton);
