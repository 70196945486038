import React from "react";
import PlayNumbered from "./PlayNumbered";
import TextAndPlay from "./TextAndPlay";
// import PlayNumbered from "./PlayNumbered";

export default function Items({
  item,
  listType,
  number,
  screenViews,
  latestInteractions,
  findScreen,
  currentScreenId,
}) {
  const {
    title,
    linkTo,
    completeOn,
    clickableOn,
    completeOnId,
    clickableOnId,
  } = item;

  const nav = () => {
    findScreen(linkTo);
  };

  let complete = false;
  if (completeOn === "screenId") {
    complete = screenViews.find((sv) => sv.screenId === Number(completeOnId));
  } else if (completeOn === "elmId") {
    if (completeOnId.indexOf(",") > -1) {
      const ids = completeOnId.split(",");
      complete = ids.filter((id) => !(id in latestInteractions)).length === 0;
    } else {
      complete = completeOnId in latestInteractions;
    }
  } else if (completeOn === "always") {
    complete = true;
  } else if (completeOn === "elmOnScrn") {
    complete = Object.values(latestInteractions).find(
      (int) => int.screenId === Number(completeOnId)
    );
  } else {
    console.log("Unrecognised completion type:", completeOn);
  }

  let clickable = false;
  if (clickableOn === "always") {
    clickable = true;
  } else if (clickableOn === "screenId") {
    clickable = screenViews.find((sv) => sv.screenId === Number(clickableOnId));
  } else if (clickableOn === "elmId") {
    clickable = clickableOnId in latestInteractions;
  } else if (clickableOn === "progress") {
    clickable =
      currentScreenId &&
      Number(currentScreenId) >=
        (clickableOnId ? Number(clickableOnId) : linkTo ? Number(linkTo) : 0);
  } else {
    console.log("Unrecognised clickable type:", clickableOn);
  }

  switch (listType) {
    case "textAndPlay":
      return (
        <TextAndPlay
          icon="play"
          clickable={clickable}
          complete={complete}
          title={title}
          onClick={nav}
        />
      );
      break;
    case "textAndTick":
      return (
        <TextAndPlay
          icon="tick"
          clickable={clickable}
          complete={complete}
          title={title}
          onClick={nav}
        />
      );
      break;
    case "play":
      return (
        <PlayNumbered
          clickable={clickable}
          complete={complete}
          onClick={nav}
          number={number}
        />
      );
      break;

    default:
      return (
        <TextAndPlay
          clickable={clickable}
          complete={complete}
          title={title}
          onClick={nav}
        />
      );
      break;
  }
}
