import styled from 'styled-components';
import bubbles from '../../../../../imgs/bubbles_white_square_2.svg';

export const SlideWrapper = styled.div`
	background-color: ${({ theme }) => theme.primary.colours.baseColours.breathe};
	background-image: url(${bubbles});
	background-size: cover;
	width: 100%;
	flex: 1;
	display: flex;
`;

export const SlideContent = styled.div`
	flex: 1;
	flex-direction: column;
	align-items: center;
  justify-content: space-between;
	max-width: 500px;
	max-height: 650px;
	margin: 0 auto;
	display: flex;
	position: relative;
	
`;

export const Text = styled.p`
  color: black;
	font-size: 26px;
	text-align: center;
`;
