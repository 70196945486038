import React, { useEffect, useState } from "react";
import { get } from "../../../../actions/api";
import { MainScore, RangeScore, Score } from "./ScoreSection.styled";
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import moment from "moment";

const ScoreSection = ({ from, to }) => {
  const [scores, setScores] = useState(null);
  useEffect(() => {
    const params =
      from && to
        ? {
            from: moment.utc(from).isSame(moment.utc(to), "month")
              ? moment.utc(from).subtract(1, "month").toDate()
              : moment.utc(from).toDate(),
            to: moment.utc(to).toDate(),
          }
        : null;
    async function getData() {
      const scoreData = await get("reports/score", params);
      setScores(scoreData);
    }

    getData();
  }, [from, to]);

  if (scores === null) {
    return null;
  }

  const chartData = scores.scores.map((s) => {
    return { name: moment(s.date).format("MMM YY"), score: s.score };
  });

  return (
    <>
      <div className="report-item size4">
        <MainScore>{scores.dateRangeAverage}</MainScore>
        <RangeScore>
          All time average <Score>{scores.overallAverage}</Score>
        </RangeScore>
      </div>
      <div className="report-item size8" style={{ height: 291 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData}
            strokeWidth={1}
            connectNulls={true}
            margin={{
              top: 0,
              right: 0,
              left: -40,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              interval="preserveStartEnd"
              domain={[0, 6]}
              dataKey="score"
              ticks={[0,1,2,3,4,5,6]}
            />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="score"
              stroke="#22282b"
              strokeWidth={2}
              dot={{ r: 3, strokeWidth:6 }}
              activeDot={{ r: 9 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default ScoreSection;
