import React from "react";
import { Text, SlideWrapper, SlideContent } from "./Slides.styled";
import { ButtonWhite } from "../../../../components/common";
import VimeoPlayer from "@u-wave/react-vimeo";

export function Slide5(props) {
	return (
		<SlideWrapper>
			<SlideContent>
				<Text></Text>
				<Text>
					This Deep Breathing exercise will reset your nervous system and turn
					off your stress response, helping you to relax and quieten your mind.
					Here's how
					<br />
					<br />
					Here is how it works:
				</Text>
				<VimeoPlayer
					style={{ width: 500, height: 281 }}
					responsive={true}
					video={673526577}
					autoplay
				/>
				<ButtonWhite
					onClick={props.nextSlide}
					style={{ width: "100%", maxWidth: 300 }}
				>
					Start
				</ButtonWhite>
			</SlideContent>
		</SlideWrapper>
	);
}
