import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { NavTitle, PageRoot, TopNavContainer, Month } from '../../components/common';
import { PageContent } from './Calendar.styled';
import { useCalendarData } from '../../hooks';
import moment from "moment";

const Calendar = () => {
  const history = useHistory();
  const { eventMonthLookup: eventMonthLookupData, months: monthsData } = useCalendarData();
  const currentMonth = moment().format('YYMM');
  const months = monthsData.includes(currentMonth) ? monthsData : [...monthsData, currentMonth];
  const eventMonthLookup = eventMonthLookupData[currentMonth] == null
    ? { ...eventMonthLookupData, [currentMonth]: [] }
    : eventMonthLookupData;

  const navigateToDay = (date, hasEntry) => {
    if (hasEntry === true) {
      history.push(`calendar-detail?selectedDate=${date.format('YYMMDD')}`);
    } else {
      history.push(`daily-reflection?date=${date.format('DDMMYY')}`);
    }
  };
  return (
    <PageRoot>
      <TopNavContainer>
        <NavTitle>Calendar</NavTitle>
      </TopNavContainer>
      <PageContent>
        {months.map(month => (
          <Month
            key={month}
            month={month}
            days={eventMonthLookup[month]}
            selectedDateChanged={navigateToDay}
          />
        ))}
      </PageContent>
    </PageRoot>
  );
};

export default memo(Calendar);
