import React from "react";
import styled from "styled-components";

export const PageContent = styled.div`
  min-width: 100%;
  height: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  padding: 10px 40px;
`;

export const Section = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  color: ${({ theme }) => theme.primary.colours.heading};
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0;
`;

export const Left = styled.div`
  flex: 1;
  height: 100px;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-right: #eee 1px solid;
  padding-right: 20px;
`;

export const Right = styled.div`
  flex: 1;
  height: 100px;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  font-size: 70px;
  font-weight: 900;
  color: ${({ theme }) => theme.primary.colours.greenL};
  padding-left: 20px;
`;

export const SectionTitle = styled.h4`
  color: ${({ theme }) => theme.primary.colours.blueD};
  font-weight: 700;
  margin-bottom: 10px;
`;

export const KeyVal = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1rem;
`;

export const Key = styled.div`
  flex: 1;
  text-align: left;
  font-weight: bold;
`;

export const Val = styled.div`
  flex: 1;
  text-align: right;
  font-size: 1.2rem;
`;

export const B = styled.b`
  display: inline-block;
  font-weight: 900;
  color: ${({ theme }) => theme.primary.colours.greenL};
`;

export const Details = styled.div`
  text-align: left;
  margin-top: 10px;
`;

export const ListTitle = styled.h3`
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

export const ListItem = styled.li`
  padding-left: 10px;
  margin-left: 20px;
  font-size: 1rem;
  &::marker {
    font-weight: bold;
  }
`;
