import styled from 'styled-components';

export const GroupListContainer = styled.div`
  width: 100%;
`;

export const GroupContainer = styled.div`
  padding: 5px 10px;
	text-align:center;
`;


