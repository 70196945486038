import moment from 'moment';

function getKey() {
  const date = moment().format('YYMMDD');
  return `${date}_task_completion`;
}

export function getDailyTasks() {
  const key = getKey();
  try {
    const storageValue = localStorage.getItem(key);
    return JSON.parse(storageValue) ?? {};
  } catch (e) {
    localStorage.removeItem(key);
    return {};
  }
}

function setDailyTasks(newValue) {
  const key = getKey();
  const json = JSON.stringify(newValue);
  localStorage.setItem(key, json);
}

export function taskCompletedToday(name) {
  updateDailyTasks({ [name]: true });
}

export function taskRemovedToday(name) {
  const current = getDailyTasks();
  delete current.name;
  setDailyTasks(current);
}

export function updateDailyTasks(merge) {
  const current = getDailyTasks();
  const newValue = {
    ...current,
    ...merge,
  };
  setDailyTasks(newValue);
}
