import styled from "styled-components";

export const ContentRoot = styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
  flex: 1;
  border-radius: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 1rem;
  width: 100%;
`;

export const TitleContent = styled.div`
  flex: 0 0 2rem;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Body = styled.div`
  flex: 1;
`;
