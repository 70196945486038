import React, { memo, useEffect, useState } from "react";
import { get } from "../../../actions/api";
import { Body, Left, RankingWrapper, Right, Title } from "./Ranking.styled";

const Ranking = () => {
  const [ranking, setRanking] = useState(null);
  useEffect(() => {
    getRank();
  }, []);

  const getRank = async () => {
    const ranking = await get("reports/ranking");
    setRanking(ranking.report);
  };

  if (ranking === null) {
    return null;
  }

  let title, body, icon;
  if (ranking.percentageFromTop < 11) {
    title = "Amazing!";
    body = (
      <Body>
        You are in the <b>top {Math.round(ranking.percentageFromTop)}%</b> of
        your company
      </Body>
    );
    icon = (
      <img
        src={require("../../../../imgs/trophy/gold.svg")}
        style={styles.trophy}
      />
    );
  } else if (ranking.percentageFromTop < 31) {
    title = "Well done!";
    body = (
      <Body>
        You are in the <b>top 11-30%</b> of your company
      </Body>
    );
    icon = (
      <img
        src={require("../../../../imgs/trophy/silver.svg")}
        style={styles.trophy}
      />
    );
  } else if (ranking.percentageFromTop < 71) {
    title = "Keep it up";
    body = (
      <Body>
        You are in the <b>middle 31-70% </b> of your company
      </Body>
    );
    icon = (
      <img
        src={require("../../../../imgs/trophy/bronze.svg")}
        style={styles.trophy}
      />
    );
  } else {
    title = null;
    body = (
      <Body>
        You are currently in the <b> bottom 30%</b> of your company, what simple
        habit could you create to make mental fitness part of your routine?
      </Body>
    );
    icon = (
      <img
        src={require("../../../../imgs/trophy/disabled.svg")}
        style={styles.trophy}
      />
    );
  }

  return (
    <RankingWrapper>
      <Left>{icon}</Left>
      <Right>
        {title && <Title>{title}</Title>}
        {body}
      </Right>
    </RankingWrapper>
  );
};

const styles = {
  trophy: {
    width: 60,
  },
};

export default memo(Ranking);
