import React, { memo, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import LeftChevron from "@ant-design/icons/lib/icons/LeftOutlined";
import {
  PageContent,
  FloatingBox,
  SkipButton,
  SaveButton,
  BoxTitle,
  RakText,
} from "./RAK.styled";
import { get, post } from "../../actions/api";
import { NavRoot, NavTitle, PageRoot } from "../../components/common";
import AddMore from "./AddYourOwn";
import GetASuggestion from "./GetASuggestion";
import { taskCompletedToday } from "../../helpers/dailyCompletionStorageHelper";

const RAK = () => {
  const history = useHistory();
  const customRakRef = useRef(null);
  const [RAKs, setRAKs] = useState("");
  const [RAK, setRAK] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [customRakText, setCustomRakText] = useState("");
  const [rakStack, setRakStack] = useState([]);
  const index = rakStack.indexOf(RAK);

  const params = new URLSearchParams(window.location.search);
  const dateParam = params.get("date");
  const nextPage = params.get("next");
  const eventTime = dateParam
    ? moment(dateParam, "YYMMDD").set("hour", 12).toDate()
    : moment().set("hour", 12).toDate();

  useEffect(() => {
    async function buildData() {
      const data = await get("RAK");
      setRAKs(data.RAKs);
    }
    buildData();
  }, []);

  useEffect(() => {
    if (editMode === true) {
      customRakRef.current.focus();
    }
  }, [editMode]);

  const newRAK = () => {
    if (!RAKs.length) {
      return null;
    }
    const nRAK = RAKs[Math.floor(Math.random() * RAKs.length)];
    setRAK(nRAK);
    setRakStack((stack) => [...stack, nRAK]);
  };

  if (!RAK) {
    newRAK();
  }

  const saveRAK = async () => {
    const newRecord =
      editMode === true
        ? await post("RAK/records", {
            kindness: customRakText.trim(),
            eventTime,
          })
        : await post("RAK/" + RAK.id, { eventTime });
    taskCompletedToday("rak");
    const next =
      nextPage ??
      `/suggestions?bgCol=58C6AC%26title=Random Act Of Kindness%26eventType=RAK%26eventId=${newRecord.RAKRecord.id}`;
    history.push(`/feedback/rak?next=${next}`);
  };
  const toggleClicked = () => {
    setEditMode((current) => !current);
    setCustomRakText("");
    setRAK(null);
  };
  const textareaChanged = (event) =>
    setCustomRakText(event.target.value.slice(0, 200));
  const saveDisabled = editMode === true && customRakText.length === 0;
  const showAnother = () => {
    const next = rakStack[index + 1];
    if (next == null) {
      newRAK();
    } else {
      setRAK(next);
    }
  };
  const goBack = () => {
    setEditMode(false);
    const previous = rakStack[index - 1];
    setRAK(previous);
  };
  return (
    <PageRoot>
      <NavRoot>
        <NavTitle>Perform a random act of kindness</NavTitle>
        {editMode === true && <GetASuggestion onClick={toggleClicked} />}
        {editMode === false && <AddMore onClick={toggleClicked} />}
      </NavRoot>
      <PageContent>
        <FloatingBox>
          {editMode === false && <BoxTitle>{RAK?.kindness}</BoxTitle>}
          {editMode === false && (
            <SkipButton onClick={showAnother}>Show me another</SkipButton>
          )}
          {editMode === true && (
            <RakText
              ref={customRakRef}
              value={customRakText}
              onChange={textareaChanged}
            />
          )}
          {index !== 0 && editMode === false && (
            <SkipButton onClick={goBack}>
              <LeftChevron />
            </SkipButton>
          )}
          <SaveButton disabled={saveDisabled} onClick={saveRAK}>
            I'll do this one
          </SaveButton>
        </FloatingBox>
      </PageContent>
    </PageRoot>
  );
};

export default memo(RAK);
