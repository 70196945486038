import React, { memo } from 'react';
import { Btn } from './ResetButton.styled';

const Button = (props) => {
	const elm = props.elm;

	let styles = {};
	if (elm.style) {
		styles = elm.style;
	}

	return (
		<Btn onClick={props.resetCourse} style={styles}>{elm.content.text}</Btn>
	);
};

export default memo(Button);
