import styled from "styled-components";

export const TextArea = styled.textarea`
  width: 100%;
  min-height: ${(props) => {
    return props.minHeight ? props.minHeight : 20;
  }}px;
  border: #eee 1px solid;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  color: #222;
  font-size: 16px;
`;
