import React, { memo } from 'react';
import { RaNavContainer, RaTitle } from './RaNav.styled';
import Streaks from './Streaks/Streaks';

const RaNav = () => {
  return (
    <RaNavContainer>
      <RaTitle>Reflection Analytics</RaTitle>
      <Streaks />
      <div />
    </RaNavContainer>
  )
};

export default memo(RaNav);
