import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
	checkInItem as checkInItemPropType,
	checkInGroup as checkInGroupPropType,
} from "../../../propTypes";
import { HeadingP } from '../DailyCheckIn.styled';
import InfluenceSelector from './InfluenceSelector/InfluenceSelector';
import { ContentRoot  } from './InfluenceStep.styled';
import EmotionLevel from '../EmotionLevel/EmotionLevel';

const InfluenceStep = ({
	selectedEmotion,
	selectedInfluences,
	influenceGroups,
	resetEmotion,
	onInfluencesSelected,
	emotionGroup,
}) => {
	const influenceSelected = influence => onInfluencesSelected([influence, ...selectedInfluences]);
	const influenceDeselected = influence => onInfluencesSelected(
		selectedInfluences.filter(i => i.id !== influence.id),
	);

	return (
		<ContentRoot>
			<HeadingP>
				Why are you feeling <EmotionLevel scale={0.8} isSelected={false} onClick={resetEmotion} group={emotionGroup} />?
			</HeadingP>
			{influenceGroups.map(influenceGroup =>
				<InfluenceSelector
					key={influenceGroup.id}
					influenceGroup={influenceGroup}
					onInfluenceSelected={influenceSelected}
					onInfluenceDeselected={influenceDeselected}
					selectedInfluences={selectedInfluences}
					moodColour={selectedEmotion.colour}
				/>
			)}
		</ContentRoot>
	);
};

InfluenceStep.propTypes = {
	selectedEmotion: checkInItemPropType.isRequired,
	selectedInfluences: PropTypes.arrayOf(checkInItemPropType).isRequired,
	influenceGroups: PropTypes.arrayOf(checkInGroupPropType).isRequired,
	resetEmotion: PropTypes.func.isRequired,
	onInfluencesSelected: PropTypes.func.isRequired,
	emotionGroup: checkInGroupPropType.isRequired,
};

export default memo(InfluenceStep);
