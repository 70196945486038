import React, { useEffect, useState } from "react";
import { post, put } from "../../actions/api";
import { Btn } from "../../pages/Course/ElmGroups/Element/Button/Button.styled";
import {
  LabelLeft,
  LabelRight,
  Labels,
  Option,
  Options,
  OptionText,
} from "../../pages/Course/ElmGroups/Element/MCQ/MCQ.styled";
import {
  FeedbackWrapper,
  Question,
  Context,
  QuestionBinary,
} from "./FeedbackQuestion.styled";

export default ({ style, feedback, doCompleted }) => {
  const options = [1, 2, 3, 4, 5, 6];
  const [missingVals, setMissingVals] = useState(false);
  const [saveState, setSaveState] = useState(0);
  const [answers, setAnswers] = useState({
    id: null,
    k: null,
    a: null,
    s: null,
    o: null,
  });

  useEffect(() => {
    async function checkFeedback() {
      // now mark as due if there's not currently a response
      if (!feedback.feedbackResponses.filter((r) => !r.completed).length) {
        const response = await post("feedback/response", {
          feedbackId: feedback.id,
        });
        setAnswers({ ...answers, id: response.response.id });
      } else {
        setAnswers({
          ...answers,
          id: feedback.feedbackResponses.filter((r) => !r.completed)[0].id,
        });
      }
    }
    checkFeedback();
  }, []);

  const saveFeedback = async () => {
    if (
      (feedback.type === "kas" &&
        Object.entries(answers).find(
          (e) => ["k", "a", "s"].includes(e[0]) && e[1] === null
        )) ||
      (feedback.type === "binary" && answers.o === null)
    ) {
      return setMissingVals(true);
    } else {
      setSaveState(1);
      let done;
      if (answers.id) {
        done = await put(`feedback/response/${answers.id}`, {
          feedbackId: feedback.id,
          ...answers,
        });
      } else {
        done = await post("feedback/response", {
          feedbackId: feedback.id,
          ...answers,
        });
      }
      setSaveState(2);
      doCompleted();
    }
  };

  return (
    <FeedbackWrapper style={style}>
      {feedback.context && <Context>{feedback.context}</Context>}
      {feedback.type === "kas" ? (
        <>
          <Question>{feedback.k}</Question>
          <Options>
            {options.map((op, i) => (
              <Option
                key={op}
                first={!i}
                last={i === 5}
                selected={answers.k === op}
                onClick={() => {
                  setMissingVals(false);
                  setAnswers({ ...answers, k: op });
                }}
              >
                <OptionText>{op}</OptionText>
              </Option>
            ))}
          </Options>
          <Labels>
            <LabelLeft>Strongly disagree</LabelLeft>
            <LabelRight>Strongly agree</LabelRight>
          </Labels>
          <Question>{feedback.a}</Question>
          <Options>
            {options.map((op, i) => (
              <Option
                key={op}
                first={!i}
                last={i === 5}
                selected={answers.a === op}
                onClick={() => {
                  setMissingVals(false);
                  setAnswers({ ...answers, a: op });
                }}
              >
                <OptionText>{op}</OptionText>
              </Option>
            ))}
          </Options>
          <Labels>
            <LabelLeft>Strongly disagree</LabelLeft>
            <LabelRight>Strongly agree</LabelRight>
          </Labels>
          <Question>{feedback.s}</Question>
          <Options>
            {options.map((op, i) => (
              <Option
                key={op}
                first={!i}
                last={i === 5}
                selected={answers.s === op}
                onClick={() => {
                  setMissingVals(false);
                  setAnswers({ ...answers, s: op });
                }}
              >
                <OptionText>{op}</OptionText>
              </Option>
            ))}
          </Options>
          <Labels>
            <LabelLeft>Strongly disagree</LabelLeft>
            <LabelRight>Strongly agree</LabelRight>
          </Labels>
        </>
      ) : (
        <>
          <QuestionBinary>{feedback.o}</QuestionBinary>
          <Options>
            {[1, 0].map((op, i) => (
              <Option
                key={op}
                first={!i}
                last={i === 5}
                selected={answers.o === op}
                style={{ marginBottom: 50, marginTop: 40 }}
                onClick={() => {
                  setMissingVals(false);
                  setAnswers({ ...answers, o: op });
                }}
              >
                <OptionText>{op ? "Yes" : "No"}</OptionText>
              </Option>
            ))}
          </Options>
        </>
      )}
      <Btn
        style={{ marginTop: 30 }}
        onClick={saveFeedback}
        disabled={saveState > 0}
      >
        {["Submit", "Saving...", "Saved"][saveState]}
      </Btn>
      {missingVals === true && (
        <p style={{ color: "red", textAlign: "center" }}>
          Please answer all the questions before submitting
        </p>
      )}
    </FeedbackWrapper>
  );
};
