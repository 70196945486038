import React, { memo, useContext } from 'react';
import MobileDetect from 'mobile-detect';
import { ContentRoot } from './DownloadAppBanner.styled';
import WarningOutlined from '@ant-design/icons/lib/icons/WarningOutlined';
import { appStore, playStore } from '../../../constants/urls';
import { MainContext } from '../../../context/MainContext';

function getUrl() {
  const md = new MobileDetect(window.navigator.userAgent);
  const os = md.os();

  if (os === 'AndroidOS') {
    return playStore;
  }
  if (os === 'iOS') {
    return appStore;
  }
  return null;
}

const DownloadAppBanner = () => {
	const ctx = useContext(MainContext);

  const url = getUrl();

  if (url == null){
    // should not be possible, dont display banner
    return null;
  }

  const navigateTo = () => {
    window.location.href = url;
  }

	if(!ctx.user){
		return null;
	}

  return (
    <ContentRoot onClick={navigateTo}>
      <WarningOutlined />
      <h4>Get The App For Improved Performance</h4>
      <button onClick={navigateTo}>Download</button>
    </ContentRoot>
  )
};

export default memo(DownloadAppBanner);
