import styled from 'styled-components';

export const ContentRoot = styled.section`
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  margin: 0 1rem;
  padding: 1rem 0;
  &::-webkit-scrollbar {
   height: 0;
   background: transparent;
  }
  
  > * {
    transform: scale(0.8);
    /* &:not(:first-child) {
      transform: translateX(-70px) scale(0.8);
    } */
  }
  
  .nice-dates-navigation {
    width: 270px;
  }
  
  div.nice-dates-grid {
    height: 280px !important;
  }
  height: 275px;
  transform: translateY(-35px);
  margin-bottom: -20px;
`;
