import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number,
  colour: PropTypes.string.isRequired,
  termType: PropTypes.string.isRequired,
  order: PropTypes.number, // need to fix in data
  term: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  termGroupId: PropTypes.number.isRequired,
});
