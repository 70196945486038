import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
	width: 450px;
  /* margin-top: 50px; */
`;

export const Entry = styled.div`
  width: 100%;
`;

export const JournalInput = styled.textarea`
  border: #eee 1px solid;
  padding: 10px;
  width: 100%;
  min-height: 60px;
  border-radius: 5px;
  outline: none;
`;

export const PromptModalBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.5);
`;
export const PromptModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const PromptModal = styled.div`
  border-radius: 10px;
  max-width: 100%;
  max-height: 100%;
  background-color: white;
  width: 600px;
  height: 600px;
  box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.5);
  padding: 20px;
  overflow: auto;
`;

export const PromptOption = styled.div`
  cursor: pointer;
  margin: 0 0 5px 0;
  padding: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.primary.colours.grey_light2};
  }
`;

export const PromptSection = styled.div`
  cursor: pointer;
  flex-direction: row;
  display: flex;
  margin: 5px 0;
`;

export const PromptText = styled.p`
  margin-left: 10px;
`;
