import React from 'react';
import { api } from '../actions/api';

class Activate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			token: "",
			password: "",
			password2: "",
			pwError: "",
			pwDupeError: "",
			pwErrorMsg: "",
			pwDupeErrorMsg: "",
			formError: "",
			done: false
		};

	}

	componentDidMount() {
	}

	passwordChange = (e) => {
		const password = e.target.value;
		this.setState({ password });
		if (!this.pwStrength(password)) {
			this.setState({
				pwError: "is-danger",
				pwErrorMsg: "Please enter a stronger password",
			})
		} else {
			this.setState({
				pwError: "",
				formError: "",
				pwErrorMsg: "",
			})
		}
	}

	passwordRepeat = (e) => {
		const password2 = e.target.value;
		this.setState({ password2 });
		if (password2 !== this.state.password) {
			this.setState({
				pwDupeError: "is-danger",
				pwDupeErrorMsg: "Passwords don't match",
			})
		} else {
			this.setState({
				pwDupeError: "",
				pwDupeErrorMsg: "",
				formError: "",
			})
		}
	}

	pwStrength = (pw) => {
		if (pw.length < 6) {
			return false;
		}
		return true;
	}

	reset = (e) => {
		e.preventDefault();
		let t = this;

		let token = window.location.href.split("/");
		token = token[token.length - 1];

		if (
			this.state.pwError ||
			this.state.pwDupeError ||
			!this.pwStrength(this.state.password) ||
			(this.state.password2 !== this.state.password)
		) {
			this.setState({ formError: "is-danger" });
			console.log('form error');
			return false;
		}

		api("user/resetpassword", "post", { password: this.state.password, token }, (d) => {
			if (d.message === "password updated") {
				t.setState({ done: true })
			} else {
				t.setState({ formError: "is-danger" })
			}
		});
	}

	render() {


		const styles = {
			img: {
				width: "auto",
				height: 60
			},
			form: {
				maxWidth: 300,
			},
			account: {
				backgroundColor: "#eee",
				fontSize: 14,
				borderRadius: 5,
				display: "inline-block",
				padding: "5px 10px",
				color: "#136665"
			}
		}


		if (this.state.done) {
			return (
				<div style={{ padding: "30px 50px 50px 50px", backgroundColor: '#a2cdcd', display: "inline-block" }}>
					<h3><b>Success!</b></h3>
					<h5 style={{ marginTop: 20 }}>Your password has been reset</h5>
					<p style={{ marginTop: 20 }}>You can now login using your new credentials in the app</p>
				</div>
			)
		} else {
			return (
				<div style={{ padding: 20 }}>
					<h1 className='title'>Reset Your Password</h1>

					<p style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}>To reset the password on your account just enter a new password in the two inputs below. It should be at least 6 characters long and include both numbers and letters.</p>

					<form style={styles.form} onSubmit={this.reset}>
						<div className="field">
							<label className="label">Please choose a password</label>
							<div className="control">
								<input className={"input " + this.state.pwError} type="password" placeholder="Pick a password" onChange={this.passwordChange} />
							</div>
							<p className="help is-danger">{this.state.pwErrorMsg}</p>
						</div>
						<div className="field">
							<label className="label">Repeat password</label>
							<div className="control">
								<input className={"input " + this.state.pwDupeError} type="password" placeholder="Repeat your password" onChange={this.passwordRepeat} />
							</div>
							<p className="help is-danger">{this.state.pwDupeErrorMsg}</p>
						</div>

						<div className="control">
							<button className={"button is-primary is-pulled-right " + this.state.formError}>Reset Password</button>
						</div>
					</form>
					{/* <p>
					<img src={require("../../imgs/apple_app_store.png")} alt="iPhone app" style={styles.img} />
					<img src={require("../../imgs/android_app_store.png")} alt="Android app" style={styles.img} />
				</p> */}
				</div>
			);
		}
	}
}

export default Activate;
