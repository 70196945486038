import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { TPIcon, TPItem, TPTitle } from "./Items.styled";
export default function TextAndPlay({
  title,
  clickable,
  complete,
  onClick,
  style,
  icon,
}) {
	const useIcon = icon ?? "play";
  const iconStyle = {
    width: 32,
    height: 32,
    color: null,
    marginRight: 4,
  };
  iconStyle.color = complete ? "#136665" : clickable ? "#eee" : "#eee";

  const icons = {
    play: [
      <FontAwesomeIcon icon={faCheckCircle} style={iconStyle} />,
      <FontAwesomeIcon icon={faPlayCircle} style={iconStyle} />,
    ],
    tick: [
      <FontAwesomeIcon icon={faCheckCircle} style={iconStyle} />,
      <FontAwesomeIcon icon={faCheckCircle} style={iconStyle} />,
    ],
  };

  return (
    <TPItem clickable={clickable} onClick={onClick} style={style}>
      <TPTitle>{title}</TPTitle>
      <TPIcon>{complete ? icons[useIcon][0] : icons[useIcon][1]}</TPIcon>
    </TPItem>
  );
}
