import styled from 'styled-components';
import { TopNavContainer } from '../../../components/common';
import { NavTitle } from '../../../components/common';

export const RaNavContainer = styled(TopNavContainer)`
  position: static;
  > *:first-child {
    flex: 1;
  }
  
  > *:last-child {
    flex: 1;
  }
`;

export const RaTitle = styled(NavTitle)`
  text-align: left;
`;
