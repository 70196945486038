import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { checkInItem as emotionPropType } from '../../../../propTypes';
import { WrappedSelector, EmotionButton } from '../../../../components/common';

const EmotionSelector = ({ options, onClick, selectedEmotion }) => {
  const notEmpty = options.filter(option => option.term);
  return (
    <WrappedSelector>
      {notEmpty.map(opt => (
        <EmotionButton
          key={opt.term}
          emotion={opt}
          onClick={() => onClick(opt)}
          selected={selectedEmotion && selectedEmotion.termId === opt.id}
        />
      ))}
    </WrappedSelector>
  );
};

EmotionSelector.propTypes = {
  options: PropTypes.arrayOf(emotionPropType).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedEmotion: emotionPropType,
};

export default memo(EmotionSelector);
