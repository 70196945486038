import styled from 'styled-components';

export const IconContainer = styled.div`
  background-color: ${props => props.colour};
  color: white;
  border-radius: 14px;
  width: 28px;
  height: 28px;
  text-align: center;
  opacity: 0.9;
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: center;
  
  &:hover {
    cursor: pointer;
  }

  > * {
    font-size: 14px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.1rem;
`;
