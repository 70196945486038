const groupByDate = (events) =>
  groupBy(events, (event) => event.eventTime.format("YYMMDD"));
const groupByMonth = (events) =>
  groupBy(events, (event) => event.eventTime.format("YYMM"), sortDescending);
const groupByDay = (events) =>
  groupBy(events, (event) => event.eventTime.format("DD"));

const sortAscending = (e1, e2) =>
  e1.eventTime.valueOf() - e2.eventTime.valueOf();
const sortDescending = (e1, e2) =>
  e2.eventTime.valueOf() - e1.eventTime.valueOf();

function groupBy(events, keyGenerator, sort = sortAscending) {
  return events.reduce((acc, current) => {
    const key = keyGenerator(current);
    const newEvents = [...(acc[key] ?? []), current];
    const sorted = [...newEvents].sort(sort);
    return {
      ...acc,
      [key]: sorted,
    };
  }, {});
}

export function groupCourseEvents(events) {
  let courseEvents = {};
  events
    .filter((e) => e.eventType === "courseReq")
    .forEach((e) => {
      if (!courseEvents[e.courseId]) {
        courseEvents[e.courseId] = {
          eventType: "courseReqGroup",
          eventTime: e.eventTime,
          courseTitle: e.courseTitle,
          screenTitle: e.screenTitle,
          colour: e.colour,
          courseId: e.courseId,
          events: [e],
        };
      } else {
        courseEvents[e.courseId].events.push(e);
      }
    });

  return events
    .filter((e) => e.eventType !== "courseReq")
    .concat(Object.values(courseEvents));
}

export function buildEventMonthLookup(allEvents) {
  const byMonth = groupByMonth(allEvents);
  const entries = Object.entries(byMonth);
  const addDays = entries.map(([key, value]) => [key, groupByDay(value)]);
  return Object.fromEntries(addDays);
}

export function buildEventDayLookup(allEvents) {
  return groupByDate(allEvents);
}
