import React, { memo } from "react";
import {
  ButtonTitle,
  Container,
  FaceButtonContainer,
  FaceIcon,
} from "./FaceButton.styled";
const FaceButton = ({ saveClick, emotionGroups }) => {
	const groups = [...emotionGroups].reverse();
  return (
    <Container>
      <ButtonTitle>Select a mood to save</ButtonTitle>
      {groups.map((eg) => (
        <FaceButtonContainer
          onClick={(e) => {
            saveClick(
              e,
              eg.terms.find((t) => !t.term)
            );
          }}
        >
          <FaceIcon
            src={require("../EmotionLevel/" +
              eg.title.replace(" ", "_").toLowerCase() +
              ".svg")}
          />
        </FaceButtonContainer>
      ))}
    </Container>
  );
};

export default memo(FaceButton);
