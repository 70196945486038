import React from "react";
import "../../../css/LoginForm.css";
import { Redirect, withRouter } from "react-router-dom";
import Consumer from "../../context/MainContext";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
    };
  }

  formChange = (evt) => {
    const value = evt.target.value;
    this.setState({ [evt.target.name]: value });
  };

  render() {
    return (
      <Consumer>
        {(ctx) => {
          if (ctx.errors.login === "inactive account") {
            return <Redirect to={{ pathname: "/signup" }} />;
          } else if (ctx.user) {
            let redirect = "/";
            if (ctx.user.permissions) {
              if (this.props.location.state?.source) {
                redirect = this.props.location.state.source;
              } else if (ctx.user.permissions.ENG) {
                redirect = "/reports/overview";
              } else if (ctx.user.permissions.WDS) {
                redirect = "/reports/wellbeing-data-system";
              } else if (ctx.user.permissions.DC) {
                redirect = "/reports/checkins";
              }
            }
            return <Redirect to={{ pathname: redirect }} />;
          }
          return (
            <form
              onSubmit={(e) =>
                ctx.login(e, this.state.email, this.state.password)
              }
              className="has-background-white tile is-vertical is-4 box container"
              style={{ marginTop: 20 }}
            >
              <div className="field">
                <label className="label">Email</label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    className="input"
                    type="email"
                    name="email"
                    placeholder="Email address"
                    onChange={this.formChange}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span>
                </div>
              </div>
              <div className="field">
                <p className="control has-icons-left">
                  <input
                    className="input"
                    type="password"
                    name="password"
                    placeholder="Password"
                    onChange={this.formChange}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-lock"></i>
                  </span>
                </p>
              </div>
              <div className="field">
                <div className={"tag is-danger "}>{ctx.errors.login}</div>
              </div>
              <button className="button is-primary">Log In</button>
              <div className="field" style={{ marginTop: 20 }}>
                <a href="/forgotpassword">Forgot your password?</a>
                <a href="/signup" className="is-pulled-right">
                  Activate your account
                </a>
              </div>
            </form>
          );
        }}
      </Consumer>
    );
  }
}

export default withRouter(Login);
