import React, { memo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {} from "./Gratitude.styled";
import moment from "moment";
import TickItem from "../TickItem/TickItem";
const Gratitude = ({ firstScreenInteraction, elm }) => {
  const history = useHistory();
  const location = useLocation();
	
  function clicked() {
    if (!elm.courseElmInteractions.length) {
      history.push(
        `/gratitude?date=${
          firstScreenInteraction
            ? moment(firstScreenInteraction).format("YYMMDD")
            : moment().format("YYMMDD")
        }&next=${location.pathname}`
      );
    } else {
      history.push(
        "/calendar-detail?selectedDate=" +
          moment(elm.courseElmInteractions[0].createdAt).format("YYMMDD")
      );
    }
  }

  return (
    <TickItem
      onClick={clicked}
      label="Record 3 Things You're Grateful For"
      complete={elm.courseElmInteractions.length}
    />
  );
};

export default memo(Gratitude);
