import styled from 'styled-components';

export const ButtonRoot = styled.button`
  display: flex;
  flex-direction: column;
  width: 4.5rem;
  background: none;
  align-items: center;
  padding: 0;
  position: relative;
`;

const notSelectedFilter =
  'invert(90%) sepia(13%) saturate(307%) hue-rotate(180deg) brightness(90%) contrast(97%)';

const selectedFilter =
  'invert(100%) sepia(100%) saturate(1%) hue-rotate(303deg) brightness(103%) contrast(101%)';


// filter colour #C6D2E1
export const Image = styled.img`
  filter: ${props => props.isSelected ? selectedFilter : notSelectedFilter};
`;

export const TermText = styled.span`
  box-sizing: border-box;
  margin: 0.2rem -1rem;
  color: ${({ theme }) => theme.primary.colours.heading};
`;

export const ImageContainer = styled.div`
  background-color: #f5f6f9;
  padding: 1rem;
  border-radius: 3rem;
  
  &:hover {
    opacity: 0.65;
  }
  
  ${props => props.isSelected ? `background-color: ${props.selectedColour}` : ''}
`;
