import styled, { css } from "styled-components";
import ContentBoxCommon from "../../ContentBox/ContentBox";

export const Date = styled.div`
  margin: 1rem;
  text-align: center;
  flex: 1;
  > span {
    font-size: 14px;
    color: ${({ theme }) => theme.primary.calendar.dayTitleColour};
    border-radius: 20px;
    border: 1px solid ${({ theme }) => theme.primary.calendar.dayTitleBorder};
    padding: 0.8rem;
  }
`;

const lineBase = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding: 0.5rem 0;
`;

export const TitleLine = styled.div`
  justify-content: space-between;
  > div {
    display: flex;
  }
  width: 100%;
  ${lineBase}
`;

export const DayLine = styled.div`
  justify-content: flex-start;
  ${lineBase}
`;

export const ContentBox = styled(ContentBoxCommon)`
  > section {
    min-width: 400px !important;
  }
`;

export const SectionContainer = styled.div`
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid
    ${(props) => (props.seperator ? "#D8D8D8" : "transparent")};
`;

export const DayContainer = styled.div`
  margin: 0 0.8rem;
  max-width: 100%;
  width: 600px;
`;

export const Title = styled.h5`
  color: ${(props) => props.colour};
  margin: 0.6rem 0;
  font-weight: bolder;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.primary.colours.heading};
`;

export const SectionMarker = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ colour }) => colour};
  display: inline-block;
  vertical-align: middle;
`;

export const SectionTitleText = styled.span`
  margin-left: 10px;
  // max-width: 180px;
  width: calc(100% - 100px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
`;

export const SectionSubTitleText = styled.span`
  width: calc(100% - 150px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
