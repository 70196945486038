import React, { memo, useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import {
  PageContent,
  FloatingBox,
  Tabs,
  Tab,
  CatTitle,
  CatIntro,
  Body,
  NoteButton,
  BoxTop,
  Title,
} from "./Notes.styled";
import { get, post, put } from "../../actions/api";
import { PageRoot } from "../../components/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NoteTypes = {
  me: {
    title: "Me",
    colour: "#407cb4",
    categories: [
      {
        id: "purpose",
        menuTitle: "Purpose",
        textIntro: "Why I am here...",
        placeholder: "Type your Purpose here",
      },
      {
        id: "values",
        menuTitle: "Values",
        textIntro: "Who I am…",
        placeholder: "I am… Type your Values here",
      },
      {
        id: "beliefs",
        menuTitle: "Beliefs",
        textIntro: "The beliefs I hold…",
        placeholder: "Type your Helpful & Fundamental Beliefs here",
      },
    ],
  },
  gainsAndDrains: {
    title: "Gains & Drains",
    colour: "#407cb4",
    categories: [
      {
        id: "gains",
        menuTitle: "Gains",
        textIntro: "These keep me aligned...",
        placeholder: "Type the things that boost your vitality here",
      },
      {
        id: "drains",
        menuTitle: "Drains",
        textIntro: "These pull me off center…",
        placeholder: "Type the things that erode your vitality here",
      },
    ],
  },
};

const Notes = () => {
  const editorRef = useRef(null);
  const { type } = useParams();
  const history = useHistory();
  const [record, setRecord] = useState({ note: {} });
  const [selectedTab, setSelectedTab] = useState(0);
  const metaData = NoteTypes[type];
  const [showTick, setShowTick] = useState(false);

  useEffect(() => {
    get(`note/${type}`).then((res) => {
      console.log(res);
      setRecord(res);
    });
  }, []);

  const updateNote = (value) => {
    setRecord({ ...record, note: { ...record.note, [category.id]: value } });
  };

  const save = () => {
    setShowTick(false);
    put("note/" + record.id, record);
  };

  if (!metaData) return null;
  const { title, colour, categories } = metaData;
  const category = categories[selectedTab];
  // console.log(record.note,category.id);
  return (
    <PageRoot>
      <PageContent>
        <FloatingBox>
          <BoxTop>
            <NoteButton>
              {showTick ? (
                <FontAwesomeIcon icon="fa-solid fa-check" />
              ) : (
                <FontAwesomeIcon
                  icon="fa-solid fa-chevron-left"
                  onMouseDown={() => !showTick && history.push("/")}
                />
              )}
            </NoteButton>
            <Title>{title}</Title>
          </BoxTop>
          <Tabs>
            {categories.map((cat, i) => {
              return (
                <Tab
                  key={cat.id}
                  selected={i === selectedTab}
                  onClick={() => setSelectedTab(i)}
                >
                  <CatTitle>{cat.menuTitle}</CatTitle>
                </Tab>
              );
            })}
          </Tabs>
          <Body>
            <CatIntro>{category.textIntro}</CatIntro>
            <Editor
              apiKey="yuefowyo6k9jbq5hdssy0k9rws05kzjinpx6tudtx0tz3uwa"
              onInit={(_evt, editor) => (editorRef.current = editor)}
              value={record.note[category.id]}
              onEditorChange={updateNote}
              onFocus={() => setShowTick(true)}
              onBlur={save}
              init={{
                height: 500,
                menubar: false,
                statusbar: false,
                plugins: "lists",
                toolbar: "undo redo bold italic numlist bullist",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </Body>
        </FloatingBox>
      </PageContent>
    </PageRoot>
  );
};

export default memo(Notes);
