export function transformResponse({ surveyGroups }) {
	const {
		complete: completeGroup,
		incomplete: incompleteGroup,
	} = surveyGroups;

	const complete = completeGroup.map(s => s.surveys);
	const incomplete = incompleteGroup.flatMap(s => s.surveys);
	const incompletePerGroup = incompleteGroup.map(s => s.surveys.find(s => !s.complete)).filter(group => group !== undefined);
	const incompleteDuePerGroup = incompleteGroup.map(s => s.surveys.find(s => !s.complete && s.due)).filter(group => group !== undefined);
	return [complete, incomplete, incompletePerGroup, incompleteDuePerGroup ?? []];
}
