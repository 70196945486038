import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  ContentRoot,
  TitleContent,
  Body,
} from './ContentBox.styled';

const ContentBox = ({ titleContent, body }) => {
  return (
    <ContentRoot>
      <TitleContent>{titleContent}</TitleContent>
      {body == null ? null : <Body>{body}</Body>}
    </ContentRoot>
  );
};

ContentBox.propTypes = {
  titleContent: PropTypes.node.isRequired,
  body: PropTypes.node,
};

export default memo(ContentBox);
