import React, { memo, useContext, useEffect, useState } from "react";
import {
  Bottom,
  BottomTitle,
  DurationInput,
  HabitList,
  Inner,
  PageContent,
  Title,
  Top,
  DeleteButton,
  Or,
  SubTitle,
	InnerTopAlign,
} from "./HabitTracker.styled";
import { get, post, put } from "../../actions/api";
import { ButtonBlue, ButtonWhite, PageRoot } from "../../components/common";
import { habitActivities } from "./habitActivities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import HabitViewHeader from "./HabitViewHeader";
import ProgressBar from "./ProgressBar";
import Complete from "./Complete";
import HabitDay from "./HabitDay";
import { MainContext } from "../../context/MainContext";
import theme from "../../theme";
import Activity from "./Activity";

const ViewHabitTracker = () => {
  const history = useHistory();
  const ctx = useContext(MainContext);

  const { id, habitDayId } = useParams();
  const [habitTemplate, setHabitTemplate] = useState(null);
  const [dayIndex, setDayIndex] = useState(null);
  const [currentDayIndex, setCurrentDayIndex] = useState(0);
  const [percentComplete, setPercentComplete] = useState(0);
  const [showIndex, setShowIndex] = useState(false);
  const [complete, setComplete] = useState(false);

  const calcProgess = (template, i) => {
    if (!template.habitDays[i]) {
      return 0;
    }
    const activities = template.habitDays[i].activities;
    // calcyulate the percentage of completed activities
    const completedActivities = activities.filter((a) => a.completedOn);
    const percentComplete =
      (completedActivities.length / activities.length) * 100;
    setPercentComplete(percentComplete);
  };

  useEffect(() => {
    async function getTemplate() {
      const template = await get(`habit/${id}`);
      if (template) {
        setHabitTemplate(template);

        const i = template.habitDays.findIndex((d) =>
          moment(d.dueOn).isSame(moment(), "day")
        );

        calcProgess(template, dayIndex !== null ? dayIndex : i);
        if (dayIndex === null) {
          if (habitDayId) {
            const selectedDayIndex = template.habitDays.findIndex(
              (d) => d.id === habitDayId
            );
            setDayIndex(selectedDayIndex);
          } else {
            setDayIndex(i);
          }
          setCurrentDayIndex(i);
        }

        if (
          moment(
            template.habitDays[template.habitDays.length - 1].dueOn
          ).isBefore(moment(), "day")
        ) {
          setComplete(true);
        }
      }
    }
    getTemplate();
  }, [id]);

  const archive = () => {
    put(`habit/${id}`, {
      completed: true,
    });
    ctx.updateHabitTrackerTemplateCache({ ...habitTemplate, completed: true });
    ctx.changeView("BottomTabNavigator");
  };

  const handlePress = async (index) => {
    const day = habitTemplate.habitDays[dayIndex];
    const activity = day.activities[index];

    // if (habitActivities[activity.type] && !activity.completedOn) {
    //   ctx.changeView(habitActivities[activity.type].view, {
    //     ...habitActivities[activity.type].viewData,
    //     next: "HabitTrackerView",
    //     nextData: { id },
    //   });
    // }

    // if (
    //   !habitActivities[activity.type] ||
    //   habitActivities[activity.type].completeonClick
    // ) {
    //   ctx.completeHabitActivity({
    //     index: {
    //       template: ctx.habitTrackerTemplates.findIndex(
    //         (t) => t.id === id
    //       ),
    //       day: dayIndex,
    //     },
    //     activities: day.activities.map((a, j) => {
    //       if (j === index) {
    //         return {
    //           ...a,
    //           completedOn: activity.completedOn ? null : new Date(),
    //         };
    //       } else {
    //         return a;
    //       }
    //     }),
    //   });
    //   calcProgess();
    // }
  };

  // console.log("habitTemplate", habitTemplate);
  // console.log("dayIndex", dayIndex);

  return habitTemplate && dayIndex !== null ? (
    <PageRoot>
      <PageContent>
        <InnerTopAlign>
          <HabitViewHeader
            showBack={false}
            title={
              !habitTemplate
                ? ""
                : showIndex || complete
                ? `${habitTemplate.duration} Day Challenge`
                : `Day ${dayIndex + 1}`
            }
            id={habitTemplate ? habitTemplate.id : null}
            showIndex={showIndex}
            setShowIndex={setShowIndex}
            complete={complete}
          />
          {habitTemplate ? (
            <>
              {!showIndex && !complete && (
                <ProgressBar
                  percentComplete={percentComplete}
                  style={{ paddingHorizontal: 20 }}
                />
              )}
              <div
                style={{
                  width: "100%",
                  marginBottom: percentComplete === 100 ? 100 : 0,
                  paddingHorizontal: 20,
                  paddingBottom: 20,
                }}
              >
                {complete ? (
                  <Complete habitTemplate={habitTemplate} />
                ) : showIndex ? (
                  habitTemplate.habitDays.map((day, i) => (
                    <HabitDay
                      key={i}
                      index={i}
                      day={day}
                      setDayIndex={setDayIndex}
                      setShowIndex={setShowIndex}
                    />
                  ))
                ) : (
                  habitTemplate.habitDays[dayIndex].activities.map(
                    (activity, i) => (
                      <Activity
                        key={i}
                        activity={activity}
                        handlePress={() => handlePress(i)}
                        isCurrentDay={dayIndex === currentDayIndex}
                      />
                    )
                  )
                )}
              </div>
              {percentComplete === 100 && (
                <ButtonWhite
                  onClick={() => ctx.changeView("BottomTabNavigator")}
                  style={styles.doneButton}
                >
                  Done
                </ButtonWhite>
              )}
            </>
          ) : (
            <div>Loading...</div>
          )}
          {complete && (
            <ButtonWhite style={styles.doneButton} onClick={archive}>
              Finish Challenge
            </ButtonWhite>
          )}
        </InnerTopAlign>
      </PageContent>
    </PageRoot>
  ) : (
    <div>LOADING...</div>
  );
};

const styles = {
  wrapper: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme.primary.colours.greenBright,
  },
  body: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    backgroundColor: "white",
    paddingVertical: 20,
  },
  doneButton: {
    position: "absolute",
    bottom: 20,
    paddingHorizontal: 100,
  },
};

export default memo(ViewHabitTracker);
