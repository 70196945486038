import { useEffect, useState } from "react";
import moment from "moment";
import { get } from "../actions/api";
import {
  getDailyTasks,
  updateDailyTasks,
} from "../helpers/dailyCompletionStorageHelper";

function homeDataInitialised(current) {
  return Object.values(current).every((v) => v != null);
}

export default function useHomeData() {
  const initialDailyCompletion = {
    rak: null,
    gratitude: null,
    checkin: null,
    breathe: null,
  };
  const initialShowInToday = {
    rak: false,
    gratitude: false,
    breathe: false,
  };
  const [dailyCompletion, setDailyCompletion] = useState(
    initialDailyCompletion
  );
  const [showInToday, setShowInToday] = useState(initialShowInToday);
  const today = moment().startOf("day");

  useEffect(() => {
    async function getCompletionData() {
      const localCompletions = getDailyTasks();
      const doneToday = (data) =>
        data.some((r) => moment(r.eventTime).isSame(today, "day"));

      const [rak, checkin, gratitude, breathe] = await Promise.all([
        localCompletions.rak ||
          get("RAK/records").then((d) => doneToday(d.RAKs)),
        localCompletions.checkin ||
          get("checkin").then((d) => doneToday(d.checkins)),
        localCompletions.gratitude ||
          get("gratitude").then((d) => doneToday(d.gratitudes)),
        localCompletions.breathe ||
          get("event?type=breathe").then((d) => doneToday(d.events)),
      ]);

      const completions = { rak, checkin, gratitude, breathe };
      updateDailyTasks(completions);
      setDailyCompletion((d) => ({ ...d, ...completions }));
    }

    async function getShowInTodayData() {
      const todayFeedData = await get("todayfeed");
			
      let show = { ...initialShowInToday };
			todayFeedData.settings.forEach((s) => {
				if(s.itemType!=="course"){
					show[s.itemType] = s.show;
				}
			});
      setShowInToday(show);
    }

    getCompletionData();
    getShowInTodayData();
  }, []);

  return {
    loadingComplete:
      homeDataInitialised(dailyCompletion) === true &&
      homeDataInitialised(showInToday) === true,
    dailyCompletion,
    showInToday,
  };
}
