import React, { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { PageRoot, MonthSelector, Days } from "../../components/common";
import {
  GridTwoRow,
  GridThreeRow,
  DateRow,
  ContentRoot,
} from "./ReflectionAnalytics.styled";
import { useReportData } from "../../hooks";
import MoodCount from "./MoodCount/MoodCount";
import PositiveInfluences from "./PositiveInfluences/PositiveInfluences";
import NegativeInfluences from "./NegativeInfluences/NegativeInfluences";
import EmotionCount from "./EmotionCount/EmotionCount";
import InfluenceCount from "./InfluenceCount/InfluenceCount";
import RaNav from "./RaNav/RaNav";
import RelatesToHeader from "./RelatesToHeader/RelatesToHeader";
import NoData from "./NoData/NoData";
import { deleteEvent as deleteEventApi } from "../../helpers/eventHelpers";
import Ranking from "./Ranking/Ranking";

const all = "all";
const defaultFilter = { value: all, index: null };

const ReflectionAnalytics = () => {
  const history = useHistory();
  const initialMonth = moment().startOf("month");
  const [startMonth, setStartMonth] = useState(initialMonth);
  const [stateFilter, setStateFilter] = useState(defaultFilter);
  const [stateGroupFilter, setStateGroupFilter] = useState(defaultFilter);
  const [influenceFilter, setInfluenceFilter] = useState(defaultFilter);
  const [deletedEventKeys, setDeletedEventKeys] = useState([]);

  const endMonth = startMonth.clone().endOf("month");
  const {
    positiveInfluenceTotals,
    negativeInfluenceTotals,
    influenceTotals,
    emotionTotals,
    stateGroupTotals,
    checkIns,
  } = useReportData({
    fromDate: startMonth,
    toDate: endMonth,
    state: stateFilter.value,
    stateGroup: stateGroupFilter.value,
    influence: influenceFilter.value,
    deletedEventKeys,
  });

  const setActions = {
    state: (filter) => setStateFilter(filter),
    stateGroup: (filter) => setStateGroupFilter(filter),
    influence: (filter) => setInfluenceFilter(filter),
  };

  const removeActions = {
    state: () => setStateFilter(defaultFilter),
    stateGroup: () => setStateGroupFilter(defaultFilter),
    influence: () => setInfluenceFilter(defaultFilter),
  };

  const filters = [
    { ...stateFilter, type: "state" },
    { ...stateGroupFilter, type: "stateGroup" },
    { ...influenceFilter, type: "influence" },
  ].filter((f) => f.value !== all);
  const hasFilter = filters.length > 0;

  const removeFilter = (filter) => {
    const { index } = filter;
    const laterFilters = filters.filter((f) => f.index > index);
    for (const filter of laterFilters) {
      const action = removeActions[filter.type];
      action();
    }
  };

  const filterSelected = (filter, type) => {
    const setter = setActions[type];

    const currentIndexes = filters.map((x) => x.index);
    const nextIndex =
      currentIndexes.length === 0 ? 0 : Math.max(...currentIndexes) + 1;

    setter({ index: nextIndex, value: filter.term });
  };

  const resetFilters = () => {
    Object.values(removeActions).forEach((action) => action());
  };

  const reset = () => {
    resetFilters();
  };

  const deleteEvent = (event) => {
    // todo deal with error
    deleteEventApi(event);
    setDeletedEventKeys((keys) => [...keys, event.key]);
  };

  const editEvent = (event) => {
    history.push(`daily-reflection/${event.id}`);
  };

  const allCheckIns = Object.keys(checkIns);
  const lastCheckIn = allCheckIns[allCheckIns.length - 1];
  const defaultSelectedDate = moment(lastCheckIn, "YYMMDD");
  const noDataToDisplay = stateGroupTotals.data.every((x) => x.total === 0);
  return (
    <PageRoot>
      <RaNav />
      <Ranking />
      <ContentRoot>
        {hasFilter === false && (
          <DateRow>
            <MonthSelector
              startMonth={startMonth}
              monthChanged={setStartMonth}
            />
          </DateRow>
        )}
        {noDataToDisplay === false && (
          <>
            {hasFilter === true && (
              <RelatesToHeader
                month={startMonth}
                filters={filters}
                removeFilter={removeFilter}
                onBackClick={reset}
              />
            )}
            <GridThreeRow>
              <MoodCount
                totals={stateGroupTotals}
                filterSelected={filterSelected}
              />
              {hasFilter === false && (
                <PositiveInfluences
                  influences={positiveInfluenceTotals}
                  filterSelected={filterSelected}
                />
              )}
              {hasFilter === false && (
                <NegativeInfluences
                  influences={negativeInfluenceTotals}
                  filterSelected={filterSelected}
                />
              )}
            </GridThreeRow>
            {emotionTotals.filter((e) => e.term).length > 0 ||
              (influenceFilter.value === all && influenceTotals.length > 0 && (
                <GridTwoRow>
                  {emotionTotals.filter((e) => e.term).length > 0 && (
                    <EmotionCount
                      emotions={emotionTotals}
                      filterSelected={filterSelected}
                    />
                  )}

                  {influenceFilter.value === all &&
                    influenceTotals.length > 0 && (
                      <InfluenceCount
                        influences={influenceTotals}
                        filterSelected={filterSelected}
                      />
                    )}
                </GridTwoRow>
              ))}
            <div>
              {hasFilter === true && (
                <Days
                  days={Object.keys(checkIns)}
                  eventDayLookup={checkIns}
                  selectedDate={defaultSelectedDate}
                  daysToDisplay={2}
                  reverseDays={true}
                  deleteEvent={deleteEvent}
                  editEvent={editEvent}
                />
              )}
            </div>
          </>
        )}
        {noDataToDisplay === true && <NoData />}
      </ContentRoot>
    </PageRoot>
  );
};

export default memo(ReflectionAnalytics);
