import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Input, Wrapper, Label } from './InputBoxOutlined.styled';

const InputBoxOutlined = ({ title, placeholder, value, onChange }) => {
	const onChangeLocal = event => onChange(event.target.value);

	const body = <Input type="text" value={value} placeholder={placeholder} onChange={onChangeLocal} />;
	return (
		<Wrapper>
			<Label>{title}</Label>
			{body}
		</Wrapper>
	);
};

InputBoxOutlined.propTypes = {
	title: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
}

export default memo(InputBoxOutlined);
