import React, { memo } from 'react';
import { EmotionsContainer } from './EmotionBody.styled';
import { EmotionButton } from '../../../components/common';

const EmotionBody = ({ emotions, filterSelected }) => {
  return (
    <EmotionsContainer>
      {emotions.map(e => (
        <EmotionButton
          key={e.term}
          emotion={e}
          badge={e.total}
          onClick={() => filterSelected(e, 'state')}
        />
      ))}
    </EmotionsContainer>
  );
};

export default memo(EmotionBody);
