import styled from 'styled-components';
import { TopNav } from '../../components/common';

export const PageContent = styled.div`
	flex: 1 1 auto;
	display: flex;
  align-items: center;
  justify-content: flex-start;
	padding-top: 3rem;
	flex-direction: column;
	position: relative;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	padding-bottom: 50px;
`;

export const Heading = styled.h1`
	font-size: 2rem;
	color: ${({ theme }) => theme.primary.colours.heading};
	margin-bottom: 30px;

`;

export const NavRoot = styled(TopNav)`
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 1rem;
  align-items: center;
`;

export const Description = styled.div`
	font-size: 1rem;
`;


export const BackButton = styled.span`
	color: ${({ theme }) => theme.primary.colours.heading};
	position: fixed;
	top: 150px;
	left: 280px;
	font-size: 1rem;
	cursor: pointer;
	z-index:100;
`;
