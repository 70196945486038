import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

export const WrappedSelector = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;

  > * {
    margin: 0.3rem 0.3rem;
  }
`;

export const TitleText = styled.h5`
  font-weight: bold;
  color: ${({ theme }) => theme.primary.colours.heading};
`;

export const H2 = styled.h2`
  font-weight: bold;
  color: ${({ theme }) => theme.primary.colours.heading};
  font-size: 24px;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;
  margin: 3rem 0;
`;

export const PageRoot = styled.main`
  width: 100%;
  min-height: 100%;
  background-color: ${({ theme }) => theme.primary.colours.pageBackground};
  display: flex;
  flex-direction: column;
  flex-direction: flex-start;
  align-items: center;
`;

export const TopNav = styled.nav`
  height: 8rem;
  align-self: stretch;
  background-color: white;
  flex: 0 0 5rem;
  padding: 0 3rem;
  position: sticky;
  top: 0;
  z-index: 10;
  user-select: none;
`;

export const NavRoot = styled(TopNav)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavTitle = styled.h3`
  color: ${({ theme }) => theme.primary.colours.heading};
  font-size: ${({ theme }) => theme.primary.fontSize.secondaryHeading};
  font-weight: bold;
  text-align: center;
  margin-top: 0.5rem;
`;

export const TopNavContainer = styled(TopNav)`
  display: flex;
  flow-direction: flex-start;
  align-items: center;
  border-bottom: ${({ theme }) => theme.primary.colours.pageBackground} 2px
    solid;
  background-color: white;
`;

export const Badge = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  color: white;
  background-color: #263b59;
  right: ${(props) => props.right ?? 0};
  top: ${(props) => props.top ?? 0};
  top: 0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  font-size: 12px;
`;

export const ButtonWhite = styled.button`
  padding: 10px 30px;
  border-radius: 1.5rem;
  color: ${({ theme }) => theme.primary.colours.blueD};
  font-size: 1.2rem;
  background-color: white;
  margin: 5px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;
export const ButtonBlue = styled.button`
  padding: 10px 30px;
  border-radius: 1.5rem;
  color: white;
  font-size: 1.2rem;
  background-color: ${({ theme }) => theme.primary.colours.blueD};
  margin: 5px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const getColour = ({ theme, isPrimaryAction }) =>
  isPrimaryAction
    ? theme.primary.colours.primaryAction
    : theme.primary.colours.heading;

export const ActionButton = styled.button`
  background-color: ${getColour};
  color: white;
  width: 12rem;
  padding: 1rem;
  border-radius: 3rem;
  font-size: 18px;
  font-weight: bold;
  justify-self: flex-end;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export const ActionButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
  text-align: center;
  padding: 10px 0;
`;

export const ButtonOutline = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.primary.colours.blueD};
  border: ${({ theme }) => theme.primary.colours.blueD} 1px solid;
  flex-direction: column;
  display: flex;
  padding: 6px 15px;
  margin: 5px;
  border-radius: 30px;
`;

ActionButton.defaultProps = {
  visible: true,
};

export const ShadowBox = styled.div`
  color: #263b59;
  background-color: #fff;
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 10%);
  float: left;
  position: relative;
  border-radius: 20px;
  padding: 20px;
  flex: 1;
`;

const BackButtonContainer = styled.span`
  position: absolute;
  top: 25px;
  left: 20px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
`;

export const BackButton = ({ onClick, style }) => (
  <BackButtonContainer onClick={onClick} style={style}>
    <FontAwesomeIcon style={{ marginRight: 5 }} icon={faChevronLeft} /> Back
  </BackButtonContainer>
);
