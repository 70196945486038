import React from 'react';
import '../../../css/ControlPanel.css';

class Settings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (
			null
		);
	}
}

export default Settings;
