import styled from "styled-components";

export const Btn = styled.button`
  padding: 10px 50px;
  border-radius: 1.25em;
  color: white;
  font-size: 1.2em;
  background-color: ${({ theme, disabled }) =>
    disabled ? "#bbb" : theme.primary.colours.blueMD};
  margin: 5px;
  &:hover {
    filter: brightness(120%);
  }
`;
