import React, { memo } from 'react';
import { ContentBox } from '../../../components/common';
import { PositiveInfluencesTitle } from '../ReflectionAnalytics.styled';
import InfluenceBody from '../InfluenceBody/InfluenceBody';

const PositiveInfluences = ({ influences, filterSelected }) => {
  const getBadge = influence => influence.positive;

  const body = (
    <InfluenceBody influences={influences} getBadge={getBadge} filterSelected={filterSelected} />
  );

  return (
    <ContentBox
      titleContent={<PositiveInfluencesTitle />}
      body={body}
    />
  );
};

export default memo(PositiveInfluences);
