import React,{ useEffect, useState } from "react";

const Complete = ({ habitTemplate }) => {
  const [totalActivities, setTotalActivities] = useState(0);
  const [completedActivities, setCompletedActivities] = useState(0);

  useEffect(() => {
    if (habitTemplate) {
      calcProgess(habitTemplate);
    }
  }, [habitTemplate.id]);

  // calculate percentage of temaplate.[habitDay].[activities] are complete
  const calcProgess = (habitTemplate) => {
    const totalActivities = habitTemplate.habitDays.reduce(
      (acc, day) => acc + day.activities.length,
      0
    );
    const completedActivities = habitTemplate.habitDays.reduce(
      (acc, day) =>
        acc +
        day.activities.reduce(
          (acc, activity) => (activity.completedOn ? acc + 1 : acc),
          0
        ),
      0
    );
    setCompletedActivities(completedActivities);
    setTotalActivities(totalActivities);
  };
  return (
    <div style={styles.wrapper}>
      {/* <img
        style={{ width: 200, height: 200 }}
        source={}
      /> */}
      <div style={styles.title}>Congratulations!</div>
      <div style={styles.subtitle}>You completed</div>
      <div style={styles.percent}>
        {Math.round((completedActivities / totalActivities) * 100)}%
      </div>
      <div style={styles.subtitle}>
        of your activities
      </div>
      <div style={styles.subtitle}></div>
    </div>
  );
};

const styles = {
  wrapper: {
    width: "100%",
    marginTop: 20,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 30,
  },
  title: {
    fontSize: 30,
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    marginVertical: 20,
  },
  subtitle: {
    fontSize: 22,
    color: "white",
    textAlign: "center",
  },
  percent: {
    fontSize: 24,
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
};

export default Complete;
