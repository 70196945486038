import React, { memo, useEffect, useState } from 'react';
import { get } from '../../../../actions/api';
import { NavTile, StreakTiles } from './Streaks.styled';

const initialData = {
  checkinsTotal: '',
  current: '',
  pb: '',
};

const Streaks = () => {
  const [streakData, setStreakData] = useState(initialData);
  useEffect(() => {
    async function getData() {
      const { streaks } = await get('reports/streaks');
      const data = {
        checkinsTotal: String(streaks.checkinsTotal),
        eventTotal: String(streaks.eventTotal),
        current: String(streaks.current),
        pb: String(streaks.pb),
      };
      setStreakData(data);
    }
    getData();
  }, []);

  return (
    <StreakTiles>
      <NavTile>
        <span>{streakData.eventTotal}</span>
        <span>Actions</span>
      </NavTile>
      <NavTile>
        <span>{streakData.current}</span>
        <span>Days In a Row</span>
      </NavTile>
      <NavTile>
        <span>{streakData.pb}</span>
        <span>Longest Streak</span>
      </NavTile>
    </StreakTiles>
  );
};

export default memo(Streaks);
