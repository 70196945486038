import React from 'react';

class Nothing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};

	}

	render() {
		return (
			<div style={{ padding: 20 }}>
				<h1 className='title'>Nothing to show</h1>
				<p>Nothing to see here at the moment I'm afraid!</p>
			</div>
		);
	}
}

export default Nothing;
