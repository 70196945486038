import PropTypes from 'prop-types';
import questionnaire from "./questionnaire";

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  complete: PropTypes.bool.isRequired,
  due: PropTypes.bool.isRequired,
  questionnaire: questionnaire.isRequired,
  // more to add here...
});
