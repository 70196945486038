import React, { memo } from "react";
import PropTypes from "prop-types";
import { checkInGroup as checkInGroupPropType } from "../../../propTypes";

import veryNegative from "./very_negative.svg";
import negative from "./negative.svg";
import ok from "./ok.svg";
import positive from "./positive.svg";
import veryPositive from "./very_positive.svg";
import { Face, Button } from "./EmotionLevel.styled";

const imageMap = {
  1: veryPositive,
  2: positive,
  3: ok,
  4: negative,
  5: veryNegative,
};

const EmotionLevel = ({
  group,
  onClick,
  isSelected,
  scale,
  stageGroupMode,
  checkinMode,
}) => {
  const { id, title, disabled } = group;
  const image = imageMap[id];
  return (
    <Button
      onClick={() =>
        (checkinMode === "positive" || disabled === false) && onClick(group)
      }
      disabled={checkinMode !== "positive" && disabled}
    >
      <Face
        src={image}
        alt={title}
        isSelected={isSelected}
        scale={scale}
        disabled={disabled}
        checkinMode={checkinMode}
      />
    </Button>
  );
};

EmotionLevel.defaultProps = {
  isSelected: false,
  scale: 1,
};

EmotionLevel.propTypes = {
  group: checkInGroupPropType.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  scale: PropTypes.number,
};

export default memo(EmotionLevel);
