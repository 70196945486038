import React from 'react';
import styled from 'styled-components';
import negativeInfluences from '../negative-influences.svg';
import positiveInfluences from '../positive-influences.svg';

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  img {
    width: ${props => props.width};
  }
  span {
    text-align: center;
  }
`;

const SummaryBase = ({ image, value, width }) => {
  const rounded  = Math.round(value);
  const percent = `${rounded}%`;
  return (
    <SummaryContainer width={width}>
      <img src={image} />
      <span>{percent}</span>
    </SummaryContainer>
  );
};

const PositiveSummaryContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 2rem;
`;

const NegativeSummaryContainer = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`;

export const PositiveSummary = ({ value }) => {
  return (
    <PositiveSummaryContainer>
      <SummaryBase image={positiveInfluences} value={value} width="3rem" />
    </PositiveSummaryContainer>
  );
};

export const NegativeSummary = ({ value }) => {
  return (
    <NegativeSummaryContainer>
      <SummaryBase image={negativeInfluences} value={value} width="4rem" />
    </NegativeSummaryContainer>
  );
};

export const ContentRoot = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
