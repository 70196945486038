import React, { useEffect, useState } from "react";
import { get, post } from "../../../actions/api";
import { Section, ValLabel,TooLow } from "./Anonymity.styled";

const Anonymity = (props) => {
  const [segSizeSurvey, setSegSizeSurvey] = useState(5);
  const [samplesSizeSurvey, setSamplesSizeSurvey] = useState(5);

  const [segSizeCheckin, setSegSizeCheckin] = useState(5);
  const [samplesSizeCheckin, setSamplesSizeCheckin] = useState(5);

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    async function getSettings() {
      const data = await get("anonymity");
      const cats = data.settings;
      setSegSizeSurvey(
        cats.survey ? cats.survey.segment : data.defaults.survey.segment
      );
      setSamplesSizeSurvey(
        cats.survey ? cats.survey.samples : data.defaults.survey.samples
      );
      setSegSizeCheckin(
        cats.checkin ? cats.checkin.segment : data.defaults.checkin.segment
      );
      setSamplesSizeCheckin(
        cats.checkin ? cats.checkin.samples : data.defaults.checkin.samples
      );
    }
    getSettings();
  }, []);

  const save = async () => {
    setSaving(true);
    // two calls to make here
    await post("anonymity", {
      category: "survey",
      segment: segSizeSurvey,
      samples: samplesSizeSurvey,
    });
    await post("anonymity", {
      category: "checkin",
      segment: segSizeCheckin,
      samples: samplesSizeCheckin,
    });
    setTimeout(() => {
      setSaving(false);
    }, 500);
    return true;
  };

  return (
    <div className="section" style={{ paddingTop: 0 }}>
      <h1 className="is-size-3">Anonymity Settings</h1>
      <Section>
        <h1 className="is-size-5">Wellbeing Data System and Survey Data</h1>
				<p style={{fontSize:16, marginBottom:20, maxWidth:500,}}>Decide how small a sample you're happy to view data from, if fewer than this number have submitted data we will keep it hidden to protect people's anonymity.</p>
        <label className="label">Minimum number of people:</label>
        <ValLabel>{segSizeSurvey}</ValLabel>
        <input
          className="slider is-fullwidth"
          step="1"
          min="1"
          max="10"
          type="range"
          value={segSizeSurvey}
          onChange={(e) => {
            setSegSizeSurvey(e.target.value);
          }}
        />
				<p>{segSizeSurvey > 2 ? "We suggest new fewer than 3" : <TooLow>This is lower than our recommended level</TooLow>}</p>
        {/* <label className="label">Minimum number of data entries</label>
        <ValLabel>{samplesSizeSurvey}</ValLabel>
        <input
          className="slider is-fullwidth"
          step="1"
          min="2"
          max="10"
          type="range"
          value={samplesSizeSurvey}
          onChange={(e) => {
            setSamplesSizeSurvey(e.target.value);
          }}
        /> */}
      </Section>
      <Section>
        <h1 className="is-size-5">Daily Reflection Data</h1>
				<p style={{fontSize:16, marginBottom:20, maxWidth:500,}}>Decide how small a sample you're happy to view data from, if fewer than this number have submitted data we will keep it hidden to protect people's anonymity.</p>
        <label className="label">Minimum number of people:</label>
        <ValLabel>{segSizeCheckin}</ValLabel>
        <input
          className="slider is-fullwidth"
          step="1"
          min="2"
          max="10"
          type="range"
          value={segSizeCheckin}
          onChange={(e) => {
            setSegSizeCheckin(e.target.value);
          }}
        />
				<p>{segSizeCheckin > 2 ? "We suggest new fewer than 3" : <TooLow>This is lower than our recommended level</TooLow>}</p>

        {/* <label className="label">Minimum number of data entries</label>
        <ValLabel>{samplesSizeCheckin}</ValLabel>
        <input
          className="slider is-fullwidth"
          step="1"
          min="2"
          max="10"
          value={samplesSizeCheckin}
          type="range"
          onChange={(e) => {
            setSamplesSizeCheckin(e.target.value);
          }}
        /> */}
      </Section>
      <Section>
        <button className="button is-primary" onClick={save} disabled={saving}>
          {saving ? "Saving..." : "Save"}
        </button>
      </Section>
    </div>
  );
};

export default Anonymity;
