import styled from 'styled-components';


export const Wrapper = styled.a`
	display: inline-block;
	padding: 10px 20px;
	border: ${({ theme }) => theme.primary.colours.blueD} 1px solid;
	color: ${({ theme }) => theme.primary.colours.blueD};
	border-radius: 20px;
	
	&:hover{
		text-decoration: none;
		color: ${({ theme }) => theme.primary.colours.blueM};
	}
`;

export const Text = styled.span`
	text-decoration: none;
`;