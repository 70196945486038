import styled from 'styled-components';

export const Input = styled.input`
  border: 0;
  width: 100%;
  margin: 0 1rem;
  
  &:focus {
    outline: none;
  }
`;
