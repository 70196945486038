import React, { memo, useState } from 'react';
import moment from 'moment';
import LeftArrowIcon from '@ant-design/icons/lib/icons/LeftOutlined';
import RightArrowIcon from '@ant-design/icons/lib/icons/RightOutlined';
import { ContentRoot } from './MonthSelector.styled';

const MonthSelector = ({ startMonth, monthChanged }) => {
  const [month, setMonth] = useState(startMonth.startOf('month'));
  const currentMonth = moment().startOf('month');
  const isCurrentMonth = month.isSame(currentMonth);

  const incMonth = amount => {
    const newMonth = month.clone().add(amount, 'month');
    if (newMonth.isAfter(currentMonth)) {
      return;
    }

    setMonth(newMonth);
    monthChanged(newMonth);
  };

  const back = () => incMonth(-1);
  const forward = () => incMonth(1);

  return (
    <ContentRoot>
      <LeftArrowIcon onClick={back} />
      <span>{month.format('MMM, YYYY')}</span>
      {isCurrentMonth === false && <RightArrowIcon onClick={forward}/>}
    </ContentRoot>
  );
};

export default memo(MonthSelector);
