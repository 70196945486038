import React, { memo, useState } from 'react';
import { ReInviteForm, Title } from './Signup.styled';

import { post } from '../../actions/api';

const Gratitude = () => {
	const [sent, setSent] = useState(false);
	const [email, setEmail] = useState('');

	const send = async (event) => {
		if (!email) { return false }
		event.preventDefault();
		await post("user/reinviteself", { email });
		setSent(true);
	}

	return (
		<div className="has-background-white tile is-vertical is-4 box container" style={{ marginTop: 20 }}>
			<ReInviteForm sent={sent} onSubmit={send}>
				<Title>Add your work email below to resend your invitation to activate your account</Title>
				<div className="field">
					<div className="control has-icons-left has-icons-right">
						<input className="input" type="email" name="email" placeholder="Work Email Address" onChange={(e) => setEmail(e.target.value)} />
						<span className="icon is-small is-left">
							<i className="fas fa-envelope"></i>
						</span>
					</div>
				</div>
				<button className="button is-primary">
					Resend Invitation
				</button>
			</ReInviteForm>
			<div className="content">
				<Title style={{ marginBottom: 0 }}>Still can't see your invitation to activate your account?</Title>
				<ul>
					<li>Search for an email from hello@lumeapp.io</li>
					<li>Check your junk or spam folder</li>
					<li>If your company hasn't registered with LUME, contact <a href='mailto:hello@lumeapp.io'>hello@lumeapp.io</a> to discuss signing your company up</li>
				</ul>
			</div>
		</div>
	)
}

export default memo(Gratitude);