import React from 'react';
import { Text, SlideWrapper, SlideContent } from './Slides.styled';
import { ButtonWhite } from '../../../../components/common';
import VimeoPlayer from '@u-wave/react-vimeo';

export function Slide2(props) {

	return (
		<SlideWrapper>
			<SlideContent>
				<Text></Text>
				<Text>The Daily Reflection is a simple tool to help you do just that.</Text>
				<Text>Here is how it works...</Text>
				<VimeoPlayer
					style={{ width: 500, height: 281 }}
					responsive={true}
					video={566479834}
					// onEnd={finished}
					autoplay
				// paused={paused}
				// controls={false}
				// onPause={handlePlayerPause}
				// onPlay={handlePlayerPlay}
				/>
				<ButtonWhite onClick={props.nextSlide} style={{ width: "100%", maxWidth: 300  }}>Record A Reflection</ButtonWhite>
			</SlideContent>
		</SlideWrapper >
	)
}
