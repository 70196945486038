import React, { memo, useState, useEffect, useRef, createRef } from "react";
import { AddAnother, TextArea } from "./InputMulti.styled";

const InputMulti = (props) => {
  const [values, setValues] = useState([]);
  const [textAreaHeight, setTextAreaHeight] = useState(16);
  const elm = props.elm;
  const content = elm.content;
  const borderWidth = 1;
  let styles = null;
  if (elm.style) {
    styles = elm.style;
  }

	const myRefs = useRef([]);
	myRefs.current = values.map((element, i) => myRefs.current[i] ?? createRef());

  useEffect(() => {
		console.log(content.recall)
    let vals = [];
    const prevVals = props.latestInteractions[props.elm.id]
      ? JSON.parse(props.latestInteractions[props.elm.id].value)
      : [];
    const num =
      prevVals.length > content.inputNum ? prevVals.length : content.inputNum;
    for (let i = 0; i < num; i++) {
      if (i < content.inputNum || prevVals[i]) {
        vals.push(prevVals[i] && Number(content.recall) ? prevVals[i] : "");
      }
    }
    setValues(vals);
  }, [props.elm.id]);

	useEffect(()=>{
		console.log(myRefs.current)
		myRefs.current.forEach(ref=>{
			grow(ref)
		})
	},[values])

	const grow = (ref) => {
		ref.current.style.height = ref.current.scrollHeight + "px";
    console.log(ref.current.scrollHeight)
  };

  const textChange = (val, i) => {
    let newValues = [...values];
    newValues[i] = val;
    setValues(newValues);
  };

	const expandArea = (e) =>{
		console.log('here')
		e.target.style.height = e.target.scrollHeight + "px";
	}

  let out = values.map((v, i) => {
		
    return (
      <TextArea
        key={i}
        style={{ ...styles, height: textAreaHeight, borderWidth }}
        minHeight={content.height}
        placeholder={content.placeholder}
        onBlur={(e) => {
          props.recordInteraction(elm.id, values);
        }}
        value={values[i]}
        onChange={(e) => {
					expandArea(e);
					textChange(e.target.value, i)
				}}
				ref={myRefs.current[i]}
      />
    );
  });

  out.push(
    <AddAnother
      key="new1"
      onClick={() => {
        textChange("", values.length);
      }}
    >
      +
    </AddAnother>
  );

  return <div>{out}</div>;
};

export default memo(InputMulti);
