import React, { memo } from "react";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import ClockCircleOutlined from "@ant-design/icons/lib/icons/ClockCircleOutlined";
import Delete from "@ant-design/icons/lib/icons/DeleteFilled";
import Edit from "@ant-design/icons/lib/icons/EditFilled";
import { TitleLine, Title } from "../Day.styled";
import { Time } from "../CheckInDay/CheckInDay.styled";
import { IconContainer, ButtonContainer } from "./sectionTitle.styled";
import theme from "../../../../../theme";

const SectionTitle = ({ colour, text, time, deleteClicked, editClicked }) => {
  const timeText = time.format("h:mmA");
  return (
    <TitleLine>
      <div style={{ width: "calc(100% - 60px)" }}>
        <Title colour={colour}>{text}</Title>
        <Time>
          {timeText}
          <ClockCircleOutlined />
        </Time>
      </div>
      <ButtonContainer>
        {editClicked && (
          <IconContainer
            colour={theme.primary.colours.buttons.edit}
            onClick={editClicked}
          >
            <Edit />
          </IconContainer>
        )}
        {deleteClicked && (
          <IconContainer
            colour={theme.primary.colours.buttons.delete}
            onClick={deleteClicked}
          >
            <Delete />
          </IconContainer>
        )}
      </ButtonContainer>
    </TitleLine>
  );
};

SectionTitle.defaultProps = {
  colour: theme.primary.colours.heading,
};

SectionTitle.propTypes = {
  colour: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  time: momentPropTypes.momentObj.isRequired,
  deleteClicked: PropTypes.func,
};

export default memo(SectionTitle);
