import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { checkInItem as influencePropType } from '../../../propTypes';
import { ButtonRoot, ImageContainer, TermText, Image } from './InfluenceButton.styled';
import { Badge } from '../common.styled';

const InfluenceButton = ({ influence, onClick, isSelected, moodColour, badge, style }) => {
  const { term, id, colour } = influence;

  return (
    <ButtonRoot onClick={onClick} style={style}>
      {badge  != null ? <Badge>{badge}</Badge> : null}
      <ImageContainer isSelected={isSelected} colour={colour} selectedColour={moodColour}>
        <Image
          src={`https://api.lumeapp.io/api/term/${id}/icon.png`}
          alt={term}
          isSelected={isSelected}
        />
      </ImageContainer>
      <TermText>{term}</TermText>
    </ButtonRoot>
  );
};

InfluenceButton.defaultProps = {
  onClick: () => {},
  isSelected: false,
  badge: null,
  moodColour: null,
};

InfluenceButton.propTypes = {
  influence: influencePropType.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  moodColour: PropTypes.string,
  badge: PropTypes.number,
};

export default memo(InfluenceButton);
