import styled from "styled-components";
import { Link } from "react-router-dom";
import bubbles from "../../../../imgs/bubbles_white_square.svg";

export const DoneWrapper = styled.div``;

export const DoneBg = styled.div``;

export const TileBase = styled.div`
  width: 180px;
  height: 180px;
  font-size: 24px;
  border-radius: 10px;
  padding: 10px;
  background-color: ${(props) => props.backgroundColour.replace("##", "#")};
  background-image: url(${bubbles});
  background-size: 100% 100%;
  background-position: 50% 100%;
  transition: 1s;
  position: relative;
  &:hover {
    background-size: 110% 110%;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SuperTitle = styled.span`
  font-weight: normal;
  font-size: 1.1rem;
`;

export const Title = styled.span`
  font-weight: bold;
`;

export const ShowInToday = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  padding: 7px;
  z-index: 2;
  cursor: pointer;
`;

export const Inner = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.colour};
  font-size: 20px;
  z-index: 1;

  span {
    text-align: center;
  }

  &:hover {
    text-decoration: none;
    color: ${(props) => props.colour};
  }
`;
