import styled from 'styled-components';

export const IndexItemContainer = styled.div`
  padding: 5px 10px;
	cursor: pointer;
	background-color:#fff;
	border: ${({ theme }) => theme.primary.colours.blueMD} 2px solid;
	text-align: center;
	padding: 15px;
	margin: 5px;
	color: ${({ theme }) => theme.primary.colours.blueMD};
	border-radius: 5px;
	font-weight: bold;
	opacity: ${props => props.viewed ? .2 : 1};
`;

export const IndexContainer = styled.div`
	width: 100%;
`;

