import styled from 'styled-components';
import { TopNav } from '../../components/common';
import bubbles from '../../../imgs/bubbles_white_square_2.svg';

export const PageContent = styled.div`
	flex: 1 1 auto;
	display: flex;
  align-items: center;
  justify-content: flex-start;
	padding-top: 3rem;
	flex-direction: column;
`;

export const InputWrapper = styled.div`
	width: 400px;
	max-width: 100%;
	padding: 4rem .75rem;
	display: grid;
  gap: .75rem;
  align-items: center;
	background-color: ${({theme}) => theme.primary.colours.baseColours.gratitude};
	background-image: url(${bubbles});
	background-size: 100%;
	background-repeat: no-repeat;
	border-radius: 10px;
`;

export const SaveButton = styled.button`
  background-color: ${({theme}) => theme.primary.colours.primaryAction};
  color: white;
  width: 12rem;
  padding: .7rem;
  border-radius: 3rem;
  font-size: 1rem;
  font-weight: bold;
  justify-self: flex-end;
`;

export const DatePickerContainer = styled.div`
  width: 12rem;
`;

export const NavRoot = styled(TopNav)`
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 1rem;
  align-items: center;
`;

export const BoxTitle = styled.h3`
	color: white;
	text-align: center;
	margin-bottom: 1rem;
	font-weight: bold;
  font-size: 22px;
`;