import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { get } from "../../../actions/api";
import {
  NavTitle,
  PageRoot,
  TopNavContainer,
  ShadowBox,
} from "../../../components/common";
import {
  PageContent,
  Section,
  SectionTitle,
  Left,
  Right,
  Inner,
  KeyVal,
  Key,
  Val,
  B,
  Details,
  ListTitle,
  ListItem,
} from "./Overview.styled";

const Overview = () => {
  const history = useHistory();
  const [eng, setEng] = useState(null);
  useEffect(() => {
    async function getData() {
      const engagement = await get("reports/engagement");
      if (engagement.report) {
        setEng(engagement.report);
      }
    }
    getData();
  }, []);

  const getPercent = (num, from) => {
    return Math.round((num / from) * 100) + "%";
  };

  if (eng === null) {
    return null;
  }

  const toolNames = {
    checkin: "Daily Reflection",
    RAK: "Random Act Of Kindness",
    gratitude: "Gratitude",
  };

  const tools = eng.tools
    .slice(0, 5)
    .map((t, i) => (
      <ListItem key={i}>
        {toolNames[t.type] ? toolNames[t.type] : t.type}
      </ListItem>
    ));
  const courses = eng.courses
    .slice(0, 5)
    .map((t, i) => <ListItem key={i}>{t.type}</ListItem>);
  return (
    <PageRoot style={{ backgroundColor: "#fff" }}>
      <TopNavContainer>
        <NavTitle>LUME Engagement</NavTitle>
      </TopNavContainer>
      <PageContent>
        <Section>
          <SectionTitle>Activation Rate</SectionTitle>
          <ShadowBox>
            <Inner>
              <Left>
                <KeyVal>
                  <Key>Invited:</Key>
                  <Val>{eng.invitations}</Val>
                </KeyVal>
                <KeyVal>
                  <Key>Activated:</Key>
                  <Val>{eng.activations}</Val>
                </KeyVal>
                <Details>
                  <B>{getPercent(eng.activations, eng.invitations)}</B> of your
                  workforce have become a LUME user
                </Details>
              </Left>
              <Right>{getPercent(eng.activations, eng.invitations)}</Right>
            </Inner>
          </ShadowBox>
        </Section>
        <Section>
          <SectionTitle>Engagement Rate</SectionTitle>
          <ShadowBox>
            <Inner>
              <Left>
                <KeyVal>
                  <Key>Engaged users:</Key>
                  <Val>{eng.engagement}</Val>
                </KeyVal>
                <Details>
                  <B>{getPercent(eng.engagement, eng.invitations)}</B> of users
                  have engaged with LUME
                </Details>
              </Left>
              <Right>{getPercent(eng.engagement, eng.invitations)}</Right>
            </Inner>
          </ShadowBox>
        </Section>
      </PageContent>
      <PageContent>
        <Section>
          <SectionTitle>Learning Journey Engagement Rate</SectionTitle>
          <ShadowBox>
            <Inner>
              <Left>
                <KeyVal>
                  <Key>Total users:</Key>
                  <Val>{eng.courseTotal}</Val>
                </KeyVal>
                <Details>
                  <B>{getPercent(eng.courseTotal, eng.invitations)}</B> of users
                  have engaged with Learning Journeys
                </Details>
              </Left>
              <Right>{getPercent(eng.courseTotal, eng.invitations)}</Right>
            </Inner>
          </ShadowBox>
        </Section>
        <Section>
          <SectionTitle>Tools &amp; Techniques Engagement Rate</SectionTitle>
          <ShadowBox>
            <Inner>
              <Left>
                <KeyVal>
                  <Key>Total users:</Key>
                  <Val>{eng.toolTotal}</Val>
                </KeyVal>
                <Details>
                  <B>{getPercent(eng.toolTotal, eng.invitations)}</B> of users
                  have engaged with Tools &amp; Techniques
                </Details>
              </Left>
              <Right>{getPercent(eng.toolTotal, eng.invitations)}</Right>
            </Inner>
          </ShadowBox>
        </Section>
      </PageContent>
      <PageContent style={{ paddingBottom: 40 }}>
        <Section>
          <ListTitle>
            Top {eng.courses.length < 5 ? eng.courses.length : 5} Learning
            Journeys
          </ListTitle>
          <ShadowBox>
            <Inner>
              <ol style={{ margin: 0 }}>{courses}</ol>
            </Inner>
          </ShadowBox>
        </Section>
        <Section>
          <ListTitle>
            Top {eng.tools.length < 5 ? eng.tools.length : 5} Tools &amp;
            Techniques
          </ListTitle>
          <ShadowBox>
            <Inner>
              <ol style={{ margin: 0 }}>{tools}</ol>
            </Inner>
          </ShadowBox>
        </Section>
      </PageContent>
    </PageRoot>
  );
};

export default memo(Overview);
