import React from "react";
const ProgressBar = ({ percentComplete, style }) => {
  return (
    <div style={{ ...styles.wrapper, ...style }}>
      <div style={styles.label}>{Math.floor(percentComplete)}%</div>
      <div style={styles.background}>
        <div style={{ ...styles.line, width: `${percentComplete}%` }} />
      </div>
    </div>
  );
};
const styles = {
  wrapper: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    width: "100%",
  },
  label: {
    fontSize: 12,
    marginBottom: 4,
  },
  background: {
    backgroundColor: "#C6D2E1",
    width: "100%",
    height: 8,
    borderRadius: 4,
  },
  line: {
    backgroundColor: "#1D7F7A",
    borderRadius: 4,
    height: 8,
  },
};

export default ProgressBar;
