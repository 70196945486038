import React, { memo } from "react";
import { useHistory,useLocation  } from "react-router-dom";
import { Bottom, BottomText } from "./PositiveCheckin.styled";
import moment from "moment";
import TickItem from "../TickItem/TickItem";
import {
  Face,
  Button,
} from "../../../../DailyCheckIn/EmotionLevel/EmotionLevel.styled";
import positive from "../../../../DailyCheckIn/EmotionLevel/positive.svg";
import veryPositive from "../../../../DailyCheckIn/EmotionLevel/very_positive.svg";

const PositiveCheckin = ({ elm, firstScreenInteraction }) => {
  const history = useHistory();
	const location = useLocation();
  const clicked = () => {
    if (!elm.courseElmInteractions.length) {
      history.push(
        `/daily-reflection?checkinMode=positive&stateGroups=very%20positive,positive&date=${
          firstScreenInteraction
            ? moment(firstScreenInteraction).format("DDMMYY")
            : moment().format("DDMMYY")
        }&next=${location.pathname}`
      );
    } else {
      history.push(
        "/calendar-detail?selectedDate=" +
          moment(elm.courseElmInteractions[0].createdAt).format("YYMMDD")
      );
    }
  };

  return (
    <TickItem
      onClick={clicked}
      label="Record 1 Positive Reflection"
      complete={elm.courseElmInteractions.length}
      bottom={
        <Bottom>
          <BottomText>Something positive from today</BottomText>
          <Button>
            <Face
              src={positive}
              alt={"Positive"}
              scale={0.6}
              style={{ marginRight: -10 }}
            />
            <Face src={veryPositive} alt={"Very Positive"} scale={0.6} />
          </Button>
        </Bottom>
      }
    />
  );
};

export default memo(PositiveCheckin);
