import styled from 'styled-components';

export const MainScore = styled.div`
	font-size: 5.5rem;
	font-weight: 900;
	border-bottom: #eee 1px solid;
	text-align: center;
	color: ${({ theme }) => theme.primary.colours.greenL};
	padding:30px 0 30px 0;
	margin-bottom:20px;
`;

export const RangeScore = styled.div`
	text-align: center;
	font-size: 1.1rem;
	padding: 10px 0;
`;

export const Score = styled.span`
	color: ${({ theme }) => theme.primary.colours.greenL};
	font-weight: 900;
`;