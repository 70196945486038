import styled from 'styled-components';
import bubbles from '../../../imgs/bubbles_white_square_2.svg';

export const PageContent = styled.div`
	background-color: #6FB5F5;
	background-image: url(${bubbles});
	background-size: cover;
	background-repeat: no-repeat;
	display: block;
	flex: 1;
	width: 100%;
	text-align: center;
`;

export const Title = styled.h3`
	color: white;
	text-align: center;
	margin-bottom: 1rem;
	margin-top: 1rem;
`;

export const ButtonContainer = styled.div`
	margin-top: 20px;
`;

