import React, { Component, createContext } from "react";
import { api } from "../actions/api";

// Provider and Consumer are connected through their "parent" context
const MainContext = createContext();
let Provider = MainContext.Provider;
let Consumer = MainContext.Consumer;

// Provider will be exported wrapped in MainProvider component.
class MainProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      authChecked: false,
      MainView: "LoginScreen",
      errors: { login: "" },
      APIHost: "https://api.lumeapp.io",
    };
  }

  componentDidMount() {
    // get user data and check JWT is still valid. Might remove for offline...
    this.getUser();
    if (window.location.hostname === "localhost") {
      this.setState({ APIHost: "http://localhost:3003" });
    } else {
      this.setState({ APIHost: "https://api.lumeapp.io" });
    }
  }

  // ###################################### User / auth ######################################
  getUser = () => {
    let t = this;
    // first check cache / local storage
    api(
      "user/me",
      "GET",
      null,
      (d) => {
        if (!d.error && d.user) {
          t.setState({ user: d.user, authChecked: true });
        } else {
          t.setState({ user: null, authChecked: true });
        }
      },
      {
        fetch: "background",
        store: true,
      }
    );
  };

  login = (e, email, password) => {
    e.preventDefault();
    const t = this;
    t.setState({ loginError: "" });
    api(
      "user/login",
      "post",
      { email: email, password: password },
      async (response) => {
        if (response.error) {
          let errors = this.state.errors;
          errors.login = response.data
            ? response.data.error
              ? response.data.error
              : "Incorrect details"
            : "Incorrect details";
          t.setState({ errors });
        } else {
          if (response.token) {
            localStorage.setItem("JWT", response.token);
            // update user state without refetching
            t.setState({ user: response.user });
          }
        }
      }
    );
  };

  logout = async () => {
    localStorage.setItem("JWT", "");
    this.setState({ user: null });
  };

  render() {
    return (
      <Provider
        value={{
          user: this.state.user,
          logout: this.logout,
          login: this.login,
          errors: this.state.errors,
          authChecked: this.state.authChecked,
          APIHost: this.state.APIHost,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export { MainProvider, MainContext };

// I make this default since it will probably be exported most often.
export default Consumer;
