import axios from "axios";
export const api = (url, method, data, cb) => {
	const JWT = localStorage.getItem("JWT");

	const APIInDocker = false;

	let HOST;
	if (window.location.hostname === "localhost") {
		HOST = APIInDocker ? "http://localhost:3003" : "http://localhost:3000";
	} else if (window.location.hostname === "staging.lumeapp.io") {
		HOST = "https://api.lumeapp.io";
		// HOST = "https://sapi.lumeapp.io";
	} else {
		HOST = "https://api.lumeapp.io";
	}
	// HOST = "https://api.lumeapp.io";

	let options = {
		method: method,
		url: HOST + "/api/" + url,
		headers: {
			Authorization: "JWT " + JWT,
			client: "webapp",
		},
	};

	// use data for query params if it's a "get"
	if (method.toLowerCase() === "get" && data) {
		options.params = data;
	} else {
		options.data = data;
	}
	axios(options)
		.then(function (response) {
			if (cb) {
				return cb(response.data);
			} else {
				return true;
			}
		})
		.catch(function (error) {
			if (cb) {
				return cb({
					error,
					status: error.response ? error.response.status : null,
					data: error.response ? error.response.data : null,
				});
			} else {
				return false;
			}
		});
};

export function post(url, payload) {
	return new Promise((resolve, reject) => {
		api(url, "POST", payload, (data) => {
			if (data.error) {
				reject(data.error);
			} else {
				resolve(data);
			}
		});
	});
}

export function put(url, payload) {
	return new Promise((resolve, reject) => {
		api(url, "PUT", payload, (data) => {
			if (data.error) {
				reject(data.error);
			} else {
				resolve(data);
			}
		});
	});
}

export function get(url, query) {
	return new Promise((resolve, reject) => {
		api(url, "GET", query, (data) => {
			if (data.error) {
				reject(data.error);
			} else {
				resolve(data);
			}
		});
	});
}

export function del(url, query) {
	return new Promise((resolve, reject) => {
		api(url, "DELETE", query, (data) => {
			if (data.error) {
				reject(data.error);
			} else {
				resolve(data);
			}
		});
	});
}
