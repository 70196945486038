import React, { memo, useState } from "react";
import moment from "moment";
import momentPropTypes from "react-moment-proptypes";
import { NavRoot } from "./CheckInNav.styled";
import PropTypes from "prop-types";
import { SingleDatePicker } from "react-dates";
import { DatePickerContainer } from "./CheckInNav.styled";
import "./CheckInNav.styled";
import "../../../../css/datePicker.css";
import { NavTitle } from "../../../components/common";

const CheckInNav = ({ checkInDate, checkInDateChanged, checkinMode }) => {
  const [focused, setFocused] = useState(false);

  const heading =
    checkinMode === "brainDump" ? "Brain Dump" : "Daily Reflection";
  return (
    <NavRoot>
      <NavTitle>{heading}</NavTitle>
      <DatePickerContainer>
        <SingleDatePicker
          date={checkInDate}
          onDateChange={checkInDateChanged}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          numberOfMonths={1}
          displayFormat="dddd, Do MMM"
          isOutsideRange={(date) =>
            date.startOf("day").isAfter(moment().startOf("day"))
          }
          hideKeyboardShortcutsPanel={true}
          id="check_in_date"
        />
      </DatePickerContainer>
    </NavRoot>
  );
};

CheckInNav.propTypes = {
  checkInDate: momentPropTypes.momentObj.isRequired,
  checkInDateChanged: PropTypes.func.isRequired,
  actionText: PropTypes.string.isRequired,
  isPrimaryAction: PropTypes.bool.isRequired,
};

export default memo(CheckInNav);
