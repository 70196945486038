import React, { memo, useContext, useState } from "react";
import VimeoPlayer from "@u-wave/react-vimeo";
import { ActionButton } from "../../../../../components/common";
import { ButtonContainer } from "./Vimeo.styled";
import { post } from "../../../../../actions/api";
import { MainContext } from "../../../../../context/MainContext";

const Vimeo = (props) => {
  const ctx = useContext(MainContext);

  const [paused, setPaused] = useState(false);
  const [canContinue, setCanContinue] = useState(false);
  const [intRec, setIntRec] = useState(false);
  const [recordedPlay, setRecordedPlay] = useState(false);
  const elm = props.elm;

  if (!elm.content) {
    return <p>No video id</p>;
  }
  if (!elm.content.id) {
    return <p>No video id</p>;
  }

  const finished = async () => {
    if (elm.content.completeActivity) {
      await post("course/completerequired", {
        eventType: elm.content.completeActivity,
      });
    }
    if (elm.linkToScreenId) {
      setCanContinue(true);
    }
  };

  const handlePlayerPause = () => {
    setPaused(true);
  };

  const handlePlayerPlay = () => {
    setPaused(false);
  };

  const onContinueClick = () => {
    props.findScreen(elm.linkToScreenId);
  };

  const handleTimeUpdate = (e) => {
    if (e.percent > 0.95) {
      setCanContinue(true);
      if (!intRec) {
        setIntRec(true);
        props.recordInteraction(elm.id, true);
      }
      if (Number(elm.content.progressMarker) && !recordedPlay) {
        setRecordedPlay(true);
        post("event", {
          event: {
            eventType: "progressMk",
            eventData: {
              name: elm.content.progressMarkerName,
              courseId: props.course.id,
              courseTitle: props.course.title,
              elmId: elm.id,
            },
          },
        });
      }
    }
  };

  const videoId =
    ctx.user.cohort &&
    elm.content.cohortVideos &&
    elm.content.cohortVideos.filter((c) => c.cohortId === ctx.user.cohort)
      .length
      ? elm.content.cohortVideos.filter(
          (c) => c.cohortId === ctx.user.cohort
        )[0].videoId
      : elm.content.id;
  return (
    <div>
      <VimeoPlayer
        responsive={true}
        video={videoId}
        onEnd={finished}
        autoplay
        paused={paused}
        // controls={false}
        onPause={handlePlayerPause}
        onPlay={handlePlayerPlay}
        onTimeUpdate={handleTimeUpdate}
      />
      {elm.linkToScreenId !== null && (
        <ButtonContainer>
          <ActionButton
            disabled={
              !canContinue &&
              (!elm.content.skippable || !Number(elm.content.skippable))
            }
            onClick={onContinueClick}
          >
            Continue
          </ActionButton>
        </ButtonContainer>
      )}
    </div>
  );
};
{
  /* <script src="https://player.vimeo.com/api/player.js"></script> */
}
export default memo(Vimeo);
