import React from "react";
export const getGraph = (answers) => {
  const colours = {
    0: "#F9F9F9", // empty
    1: "#E96667", // 1-2
    2: "#FFA171", // 2-3
    3: "#F9C76C", // 3-4
    4: "#99DDCD", // 4-5
    5: "#44BFA2", // 5-6
    6: "#1d7f7a", // 6-7
  };

  let maxScore = 0;
  let totalScore = 0;
  Object.values(answers).forEach((a) => {
    if (a > maxScore) {
      maxScore = a;
    }
    totalScore += a;
  });

  let bars = [];
  let labels = [];
  let percentTotal = 0;
  let scoreTotal = 0;
  [1, 2, 3, 4, 5,6].forEach((answer) => {
    let score = 0;
    if (answers[answer]) {
      score = answers[answer];
    }
    let height = (score / maxScore) * 100 + "%";
    if (height === "0%") {
      height = 1;
    }
    bars.push(
      <div
        key={answer}
        style={{
          height,
          backgroundColor: colours[answer],
          left: (answer - 1) * 16.667 + "%",
        }}
      ></div>
    );

    labels.push(
      <div key={"label" + answer} style={{ left: (answer - 1) * 16.667 + "%" }}>
        {Math.round((score / totalScore) * 100)}%
      </div>
    );

    percentTotal += Math.round((score / totalScore) * 100);
    scoreTotal += score;
  });

  return (
    <div>
      <div className="cropper">{bars}</div>
      <div className="graphPopup">
        <div className="cropper">{bars}</div>
        <div className="labels">{labels}</div>
      </div>
    </div>
  );
};
