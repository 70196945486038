import React, { memo } from 'react';
import PropTypes from 'prop-types';
import PlusCircleFilled from '@ant-design/icons/lib/icons/PlusCircleFilled';
import { ToggleButton } from './RAK.styled';

const AddYourOwn = (props) => (
  <ToggleButton {...props}>
    <PlusCircleFilled style={{fontSize:28}} />
    <h5 style={{fontSize:18}}>Add Your Own</h5>
  </ToggleButton>
);

AddYourOwn.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default memo(AddYourOwn);
