import styled from 'styled-components';

export const xxx = styled.button`
  padding: 10px 50px;
	border-radius: 1.25em;
	color: white;
	font-size: 1.2em;
	background-color: ${({ theme }) => theme.primary.colours.blueMD};
	margin: 5px;
`;


