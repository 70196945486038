import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Title } from './HomeSection.styled';

const HomeSection = ({ title, body }) => {
  return (
    <div>
      <Title>{title}</Title>
      {body}
    </div>
  );
};

HomeSection.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
};

export default memo(HomeSection);
